window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.thanks_for_applying_modal = window.locales.am.careers.thanks_for_applying_modal || {};window.locales.am.careers.thanks_for_applying_modal = {...window.locales.am.careers.thanks_for_applying_modal, ...{
    "message_network_only": "በቅርቡ ከእኛ ምላሽ ይሰማሉ። የሪፈራል ማገናኛዎን በማጋራት ሽልማት ያግኙ፣ ከዚያም በመነሻ ትር ላይ በነፃ ክፍት ኮርሶቻችን ይደሰቱ!",           
    "action": "ዝጋ",           
    "message_other": "<p>ዝማኔዎችን በኢሜይል ይመልከቱ ወይም የእርስዎን {{brandName}} ዳሽቦርድ ያረጋግጡ።</p><p> በሚቀጥሉት ሳምንታት፣ ለአጭር የመግቢያ ቃለ መጠይቅ ግብዣ ሊደርስዎት ይችላል። መልካም ምኞት!</p>",
    "title": "ስላመለከቱ እናመሰግናለን።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.thanks_for_applying_modal = window.locales.ar.careers.thanks_for_applying_modal || {};window.locales.ar.careers.thanks_for_applying_modal = {...window.locales.ar.careers.thanks_for_applying_modal, ...{
    "action": "قريب",
    "message_network_only": "ستستمع إلينا قريبا. كسب مكافأة من خلال تقاسم رابط الإحالة الخاص بك، ثم التمتع لدينا دورات مفتوحة مجانا على علامة التبويب الصفحة الرئيسية!",
    "message_other": "<p style=\";text-align:right;direction:rtl\">ترقب التحديثات عبر البريد الإلكتروني أو تحقق من لوحة تحكم {{brandName}} .</p><p style=\";text-align:right;direction:rtl\"> في الأسابيع المقبلة، قد تتلقى دعوة لإجراء مقابلة قبول قصيرة. حظ سعيد!</p>",
    "title": "شكرا لك على التقديم."
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.thanks_for_applying_modal = window.locales.en.careers.thanks_for_applying_modal || {};window.locales.en.careers.thanks_for_applying_modal = {...window.locales.en.careers.thanks_for_applying_modal, ...{
    "message_network_only": "You’ll hear back from us soon. Earn a reward by sharing your referral link, then enjoy our free open courses on the Home tab!",
    "message_other": "<p>Watch for updates by email or check your {{brandName}} dashboard.</p><p>In the coming weeks, you may receive an invitation for a brief Admissions interview. Good luck!</p>",
    "action": "Close",
    "title": "Thank you for applying."
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.thanks_for_applying_modal = window.locales.es.careers.thanks_for_applying_modal || {};window.locales.es.careers.thanks_for_applying_modal = {...window.locales.es.careers.thanks_for_applying_modal, ...{
    "action": "Cerca",
    "message_network_only": "Pronto recibirás noticias tuyas. ¡Gane una recompensa al compartir su enlace de referencia, luego disfrute de nuestros cursos abiertos gratuitos en la pestaña Inicio!",
    "message_other": "<p>Esté atento a las actualizaciones por correo electrónico o consulte su panel de control de {{brandName}} .</p><p> En las próximas semanas, es posible que reciba una invitación para una breve entrevista de admisión. ¡Buena suerte!</p>",
    "title": "Gracias por aplicar."
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.thanks_for_applying_modal = window.locales.it.careers.thanks_for_applying_modal || {};window.locales.it.careers.thanks_for_applying_modal = {...window.locales.it.careers.thanks_for_applying_modal, ...{
    "action": "Chiudi",           
    "message_network_only": "Sentirete subito da noi. Guadagna una ricompensa condividendo il tuo link di referral, quindi goditi i nostri corsi gratuiti aperti nella scheda Home!",
    "message_other": "<p>Controlla gli aggiornamenti via email o controlla la dashboard di {{brandName}} .</p><p> Nelle prossime settimane potresti ricevere un invito per un breve colloquio di ammissione. Buona fortuna!</p>",
    "title": "Grazie per aver presentato domanda."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.thanks_for_applying_modal = window.locales.zh.careers.thanks_for_applying_modal || {};window.locales.zh.careers.thanks_for_applying_modal = {...window.locales.zh.careers.thanks_for_applying_modal, ...{
    "action": "关闭",           
    "message_network_only": "你很快就会收到我们的答复。分享推荐链接可获得奖励，然后在主页上享受我们的免费开放课程！",           
    "message_other": "<p>通过电子邮件关注更新或检查您的{{brandName}}仪表板。</p><p>在接下来的几周内，您可能会收到简短的招生面试邀请。祝你好运！</p>",
    "title": "感谢您的申请。"
}
};