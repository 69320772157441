window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.deferral_link = window.locales.am.careers.deferral_link || {};window.locales.am.careers.deferral_link = {...window.locales.am.careers.deferral_link, ...{
    "quantic_online_deferral": "የኳንቲክ ኦንላይን መዘግየት",           
    "you_are_registered": "ለቡድን <b>{{fromCohortTitle}} ስብስብ</b> ተመዝግበዋል። ቦታዎን ለወደፊት ክፍል ለማዘዋወር፣ እባክዎ ከዚህ በታች ያለውን ፎርም ይሙሉ፣ በመጀመርያ ከተመዘገቡ በአንድ አመት ውስጥ የመጀመሪያ ቀንዎን ይምረጡ።",           
    "would_like_to_join": "መቀላቀል እፈልጋለሁ...",           
    "billing_confirmation": "ወርሃዊ ክፍያ አሁን ላይ እንደታገደ እና በ {{trialEndDate}} እንደገና እንደሚጀምር ተረድቻለሁ።",           
    "submit": "ያስገቡ",           
    "questions_contact_us": "ጥያቄዎች? በ <a href=\"mailto:{{supportEmail}}\">{{supportEmail}}</a> ላይ ያግኙን።",           
    "thanks_for_registering": "በ {{toCohortStartDate}} ላይ ለሚጀመረው <b>{{toCohortTitle}} ቡድን</b> ስለተመዘገቡ እናመሰግናለን! ክፍሉ ከሚጀምርበት ቀን ከሶስት ሳምንታት በፊት የኢሜይል ማሳሰቢያ ይደርስዎታል።",           
    "calendar_event_description": "ክፍል ለ {{cohortTitle}} ቡድን ይጀምራል።"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.deferral_link = window.locales.ar.careers.deferral_link || {};window.locales.ar.careers.deferral_link = {...window.locales.ar.careers.deferral_link, ...{
    "quantic_online_deferral": "Quantic على الانترنت تأجيل",
    "you_are_registered": "أنت مسجل في <b>{{fromCohortTitle}} الفوج</b> . لتأجيل مكانك إلى فصل دراسي مستقبلي ، يرجى إكمال النموذج أدناه ، وتحديد تاريخ البدء خلال سنة واحدة من تسجيلك الأصلي.",
    "would_like_to_join": "أود الانضمام ...",
    "billing_confirmation": "أدركت أن فواتير التعليم الشهرية قد تم تعليقها الآن وسيتم إعادة التشغيل في {{trialEndDate}} .",
    "submit": "إرسال",
    "questions_contact_us": "أسئلة؟ اتصل بنا على <a href=\"mailto:{{supportEmail}}\">{{supportEmail}}</a>",
    "calendar_event_description": "يبدأ الفصل {{cohortTitle}} لـ {{cohortTitle}} الفوج.",
    "thanks_for_registering": "نشكرك على التسجيل في المجموعة <b>{{toCohortTitle}}</b> ، والتي تبدأ في {{toCohortStartDate}} ! ستتلقى تذكيرًا عبر البريد الإلكتروني قبل ثلاثة أسابيع من تاريخ بدء الفصل."
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.deferral_link = window.locales.en.careers.deferral_link || {};window.locales.en.careers.deferral_link = {...window.locales.en.careers.deferral_link, ...{
    "quantic_online_deferral": "Quantic Online Deferral",
    "you_are_registered": "You are registered for the <b>{{fromCohortTitle}} Cohort</b>. To defer your place to a future class, please complete the form below, selecting a start date within one year of your original registration.",
    "would_like_to_join": "I would like to join...",
    "billing_confirmation": "I understand monthly tuition billing is now suspended and will restart on {{trialEndDate}}.",
    "submit": "Submit",
    "questions_contact_us": "Questions? Contact us at <a href=\"mailto:{{supportEmail}}\">{{supportEmail}}</a>",
    "thanks_for_registering": "Thanks for registering for the <b>{{toCohortTitle}} Cohort</b>, which begins on {{toCohortStartDate}}! You will receive an email reminder three weeks prior to the class start date.",
    "calendar_event_description": "Class begins for the {{cohortTitle}} Cohort."
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.deferral_link = window.locales.es.careers.deferral_link || {};window.locales.es.careers.deferral_link = {...window.locales.es.careers.deferral_link, ...{
    "quantic_online_deferral": "Aplazamiento en línea cuántico",
    "you_are_registered": "Estás registrado en la <b>cohorte {{fromCohortTitle}}</b> . Para diferir su lugar a una clase futura, complete el formulario a continuación, seleccionando una fecha de inicio dentro de un año de su registro original.",
    "would_like_to_join": "Me gustaría unirme...",
    "billing_confirmation": "Entiendo que la facturación mensual de la matrícula ahora está suspendida y se reiniciará el {{trialEndDate}} .",
    "submit": "Enviar",
    "questions_contact_us": "Preguntas? Contáctenos en <a href=\"mailto:{{supportEmail}}\">{{supportEmail}}</a>",
    "calendar_event_description": "La clase comienza para la cohorte {{cohortTitle}} .",
    "thanks_for_registering": "¡Gracias por registrarse en el grupo <b>{{toCohortTitle}}</b> , que comienza el {{toCohortStartDate}} ! Recibirá un recordatorio por correo electrónico tres semanas antes de la fecha de inicio de la clase."
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.deferral_link = window.locales.it.careers.deferral_link || {};window.locales.it.careers.deferral_link = {...window.locales.it.careers.deferral_link, ...{
    "quantic_online_deferral": "Differimento online Quantic",
    "you_are_registered": "Sei registrato per la <b>{{fromCohortTitle}} Coorte</b> . Per rinviare il tuo posto a una lezione futura, compila il modulo sottostante, selezionando una data di inizio entro un anno dalla registrazione originale.",
    "would_like_to_join": "Mi piacerebbe partecipare...",
    "billing_confirmation": "Comprendo che la fatturazione mensile delle lezioni è ora sospesa e ricomincerà il {{trialEndDate}} .",
    "submit": "Invia",
    "questions_contact_us": "Domande? Contattaci a <a href=\"mailto:{{supportEmail}}\">{{supportEmail}}</a>",
    "calendar_event_description": "La {{cohortTitle}} inizia per la {{cohortTitle}} Coorte.",
    "thanks_for_registering": "Grazie per <b>{{toCohortTitle}}</b> registrato alla <b>coorte {{toCohortTitle}}</b> , che inizia il giorno {{toCohortStartDate}} ! Riceverai un'e-mail di promemoria tre settimane prima della data di inizio del corso."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.deferral_link = window.locales.zh.careers.deferral_link || {};window.locales.zh.careers.deferral_link = {...window.locales.zh.careers.deferral_link, ...{
    "quantic_online_deferral": "Quantic网络延迟",           
    "you_are_registered": "你已注册<b>{{fromCohortTitle}}组群</b>。如果你想将自己的名额推迟到以后的课程中，请填写以下表格，选择最初注册期一年内的开始日期。",           
    "would_like_to_join": "我想要加入...",           
    "billing_confirmation": "我知道每月的学费计费现处于搁置状态，并将在{{trialEndDate}}重新开始。",           
    "submit": "提交",           
    "questions_contact_us": "有疑问？通过<a href=\"mailto:{{supportEmail}}\">{{supportEmail}}</a>联系我们",           
    "calendar_event_description": "课程从{{cohortTitle}}组群开始。",           
    "thanks_for_registering": "感谢注册<b>{{toCohortTitle}}组群</b>，从{{toCohortStartDate}}开始！在开课前三周，你会收到一封电子邮件提醒。"           
}
};