window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.more_about_you_form = window.locales.am.careers.edit_career_profile.more_about_you_form || {};window.locales.am.careers.edit_career_profile.more_about_you_form = {...window.locales.am.careers.edit_career_profile.more_about_you_form, ...{
    "add_a_skill": "ችሎታ ያክሉ",           
    "add_an_award": "ሽልማት ወይም ፍላጎት አክል",           
    "awards_and_interests": "ሽልማቶች እና ፍላጎቶች",           
    "awards_and_interests_please_list": "እባክዎ 2 ወይም ከዚያ በላይ ሽልማቶችን ወይም ፍላጎቶችን እንደ አስፈላጊት ቅደም ተከተል ይዘርዝሩ።",           
    "awards_and_interests_please_list_non_english": "እባክዎን እንደ አስፈላጊነቱ 2 ወይም ከዚያ በላይ ሽልማቶችን ወይም ፍላጎቶችን ዘርዝሩ። እባኮትን በእንግሊዘኛ መልሱ።",           
    "awards_and_interests_desc": "እባክዎ 2 ወይም ከዚያ በላይ ሽልማቶችን ወይም ፍላጎቶችን ይዘርዝሩ። ከፍተኛ ደረጃ ያላቸው ንጥሎች በይበልጥ በጉልህ ይታያሉ።",           
    "awards_and_interests_examples": "ምሳሌዎች፦ የሳምንቱ ጀማሪ የፍጻሜ ተፋላሚ፣ ተፎካካሪ ፖከር ተጫዋች፣ ወዘተ.",           
    "my_attributes": "የእኔ ባህሪያት",           
    "my_attributes_desc": "እርስዎን በተሻለ ሁኔታ የሚገልፁትን 3 ቃላት ይምረጡና ደረጃ ይስጡ። ከላይ ያለው በጣም ገላጭ ነው።",           
    "my_motivations": "የእኔ ተነሳሽነቶች",           
    "my_motivations_desc": "እርስዎን በተሻለ ሁኔታ የሚገልፁትን 3 ተነሳሽነቶች ይምረጡና ደረጃ ይስጡ። ከላይ ያለው በጣም ገላጭ ነው።",           
    "my_strengths": "የእኔ ጥንካሬዎች",           
    "my_strengths_desc": "የስራ ቦታዎን ጥንካሬዎች በተሻለ ሁኔታ የሚገልፁትን 3 ባህሪያት ይምረጡ እና ደረጃ ይስጡ። ከላይ ያለው በጣም ገላጭ ነው።",           
    "hispanic_or_latino": "ሂስፓኒክ ወይስ ላቲኖ ነዎት?",           
    "primary_reason_for_applying_post_application": "ለ {{brandName}} {{programType}} ያመለከቱበት ዋና ምክንያት ምንድን ነው?",           
    "other_primary_reason_placeholder": "ዋና ምክንያት (other)"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.more_about_you_form = window.locales.ar.careers.edit_career_profile.more_about_you_form || {};window.locales.ar.careers.edit_career_profile.more_about_you_form = {...window.locales.ar.careers.edit_career_profile.more_about_you_form, ...{
    "add_a_skill": "إضافة مهارة",           
    "add_an_award": "إضافة جائزة أو اهتمام",           
    "awards_and_interests": "الجوائز والاهتمامات",           
    "awards_and_interests_examples": "أمثلة: ابدأ نهائي عطلة نهاية الأسبوع، لاعب بوكر تنافسي، إلخ",           
    "my_attributes": "سماتي",           
    "my_motivations": "دوافعي",           
    "my_strengths": "نقاط قوتي",           
    "hispanic_or_latino": "هل انت اسباني ام لاتيني؟",
    "awards_and_interests_desc": "يرجى ذكر 2 أو أكثر من جوائز أو المصالح التي ترغب في تسليط الضوء على أصحاب العمل. سيتم عرض العناصر ذات مرتبة أكثر بروزا.",
    "my_attributes_desc": "اختيار وترتيب الكلمات 3 التي تحدد لك أفضل. الجزء العلوي هي الأكثر حاسمة.",
    "my_motivations_desc": "اختيار وترتيب 3 الدوافع التي تحدد لك أفضل. الجزء العلوي هي الأكثر حاسمة.",
    "my_strengths_desc": "اختيار وترتيب الصفات 3 التي تحدد أفضل نقاط القوة الخاصة بك في مكان العمل. الجزء العلوي هي الأكثر حاسمة.",
    "awards_and_interests_please_list": "يرجى ذكر 2 أو أكثر من جوائز أو المصالح التي ترغب في تسليط الضوء، في الترتيب من حيث الأهمية.",
    "awards_and_interests_please_list_non_english": "الرجاء سرد جائزتين أو أكثر أو أكثر من الاهتمامات التي ترغب في إبرازها ، بترتيب الأهمية. الرجاء الإجابة باللغة الإنجليزية.",
    "other_primary_reason_placeholder": "السبب الرئيسي (الآخر)",
    "primary_reason_for_applying_post_application": "ما هو السبب الرئيسي الذي طبقته على {{brandName}} {{programType}} ؟"
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.more_about_you_form = window.locales.en.careers.edit_career_profile.more_about_you_form || {};window.locales.en.careers.edit_career_profile.more_about_you_form = {...window.locales.en.careers.edit_career_profile.more_about_you_form, ...{
    "add_a_skill": "add a skill",
    "add_an_award": "add an award or interest",
    "awards_and_interests": "Awards & Interests",
    "awards_and_interests_please_list": "Please list 2 or more awards or interests you'd like to highlight, in order of importance.",
    "awards_and_interests_please_list_non_english": "Please list 2 or more awards or interests you'd like to highlight, in order of importance. Please answer in English.",
    "awards_and_interests_desc": "Please list 2 or more awards or interests you’d like to highlight to employers. High rank items will be shown more prominently.",
    "awards_and_interests_examples": "Examples: Startup Weekend Finalist, Competitive poker player, etc.",
    "my_attributes": "My Attributes",
    "my_attributes_desc": "Choose and rank the 3 words that best define you. The top being the most defining.",
    "my_motivations": "My Motivations",
    "my_motivations_desc": "Choose and rank the 3 motivations that best define you. The top being the most defining.",
    "my_strengths": "My Strengths",
    "my_strengths_desc": "Choose and rank the 3 attributes that best define your workplace strengths. The top being the most defining.",
    "hispanic_or_latino": "Are you Hispanic or Latino?",
    "primary_reason_for_applying_post_application": "What is the primary reason you applied to the {{brandName}} {{programType}}?",
    "other_primary_reason_placeholder": "Primary reason (other)"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.more_about_you_form = window.locales.es.careers.edit_career_profile.more_about_you_form || {};window.locales.es.careers.edit_career_profile.more_about_you_form = {...window.locales.es.careers.edit_career_profile.more_about_you_form, ...{
    "add_a_skill": "añadir una competencia",           
    "add_an_award": "añadir un premio o interés",           
    "awards_and_interests": "Premios e intereses",           
    "awards_and_interests_examples": "Ejemplos: finalista del fin de semana de nuevas empresas, jugador de póquer competitivo, etc.",           
    "my_attributes": "Mis atributos",           
    "my_motivations": "Mis motivaciones",           
    "my_strengths": "Mis fortalezas",           
    "hispanic_or_latino": "¿Eres hispano o latino?",
    "awards_and_interests_desc": "Por favor liste 2 o más premios o intereses que le gustaría destacar a los empleadores. artículos de alto rango se mostrarán de manera más prominente.",
    "my_attributes_desc": "Elegir y clasificar las 3 palabras que mejor lo definen. La parte superior es el más definitorio.",
    "my_motivations_desc": "Elegir y clasificar los 3 motivaciones que mejor lo definen. La parte superior es el más definitorio.",
    "my_strengths_desc": "Elegir y clasificar los 3 atributos que mejor definen sus puntos fuertes del lugar de trabajo. La parte superior es el más definitorio.",
    "awards_and_interests_please_list": "Por favor, enumere 2 o más premios o intereses que le gustaría poner de relieve, en orden de importancia.",
    "awards_and_interests_please_list_non_english": "Enumere 2 o más premios o intereses que le gustaría destacar, en orden de importancia. Por favor responda en inglés.",
    "other_primary_reason_placeholder": "Razón principal (otro)",
    "primary_reason_for_applying_post_application": "¿Cuál es la razón principal por la que aplicó a {{brandName}} {{programType}} ?"
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.more_about_you_form = window.locales.it.careers.edit_career_profile.more_about_you_form || {};window.locales.it.careers.edit_career_profile.more_about_you_form = {...window.locales.it.careers.edit_career_profile.more_about_you_form, ...{
    "add_a_skill": "aggiungi una competenza",           
    "add_an_award": "aggiungi un interesse o un conseguimento",           
    "awards_and_interests": "Interessi e conseguimenti",           
    "awards_and_interests_please_list": "Per favore, elenca in ordine di importanza 2 o più interessi o conseguimenti che ti piacerebbe mettere in evidenza",           
    "awards_and_interests_please_list_non_english": "Elenca 2 o più premi o interessi che desideri evidenziare, in ordine di importanza. Si prega di rispondere in inglese.",           
    "awards_and_interests_desc": "Per favore, elenca 2 o più interessi o conseguimenti da portare all'attenzione dei datori di lavoro. Saranno mostrati in modo predominante gli elementi di rango più alto.",           
    "awards_and_interests_examples": "Esempi: Finalista Startup Weekend, Giocatore di poker professionista, ecc.",           
    "my_attributes": "I miei aggettivi",           
    "my_attributes_desc": "Scegli e ordina le 3 parole che ti descrivono meglio. La prima è quella che ti caratterizza di più.",           
    "my_motivations": "Le mie motivazioni",           
    "my_motivations_desc": "Scegli e ordina le 3 motivazioni che ti descrivono meglio. La prima è quella che ti caratterizza di più.",           
    "my_strengths": "I miei punti di forza",           
    "my_strengths_desc": "Scegli e ordina le 3 caratteristiche che descrivono meglio i tuoi punti di forza sul lavoro. La prima è quella che ti caratterizza di più.",           
    "hispanic_or_latino": "Sei Ispanico o Latino americano?",           
    "other_primary_reason_placeholder": "Ragione principale (altro)",           
    "primary_reason_for_applying_post_application": "Qual è il motivo principale che hai applicato a {{brandName}} {{programType}} ?"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.more_about_you_form = window.locales.zh.careers.edit_career_profile.more_about_you_form || {};window.locales.zh.careers.edit_career_profile.more_about_you_form = {...window.locales.zh.careers.edit_career_profile.more_about_you_form, ...{
    "add_a_skill": "添加技能",           
    "add_an_award": "添加奖项或兴趣",           
    "awards_and_interests": "奖项和兴趣",           
    "awards_and_interests_examples": "例：创业周末决赛选手，优秀扑克玩家，等等",           
    "my_attributes": "我的特征",           
    "my_motivations": "我的动力",           
    "my_strengths": "我的优势",           
    "hispanic_or_latino": "你是否为西班牙裔或拉丁裔？",           
    "awards_and_interests_desc": "请列出2项或以上你希望雇主注重的奖励或利益。按重要性排列显示。",           
    "my_attributes_desc": "选择3个与你自身个性最符合的词语并排序。按由高到低的程度排序。",           
    "my_motivations_desc": "选择3个与你自身最符合的动机并排序。按由高到低的程度排序。",           
    "my_strengths_desc": "选择3个最能体现你工作优势的属性并排序。按由高到低的程度排序。",           
    "awards_and_interests_please_list": "请列出2项或以上你看重的奖励或利益，按重要性排序。",           
    "awards_and_interests_please_list_non_english": "请列出2项或以上你看重的奖励或利益，按重要性排序。请以英文回答。",           
    "other_primary_reason_placeholder": "主要原因（其他）",           
    "primary_reason_for_applying_post_application": "你申请{{brandName}} {{programType}}的主要原因是？"           
}
};