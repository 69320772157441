window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.field_options = window.locales.am.careers.field_options || {};window.locales.am.careers.field_options = {...window.locales.am.careers.field_options, ...{
    "other": "ሌላ",           
    "data_analysis": "የቢዝነስ ትንተና / ኢንተለጀንስ",           
    "consultant": "የማኔጅመንት ማማከር / ስትራቴጂ",           
    "data_science": "የውሂብ ሳይንስ",           
    "design": "ንድፍ",           
    "engineering": "ምህንድስና",           
    "finance": "ፋይናንስ",           
    "information_technology": "ኢንፎርሜሽን ቴክኖሎጂ (አይቲ)",           
    "general_management": "አስተዳደር / ክወናዎች",           
    "marketing": "ማርኬቲንግ",           
    "product_management": "የምርት አስተዳደር",           
    "research": "ምርምር",           
    "sales": "የሽያጭ / የቢዝነስ ልማት",           
    "writing": "ተይግቡቦቶች / መፃፍ",           
    "medical_services": "የህክምና አገልግሎቶች",           
    "teaching_education": "ማስተማር / ትምህርት",           
    "permanent": "ቋሚ",           
    "part_time": "የትርፍ ጊዜ",           
    "contract": "ውል",           
    "internship": "ኢንተርንሺፕ (ልምምድ)",           
    "ux_ui": "UX / UI",           
    "art_creative": "አርክቴክቸር / አርት",           
    "graphic_design": "ግራፊክ ዲዛይን",           
    "software_dev_front_end": "የሶፍትዌር ልማት (front-end)",           
    "software_dev_back_end": "የሶፍትዌር ልማት (back-end)",           
    "software_dev_full_stack": "የሶፍትዌር ልማት (full-stack)",           
    "qa": "QA",           
    "devops": "DevOps",           
    "hardware": "የኤሌክትሪካል ምህንድስና",           
    "chemical": "የኬሚካል ምህንድስና",           
    "civil": "የሲቪል ምህንድስና",           
    "mechanical": "የሜካኒካል ምህንድስና",           
    "aerospace": "የኤሮስፔስ ምህንድስና",           
    "biomedical": "የባዮሜዲካል ምህንድስና",           
    "corporate_banking": "የኢንቨስትመንት ባንኪንግ",           
    "investment": "ኢንቨስት ማድረግ",           
    "trading": "ግብይት",           
    "fpa_accounting_audit": "FP&A / አካውንቲንግ / ቁጥጥር",           
    "wealth_management": "የሀብት አስተዳደር",           
    "risk_audit": "ስጋት / ኦዲት",           
    "senior_management": "ከፍተኛ አስተዳደር",           
    "operations": "ክወናዎች / የፕሮጀክት አስተዳደር",           
    "human_resources": "የሰው ሀብት / ተሰጥዖ ማግኛ",           
    "legal": "ህጋዊ",           
    "logistics_supply_chain": "ሎጂስቲክስ / የአቅርቦት ሰንሰለት",           
    "administrative": "አስተዳደራዊ",           
    "account_executive": "የሂሳብ ስራ አስፈፃሚ",           
    "account_manager": "የባንክ ስራ አስኪያጅ",           
    "customer_success": "የደንበኛ ስኬት",           
    "sales_engineer": "የሽያጭ መሀንዲስ",           
    "sales_development_rep": "የሽያጭ ልማት ተወካይ",           
    "consulting": "ማማከር",           
    "consumer_products": "የሸማቾች ምርቶች",           
    "media": "ሚዲያ / መዝናኛ",           
    "financial": "የፋይናንስ አገልግሎቶች",           
    "health_care": "የጤና ጥበቃ",           
    "manufacturing": "ማምረት",           
    "nonprofit": "ለትርፍ ያልተቋቋመ / መንግስት",           
    "retail": "ችርቻሮ / ግብይት",           
    "real_estate": "ሪል ስቴት",           
    "technology": "ቴክኖሎጂ",           
    "education": "ትምህርት",           
    "energy": "ኢነርጂ",           
    "government": "መንግስት",           
    "military": "ወታደራዊ",           
    "statistics": "ስታትስቲክስ",           
    "finance_and_accounting": "ፋይናንስ እና አካውንቲንግ",           
    "strategy_and_innovation": "ስትራቴጂ እና ፈጠራ",           
    "entrepreneurship": "ስራ ፈጣሪነት",           
    "economics": "ኢኮኖሚክስ",           
    "operations_management": "የክወናዎች አስተዳደር",           
    "management_and_leadership": "አስተዳደር እና አመራር",           
    "creative": "ፈጠራ",           
    "competitive": "ተወዳዳሪ",           
    "conscientious": "ህሊና ያለው",           
    "loyal": "ታማኝ",           
    "resilient": "የሚቋቋም",           
    "optimistic": "ብሩህ አመለካከት ያለው",           
    "driven": "ታታሪ",           
    "flexible": "ተለዋዋጭ ነኝ",           
    "flexible_attribute": "ተለዋዋጭ",           
    "balanced": "ሚዛናዊ",           
    "persuasive": "አሳማኝ",           
    "compassionate": "ርሁሩህ",           
    "courageous": "ደፋር",           
    "motivating": "የሚያነሳሳ",           
    "meticulous": "ጥንቁቅ",           
    "high_energy": "ከፍተኛ-ኃይል",           
    "results": "ውጤቶች / አሸናፊዎች",           
    "helping_others": "ሌሎችን መርዳት",           
    "career_advancement": "የሙያ እድገት",           
    "innovation": "ፈጠራ / ግኝት",           
    "fellowship": "ህብረት",           
    "personal_growth": "የግል እድገት",           
    "respect": "አክብሮት / እውቅና",           
    "security": "ሴኪውሪቲ",           
    "problem_solving": "ችግር አፈታት",           
    "leadership": "አመራር",           
    "business_acumen": "የቢዝነስ ችሎታ",           
    "professionalism": "ሙያዊነት",           
    "entrepreneurial_mindset": "የንግድ አገናኝ",           
    "relationship_building": "የግንኙነት ግንባታ",           
    "work_ethic": "የስራ ስነ-ምይግቡር",           
    "design_aesthetic": "የንድፍ ውበት",           
    "communication": "ግንኙነት",           
    "resourcefulness": "ብዙ ችሎታ የያለው",           
    "best_in_class": "በክፍል ውስጥ ምርጥ",           
    "fast_paced": "ፈጣን-እንቅስቃሴ ያለው",           
    "hierarchical": "ተዋረድ ያለው",           
    "merit_based": "ብቃት-ተኮር",           
    "stable": "የተረጋጋ / የተቋቋመ",           
    "entrepreneurial": "የንግድ አገናኝ",           
    "results_oriented": "ውጤቶች-ተኮር",           
    "harmonious": "ቅንጅታዊ",           
    "flat_structured": "ወጥ-የተዋቀረ",           
    "mission_driven": "በተልዕኮ የሚመራ",           
    "bootstrapped": "የተጠላለፈ",           
    "seed": "ዘር",           
    "series_a": "ተከታታይ ኤ",           
    "series_b_plus": "ተከታታይ ቢ+",           
    "public": "የህዝብ",           
    "private": "የግል",           
    "rather_not_specify": "ባልገልፅ ይሻላል",           
    "lesson_than_1_million": "< $1 ሚሊዮን",           
    "1_to_5_million": "$1-$5 ሚሊዮን",           
    "5_to_50_million": "$5-$50 ሚሊዮን",           
    "50_to_500_million": "$50-$500 ሚሊዮን",           
    "more_than_500_million": "> $500 ሚሊዮን",           
    "1-24": "1-24",           
    "25-99": "25-99",           
    "100-499": "100-499",           
    "500-4999": "500-4999",           
    "5000_plus": "5000+",           
    "500_plus": "500+",           
    "hiring_manager": "የቅጥር ስራ አስኪያጅ",           
    "hr_or_internal_recruiter": "የሰው ሃብት / የውስጥ ቀጣሪ",           
    "contract_recruiter": "የኮንትራት ቀጣሪ",           
    "job_role_other": "ሌላ",           
    "yes": "አዎ",           
    "no": "አይ",           
    "american_indian_or_alaska": "አሜሪካዊ ህንዳዊ ወይም የአላስካ ተወላጅ",           
    "asian": "እስያዊ",           
    "african_american": "ጥቁር ወይም አፍሪካዊ አሜሪካዊ",           
    "arab": "መካከለኛው ምስራቅ ወይም ሰሜን አፍሪካ",           
    "native_hawaiian_or_other": "የሃዋይ ተወላጅ ወይም ሌላ የፓሲፊክ ደሴት ተወላጅ",           
    "male": "ወንድ",           
    "female": "ሴት",           
    "hispanic": "አዎ",           
    "not_hispanic": "አይ",           
    "start_own_company": "የራሴን ኩባንያ ለመመስረት",           
    "advance_in_current_company": "አሁን ባለኝ ድርጅት ውስጥ ለማደግ",           
    "get_better_job": "ድርጅቶችን / ሙያን ለመለወጥ",           
    "expand_business_knowledge": "የቢዝነስ እውቀቴን ለማስፋት",           
    "more_effective_leader": "የበለጠ ውጤታማ መሪ ለመሆን",           
    "complimentary_lessons": "ለተጨማሪ ትምህርቶች እዚህ እገኛለሁ",           
    "economist": "ኢኮኖሚስት",           
    "facebook": "ፌስቡክ",           
    "instagram": "ኢንስታግራም",           
    "friend": "ቤተሰብ / ጓደኛ",           
    "search_engine": "በጉግል መፈለጊያ",
    "radio": "ኦዲዮ (ፖድካስት፣ ሬዲዮ)",           
    "mba_studies": "MBAStudies.com",           
    "wsj": "ዎል ስትሪት ጆርናል",           
    "youtube": "ዩቲውብ",           
    "linkedin": "ሊንከዲን",           
    "tv_cnbc": "ቲቪ - CNBC",           
    "tv_bloomberg": "ቲቪ - ብሉምበርግ",           
    "tv_cnn": "ቲቪ - CNN",           
    "tv_smithsonian": "ቲቪ - Smithsonian",           
    "stack_overflow_exchange": "Stack Overflow / Exchange",           
    "poets_quants": "ገጣሚዎች እና ኩዋንትስ",           
    "bloomberg_businessweek": "ብሉምበርግ ቢዝነስ ሳምንት",           
    "mit_technology_review": "የ MIT ቴክኖሎጂ ግምገማ",           
    "smart_audio": "ስማርት ኦዲዮ (አሌክሳ፣ ሲሪ፣ ወዘተ.)",           
    "colleague_manager_hr": "የስራ ባልደረባ / ስራ አስኪያጅ / የሰው ኃይል መምሪ.",           
    "america_economia": "América Economia",           
    "e_and_t": "E&T",           
    "reddit": "Reddit",           
    "none": "የለም",           
    "san_francisco": "ሳን ፍራንሲስኮ የባህር ወሽመጥ አካባቢ",           
    "new_york": "ኒው ዮርክ፣ NY",           
    "seattle": "ሲያትል፣ WA",           
    "denver": "ዴንቨር፣ CO",           
    "boston": "ቦስተን፣ MA",           
    "los_angeles": "ሎስ አንጀለስ፣ CA",           
    "san_diego": "ሳንዲያጎ፣ CA",           
    "washington_dc": "ዋሽንግተን፣ DC",           
    "chicago": "ቺካጎ፣ IL",           
    "austin": "ኦስቲን፣ TX",           
    "dallas": "ዳላስ፣ TX",           
    "london": "ለንደን",           
    "raleigh_durham": "ራሌይግ/ዱርሃም፣ NC",           
    "miami": "ማያሚ፣ FL",           
    "atlanta": "አትላንታ፣ ጂኤ",           
    "houston": "ሂዩስተን፣ GA",           
    "seoul": "ሴኡል፣ ደቡብ ኮሪያ",           
    "singapore": "ሲንጋፖር",           
    "dubai": "ዱባይ፣ UAE",           
    "paris": "ፓሪስ፣ ፈረንሳይ",           
    "berlin": "በርሊን፣ ጀርመን",           
    "hong_kong": "ሆንግ ኮንግ",           
    "beijing": "ቤጂንግ፣ ቻይና",           
    "shanghai": "ሻንጋይ፣ ቻይና",           
    "tokyo": "ቶኪዮ፣ ጃፓን",           
    "0_1_years": "0-1 አመት",           
    "1_2_years": "1-2 አመት",           
    "2_4_years": "2-4 አመት",           
    "4_6_years": "4-6 አመት",           
    "6_plus_years": "6+ አመት",           
    "0_1": "ከ 2 በታች",           
    "2_6": "2-6",           
    "7_10": "7-10",           
    "11_15": "11-15",           
    "16_plus": "16+",           
    "senior_management_role": "ከፍተኛ አስተዳደር (VP/C-ደረጃ)",           
    "management_role": "አስተዳደር",           
    "professional_role": "ፕሮፌሽናል",           
    "administrative_role": "አስተዳደራዊ",           
    "skilled_labor_role": "የሰለጠነ ሰራተኛ",           
    "high_school": "የሁለተኛ ደረጃ ትምህር ቤት ዲፕሎማ",           
    "associates": "የተባባሪ ዲግሪ",           
    "undergraduate_bachelor": "የመጀመሪያ ዲግሪ ዩኒቨርሲቲ / ባችለር ዲግሪ",           
    "masters": "የማስተርስ ዲግሪ",           
    "doctorate": "ዶክትሬት / ፒኤችዲ ዲግሪ",           
    "guild_education": "የ Guild ትምህርት",           
    "quantic_blog": "የኳንቲክ ብሎግ / አንቀጽ",
    "google_or_apple_app_store": "የመተግበሪያ መደብር",
    "tiktok": "ቲክቶክ",
    "linkedin_live_event": "የLinkedIn የቀጥታ ክስተት",
    "company_ambassador": "የኩባንያ ጠበቃ",
    "ghin": "ዓለም አቀፍ የጤና ተጽዕኖ አውታረ መረብ",
    "advance_in_career": "በሙያዬ ለመራመድ",
    "network_with_top_tier_professionals": "ከከፍተኛ ደረጃ ባለሙያዎች ጋር ለመገናኘት",
    "service_role": "ድጋፍ / አገልግሎት",
    "retail_role": "የችርቻሮ ቦታ",
    "no_identify": "ላለመመለስ እመርጣለሁ።",
    "non_binary": "ሁለትዮሽ ያልሆነ",
    "self_describe": "እራስን መግለጽ እመርጣለሁ።",
    "latin_american": "ላቲን አሜሪካዊ ወይም ሂስፓኒክ",
    "white": "ነጭ ወይም የካውካሲያን",
    "tldr_newsletter": "TLDR ጋዜጣ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.field_options = window.locales.ar.careers.field_options || {};window.locales.ar.careers.field_options = {...window.locales.ar.careers.field_options, ...{
    "marketing": "التسويق",           
    "product_management": "إدارة المنتج",           
    "legal": "قانوني",           
    "other": "آخر",           
    "consulting": "الاستشارات",           
    "consumer_products": "منتجات المستهلك",           
    "media": "الترفيه / وسائل الإعلام",           
    "financial": "الخدمات المالية",           
    "health_care": "الرعاىة الصحية",           
    "manufacturing": "التصنيع",           
    "nonprofit": "غير هادفة للربح / الحكومة",           
    "retail": "البيع بالتجزئة / التجارة",           
    "real_estate": "العقارات",           
    "technology": "التكنولوجيا",           
    "statistics": "الإحصائيات",           
    "finance_and_accounting": "الشئون المالية والمحاسبة",           
    "strategy_and_innovation": "الاستراتيجية والابتكار",           
    "entrepreneurship": "ريادة الأعمال",           
    "economics": "علم الاقتصاد",           
    "operations_management": "إدارة العمليات",           
    "creative": "مبتكر",           
    "competitive": "تنافسي",           
    "conscientious": "واعي",           
    "loyal": "مخلص",           
    "resilient": "قادر على التكيف",           
    "optimistic": "متفائل",           
    "driven": "مُوَجّه",           
    "flexible": "مرن",           
    "balanced": "متوازن",           
    "persuasive": "مقنع",           
    "compassionate": "عطوف",           
    "courageous": "شجاع",           
    "motivating": "تحفيزي",           
    "meticulous": "دقيق",           
    "high_energy": "الطاقة العالية",           
    "results": "النتائج / الفوز",           
    "helping_others": "مساعدة الآخرين",           
    "career_advancement": "التقدم الوظيفي",           
    "innovation": "الابتكار / الاكتشاف",           
    "fellowship": "الزمالة",           
    "personal_growth": "التنمية الذاتية",           
    "respect": "الاحترام / التقدير",           
    "security": "الأمان",           
    "problem_solving": "حل المشكلات",           
    "leadership": "القيادة",           
    "business_acumen": "الذكاء التجاري",           
    "professionalism": "الاحترافية",           
    "entrepreneurial_mindset": "منظم",           
    "relationship_building": "بناء العلاقات",           
    "work_ethic": "أخلاقيات العمل",           
    "design_aesthetic": "محب لجمال التصميم",           
    "communication": "مهارات الاتصال",           
    "resourcefulness": "المهارة وسعة الحيلة",           
    "best_in_class": "الأفضل في فئته",           
    "fast_paced": "يخطو خطى سريعة",           
    "hierarchical": "يحترم التسلسل الوظيفي",           
    "merit_based": "على أساس الجدارة",           
    "stable": "مستقر / مؤسس",           
    "entrepreneurial": "منظم",           
    "results_oriented": "يركز على النتائج",           
    "harmonious": "متناغم",           
    "flat_structured": "سهل-الهيكلة",           
    "mission_driven": "تحركه المهمة",           
    "bootstrapped": "ألبس الحذاء",
    "seed": "بذرة",
    "series_a": "سلسلة ل",
    "series_b_plus": "سلسلة B +",
    "public": "عامة",
    "rather_not_specify": "لا ترغب في تحديد",
    "lesson_than_1_million": "< مليون دولار",           
    "1_to_5_million": "مليون دولار-5 مليون دولار",           
    "5_to_50_million": "5 مليون دولار- 50 مليون دولار",           
    "50_to_500_million": "50 مليون دولار- 500 مليون دولار",           
    "more_than_500_million": "> 500 مليون دولار",           
    "1-24": "1-24",           
    "25-99": "25-99",           
    "100-499": "100-499",           
    "500-4999": "500-4999",           
    "5000_plus": "أكثر من 5000",           
    "senior_management": "الإدارة العليا",           
    "hiring_manager": "مدير التوظيف",           
    "hr_or_internal_recruiter": "HR / مسؤول التوظيف الداخلي",           
    "contract_recruiter": "مسؤول التوظيف بالعقود",           
    "job_role_other": "آخر",           
    "500_plus": "500+",
    "yes": "نعم",           
    "no": "لا",           
    "american_indian_or_alaska": "الأمريكية الهندية أو سكان ألاسكا الأصليين",
    "asian": "آسيا",
    "african_american": "أمريكي أسود أو الأفريقية",
    "native_hawaiian_or_other": "جزر هاواي أو غيرها من الأم المحيط الهادئ",
    "male": "ذكر",
    "female": "أنثى",
    "hispanic": "نعم فعلا",
    "not_hispanic": "لا",
    "start_own_company": "لبدء شركتي الخاصة",
    "expand_business_knowledge": "لتوسيع معرفتي الأعمال",
    "facebook": "فيس بوك",
    "instagram": "الانستقرام",
    "search_engine": "بحث جوجل",
    "finance": "المالية",
    "engineering": "هندسة",
    "design": "التصميم",
    "management_and_leadership": "الإدارة والقيادة",
    "more_effective_leader": "لتصبح رائدة أكثر فعالية",
    "education": "التعليم",
    "energy": "الطاقة",
    "san_francisco": "منطقة خليج سان فرانسيسكو",
    "new_york": "نيويورك، نيويورك",
    "seattle": "سياتل، وا",
    "denver": "دنفر، كو",
    "boston": "بوسطن، MA",
    "los_angeles": "لوس أنجلوس، كاليفورنيا",
    "san_diego": "سان دييغو، كا",
    "washington_dc": "واشنطن العاصمة",
    "chicago": "شيكاغو، IL",
    "austin": "أوستن، تكساس",
    "dallas": "دالاس، تكساس",
    "london": "لندن",
    "raleigh_durham": "رالي / دورهام، نك",
    "miami": "ميامي، FL",
    "houston": "هيوستن، تكس",
    "seoul": "سيول، كوريا الجنوبية",
    "singapore": "سنغافورة",
    "dubai": "دبي الامارات العربية المتحدة",
    "paris": "باريس، فرنسا",
    "berlin": "برلين، ألمانيا",
    "hong_kong": "هونغ كونغ",
    "beijing": "بكين، الصين",
    "shanghai": "شنغهاي، الصين",
    "tokyo": "طوكيو، اليابان",
    "0_1_years": "0-1 سنة",
    "1_2_years": "1-2 سنوات",
    "2_4_years": "2-4 سنوات",
    "4_6_years": "4-6 سنوات",
    "6_plus_years": "6+ سنوات",
    "none": "لا شيء",
    "private": "نشر",
    "mba_studies": "MBAStudies.com",
    "economist": "The Economist",
    "wsj": "Wall Street Journal",
    "data_science": "علوم البيانات",
    "information_technology": "تكنولوجيا المعلومات (IT)",
    "general_management": "الإدارة / العمليات",
    "research": "ابحاث",
    "sales": "المبيعات / تطوير الأعمال",
    "ux_ui": "أوكس / أوي",
    "graphic_design": "التصميم الجرافيكي",
    "qa": "QA",
    "devops": "DevOps",
    "investment": "الاستثمار",
    "trading": "تجارة",
    "operations": "العمليات / إدارة المشاريع",
    "human_resources": "الموارد البشرية / اكتساب المواهب",
    "administrative": "إداري",
    "account_executive": "منفذ الحساب",
    "account_manager": "إدارة حساب المستخدم",
    "customer_success": "نجاح العملاء",
    "sales_engineer": "مهندس مبيعات",
    "government": "حكومة",
    "military": "الجيش",
    "software_dev_front_end": "تطوير البرمجيات (الواجهة الأمامية)",
    "software_dev_back_end": "تطوير البرمجيات (الخلفية)",
    "software_dev_full_stack": "تطوير البرمجيات (مكدس كامل)",
    "youtube": "موقع YouTube",
    "data_analysis": "تحليل الأعمال / الاستخبارات",
    "writing": "الاتصالات / الكتابة",
    "medical_services": "الخدمات الطبية",
    "teaching_education": "التدريس / التعليم",
    "art_creative": "العمارة / الفن",
    "wealth_management": "إدارة الثروة",
    "risk_audit": "المخاطر / التدقيق",
    "logistics_supply_chain": "اللوجستية / سلسلة التوريد",
    "sales_development_rep": "ممثل تطوير المبيعات",
    "consultant": "الاستشارات الإدارية / الاستراتيجية",
    "linkedin": "ينكدين",
    "permanent": "دائم",
    "part_time": "دوام جزئى",
    "contract": "عقد",
    "internship": "فترة تدريب",
    "atlanta": "أتلانتا، GA",
    "corporate_banking": "الخدمات المصرفية الإستثمارية",
    "fpa_accounting_audit": "فب & A / المحاسبة / التحكم",
    "flexible_attribute": "مرن",
    "7_10": "7-10",
    "11_15": "11-15",
    "16_plus": "16+",
    "senior_management_role": "الإدارة العليا (نائب الرئيس / مستوى C)",
    "management_role": "إدارة",
    "professional_role": "المحترفين",
    "administrative_role": "إداري",
    "skilled_labor_role": "العمالة الماهرة",
    "associates": "شهادة المنتسب",
    "undergraduate_bachelor": "المرحلة الجامعية / درجة البكالوريوس",
    "masters": "ماجيستير",
    "hardware": "الهندسة الكهربائية",
    "chemical": "هندسة كيميائية",
    "civil": "هندسة مدنية",
    "mechanical": "هندسة ميكانيكي",
    "aerospace": "هندسة الطيران",
    "biomedical": "الهندسة الطبية الحيوية",
    "advance_in_current_company": "للتقدم في مؤسستي الحالية",
    "get_better_job": "لتغيير المنظمات / الوظيفي",
    "arab": "الشرق الأوسط أو شمال أفريقيا",
    "friend": "صديق / زميل",
    "tv_cnbc": "تلفزيون - CNBC",
    "tv_bloomberg": "تلفزيون - بلومبرغ",
    "0_1": "أقل من 2",
    "2_6": "2-6",
    "radio": "صوتي (بودكاست ، راديو)",
    "high_school": "شهادة الدراسة الثانوية",
    "doctorate": "دكتوراه / دكتوراه",
    "tv_cnn": "تلفزيون - CNN",
    "tv_smithsonian": "تلفزيون - سميثسونيان",
    "stack_overflow_exchange": "Stack Overflow / Exchange",
    "poets_quants": "الشعراء و Quants",
    "complimentary_lessons": "أنا هنا من أجل الدروس المجانية",
    "bloomberg_businessweek": "بلومبرج بيزنس ويك",
    "mit_technology_review": "MIT Technology Review",
    "smart_audio": "الصوت الذكي (Alexa ، Siri ، إلخ.)",
    "colleague_manager_hr": "زميل / مدير / قسم الموارد البشرية.",
    "america_economia": "اقتصاد أمريكا",
    "e_and_t": "E&T",
    "reddit": "رديت",
    "guild_education": "تعليم النقابة",
    "quantic_blog": "مدونة Quantic / المادة",
    "google_or_apple_app_store": "متجر التطبيقات",
    "tiktok": "تيك توك",
    "linkedin_live_event": "ينكدين لايف حدث",
    "company_ambassador": "محامي الشركة",
    "ghin": "شبكة التأثير الصحي العالمية",
    "advance_in_career": "للتقدم في مسيرتي",
    "network_with_top_tier_professionals": "للتواصل مع محترفين من الدرجة الأولى",
    "service_role": "الدعم / الخدمة",
    "retail_role": "موقف البيع بالتجزئة",
    "no_identify": "افضل عدم الاجابة",
    "non_binary": "غير ثنائي",
    "self_describe": "تفضل أن تصف نفسك",
    "latin_american": "أمريكا اللاتينية أو من أصل اسباني",
    "white": "أبيض أو قوقازي",
    "tldr_newsletter": "النشرة الإخبارية TLDR"
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.field_options = window.locales.en.careers.field_options || {};window.locales.en.careers.field_options = {...window.locales.en.careers.field_options, ...{
            
    "other": "Other",

                                  
    "data_analysis": "Business Analysis / Intelligence",
    "consultant": "Management Consulting / Strategy",
    "data_science": "Data Science",       
    "design": "Design",
    "engineering": "Engineering",
    "finance": "Finance",
    "information_technology": "Information Technology (IT)",       
    "general_management": "Management / Operations",
    "marketing": "Marketing",
    "product_management": "Product Management",
    "research": "Research",
    "sales": "Sales / Business Development",
    "writing": "Communications / Writing",
    "medical_services": "Medical Services",
    "teaching_education": "Teaching / Education",
                        

                       
    "permanent": "Permanent",
    "part_time": "Part-Time",
    "contract": "Contract",
    "internship": "Internship",

                                     
    "ux_ui": "UX / UI",            
    "art_creative": "Architecture / Art",            
    "graphic_design": "Graphic Design",            
    "software_dev_front_end": "Software Development (front-end)",                 
    "software_dev_back_end": "Software Development (back-end)",                 
    "software_dev_full_stack": "Software Development (full-stack)",                 
    "qa": "QA",                 
    "devops": "DevOps",                 
    "hardware": "Electrical Engineering",                 
    "chemical": "Chemical Engineering",                 
    "civil": "Civil Engineering",                 
    "mechanical": "Mechanical Engineering",                 
    "aerospace": "Aerospace Engineering",                 
    "biomedical": "Biomedical Engineering",                 
    "corporate_banking": "Investment Banking",             
    "investment": "Investing",             
    "trading": "Trading",             
    "fpa_accounting_audit": "FP&A / Accounting / Control",             
    "wealth_management": "Wealth Management",             
    "risk_audit": "Risk / Audit",             
    "senior_management": "Senior Management",                      
    "operations": "Operations / Project Management",                                                
    "human_resources": "Human Resources / Talent Acquisition",                                               
    "legal": "Legal",                                               
    "logistics_supply_chain": "Logistics / Supply Chain",                      
    "administrative": "Administrative",                      
    "account_executive": "Account Executive",           
    "account_manager": "Account Manager",           
    "customer_success": "Customer Success",           
    "sales_engineer": "Sales Engineer",           
    "sales_development_rep": "Sales Development Representative",           

                 
    "consulting": "Consulting",
    "consumer_products": "Consumer Products",
    "media": "Media / Entertainment",
    "financial": "Financial Services",
    "health_care": "Health Care",
    "manufacturing": "Manufacturing",
    "nonprofit": "Nonprofit / Government",
    "retail": "Retail / Trading",
    "real_estate": "Real Estate",
    "technology": "Technology",
    "education": "Education",
    "energy": "Energy",
    "government": "Government",
    "military": "Military",
                        

                    
    "statistics": "Statistics",
    "finance_and_accounting": "Finance & Accounting",
    "strategy_and_innovation": "Strategy & Innovation",
    "entrepreneurship": "Entrepreneurship",
    "economics": "Economics",
    "operations_management": "Operations Management",
    "management_and_leadership": "Management and Leadership",
                            

                 
    "creative": "Creative",
    "competitive": "Competitive",
    "conscientious": "Conscientious",
    "loyal": "Loyal",
    "resilient": "Resilient",
    "optimistic": "Optimistic",
    "driven": "Driven",
    "flexible": "Flexible",
    "flexible_attribute": "Flexible",
    "balanced": "Balanced",
    "persuasive": "Persuasive",
    "compassionate": "Compassionate",
    "courageous": "Courageous",
    "motivating": "Motivating",
    "meticulous": "Meticulous",
    "high_energy": "High-energy",

                  
    "results": "Results / Winning",
    "helping_others": "Helping Others",
    "career_advancement": "Career Advancement",
    "innovation": "Innovation / Discovery",
    "fellowship": "Fellowship",
    "personal_growth": "Personal Growth",
    "respect": "Respect / Recognition",
    "security": "Security",

                
    "problem_solving": "Problem Solving",
    "leadership": "Leadership",
    "business_acumen": "Business Acumen",
    "professionalism": "Professionalism",
    "entrepreneurial_mindset": "Entrepreneurial",
    "relationship_building": "Relationship Building",
    "work_ethic": "Work Ethic",
    "design_aesthetic": "Design Aesthetic",
    "communication": "Communication",
    "resourcefulness": "Resourcefulness",

              
    "best_in_class": "Best in Class",
    "fast_paced": "Fast-Paced",
    "hierarchical": "Hierarchical",
    "merit_based": "Merit-based",
    "stable": "Stable / Established",
    "entrepreneurial": "Entrepreneurial",
    "results_oriented": "Results-oriented",
    "harmonious": "Harmonious",
    "flat_structured": "Flat-Structured",
    "mission_driven": "Mission-Driven",

              
    "bootstrapped": "Bootstrapped",
    "seed": "Seed",
    "series_a": "Series A",
    "series_b_plus": "Series B+",
    "public": "Public",
    "private": "Private",

                     
    "rather_not_specify": "Would rather not specify",
    "lesson_than_1_million": "< $1 million",
    "1_to_5_million": "$1-$5 million",
    "5_to_50_million": "$5-$50 million",
    "50_to_500_million": "$50-$500 million",
    "more_than_500_million": "> $500 million",

                     
    "1-24": "1-24",
    "25-99": "25-99",
    "100-499": "100-499",
    "500-4999": "500-4999",
    "5000_plus": "5000+",
    "500_plus": "500+",                                                                                      

                
                                    
    "hiring_manager": "Hiring Manager",
    "hr_or_internal_recruiter": "HR / Internal Recruiter",
    "contract_recruiter": "Contract Recruiter",
    "job_role_other": "Other",

                                                  
    "yes": "Yes",
    "no": "No",

           
    "american_indian_or_alaska": "American Indian or Alaska Native",
    "asian": "Asian",
    "african_american": "Black or African American",
    "latin_american": "Latin American or Hispanic",
    "arab": "Middle Eastern or North African",
    "native_hawaiian_or_other": "Native Hawaiian or Other Pacific Islander",
    "white": "White or Caucasian",

          
    "male": "Male",
    "female": "Female",
    "no_identify": "Prefer not to answer",
    "non_binary": "Non-binary",
    "self_describe": "Prefer to self-describe",

                
    "hispanic": "Yes",
    "not_hispanic": "No",

                                  
    "start_own_company": "To start my own company",
    "advance_in_current_company": "To advance in my current organization",
    "get_better_job": "To change organizations / career",
    "expand_business_knowledge": "To expand my business knowledge",
    "more_effective_leader": "To become a more effective leader",
    "complimentary_lessons": "I'm here for the complimentary lessons",
    "advance_in_career": "To advance in my career",
    "network_with_top_tier_professionals": "To network with top-tier professionals",

                                 
    "economist": "The Economist",
    "facebook": "Facebook",
    "instagram": "Instagram",
    "friend": "Family / Friend",
    "search_engine": "Google Search",
    "radio": "Audio (Podcast, Radio)",
    "mba_studies": "MBAStudies.com",
    "wsj": "Wall Street Journal",
    "youtube": "YouTube",
    "linkedin": "LinkedIn",
    "tv_cnbc": "TV - CNBC",
    "tv_bloomberg": "TV - Bloomberg",
    "tv_cnn": "TV - CNN",
    "tv_smithsonian": "TV - Smithsonian",
    "stack_overflow_exchange": "Stack Overflow / Exchange",
    "poets_quants": "Poets & Quants",
    "bloomberg_businessweek": "Bloomberg Businessweek",
    "mit_technology_review": "MIT Technology Review",
    "smart_audio": "Smart Audio (Alexa, Siri, etc.)",
    "colleague_manager_hr": "Colleague / Manager / HR Dept.",
    "america_economia": "América Economia",
    "e_and_t": "E&T",
    "reddit": "Reddit",
    "guild_education": "Guild Education",
    "quantic_blog": "Quantic Blog / Article",
    "google_or_apple_app_store": "App Store",
    "tiktok": "TikTok",
    "linkedin_live_event": "LinkedIn Live Event",
    "company_ambassador": "Company Advocate",
    "ghin": "Global Health Impact Network",
    "tldr_newsletter": "TLDR Newsletter",

                            
    "flexible": "I'm flexible",
    "none": "None",
    "san_francisco": "San Francisco Bay Area",
    "new_york": "New York, NY",
    "seattle": "Seattle, WA",
    "denver": "Denver, CO",
    "boston": "Boston, MA",
    "los_angeles": "Los Angeles, CA",
    "san_diego": "San Diego, CA",
    "washington_dc": "Washington, DC",
    "chicago": "Chicago, IL",
    "austin": "Austin, TX",
    "dallas": "Dallas, TX",
    "london": "London",
    "raleigh_durham": "Raleigh/Durham, NC",
    "miami": "Miami, FL",
    "atlanta": "Atlanta, GA",
    "houston": "Houston, TX",
    "seoul": "Seoul, South Korea",
    "singapore": "Singapore",
    "dubai": "Dubai, UAE",
    "paris": "Paris, France",
    "berlin": "Berlin, Germany",
    "hong_kong": "Hong Kong",
    "beijing": "Beijing, China",
    "shanghai": "Shanghai, China",
    "tokyo": "Tokyo, Japan",

                       
    "0_1_years": "0-1 Years",
    "1_2_years": "1-2 Years",
    "2_4_years": "2-4 Years",
    "4_6_years": "4-6 Years",
    "6_plus_years": "6+ Years",

                                 
    "0_1": "Less than 2",
    "2_6": "2-6",
    "7_10": "7-10",
    "11_15": "11-15",
    "16_plus": "16+",

                   
    "senior_management_role": "Senior Management (VP / C-level)",
    "management_role": "Management",
    "professional_role": "Professional",
    "administrative_role": "Administrative",
    "skilled_labor_role": "Skilled Labor",
    "service_role": "Support / Service",
    "retail_role": "Retail Position",

                              
    "high_school": "High School Diploma",
    "associates": "Associate's Degree",
    "undergraduate_bachelor": "Undergraduate University / Bachelor's Degree",
    "masters": "Master's Degree",
    "doctorate": "Doctorate / PhD Degree"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.field_options = window.locales.es.careers.field_options || {};window.locales.es.careers.field_options = {...window.locales.es.careers.field_options, ...{
    "marketing": "Márketing",           
    "product_management": "Gestión de producto",           
    "legal": "Asuntos jurídicos",           
    "other": "Otro",           
    "consulting": "Asesoría",           
    "consumer_products": "Productos de consumo",           
    "media": "Entretenimiento / medios",           
    "financial": "Servicios financieros",           
    "health_care": "Asistencia sanitaria",           
    "manufacturing": "Fabricación",           
    "nonprofit": "Sin fines de lucro / gobierno",           
    "retail": "Al por menor / comercio",           
    "real_estate": "Bienes inmuebles",           
    "technology": "Tecnología",           
    "statistics": "Estadística",           
    "finance_and_accounting": "Finanzas y contabilidad",           
    "strategy_and_innovation": "Estrategia e innovación",           
    "entrepreneurship": "Emprendimiento",           
    "economics": "Economía",           
    "operations_management": "Dirección de operaciones",           
    "creative": "Creativo",           
    "competitive": "Competitivo",           
    "conscientious": "Concienzudo",           
    "loyal": "Leal",           
    "resilient": "Resistente",           
    "optimistic": "Optimista",           
    "driven": "Motivado",           
    "flexible": "Flexible",           
    "balanced": "Equilibrado",           
    "persuasive": "Persuasivo",           
    "compassionate": "Compasivo",           
    "courageous": "Valiente",           
    "motivating": "Motivador",           
    "meticulous": "Meticuloso",           
    "high_energy": "Enérgico",           
    "results": "Resultados / ganador",           
    "helping_others": "Ayudar a los demás",           
    "career_advancement": "Desarrollo profesional",           
    "innovation": "Innovación / descubrimiento",           
    "fellowship": "Compañerismo",           
    "personal_growth": "Crecimiento personal",           
    "respect": "Respeto / reconocimiento",           
    "security": "Seguridad",           
    "problem_solving": "Resolución de problemas",           
    "leadership": "Liderazgo",           
    "business_acumen": "Visión para los negocios",           
    "professionalism": "Profesionalismo",           
    "entrepreneurial_mindset": "Emprendedor",           
    "relationship_building": "Creación de vínculos",           
    "work_ethic": "Ética de trabajo",           
    "design_aesthetic": "Diseño estético",           
    "communication": "Comunicación",           
    "resourcefulness": "Inventiva",           
    "best_in_class": "Mejor de clase",           
    "fast_paced": "Acelerado",           
    "hierarchical": "Jerárquico",           
    "merit_based": "Basado en el mérito",           
    "stable": "Estable / establecido",           
    "entrepreneurial": "Emprendedor",           
    "results_oriented": "Orientado a resultados",           
    "harmonious": "Armonioso",           
    "flat_structured": "Con estructura vertical",           
    "mission_driven": "Comprometido con la misión",           
    "bootstrapped": "bootstrapped",
    "seed": "Semilla",
    "series_a": "serie A",
    "series_b_plus": "Serie B +",
    "public": "Público",
    "rather_not_specify": "Prefieren no especificar",
    "lesson_than_1_million": "Menos de 1 millón de dólares",           
    "1_to_5_million": "Entre 1 y 5 millones de dólares",           
    "5_to_50_million": "Entre 5 y 50 millones de dólares",           
    "50_to_500_million": "Entre 50 y 500 millones de dólares",           
    "more_than_500_million": "Más de 500 millones de dólares",           
    "1-24": "1-24",           
    "25-99": "25-99",           
    "100-499": "100-499",           
    "500-4999": "500-4999",           
    "5000_plus": "Más de 5000",           
    "senior_management": "Alta dirección",           
    "hiring_manager": "Director de recursos humanos",           
    "hr_or_internal_recruiter": "Recursos humanos/encargado de personal interno",           
    "contract_recruiter": "Encargado de personal con contrato",           
    "job_role_other": "Otro",           
    "500_plus": "500+",
    "yes": "Sí",           
    "no": "No",           
    "american_indian_or_alaska": "Indian EE.UU. o Alaska",
    "asian": "asiático",
    "african_american": "Americano negro o africano",
    "native_hawaiian_or_other": "Nativo de Hawai u otra isla del Pacífico",
    "male": "Masculino",
    "female": "Hembra",
    "hispanic": "Sí",
    "not_hispanic": "No",
    "start_own_company": "Para iniciar mi propia empresa",
    "expand_business_knowledge": "Para ampliar mis conocimientos empresariales",
    "facebook": "Facebook",
    "instagram": "Instagram",
    "search_engine": "Búsqueda de Google",
    "finance": "Financiar",
    "engineering": "Ingenieria",
    "design": "Diseño",
    "management_and_leadership": "Gestión y Liderazgo",
    "more_effective_leader": "Para convertirse en un líder más eficaz",
    "education": "Educación",
    "energy": "Energía",
    "san_francisco": "San Francisco Bay Area",
    "new_york": "Nueva York, NY",
    "seattle": "Seattle, WA",
    "denver": "Denver, CO",
    "boston": "Boston, MA",
    "los_angeles": "Los Ángeles, California",
    "san_diego": "San Diego, CA",
    "washington_dc": "Washington DC",
    "chicago": "Chicago, IL",
    "austin": "Austin, TX",
    "dallas": "Dallas, TX",
    "london": "Londres",
    "raleigh_durham": "Raleigh / Durham, NC",
    "miami": "Miami, FL",
    "houston": "Houston, TX",
    "seoul": "Seúl, Corea del Sur",
    "singapore": "Singapur",
    "dubai": "Dubai, Emiratos Árabes Unidos",
    "paris": "París, Francia",
    "berlin": "Berlín, Alemania",
    "hong_kong": "Hong Kong",
    "beijing": "Beijing, China",
    "shanghai": "Shanghai, China",
    "tokyo": "Tokio, Japón",
    "0_1_years": "0-1 Años",
    "1_2_years": "1-2 Años",
    "2_4_years": "2-4 Años",
    "4_6_years": "4-6 Años",
    "6_plus_years": "6+ Años",
    "none": "Ninguna",
    "private": "Privado",
    "mba_studies": "MBAStudies.com",
    "economist": "The Economist",
    "wsj": "Wall Street Journal",
    "data_science": "Ciencia de los datos",
    "information_technology": "Tecnología de la Información (TI)",
    "general_management": "Gestión / Operaciones",
    "research": "Investigación",
    "sales": "Ventas / Desarrollo de Negocios",
    "ux_ui": "UX / UI",
    "graphic_design": "Diseño gráfico",
    "qa": "QA",
    "devops": "DevOps",
    "investment": "Invertir",
    "trading": "Comercio",
    "operations": "Operaciones / Gestión de Proyectos",
    "human_resources": "Recursos Humanos / Adquisición de Talentos",
    "administrative": "Administrativo",
    "account_executive": "Ejecutivo de cuentas",
    "account_manager": "Gerente de cuentas",
    "customer_success": "Éxito del cliente",
    "sales_engineer": "Ingeniero de Ventas",
    "government": "Gobierno",
    "military": "Militar",
    "software_dev_front_end": "Desarrollo de software (front-end)",
    "software_dev_back_end": "Desarrollo de software (back-end)",
    "software_dev_full_stack": "Desarrollo de software (full-stack)",
    "youtube": "Youtube",
    "data_analysis": "Análisis de negocios / Inteligencia",
    "consultant": "Consultoría de gestión / Estrategia",
    "writing": "Comunicaciones / Escritura",
    "medical_services": "Servicios médicos",
    "teaching_education": "Enseñanza / Educación",
    "art_creative": "Arquitectura / Arte",
    "wealth_management": "Gestión de patrimonios",
    "risk_audit": "Riesgo / Auditoría",
    "logistics_supply_chain": "Logística / cadena de suministro",
    "sales_development_rep": "Representante de desarrollo de ventas",
    "linkedin": "LinkedIn",
    "permanent": "Permanente",
    "part_time": "Medio tiempo",
    "contract": "Contrato",
    "internship": "Internado",
    "atlanta": "Atlanta, Georgia",
    "corporate_banking": "Banca de inversión",
    "fpa_accounting_audit": "FP & A / Contabilidad / Control",
    "flexible_attribute": "Flexible",
    "7_10": "7-10",
    "11_15": "11-15",
    "16_plus": "16+",
    "senior_management_role": "Alta Gerencia (nivel VP / C)",
    "management_role": "administración",
    "professional_role": "Profesional",
    "administrative_role": "Administrativo",
    "skilled_labor_role": "Mano de obra calificada",
    "associates": "Grado Asociado",
    "undergraduate_bachelor": "Pregrado Universidad / Licenciatura",
    "masters": "Maestría",
    "hardware": "Ingenieria Eléctrica",
    "chemical": "Ingeniería Química",
    "civil": "Ingeniero civil",
    "mechanical": "Ingeniería mecánica",
    "aerospace": "Ingeniería Aeroespacial",
    "biomedical": "Ingeniería Biomédica",
    "advance_in_current_company": "Para avanzar en mi organización actual",
    "get_better_job": "Para cambiar organizaciones / carrera",
    "arab": "Medio Oriente o África del Norte",
    "friend": "Familia / Amigo",
    "tv_cnbc": "TV - CNBC",
    "tv_bloomberg": "TV - Bloomberg",
    "0_1": "Menos de 2",
    "2_6": "2-6",
    "radio": "Audio (podcast, radio)",
    "high_school": "Diploma de escuela secundaria",
    "doctorate": "Doctorado / Doctorado",
    "tv_cnn": "TV - CNN",
    "tv_smithsonian": "TV - Smithsonian",
    "stack_overflow_exchange": "Stack Overflow / Exchange",
    "poets_quants": "Poets & Cuántos",
    "complimentary_lessons": "Estoy aquí para las lecciones complementarias",
    "bloomberg_businessweek": "Bloomberg Businessweek",
    "mit_technology_review": "MIT Technology Review",
    "smart_audio": "Audio inteligente (Alexa, Siri, etc.)",
    "colleague_manager_hr": "Colega / Gerente / Departamento de Recursos Humanos",
    "america_economia": "Economía de América",
    "e_and_t": "E&T",
    "reddit": "Reddit",
    "guild_education": "Educación del gremio",
    "quantic_blog": "Blog / artículo de Quantic",
    "google_or_apple_app_store": "Tienda de aplicaciones",
    "tiktok": "Tik Tok",
    "linkedin_live_event": "Evento en vivo de LinkedIn",
    "company_ambassador": "Abogado de la empresa",
    "ghin": "Red Global de Impacto en la Salud",
    "advance_in_career": "Para avanzar en mi carrera",
    "network_with_top_tier_professionals": "Para hacer networking con profesionales de primer nivel",
    "service_role": "Servicio de soporte",
    "retail_role": "Posición minorista",
    "no_identify": "Prefiero no responder",
    "non_binary": "no binario",
    "self_describe": "Prefiero autodescribirme",
    "latin_american": "latinoamericano o hispano",
    "white": "Blanco o caucásico",
    "tldr_newsletter": "Boletín informativo TLDR"
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.field_options = window.locales.it.careers.field_options || {};window.locales.it.careers.field_options = {...window.locales.it.careers.field_options, ...{
    "other": "Altro",           
    "marketing": "Marketing",           
    "finance": "Finanze",           
    "product_management": "Management Prodotto",           
    "legal": "Legale",           
    "engineering": "Ingegneria",           
    "design": "Design",           
    "consulting": "Consulenza",           
    "consumer_products": "Prodotti Cliente",           
    "media": "Intrattenimento / Media",           
    "financial": "Servizi Finanziari",           
    "health_care": "Sanità",           
    "manufacturing": "Produzione",           
    "nonprofit": "No-profit / Governo",           
    "retail": "Trading / Commercio al Dettaglio",           
    "real_estate": "Immobiliare",           
    "technology": "Tecnologia",           
    "education": "Istruzione",           
    "energy": "Energia",           
    "statistics": "Statistica",           
    "finance_and_accounting": "Finanze e Contabilità",           
    "strategy_and_innovation": "Strategia e Innovazione",           
    "entrepreneurship": "Imprenditoria",           
    "economics": "Economia",           
    "operations_management": "Management Operazioni",           
    "management_and_leadership": "Management e Leadership",           
    "creative": "Creativo",           
    "competitive": "Competitivo",           
    "conscientious": "Coscienzioso",           
    "loyal": "Leale",           
    "resilient": "Resiliente",           
    "optimistic": "Ottimista",           
    "driven": "Appassionato",           
    "flexible": "Sono elastico",           
    "balanced": "Bilanciato",           
    "persuasive": "Persuasivo",           
    "compassionate": "Compassionevole",           
    "courageous": "Coraggioso",           
    "motivating": "Motivante",           
    "meticulous": "Meticoloso",           
    "high_energy": "Energico",           
    "results": "Risultati / Vittoria",           
    "helping_others": "Aiutare il Prossimo",           
    "career_advancement": "Avanzamento di Carriera",           
    "innovation": "Innovazione / Scoperta",           
    "fellowship": "Cameratismo",           
    "personal_growth": "Crescita Personale",           
    "respect": "Rispetto / Riconoscimento",           
    "security": "Sicurezza",           
    "problem_solving": "Problem Solving",           
    "leadership": "Leadership",           
    "business_acumen": "Acume Imprenditoriale",           
    "professionalism": "Professionalità",           
    "entrepreneurial_mindset": "Imprenditoriale",           
    "relationship_building": "Costruzione Rapporto",           
    "work_ethic": "Etica del Lavoro",           
    "design_aesthetic": "Estetica Design",           
    "communication": "Comunicazione",           
    "resourcefulness": "Ingegnosità",           
    "best_in_class": "Migliore della Categoria",           
    "fast_paced": "Frenetico",           
    "hierarchical": "Gerarchico",           
    "merit_based": "Basato sul merito",           
    "stable": "Stabile / Avviato",           
    "entrepreneurial": "Imprenditoriale",           
    "results_oriented": "Orientato al Risultato",           
    "harmonious": "Armonioso",           
    "flat_structured": "A struttura semplice",           
    "mission_driven": "Imperniato sulla Missione",           
    "bootstrapped": "Fatto da Sé",           
    "seed": "Seed",           
    "series_a": "Serie A",           
    "series_b_plus": "Serie B+",           
    "public": "Pubblico",           
    "rather_not_specify": "Preferisco non specificare",           
    "lesson_than_1_million": "< $1 milione",           
    "1_to_5_million": "$1-$5 milioni",           
    "5_to_50_million": "$5-$50 milioni",           
    "50_to_500_million": "$50-$500 milioni",           
    "more_than_500_million": "> $500 milioni",           
    "1-24": "1-24",           
    "25-99": "25-99",           
    "100-499": "100-499",           
    "500-4999": "500-4999",           
    "5000_plus": "5000+",           
    "500_plus": "500+",           
    "senior_management": "Senior Management",           
    "hiring_manager": "Responsabile Assunzioni",           
    "hr_or_internal_recruiter": "Risorse Umane / Selezionatore Interno",           
    "contract_recruiter": "Selezionatore Contratti",           
    "job_role_other": "Altro",           
    "yes": "Sì",           
    "no": "No",           
    "american_indian_or_alaska": "Nativo Indo-americano o dell'Alaska",           
    "asian": "Asiatico",           
    "african_american": "Nero o Afroamericano",           
    "native_hawaiian_or_other": "Nativo delle Hawaii o altre Isole del Pacifico",           
    "male": "Maschio",           
    "female": "Femmina",           
    "hispanic": "Sì",           
    "not_hispanic": "No",           
    "start_own_company": "Per avviare una mia azienda",           
    "expand_business_knowledge": "Per espandere la mia conoscenza d'impresa",           
    "more_effective_leader": "Per diventare un leader più efficace",           
    "facebook": "Facebook",           
    "instagram": "Instagram",           
    "search_engine": "Ricerca Google",
    "san_francisco": "San Francisco Bay Area",           
    "new_york": "New York, NY",           
    "seattle": "Seattle, WA",           
    "denver": "Denver, CO",           
    "boston": "Boston, MA",           
    "los_angeles": "Los Angeles, CA",           
    "san_diego": "San Diego, CA",           
    "washington_dc": "Washington, DC",           
    "chicago": "Chicago, IL",           
    "austin": "Austin, TX",           
    "dallas": "Dallas, TX",           
    "london": "Londra",           
    "raleigh_durham": "Raleigh/Durham, NC",           
    "miami": "Miami, FL",           
    "houston": "Houston, TX",           
    "seoul": "Seoul, Corea del Sud",           
    "singapore": "Singapore",           
    "dubai": "Dubai, UAE",           
    "paris": "Parigi, Francia",           
    "berlin": "Berlino, Germania",           
    "hong_kong": "Hong Kong",           
    "beijing": "Pechino, Cina",           
    "shanghai": "Shanghai, Cina",           
    "tokyo": "Tokyo, Giappone",           
    "0_1_years": "0-1 anni",           
    "1_2_years": "1-2 anni",           
    "2_4_years": "2-4 anni",           
    "4_6_years": "4-6 anni",           
    "6_plus_years": "6+ anni",           
    "none": "Nessuna",
    "private": "Privato",
    "mba_studies": "MBAStudies.com",
    "economist": "The Economist",
    "wsj": "Wall Street Journal",
    "data_science": "Data Science",
    "information_technology": "Informatica (IT)",
    "general_management": "Gestione / Operazioni",
    "research": "Ricerca",
    "sales": "Vendite / sviluppo commerciale",
    "ux_ui": "UX / UI",
    "graphic_design": "Disegno grafico",
    "qa": "QA",
    "devops": "DevOps",
    "investment": "Investire",
    "trading": "Trading",
    "operations": "Operazioni / Project Management",
    "human_resources": "Acquisizione di risorse umane / talento",
    "administrative": "Amministrativo",
    "account_executive": "Account Executive",
    "account_manager": "Account Manager",
    "customer_success": "Successo cliente",
    "sales_engineer": "Tecnico commerciale",
    "government": "Governo",
    "military": "Militare",
    "software_dev_front_end": "Sviluppo software (front-end)",
    "software_dev_back_end": "Sviluppo software (back-end)",
    "software_dev_full_stack": "Sviluppo software (full-stack)",
    "youtube": "Youtube",
    "data_analysis": "Analisi aziendale / intelligenza",
    "consultant": "Management Consulting / Strategia",
    "writing": "Comunicazioni / scrittura",
    "medical_services": "Servizi medici",
    "teaching_education": "Insegnamento / istruzione",
    "art_creative": "Architettura / Art",
    "wealth_management": "Gestione patrimoniale",
    "risk_audit": "Rischio / audit",
    "logistics_supply_chain": "Logistica / catena di approvvigionamento",
    "sales_development_rep": "Rappresentante per lo sviluppo delle vendite",
    "linkedin": "LinkedIn",
    "permanent": "Permanente",
    "part_time": "Mezza giornata",
    "contract": "Contrarre",
    "internship": "tirocinio",
    "atlanta": "Atlanta, GA",
    "corporate_banking": "Investimento bancario",
    "fpa_accounting_audit": "FP & A / Contabilità / Controllo",
    "flexible_attribute": "Flessibile",
    "7_10": "7-10",
    "11_15": "11-15",
    "16_plus": "16+",
    "senior_management_role": "Senior Management (VP / C-level)",
    "management_role": "Gestione",
    "professional_role": "Professionale",
    "administrative_role": "Amministrativo",
    "skilled_labor_role": "Manodopera specializzata",
    "associates": "Diploma di socio",
    "undergraduate_bachelor": "Laurea universitaria / Laurea triennale",
    "masters": "Master",
    "hardware": "Ingegnere elettrico",
    "chemical": "Ingegneria Chimica",
    "civil": "Ingegneria Civile",
    "mechanical": "Ingegnere meccanico",
    "aerospace": "Ingegneria Aerospaziale",
    "biomedical": "Ingegneria Biomedica",
    "advance_in_current_company": "Per avanzare nella mia attuale organizzazione",
    "get_better_job": "Per cambiare organizzazione / carriera",
    "arab": "Mediorientale o Nordafricano",
    "friend": "Famiglia / Amico",
    "tv_cnbc": "TV - CNBC",
    "tv_bloomberg": "TV - Bloomberg",
    "0_1": "Meno di 2",
    "2_6": "2-6",
    "radio": "Audio (Podcast, Radio)",
    "high_school": "Diploma di scuola superiore",
    "doctorate": "Dottorato / Dottorato",
    "tv_cnn": "TV - CNN",
    "tv_smithsonian": "TV - Smithsonian",
    "stack_overflow_exchange": "Stack Overflow / Exchange",
    "poets_quants": "Poets & Quants",
    "complimentary_lessons": "Sono qui per le lezioni gratuite",
    "bloomberg_businessweek": "Bloomberg Businessweek",
    "mit_technology_review": "MIT Technology Review",
    "smart_audio": "Smart Audio (Alexa, Siri, ecc.)",
    "colleague_manager_hr": "Collega / Manager / Reparto Risorse Umane",
    "america_economia": "America Economy",
    "e_and_t": "E&T",
    "reddit": "Reddit",
    "guild_education": "Istruzione di gilda",
    "quantic_blog": "Blog/Articolo quantico",
    "google_or_apple_app_store": "App Store",
    "tiktok": "Tic toc",
    "linkedin_live_event": "Evento dal vivo su LinkedIn",
    "company_ambassador": "Avvocato aziendale",
    "ghin": "Rete globale di impatto sulla salute",
    "advance_in_career": "Per avanzare nella mia carriera",
    "network_with_top_tier_professionals": "Per entrare in contatto con professionisti di alto livello",
    "service_role": "Servizio di assistenza",
    "retail_role": "Posizione al dettaglio",
    "no_identify": "Preferisco non rispondere",
    "non_binary": "Non binario",
    "self_describe": "Preferisco autodescrivermi",
    "latin_american": "latinoamericano o ispanico",
    "white": "Bianco o caucasico",
    "tldr_newsletter": "Notiziario TLDR"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.field_options = window.locales.zh.careers.field_options || {};window.locales.zh.careers.field_options = {...window.locales.zh.careers.field_options, ...{
    "marketing": "营销",           
    "product_management": "产品管理",           
    "legal": "法律",           
    "other": "其他",           
    "consulting": "咨询",           
    "consumer_products": "消费产品",           
    "media": "娱乐 / 媒体",           
    "financial": "金融服务",           
    "health_care": "卫生保健",           
    "manufacturing": "制造业",           
    "nonprofit": "非营利 / 政府",           
    "retail": "零售 / 贸易",           
    "real_estate": "房地产",           
    "technology": "技术",           
    "statistics": "统计",           
    "finance_and_accounting": "财务和会计",           
    "strategy_and_innovation": "策略和创新",           
    "entrepreneurship": "创业",           
    "economics": "经济",           
    "operations_management": "运营管理",           
    "creative": "有创意",           
    "competitive": "有竞争性",           
    "conscientious": "正直",           
    "loyal": "忠诚",           
    "resilient": "适应力强",           
    "optimistic": "乐观",           
    "driven": "有动力",           
    "flexible": "灵活",           
    "balanced": "均衡",           
    "persuasive": "有说服力",           
    "compassionate": "富于同情心",           
    "courageous": "勇敢",           
    "motivating": "激励人心",           
    "meticulous": "细致",           
    "high_energy": "高能量",           
    "results": "结果/致胜",           
    "helping_others": "帮助他人",           
    "career_advancement": "职业发展",           
    "innovation": "创新/发现",           
    "fellowship": "友谊",           
    "personal_growth": "个人成长",           
    "respect": "尊重/认可",           
    "security": "安全",           
    "problem_solving": "解决问题",           
    "leadership": "领导能力",           
    "business_acumen": "商业智慧",           
    "professionalism": "专业素养",           
    "entrepreneurial_mindset": "具有创业精神",           
    "relationship_building": "建立关系",           
    "work_ethic": "职业道德",           
    "design_aesthetic": "设计美学",           
    "communication": "沟通",           
    "resourcefulness": "机智",           
    "best_in_class": "全班最优",           
    "fast_paced": "快节奏",           
    "hierarchical": "分级",           
    "merit_based": "择优",           
    "stable": "稳定/被认可",           
    "entrepreneurial": "具有创业精神",           
    "results_oriented": "注重结果",           
    "harmonious": "和谐",           
    "flat_structured": "平面结构的",           
    "mission_driven": "以任务为动力",           
    "bootstrapped": "自力更生",           
    "seed": "种子",           
    "series_a": "A轮融资",           
    "series_b_plus": "B轮融资",           
    "public": "公共",           
    "rather_not_specify": "不愿具体说明",           
    "lesson_than_1_million": "<$100万",           
    "1_to_5_million": "$100万- $500万",           
    "5_to_50_million": "$500万 - $5000万",           
    "50_to_500_million": "$5000万- $ 5亿",           
    "more_than_500_million": "> $5亿",           
    "1-24": "1-24",           
    "25-99": "25-99",           
    "100-499": "100-499",           
    "500-4999": "500-4999",           
    "5000_plus": "5000+",           
    "senior_management": "高层管理人员",           
    "hiring_manager": "招聘经理",           
    "hr_or_internal_recruiter": "人力资源 /内部招聘员",           
    "contract_recruiter": "合同招聘员",           
    "job_role_other": "其他",           
    "500_plus": "500+",           
    "yes": "是",           
    "no": "否",           
    "american_indian_or_alaska": "美洲印第安人或阿拉斯加土著人",           
    "asian": "亚洲人",           
    "african_american": "黑人或非裔美国人",           
    "native_hawaiian_or_other": "夏威夷土著或其他太平洋岛民",           
    "male": "男性",           
    "female": "女性",           
    "hispanic": "是",           
    "not_hispanic": "否",           
    "start_own_company": "创建自己的公司",           
    "expand_business_knowledge": "拓展我的业务知识",           
    "facebook": "Facebook",           
    "instagram": "Instagram",           
    "search_engine": "谷歌搜索",
    "finance": "金融",           
    "engineering": "工程",           
    "design": "设计",           
    "management_and_leadership": "管理和领导",           
    "more_effective_leader": "成为更有效的领导者",           
    "education": "教育背景",           
    "energy": "能源",           
    "san_francisco": "旧金山港湾区",           
    "new_york": "纽约州纽约",           
    "seattle": "华盛顿州西雅图",           
    "denver": "科罗拉多州丹佛",           
    "boston": "马萨诸塞州波士顿",           
    "los_angeles": "加利福尼亚州洛杉矶",           
    "san_diego": "加利福尼亚州圣地亚哥",           
    "washington_dc": "华盛顿哥伦比亚特区",           
    "chicago": "伊利诺伊州芝加哥",           
    "austin": "德克萨斯州奥斯汀",           
    "dallas": "德克萨斯州拉达斯",           
    "london": "伦敦",           
    "raleigh_durham": "北卡罗来纳州罗利/达勒姆",           
    "miami": "佛罗里达州迈阿密",           
    "houston": "德克萨斯州休斯敦",           
    "seoul": "韩国首尔",           
    "singapore": "新加坡",           
    "dubai": "阿联酋迪拜",           
    "paris": "法国巴黎",           
    "berlin": "德国柏林",           
    "hong_kong": "中国香港",           
    "beijing": "中国北京",           
    "shanghai": "中国上海",           
    "tokyo": "日本东京",           
    "0_1_years": "0-1年",           
    "1_2_years": "1-2年",           
    "2_4_years": "2-4年",           
    "4_6_years": "4-6年",           
    "6_plus_years": "6年以上",           
    "none": "无",           
    "private": "私人",           
    "mba_studies": "MBAStudies.com",           
    "economist": "经济学人",           
    "wsj": "华尔街日报",           
    "data_science": "数据科学",           
    "information_technology": "信息技术（IT）",           
    "general_management": "管理/运营",           
    "research": "研究",           
    "sales": "销售/业务拓展",           
    "ux_ui": "用户体验/用户界面",           
    "graphic_design": "平面设计",           
    "qa": "品质保证",           
    "devops": "开发运营",           
    "investment": "投资",           
    "trading": "贸易",           
    "operations": "运营/项目管理",           
    "human_resources": "人力资源/人才招聘",           
    "administrative": "行政",           
    "account_executive": "客户代表",           
    "account_manager": "客户经理",           
    "customer_success": "客户服务",           
    "sales_engineer": "销售工程师",           
    "government": "政府",           
    "military": "军事",           
    "software_dev_front_end": "软件开发（前端）",           
    "software_dev_back_end": "软件开发（后端）",           
    "software_dev_full_stack": "软件开发（全栈）",           
    "youtube": "YouTube",           
    "data_analysis": "商业分析/只能",           
    "writing": "交流/写作",           
    "medical_services": "医疗服务",           
    "teaching_education": "教学/教育",           
    "art_creative": "建筑/艺术",           
    "wealth_management": "财富管理",           
    "risk_audit": "风险/审计",           
    "logistics_supply_chain": "物流/供应链",           
    "sales_development_rep": "销售开发代表",           
    "consultant": "管理咨询/策略",           
    "linkedin": "领英",           
    "permanent": "固定",           
    "part_time": "兼职",           
    "contract": "正式工",           
    "internship": "实习生",           
    "atlanta": "乔治亚州亚特兰大",           
    "corporate_banking": "投资银行",           
    "fpa_accounting_audit": "财务分析师/会计/管制",           
    "flexible_attribute": "灵活性",           
    "7_10": "7-10",           
    "11_15": "11-15",           
    "16_plus": "16年以上",           
    "senior_management_role": "高层管理人员（副总裁/C级）",           
    "management_role": "管理人员",           
    "professional_role": "专业人士",           
    "administrative_role": "行政",           
    "skilled_labor_role": "熟练工人",           
    "associates": "副学士学位",           
    "undergraduate_bachelor": "本科大学/学士学位",           
    "masters": "硕士学位",           
    "hardware": "电气工程",           
    "chemical": "化学工程",           
    "civil": "土木工程",           
    "mechanical": "机械工程",           
    "aerospace": "航空航天工程",           
    "biomedical": "生物医学工程",           
    "advance_in_current_company": "在目前的工作机构中获得晋升",           
    "get_better_job": "改变工作机构/职业",           
    "arab": "中东或北非人",           
    "friend": "家人/朋友",           
    "tv_cnbc": "电视——美国全国广播公司财经频道",           
    "tv_bloomberg": "电视——彭博",           
    "0_1": "2年以下",           
    "2_6": "2-6",           
    "radio": "音频（播客、广播）",           
    "high_school": "高中文凭",           
    "doctorate": "博士学位",           
    "tv_cnn": "电视——美国有线电视新闻网络",           
    "tv_smithsonian": "电视——史密森尼",           
    "stack_overflow_exchange": "堆栈溢出/交换",           
    "poets_quants": "诗人及定量分析专家",           
    "complimentary_lessons": "我要申请免费课程",           
    "bloomberg_businessweek": "彭博商业周刊",           
    "mit_technology_review": "麻省理工学院技术评论",           
    "smart_audio": "智能音频（Alexa、Siri等）",           
    "colleague_manager_hr": "同事/经理/人力资源部门",           
    "america_economia": "AmÃ©rica Economia",           
    "e_and_t": "E&T",           
    "reddit": "Reddit",           
    "guild_education": "Guild Education",           
    "quantic_blog": "Quantic 博客/文章",
    "google_or_apple_app_store": "应用商店",
    "tiktok": "抖音",
    "linkedin_live_event": "领英现场活动",
    "company_ambassador": "公司代言人",
    "ghin": "全球健康影响网络",
    "advance_in_career": "在我的职业生涯中取得进步",
    "network_with_top_tier_professionals": "与顶级专业人士建立联系",
    "service_role": "支持服务",
    "retail_role": "零售头寸",
    "no_identify": "不想回答",
    "non_binary": "非二元",
    "self_describe": "更喜欢自我描述",
    "latin_american": "拉丁美洲或西班牙裔",
    "white": "白人或白种人",
    "tldr_newsletter": "TLDR 时事通讯"
}
};