window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.basic_info_form = window.locales.am.careers.edit_career_profile.basic_info_form || {};window.locales.am.careers.edit_career_profile.basic_info_form = {...window.locales.am.careers.edit_career_profile.basic_info_form, ...{
    "name": "ሙሉ ስም",           
    "nickname": "የመጀመሪያ ስም ወይም ቅፅል ስም",           
    "nickname_what_prefer": "ማን ብለን ብንጠራዎት ይመርጣሉ?",           
    "nickname_for_example": "ለምሳ፡- የመጀመሪያ ስምዎ",           
    "location": "ቦታ",           
    "mailing_address": "የፖስታ መላኪያ አድራሻ",           
    "mailing_address_description": "በጥብቅ ምስጢራዊ። ለማንነት ማረጋገጫ እና ዲፕሎማ ማድረሻ።",           
    "birthdate": "የትውልድ ቀን",           
    "city_state": "ከተማ፣ ስቴት",           
    "address_line_1": "አድራሻ መስመር 1",           
    "address_line_1_placeholder": "የመንገድ አድራሻ፣ ፖስታ ሳጥን ቁጥ.፣ የኩባንያ ስም፣ c/o",           
    "address_line_2": "አድራሻ መስመር 2",           
    "address_line_2_placeholder": "አፓርትመንት፣ ክፍሎች፣ ክፍል፣ ህንፃ፣ ወለል፣ ወዘተ.",           
    "city": "ከተማ",           
    "state": "ስቴት / ክፍለ ሀገር / ክልል",           
    "zip": "ዚፕ",           
    "country": "ሀገር",           
    "invalid_birthdate": "ልክ ያልሆነ ዕድሜ ({{age}}ዓመት ዕድሜ)"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.basic_info_form = window.locales.ar.careers.edit_career_profile.basic_info_form || {};window.locales.ar.careers.edit_career_profile.basic_info_form = {...window.locales.ar.careers.edit_career_profile.basic_info_form, ...{
    "name": "الاسم بالكامل",           
    "location": "الموقع",           
    "city_state": "المدينة، المقاطعة",           
    "nickname_what_prefer": "ماذا تفضل نحن ندعو لكم؟",
    "mailing_address": "العنوان البريدي",
    "address_line_1": "العنوان الأول",
    "address_line_1_placeholder": "عنوان الشارع، ص. مربع، اسم الشركة، ج / س",
    "address_line_2": "سطر العنوان 2",
    "address_line_2_placeholder": "شقة، جناح، وحدة، وبناء، والكلمة، الخ",
    "city": "مدينة",
    "state": "الدولة / المقاطعة / المنطقة",
    "zip": "ZIP",
    "country": "بلد",
    "birthdate": "تاريخ الميلاد",
    "nickname_for_example": "على سبيل المثال: اسمك الأول",
    "nickname": "الاسم الأول أو الكنية",
    "mailing_address_description": "في سرية تامة. للتحقق من الهوية وتسليم شهادة.",
    "invalid_birthdate": "عمر غير صالح ( {{age}} سنة)"
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.basic_info_form = window.locales.en.careers.edit_career_profile.basic_info_form || {};window.locales.en.careers.edit_career_profile.basic_info_form = {...window.locales.en.careers.edit_career_profile.basic_info_form, ...{
    "name": "Full Name",
    "nickname": "First Name or Nickname",
    "nickname_what_prefer": "What do you prefer we call you?",
    "nickname_for_example": "e.g.: your first name",
    "location": "Location",
    "mailing_address": "Mailing Address",
    "mailing_address_description": "Strictly confidential. For identity verification and diploma delivery.",
    "birthdate": "Birthdate",
    "city_state": "City, State",
    "address_line_1": "Address line 1",
    "address_line_1_placeholder": "Street address, P.O. box, company name, c/o",
    "address_line_2": "Address line 2",
    "address_line_2_placeholder": "Apartment, suite, unit, building, floor, etc.",
    "city": "City",
    "state": "State / Province / Region",
    "zip": "ZIP",
    "country": "Country",
    "invalid_birthdate": "Invalid age ({{age}} years old)"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.basic_info_form = window.locales.es.careers.edit_career_profile.basic_info_form || {};window.locales.es.careers.edit_career_profile.basic_info_form = {...window.locales.es.careers.edit_career_profile.basic_info_form, ...{
    "name": "Nombre completo",           
    "location": "Ubicación",           
    "city_state": "Ciudad, estado",           
    "nickname_what_prefer": "¿Qué prefiere que le llamemos?",
    "mailing_address": "Dirección de envio",
    "address_line_1": "Dirección Línea 1",
    "address_line_1_placeholder": "dirección de la calle, apartado postal, nombre de la empresa, c / o",
    "address_line_2": "Línea de dirección 2",
    "address_line_2_placeholder": "Apartamento, habitación, unidad, edificio, piso, etc.",
    "city": "Ciudad",
    "state": "Estado / Provincia / Región",
    "zip": "cremallera",
    "country": "País",
    "birthdate": "Fecha de nacimiento",
    "nickname_for_example": "por ejemplo: su nombre",
    "nickname": "En primer nombre o apodo",
    "mailing_address_description": "Estrictamente confidencial. Para la verificación de la identidad y la entrega de diplomas.",
    "invalid_birthdate": "Edad inválida ( {{age}} años)"
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.basic_info_form = window.locales.it.careers.edit_career_profile.basic_info_form || {};window.locales.it.careers.edit_career_profile.basic_info_form = {...window.locales.it.careers.edit_career_profile.basic_info_form, ...{
    "profile_photo": "Foto Profilo",           
    "name": "Nome e Cognome",           
    "nickname": "Nome di battesimo o Nickname",           
    "nickname_what_prefer": "Come preferisci che ti chiamiamo?",           
    "nickname_for_example": "ad es. il tuo nome di battesimo",           
    "location": "Località",           
    "mailing_address": "Indirizzo Postale",           
    "mailing_address_description": "Strettamente confidenziale. A scopo di verifica identità e di consegna diplomi.",           
    "birthdate": "Data di nascita",           
    "city_state": "Comune, Regione",           
    "address_line_1": "Riga indirizzo 1",           
    "address_line_1_placeholder": "Via, casella postale, ditta, c/o",           
    "address_line_2": "Riga indirizzo 2",           
    "address_line_2_placeholder": "Appartamento, alloggio, unità abitativa, edificio, piano, ecc.",           
    "city": "Comune",           
    "state": "Stato / Provincia / Regione",           
    "zip": "CAP",           
    "country": "Paese",           
    "invalid_birthdate": "Età non valida ( {{age}} anni)"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.basic_info_form = window.locales.zh.careers.edit_career_profile.basic_info_form || {};window.locales.zh.careers.edit_career_profile.basic_info_form = {...window.locales.zh.careers.edit_career_profile.basic_info_form, ...{
    "name": "全名",           
    "location": "位置",           
    "city_state": "市，州",           
    "nickname_what_prefer": "你希望我们怎么称呼你？",           
    "mailing_address": "邮寄地址",           
    "address_line_1": "地址栏1",           
    "address_line_1_placeholder": "街道地址、邮政信箱、公司名称、转交人",           
    "address_line_2": "地址栏2",           
    "address_line_2_placeholder": "公寓、套房、单元、建筑物、楼层等。",           
    "city": "城市",           
    "state": "州/省/地区",           
    "zip": "邮编",           
    "country": "国家",           
    "birthdate": "生日",           
    "nickname_for_example": "例如：你的名字",           
    "nickname": "名字或昵称",           
    "mailing_address_description": "绝密。用于身份验证和文凭发放。",           
    "invalid_birthdate": "无效年龄（{{age}}岁）"           
}
};