window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.profile_photo_form_section = window.locales.am.careers.edit_career_profile.profile_photo_form_section || {};window.locales.am.careers.edit_career_profile.profile_photo_form_section = {...window.locales.am.careers.edit_career_profile.profile_photo_form_section, ...{
    "profile_photo": "የግለ-መገለጫ ፎቶ",           
    "upload_avatar_desc": "ፈገግ ይበሉ! ፎቶዎ ለተማሪዎ እና ለሙያ አውታረ መረብ ግለ-መገለጫዎ ስብዕና ይጨምራል።",           
    "upload_avatar_desc_alt": "ፈገግ ይበሉ! ፎቶዎ ለሙያ አውታረ መረብ ግለ-መገለጫዎ ስብዕና ይጨምራል።",           
    "upload_avatar_note": "የሙያውን መረብ ከተቀላቀሉ፣ የግለ-መገለጫ ስእልዎ ለክፍል ጓደኞችዎ እና ለቀጣሪዎች ይታያል።",           
    "upload_avatar_note_alt": "የግለ-መገለጫ ስእልዎ በእኛ የስራ መረብ ውስጥ ለቀጣሪዎች በይፋ ይታያል።",           
    "upload_avatar_desc_classmates_only": "ፈገግ ይበሉ! ፎቶህ በተማሪ መገለጫህ ላይ ስብዕናን ይጨምራል።",
    "upload_avatar_note_classmates_only": "የመገለጫ ስእልህ ለክፍል ጓደኞች እና ተማሪዎች ይታያል።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.profile_photo_form_section = window.locales.ar.careers.edit_career_profile.profile_photo_form_section || {};window.locales.ar.careers.edit_career_profile.profile_photo_form_section = {...window.locales.ar.careers.edit_career_profile.profile_photo_form_section, ...{
    "profile_photo": "صورة الملف الشخصي",           
    "upload_avatar_desc": "ابتسامة! تضيف صورتك الشخصية إلى ملف تعريف شبكة الطالب والوظيفة.",
    "upload_avatar_desc_alt": "ابتسم! تضيف صورتك طابعاً شخصياً إلى ملفك الشخصي على شبكة الوظائف.",           
    "upload_avatar_note": "ستكون صورة ملفك الشخصي مرئية لزملائك وأصحاب العمل ، إذا انضممت إلى شبكة التوظيف.",
    "upload_avatar_note_alt": "ستكون صورة ملفك الشخصي مرئية للعامة لأصحاب العمل في شبكتنا المهنية.",
    "upload_avatar_desc_classmates_only": "يبتسم! تضيف صورتك شخصية إلى ملف تعريف الطالب الخاص بك.",
    "upload_avatar_note_classmates_only": "ستكون صورة ملفك الشخصي مرئية لزملاء الدراسة والخريجين."
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.profile_photo_form_section = window.locales.en.careers.edit_career_profile.profile_photo_form_section || {};window.locales.en.careers.edit_career_profile.profile_photo_form_section = {...window.locales.en.careers.edit_career_profile.profile_photo_form_section, ...{
    "profile_photo": "Profile Photo",
    "upload_avatar_desc": "Smile! Your photo adds personality to your student and career network profile.",
    "upload_avatar_desc_alt": "Smile! Your photo adds personality to your career network profile.",
    "upload_avatar_note": "Your profile picture will be visible to classmates and employers, should you join the career network.",
    "upload_avatar_note_alt": "Your profile picture will be publicly visible to employers in our career network.",
    "upload_avatar_desc_classmates_only": "Smile! Your photo adds personality to your student profile.",
    "upload_avatar_note_classmates_only": "Your profile picture will be visible to classmates and alumni."
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.profile_photo_form_section = window.locales.es.careers.edit_career_profile.profile_photo_form_section || {};window.locales.es.careers.edit_career_profile.profile_photo_form_section = {...window.locales.es.careers.edit_career_profile.profile_photo_form_section, ...{
    "profile_photo": "Foto de perfil",           
    "upload_avatar_desc": "¡Sonreír! Su foto agrega personalidad a su perfil de red de estudiantes y carreras.",
    "upload_avatar_desc_alt": "¡Sonría! Su foto añade personalidad a su perfil en la red profesional.",           
    "upload_avatar_note": "Su foto de perfil será visible para los compañeros de clase y los empleadores, si se une a la red de carreras.",
    "upload_avatar_note_alt": "Su foto de perfil será visible públicamente para los empleadores en nuestra red de carreras.",
    "upload_avatar_desc_classmates_only": "¡Sonreír! Tu foto añade personalidad a tu perfil de estudiante.",
    "upload_avatar_note_classmates_only": "Tu foto de perfil será visible para tus compañeros y ex alumnos."
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.profile_photo_form_section = window.locales.it.careers.edit_career_profile.profile_photo_form_section || {};window.locales.it.careers.edit_career_profile.profile_photo_form_section = {...window.locales.it.careers.edit_career_profile.profile_photo_form_section, ...{
    "profile_photo": "Foto Profilo",           
    "upload_avatar_desc": "Sorriso, sorridere! La tua foto aggiunge personalità al tuo profilo di rete di studenti e di carriera.",
    "upload_avatar_desc_alt": "Sorridi! La tua foto aggiunge personalità al tuo profilo di rete professionale.",           
    "upload_avatar_note": "L'immagine del tuo profilo sarà visibile ai compagni di classe e ai datori di lavoro, se ti unisci alla rete di carriera.",
    "upload_avatar_note_alt": "L'immagine del tuo profilo sarà pubblicamente visibile ai datori di lavoro nella nostra rete di carriera.",
    "upload_avatar_desc_classmates_only": "Sorriso! La tua foto aggiunge personalità al tuo profilo studente.",
    "upload_avatar_note_classmates_only": "La tua immagine del profilo sarà visibile ai compagni di classe e agli ex studenti."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.profile_photo_form_section = window.locales.zh.careers.edit_career_profile.profile_photo_form_section || {};window.locales.zh.careers.edit_career_profile.profile_photo_form_section = {...window.locales.zh.careers.edit_career_profile.profile_photo_form_section, ...{
    "profile_photo": "头像照片",           
    "upload_avatar_desc": "微笑！你的学员和求职网络档案因你的照片而增光添彩。",           
    "upload_avatar_desc_alt": "微笑！您的照片会给您的\n职业网络主页添加个性。",           
    "upload_avatar_note": "如果你加入了求职网络，你的同学和雇主都会看到你的个人资料图片。",           
    "upload_avatar_note_alt": "你的个人资料图片将在我们的求职网络中向雇主公开。",           
    "upload_avatar_desc_classmates_only": "微笑！您的照片为您的学生档案增添了个性。",
    "upload_avatar_note_classmates_only": "您的个人资料照片将对同学和校友可见。"
}
};