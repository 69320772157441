window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.schedule_interview_modal = window.locales.am.careers.schedule_interview_modal || {};window.locales.am.careers.schedule_interview_modal = {...window.locales.am.careers.schedule_interview_modal, ...{
    "title": "አመሰግናለሁ!",
    "body": "ማመልከቻህን ተቀብለናል። ቀጣዩ እርምጃ ቃለ መጠይቅዎን ከመግቢያ አማካሪ ጋር ቀጠሮ ማስያዝ ነው። ቃለ-መጠይቁ በጽሁፍ ማመልከቻዎ ላይ ለማስፋት እና ስለ ፕሮግራሙ የበለጠ እየተማሩበት እድልዎ ነው። እባክዎን ከዚህ በታች ያለውን መተግበሪያ በመጠቀም ቃለ መጠይቅዎን ያቅዱ።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.schedule_interview_modal = window.locales.ar.careers.schedule_interview_modal || {};window.locales.ar.careers.schedule_interview_modal = {...window.locales.ar.careers.schedule_interview_modal, ...{
    "title": "شكرًا لك!",
    "body": "لقد تلقينا طلبك. الخطوة التالية هي تحديد موعد للمقابلة مع مستشار القبول. تمثل المقابلة فرصتك للتوسع في طلبك المكتوب، وفي نفس الوقت تعلم المزيد عن البرنامج. يرجى تحديد موعد للمقابلة باستخدام التطبيق أدناه."
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.schedule_interview_modal = window.locales.en.careers.schedule_interview_modal || {};window.locales.en.careers.schedule_interview_modal = {...window.locales.en.careers.schedule_interview_modal, ...{
    "title": "Thank You!",
    "body": "We have received your application. The next step is to schedule your interview with an Admissions Counselor. The interview is your opportunity to expand on your written application, while also learning more about the program. Please schedule your interview using the below application."
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.schedule_interview_modal = window.locales.es.careers.schedule_interview_modal || {};window.locales.es.careers.schedule_interview_modal = {...window.locales.es.careers.schedule_interview_modal, ...{
    "title": "¡Gracias!",
    "body": "Hemos recibido su solicitud. El siguiente paso es programar su entrevista con un Consejero de Admisiones. La entrevista es su oportunidad de ampliar su solicitud escrita y, al mismo tiempo, aprender más sobre el programa. Programe su entrevista utilizando la siguiente aplicación."
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.schedule_interview_modal = window.locales.it.careers.schedule_interview_modal || {};window.locales.it.careers.schedule_interview_modal = {...window.locales.it.careers.schedule_interview_modal, ...{
    "title": "Grazie!",
    "body": "Abbiamo ricevuto la tua candidatura. Il passaggio successivo è programmare il colloquio con un consulente per l'ammissione. Il colloquio è la tua opportunità per espandere la tua domanda scritta e allo stesso tempo per saperne di più sul programma. Si prega di pianificare il colloquio utilizzando l'applicazione seguente."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.schedule_interview_modal = window.locales.zh.careers.schedule_interview_modal || {};window.locales.zh.careers.schedule_interview_modal = {...window.locales.zh.careers.schedule_interview_modal, ...{
    "title": "谢谢你！",
    "body": "我们已收到您的申请。下一步是安排与招生顾问的面谈。面试是您扩展书面申请的机会，同时也可以了解有关该计划的更多信息。请使用以下应用程序安排您的面试。"
}
};