window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.education_experience_detail = window.locales.am.careers.edit_career_profile.education_experience_detail || {};window.locales.am.careers.edit_career_profile.education_experience_detail = {...window.locales.am.careers.edit_career_profile.education_experience_detail, ...{
    "add_education": "ሌላ ፕሮግራም አክል",           
    "add_education_first": "ፕሮግራም አክል",           
    "college": "ዩኒቨርሲቲ/ኮሌጅ",           
    "degree": "ዲግሪ",           
    "institution_location": "የተቋሙ መገኛ (ሀገር)",           
    "grad_year": "የምረቃ አመት",           
    "year_completed": "የተጠናቀቀው አመት",           
    "major": "ዋና ወይም ስፔሻላይዝ ያደረገበት",           
    "minor": "አነስተኛ (አማራጭ)",           
    "gpa": "GPA (አስፈላጊ ከሆነ)",           
    "gpa_description": "ከ 4.0 ውጭ ካልሆነ የGPA ልኬት ይግለፁ",           
    "if_accepted_provide_copies": "ተቀባይነት ካገኘ በኋላ፣ ይፋዊ የትምህርት ቅጂዎችን እንፈልጋለን።",           
    "non_degree_programs": "ለሙያዊ ትምህርትዎ አስተዋፅዖን ያደረጉ ማናቸውንም አይነት የምስክር ወረቀቶች ወይም የዲግሪ ያልሆኑ ፕሮግራሞችን መዘርዘር ይችላሉ።",           
    "associate_of_applied_arts": "A.A.A.",           
    "associate_of_applied_arts_full": "የተይግቡር አርት ተባባሪ",           
    "associate_of_applied_science": "A.A.S.",           
    "associate_of_applied_science_full": "የተይግቡር ሳይንስ ተባባሪ",           
    "associate_of_arts": "A.A.",           
    "associate_of_arts_full": "የአርት ተባባሪ",           
    "associate_of_engineering": "A.E.",           
    "associate_of_engineering_full": "የምህንድስና ተባባሪ",           
    "associate_of_political_science": "A.P.S.",           
    "associate_of_political_science_full": "የፖለቲካ ሳይንስ ተባባሪ",           
    "associate_of_science": "A.S.",           
    "associate_of_science_full": "የሳይንስ ተባባሪ",           
    "bachelor_of_accounting": "B.Acc.",           
    "bachelor_of_accounting_full": "የአካውንቲንግ ባችለር",           
    "bachelor_of_accountancy": "B.Acy.",           
    "bachelor_of_accountancy_full": "የሂሳብ አያያዝ ባችለር",           
    "bachelor_of_applied_science": "B.App.Sc.",           
    "bachelor_of_applied_science_2": "B.A.Sc.",           
    "bachelor_of_applied_science_full": "የተይግቡር ሳይንስ ባችለር",           
    "bachelor_of_architecture": "B.Arch.",           
    "bachelor_of_architecture_full": "የአርክቴክቸር ባችለር",           
    "bachelor_of_arts": "B.A.",           
    "bachelor_of_arts_2": "A.B.",           
    "bachelor_of_arts_full": "የስነ-ጥበብ ባችለር",           
    "bachelor_of_arts_honours": "B.A. (የክብር)",           
    "bachelor_of_arts_honours_full": "የአርት ባችለር በክብር",           
    "bachelor_of_business_administration": "B.B.A.",           
    "bachelor_of_business_administration_full": "የቢዝነስ አድሚንስትሬሽን ባችለር",           
    "bachelor_of_business_management": "B.B.M.",           
    "bachelor_of_business_management_full": "የቢዝነስ ማኔጅመንት ባችለር",           
    "bachelor_of_business_studies": "B.B.S.",           
    "bachelor_of_business_studies_full": "የቢዝነስ ጥናቶች ባችለር",           
    "bachelor_of_civil_engineering": "B.C.E.",           
    "bachelor_of_civil_engineering_full": "የሲቪል ምህንድስና ባችለር",           
    "bachelor_of_commerce": "B.Com.",           
    "bachelor_of_commerce_full": "የኮሜርስ ባችለር",           
    "bachelor_of_commerce_honours": "B.Com. (የክብር)",           
    "bachelor_of_commerce_honours_2": "H.B.Com.",           
    "bachelor_of_commerce_honours_full": "የኮሜርስ ባችለር በክብር",           
    "bachelor_of_computer_applications": "B.C.A",           
    "bachelor_of_computer_applications_full": "የኮምፒውተር መተግበሪያዎች ባችለር",           
    "bachelor_of_computer_science": "B.Comp.Sc.",           
    "bachelor_of_computer_science_2": "B.C.S.",           
    "bachelor_of_computer_science_3": "B.S.C.S.",           
    "bachelor_of_computer_science_full": "የኮምፒውተር ሳይንስ ባችለር",           
    "bachelor_of_dentistry": "B.D.S.",           
    "bachelor_of_dentistry_full": "የጥርስ ህክምና ባችለር",           
    "bachelor_of_education": "B.Ed.",           
    "bachelor_of_education_full": "የትምህርት ባችለር",           
    "bachelor_of_electrical_engineering": "B.E.E.",           
    "bachelor_of_electrical_engineering_full": "የኤሌክትሪካል ምህንድስና ባችለር",           
    "bachelor_of_engineering": "B.E.",           
    "bachelor_of_engineering_2": "B.Eng.",           
    "bachelor_of_engineering_full": "የምህንድስና ባችለር",           
    "bachelor_of_engineering_honours": "B.Eng. (የክብር)",           
    "bachelor_of_engineering_honours_full": "የምህንድስና ባችለር ከማዕረግ ጋር",           
    "bachelor_of_engineering_in_electrical_engineering": "B.E.E.E.",           
    "bachelor_of_engineering_in_electrical_engineering_full": "በኤሌክትሪካል ምህንድስና የምህንድስና ባችለር",           
    "bachelor_of_fine_arts": "B.F.A.",           
    "bachelor_of_fine_arts_full": "የእደ-ጥበብ ባችለር",           
    "bachelor_of_information_technology": "B.I.T.",           
    "bachelor_of_information_technology_full": "የኢንፎርሜሽን ቴክኖሎጂ ባችለር",           
    "bachelor_of_laws": "B.L.",           
    "bachelor_of_laws_2": "LL.B.",           
    "bachelor_of_laws_full": "የህግ ባችለር",           
    "bachelor_of_laws_honours": "LL.B. (የክብር)",           
    "bachelor_of_laws_honours_full": "የህግ ባችለር በክብር",           
    "bachelor_of_library_science": "B.Lib.",           
    "bachelor_of_library_science_2": "B.L.S.",           
    "bachelor_of_library_science_full": "የቤተ መፃህፍት ሳይንስ ባችለር",           
    "bachelor_of_literature": "B.Lit.",           
    "bachelor_of_literature_full": "የስነ-ፅሁፍ ባችለር",           
    "bachelor_of_management_studies": "B.M.S.",           
    "bachelor_of_management_studies_full": "የአስተዳደር ጥናቶች ባችለር",           
    "bachelor_of_mathematics": "B.Math.",           
    "bachelor_of_mathematics_full": "የሂሳብ ባችለር",           
    "bachelor_of_mechanical_engineering": "B.M.E.",           
    "bachelor_of_mechanical_engineering_full": "የሜካኒካል ምህንድስና ባችለር",           
    "bachelor_of_medical_laboratory_science": "B.M.L.Sc.",           
    "bachelor_of_medical_laboratory_science_full": "የህክምና ላቦራቶሪ ሳይንስ ባችለር",           
    "bachelor_of_medical_science": "B.Med.Sc.",           
    "bachelor_of_medical_science_full": "የህክምና ሳይንስ ባችለር",           
    "bachelor_of_medicine": "M.B.",           
    "bachelor_of_medicine_full": "የህክምና ባችለር",           
    "bachelor_of_medicine_and_surgery": "M.B.B.S.",           
    "bachelor_of_medicine_and_surgery_2": "B.M.B.S.",           
    "bachelor_of_medicine_and_surgery_3": "M.B Ch.B.",           
    "bachelor_of_medicine_and_surgery_4": "M.B. B.Ch. (Oxon)",           
    "bachelor_of_medicine_and_surgery_5": "M.B. B.Ch.",           
    "bachelor_of_medicine_and_surgery_6": "M.B. B.Chir. (Cantab)",           
    "bachelor_of_medicine_and_surgery_full": "የህክምና ባችለር፣ የቀዶ ህክምና ባችለር",           
    "bachelor_of_music": "B.M.",           
    "bachelor_of_music_2": "B.Mus.",           
    "bachelor_of_music_full": "የሙዚቃ ባችለር",           
    "bachelor_of_nursing": "B.N.",           
    "bachelor_of_nursing_full": "የነርስ ባችለር",           
    "bachelor_of_pharmacy": "B.Pharm",           
    "bachelor_of_pharmacy_2": "Pharm.B.",           
    "bachelor_of_pharmacy_full": "የፋርማሲ ባችለር",           
    "bachelor_of_philosophy": "B.Ph.",           
    "bachelor_of_philosophy_full": "የፍልስፍና ባችለር",           
    "bachelor_of_physical_therapy_and_physiotherapy": "B.P.T.",           
    "bachelor_of_physical_therapy_and_physiotherapy_full": "የፊዚካል ቴራፒ ባችለር፣ የፊዚዮቴራፒ ባችለር",           
    "bachelor_of_science": "B.S.",           
    "bachelor_of_science_2": "B.Sc.",           
    "bachelor_of_science_full": "የሳይንስ ባችለር",           
    "bachelor_of_science_honours": "B.Sc. (የክብር)",           
    "bachelor_of_science_honours_full": "የሳይንስ ባችለር በክብር",           
    "bachelor_of_science_in_education": "B.S.Ed.",           
    "bachelor_of_science_in_education_full": "በትምህርት ሳይንስ ባችለር",           
    "bachelor_of_science_in_electrical_engineering": "B.Sc.E.E.",           
    "bachelor_of_science_in_electrical_engineering_2": "B.S.E.E.",           
    "bachelor_of_science_in_electrical_engineering_full": "በኤሌክትሪካል ምህንድስና የሳይንስ ባችለር",           
    "bachelor_of_science_in_nursing": "B.S.N.",           
    "bachelor_of_science_in_nursing_full": "በነርሲንግ የሳይንስ ባችለር",           
    "bachelor_of_technology": "B.Tech.",           
    "bachelor_of_technology_full": "የቴክኖሎጂ ባችለር",           
    "bachelor_of_technology_honours": "B.Tech. (የክብር)",           
    "bachelor_of_technology_honours_full": "የቴክኖሎጂ ባችለር በክብር",           
    "common_professional_examination": "C.P.E.",           
    "common_professional_examination_full": "የተለመደ የባለሙያ ፈተና",           
    "diploma_of_college_studies": "D.C.S.",           
    "diploma_of_college_studies_full": "የኮሌጅ ጥናቶች ዲፕሎማ",           
    "doctor_of_dental_surgery": "D.D.S.",           
    "doctor_of_dental_surgery_full": "የጥርስ ህክምና ዶክተር",           
    "doctor_of_education": "Ed.D.",           
    "doctor_of_education_full": "የትምህርት ዶክተር",           
    "doctor_of_medicine": "M.D.",           
    "doctor_of_medicine_full": "የህክምና ዶክተር",           
    "doctor_of_medicine_in_dentistry": "D.M.D.",           
    "doctor_of_medicine_in_dentistry_full": "በጥርስ ህክምና ውስጥ የህክምና ዶክተር",           
    "doctor_of_naturopathic_medicine": "N.D.",           
    "doctor_of_naturopathic_medicine_full": "የናቱሮፓቲክ ህክምና ዶክተር",           
    "doctor_of_nursing_practice": "D.N.P.",           
    "doctor_of_nursing_practice_full": "የነርሲንግ ልምምድ ዶክተር",           
    "doctor_of_osteopathic_medicine": "D.O.",           
    "doctor_of_osteopathic_medicine_full": "የኦስቲዮፓቲክ ህክምና ዶክተር",           
    "doctor_of_pharmacy": "Pharm.D.",           
    "doctor_of_pharmacy_2": "D.Pharm.",           
    "doctor_of_pharmacy_full": "የፋርማሲ ዶክተር",           
    "doctor_of_philosophy": "Ph.D.",           
    "doctor_of_philosophy_2": "D.Phil.",           
    "doctor_of_philosophy_full": "የፍልስፍና ዶክተር",           
    "doctor_of_physical_therapy_and_physiotherapy": "D.P.T.",           
    "doctor_of_physical_therapy_and_physiotherapy_full": "የፊዚካል ቴራፒ ዶክትሬት፣ የፊዚዮቴራፒ ዶክተር",           
    "doctor_of_veterinary_medicine": "D.V.M.",           
    "doctor_of_veterinary_medicine_full": "የእንስሳት ህክምና ዶክትሬት",           
    "graduate_diploma": "G.Dip.",           
    "graduate_diploma_2": "GradDip",           
    "graduate_diploma_full": "የዲፕሎማ ተመራቂ",           
    "graduate_diploma_in_law": "G.D.L.",           
    "graduate_diploma_in_law_full": "በህግ የዲፕሎማ ተመራቂ",           
    "high_school_diploma": "H.S.D.",           
    "high_school_diploma_full": "የሁለተኛ ደረጃ ትምህር ቤት ዲፕሎማ",           
    "higher_national_diploma": "HND",           
    "higher_national_diploma_full": "ከፍተኛ ብሄራዊ ዲፕሎማ",           
    "juris_doctor": "J.D.",           
    "juris_doctor_full": "የዳኝነት ዶክተር",           
    "master_in_urban_planning": "M.U.P.",           
    "master_in_urban_planning_full": "በከተማ ፕላን ሁለተኛ ዲግሪ",           
    "master_of_advanced_studies": "M.A.S.",           
    "master_of_advanced_studies_2": "M.A.St.",           
    "master_of_advanced_studies_full": "በላቁ ጥናቶች ሁለተኛ ዲግሪ",           
    "master_of_architecture": "M.Arch.",           
    "master_of_architecture_full": "በአርክቴክቸር ሁለተኛ ዲግሪ",           
    "master_of_arts": "M.A.",           
    "master_of_arts_2": "A.M.",           
    "master_of_arts_full": "በአርት ሁለተኛ ዲግሪ",           
    "master_of_business_administration": "M.B.A.",           
    "master_of_business_administration_full": "በቢዝነስ አድሚንስትሬሽን ሁለተኛ ዲግሪ",           
    "master_of_chemistry": "M.Chem.",           
    "master_of_chemistry_full": "በኬሚስትሪ ሁለተኛ ዲግሪ",           
    "master_of_commerce": "M.Com.",           
    "master_of_commerce_2": "M.Comm.",           
    "master_of_commerce_3": "Mag.Comm.",           
    "master_of_commerce_full": "በቢዝነስ ሁለተኛ ዲግሪ",           
    "master_of_computer_applications": "M.C.A.",           
    "master_of_computer_applications_full": "የኮምፒውተር መተግበሪያዎች ሁለተኛ ዲግሪ",           
    "master_of_divinity": "M.Div",           
    "master_of_divinity_full": "በመለኮት ሁለተኛ ዲግሪ",           
    "master_of_education": "M.Ed.",           
    "master_of_education_2": "Ed.M.",           
    "master_of_education_full": "በትምህርት ሁለተኛ ዲግሪ",           
    "master_of_engineering": "M.Eng.",           
    "master_of_engineering_2": "M.E.",           
    "master_of_engineering_full": "በምህንድስና ሁለተኛ ዲግሪ",           
    "master_of_fine_arts": "M.F.A.",           
    "master_of_fine_arts_full": "በስነ-ጥበብ ሁለተኛ ዲግሪ",           
    "master_of_international_affairs": "M.I.A.",           
    "master_of_international_affairs_full": "በአለም አቀፍ ጉዳዮች ሁለተኛ ዲግሪ",           
    "master_of_international_studies": "M.I.S.",           
    "master_of_international_studies_full": "በዓለም አቀፍ ጥናቶች ሁለተኛ ዲግሪ",           
    "master_of_laws": "LL.M.",           
    "master_of_laws_full": "በሕግ ሁለተኛ ዲግሪ",           
    "master_of_music": "M.M.",           
    "master_of_music_2": "M.Mus.",           
    "master_of_music_full": "በሙዚቃ ሁለተኛ ዲግሪ",           
    "master_of_pharmacy": "M.Pharm.",           
    "master_of_pharmacy_2": "Pharm.M.",           
    "master_of_pharmacy_full": "በፋርማሲ ሁለተኛ ዲግሪ",           
    "master_of_philosophy": "M.Phil.",           
    "master_of_philosophy_full": "በፍልስፍና ሁለተኛ ዲግሪ",           
    "master_of_physical_therapy_and_physiotherapy": "M.P.T.",           
    "master_of_physical_therapy_and_physiotherapy_full": "በአካላዊ ቴራፒ ማስተር፣ በፊዚዮ ቴራፒ ሁለተኛ ዲግሪ",           
    "master_of_physics": "M.Phys.",           
    "master_of_physics_full": "በፊዚክስ ሁለተኛ ዲግሪ",           
    "master_of_physics_honours": "M.Phys. (የክብር)",           
    "master_of_physics_honours_full": "በፊዚክስ ሁለተኛ ዲግሪ በክብር",           
    "master_of_public_health": "M.P.H.",           
    "master_of_public_health_full": "በህዝብ ጤና ሁለተኛ ዲግሪ",           
    "master_of_research": "M.Res.",           
    "master_of_research_full": "የጥናት እና ምርምር ሁለተኛ ዲግሪ",           
    "master_of_science": "M.S.",           
    "master_of_science_2": "S.M.",           
    "master_of_science_3": "M.Sc.",           
    "master_of_science_4": "Sc.M.",           
    "master_of_science_full": "በሳይንስ ሁለተኛ ዲግሪ",           
    "master_of_science_in_education": "M.S.Ed.",           
    "master_of_science_in_education_full": "በትምህርት የሳይንስ ሁለተኛ ዲግሪ",           
    "master_of_social_work": "M.S.W.",           
    "master_of_social_work_full": "በማህበራዊ ስራ ሁለተኛ ዲግሪ",           
    "master_of_studies": "M.St.",           
    "master_of_studies_full": "የጥናት ሁለተኛ ዲግሪ",           
    "master_of_technology": "M.Tech.",           
    "master_of_technology_full": "በቴክኖሎጂ ሁለተኛ ዲግሪ",           
    "nurse_practitioner": "N.P.",           
    "nurse_practitioner_full": "ነርስ ባለሙያ",           
    "postgraduate_diploma": "P.G.Dip.",           
    "postgraduate_diploma_2": "Pg.D.",           
    "postgraduate_diploma_3": "Pg.Dip.",           
    "postgraduate_diploma_4": "PostGradDip",           
    "postgraduate_diploma_full": "የድህረ ምረቃ ዲፕሎማ",           
    "postgraduate_diploma_in_law": "P.G.D.L.",           
    "postgraduate_diploma_in_law_full": "በህግ የድህረ ምረቃ ዲፕሎማ",           
    "education_blank_required": "እባክዎ ቢያንስ አንድ የትምህርት ግቤት ያክሉ።",           
    "select": "ይምረጡ",           
    "yes": "አዎ",           
    "no": "አይ",           
    "last_year_attended": "የተሳተፉበት የመጨረሻ አመት",           
    "add_degree": "ሌላ የዲግሪ ፕሮግራም አክል",           
    "add_degree_first": "የዲግሪ ፕሮግራም አክል",           
    "institution_name": "የተቋሙ ስም",           
    "program_name": "የፕሮግራሙ ስም",           
    "have_completed": "ይህንን ፕሮግራም አጠናቅቄአለሁ ወይም አጠናቅቃለሁ ብዬ እጠብቃለሁ፡-",           
    "completed_year": "የተጠናቀቀው አመት",           
    "start_year": "የጀመረበት ዓመት",           
    "end_year": "ያበቃበት ዓመት",           
    "degree_tip": "ከዝርዝሩ ውስጥ ይምረጡ ወይም የራስዎን ለማከል ይተይቡ።",           
    "have_already_graduated": "ከዚህ ቀደም በዚህ ዲግሪ ተመርቀዋል?",           
    "expect_to_graduate": "በዚህ ዲግሪ ለመመረቅ ይጠብቃሉ?",           
    "country_not_applicable": "ተይግቡራዊ አይደለም / ኦንላይን",           
    "country_not_listed": "ሀገሬ አልሰፈረም",           
    "executive_master_of_business_administration": "E.M.B.A.",           
    "executive_master_of_business_administration_full": "በቢዝነስ አድሚንስትሬሽን ዋና ሁለተኛ ዲግሪ",           
    "education_starting_with": "እባኮትን ያገኛችሁትን ወይም የተመዘገቡትን <strong>መደበኛ ዲግሪዎች</strong> ከቅድመ ምረቃ ወይም ተመጣጣኝ የዩኒቨርሲቲ ዲግሪ ጀምሮ ይዘርዝሩ። ይህ ለማስተርስ ዲግሪ ፕሮግራሞቻችን ግምት ውስጥ ማስገባት ያስፈልጋል። የምስክር ወረቀቶችን፣ የዲግሪ ያልሆኑ ፕሮግራሞችን ወይም ሌሎች ዲፕሎማዎችን በሚቀጥለው ክፍል መዘርዘር ይችላሉ።",
    "education_starting_with_slim_application": "እባኮትን ያገኛችሁትን ወይም የተመዘገቡትን <strong>መደበኛ ዲግሪዎች</strong> ከቅድመ ምረቃ ወይም ተመጣጣኝ የዩኒቨርሲቲ ዲግሪ ጀምሮ ይዘርዝሩ። ይህ ለማስተርስ ድግሪ መርሃ ግብሮቻችን ግምት ውስጥ ማስገባት ያስፈልጋል። የምስክር ወረቀቶችን፣ የዲግሪ ያልሆኑ ፕሮግራሞችን ወይም ሌሎች ዲፕሎማዎችን በሚቀጥለው ክፍል መዘርዘር ይችላሉ።",
    "education_instructions_when_checkbox_visible": "እባኮትን ያገኛችሁትን ወይም የተመዘገቡትን <strong>መደበኛ ዲግሪዎች</strong> ከቅድመ ምረቃ ወይም ተመጣጣኝ የዩኒቨርሲቲ ዲግሪ ጀምሮ ይዘርዝሩ። ይህ ለማስተርስ ድግሪ መርሃ ግብሮቻችን ግምት ውስጥ ማስገባት ያስፈልጋል። የምስክር ወረቀቶችን፣ የዲግሪ ያልሆኑ ፕሮግራሞችን ወይም ሌሎች ዲፕሎማዎችን በሚቀጥለው ክፍል መዘርዘር ይችላሉ።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.education_experience_detail = window.locales.ar.careers.edit_career_profile.education_experience_detail || {};window.locales.ar.careers.edit_career_profile.education_experience_detail = {...window.locales.ar.careers.edit_career_profile.education_experience_detail, ...{
    "college": "الجامعة / الكلية",           
    "degree": "الدرجة",           
    "grad_year": "سنة التخرج",           
    "minor": "الجزئي (اختياري)",           
    "associate_of_applied_arts": "A.A.A.",           
    "associate_of_applied_science": "A.A.S.",           
    "associate_of_arts": "A.A.",           
    "associate_of_engineering": "A.E.",           
    "associate_of_political_science": "A.P.S.",           
    "associate_of_science": "A.S.",           
    "bachelor_of_architecture": "B.Arch.",           
    "bachelor_of_arts": "B.A.",           
    "bachelor_of_arts_2": "A.B.",           
    "bachelor_of_business_administration": "B.B.A.",           
    "bachelor_of_engineering": "B.E.",           
    "bachelor_of_science": "B.S.",           
    "doctor_of_education": "Ed.D.",           
    "doctor_of_medicine": "M.D.",           
    "doctor_of_philosophy": "Ph.D.",           
    "doctor_of_philosophy_2": "D.Phil.",           
    "doctor_of_veterinary_medicine": "D.V.M.",           
    "juris_doctor": "J.D.",           
    "master_of_advanced_studies": "M.A.S.",           
    "master_of_advanced_studies_2": "M.A.St.",           
    "master_of_arts": "M.A.",           
    "master_of_arts_2": "A.M.",           
    "master_of_business_administration": "M.B.A.",           
    "master_of_engineering": "M.Eng.",           
    "master_of_laws": "LL.M.",           
    "master_of_philosophy": "M.Phil.",           
    "master_of_research": "M.Res.",           
    "master_of_science": "M.S.",           
    "master_of_science_2": "S.M",           
    "master_of_studies": "M.St.",           
    "master_of_technology": "M.Tech.",           
    "gpa_description": "GPA الوصف (اختياري)",
    "education_blank_required": "يرجى إضافة إدخال تعليم واحد على الأقل",
    "add_education": "إضافة التعليم أخرى",
    "higher_national_diploma": "HND",
    "bachelor_of_commerce": "B.Com.",
    "year_completed": "عام المنجزة",
    "major": "التخصص أو التخصص",
    "gpa": "غبا (إن وجد)",
    "select": "تحديد",
    "yes": "نعم فعلا",
    "no": "لا",
    "last_year_attended": "في العام الماضي حضر",
    "add_education_first": "إضافة برنامج",
    "institution_name": "اسم المعهد",
    "program_name": "إسم البرنامج",
    "have_completed": "لقد أكملت أو أتوقع إكمال هذا البرنامج:",
    "completed_year": "عام المنجزة",
    "degree_tip": "اختر من القائمة أو النوع لإضافة الخاصة بك.",
    "add_degree": "إضافة برنامج آخر درجة",
    "add_degree_first": "إضافة برنامج درجة",
    "if_accepted_provide_copies": "عند القبول ، سنطلب نسخًا من النصوص الأكاديمية الرسمية.",
    "non_degree_programs": "يمكنك إدراج أي شهادات أو برامج غير درجة ساهمت في تعليمك المهني.",
    "start_year": "سنة البدء",
    "end_year": "نهاية عام",
    "have_already_graduated": "هل تخرجت بالفعل بهذه الدرجة؟",
    "expect_to_graduate": "هل تتوقع التخرج بهذه الدرجة؟",
    "associate_of_applied_arts_full": "مشارك في الفنون التطبيقية",
    "associate_of_applied_science_full": "مشارك في العلوم التطبيقية",
    "associate_of_arts_full": "الزميلة للفنون",
    "associate_of_engineering_full": "مشارك في الهندسة",
    "associate_of_political_science_full": "مشارك في العلوم السياسية",
    "associate_of_science_full": "مشارك في العلوم",
    "bachelor_of_architecture_full": "بكالوريوس في الهندسة المعمارية",
    "bachelor_of_arts_full": "بكالوريوس في الآداب",
    "bachelor_of_arts_2_full": "بكالوريوس في الآداب",
    "bachelor_of_business_administration_full": "بكالوريوس في إدارة الأعمال",
    "bachelor_of_commerce_full": "بكالوريوس تجارة",
    "bachelor_of_engineering_full": "بكالوريوس هندسة",
    "bachelor_of_fine_arts": "منتدى بواو الاسيوى",
    "bachelor_of_fine_arts_full": "بكالوريوس في الفنون الجميلة",
    "bachelor_of_science_full": "بكالوريوس العلوم",
    "doctor_of_education_full": "دكتوراه في التربية والتعليم",
    "doctor_of_medicine_full": "دكتور في الطب",
    "doctor_of_philosophy_full": "دكتور فى الفلسفة",
    "doctor_of_philosophy_2_full": "دكتور فى الفلسفة",
    "doctor_of_veterinary_medicine_full": "دكتور في الطب البيطري",
    "higher_national_diploma_full": "دبلوم الوطني العالي",
    "juris_doctor_full": "دكتور في الفقه",
    "master_of_advanced_studies_full": "ماجستير في الدراسات المتقدمة",
    "master_of_advanced_studies_2_full": "ماجستير في الدراسات المتقدمة",
    "master_of_arts_full": "أستاذ فى الفنون",
    "master_of_arts_2_full": "أستاذ فى الفنون",
    "master_of_business_administration_full": "ماجستير في إدارة الأعمال",
    "master_of_engineering_full": "ماجستير في الهندسة",
    "master_of_laws_full": "سيد القوانين",
    "master_of_philosophy_full": "ماجستير في الفلسفة",
    "master_of_research_full": "ماجستير في البحث",
    "master_of_science_full": "درجة الماجستير في العلوم",
    "master_of_science_2_full": "درجة الماجستير في العلوم",
    "master_of_studies_full": "ماجستير في الدراسات",
    "master_of_technology_full": "ماجستير في التكنولوجيا",
    "honours_bachelor_of_commerce": "ب. كوم. (مع مرتبة الشرف)",
    "honours_bachelor_of_commerce_full": "يكرم بكالوريوس تجارة",
    "honours_bachelor_of_commerce_2": "HBCom.",
    "honours_bachelor_of_commerce_2_full": "يكرم بكالوريوس تجارة",
    "bachelor_of_accounting": "ب.",
    "bachelor_of_accounting_full": "بكالوريوس محاسبة",
    "bachelor_of_accountancy": "ب.",
    "bachelor_of_accountancy_full": "بكالوريوس محاسبة",
    "bachelor_of_applied_science": "ب.",
    "bachelor_of_applied_science_2": "بكالوريوس.",
    "bachelor_of_applied_science_full": "بكالوريوس في العلوم التطبيقية",
    "bachelor_of_arts_honours": "درجة البكالوريوس (مع مرتبة الشرف)",
    "bachelor_of_arts_honours_full": "ليسانس الآداب مع مرتبة الشرف",
    "bachelor_of_business_management": "BBM",
    "bachelor_of_business_management_full": "بكالوريوس في إدارة الأعمال",
    "bachelor_of_business_studies": "BBS",
    "bachelor_of_business_studies_full": "بكالوريوس في الدراسات التجارية",
    "bachelor_of_civil_engineering": "البنك المركزي الأوروبي",
    "bachelor_of_civil_engineering_full": "بكالوريوس الهندسة المدنية",
    "bachelor_of_commerce_honours": "ب. كوم. (مع مرتبة الشرف)",
    "bachelor_of_commerce_honours_2": "HBCom.",
    "bachelor_of_commerce_honours_full": "يكرم بكالوريوس تجارة",
    "bachelor_of_computer_applications": "BCA",
    "bachelor_of_computer_applications_full": "بكالوريوس تطبيقات الحاسوب",
    "bachelor_of_computer_science": "B.Comp.Sc.",
    "bachelor_of_computer_science_2": "BCS",
    "bachelor_of_computer_science_3": "BSCS",
    "bachelor_of_computer_science_full": "بكالوريوس في علوم الحاسوب",
    "bachelor_of_dentistry": "BDS",
    "bachelor_of_dentistry_full": "بكالوريوس طب أسنان",
    "bachelor_of_education": "السرير.",
    "bachelor_of_education_full": "بكالوريوس تربية",
    "bachelor_of_electrical_engineering": "نحلة",
    "bachelor_of_electrical_engineering_full": "بكالوريوس هندسة كهربائية",
    "bachelor_of_engineering_2": "ب.",
    "bachelor_of_engineering_honours": "ب. (مع مرتبة الشرف)",
    "bachelor_of_engineering_honours_full": "بكالوريوس هندسة مع مرتبة الشرف",
    "bachelor_of_engineering_in_electrical_engineering": "النحل",
    "bachelor_of_engineering_in_electrical_engineering_full": "بكالوريوس هندسة في الهندسة الكهربائية",
    "bachelor_of_information_technology": "قليلا",
    "bachelor_of_information_technology_full": "بكالوريوس في تكنولوجيا المعلومات",
    "bachelor_of_laws": "BL",
    "bachelor_of_laws_2": "بكالوريوس في القانون.",
    "bachelor_of_laws_full": "بكالوريوس في القانون",
    "bachelor_of_laws_honours": "بكالوريوس في القانون. (مع مرتبة الشرف)",
    "bachelor_of_laws_honours_full": "بكالوريوس في القانون مع مرتبة الشرف",
    "bachelor_of_library_science": "ب.",
    "bachelor_of_library_science_2": "BLS",
    "bachelor_of_library_science_full": "بكالوريوس علوم المكتبات",
    "bachelor_of_literature": "ب.",
    "bachelor_of_literature_full": "بكالوريوس آداب",
    "bachelor_of_management_studies": "BMS",
    "bachelor_of_management_studies_full": "بكالوريوس في الدراسات الإدارية",
    "bachelor_of_mathematics": "ب- الرياضيات.",
    "bachelor_of_mathematics_full": "بكالوريوس رياضيات",
    "bachelor_of_mechanical_engineering": "BME",
    "bachelor_of_mechanical_engineering_full": "بكالوريوس هندسة ميكانيكية",
    "bachelor_of_medical_laboratory_science": "BMLSc.",
    "bachelor_of_medical_laboratory_science_full": "بكالوريوس علوم المختبرات الطبية",
    "bachelor_of_medical_science": "بكالوريوس ميد.",
    "bachelor_of_medical_science_full": "بكالوريوس علوم طبية",
    "bachelor_of_medicine": "ميغا بايت",
    "bachelor_of_medicine_full": "البكالوريوس في الطب",
    "bachelor_of_medicine_and_surgery": "MBBS",
    "bachelor_of_medicine_and_surgery_2": "BMBS",
    "bachelor_of_medicine_and_surgery_3": "MB Ch.B.",
    "bachelor_of_medicine_and_surgery_4": "MBBCh. (Oxon)",
    "bachelor_of_medicine_and_surgery_5": "MBBCh.",
    "bachelor_of_medicine_and_surgery_6": "MBBChir. (كانتاب)",
    "bachelor_of_medicine_and_surgery_full": "بكالوريوس طب ، بكالوريوس جراحة",
    "bachelor_of_music": "بي ام",
    "bachelor_of_music_2": "ب- موس.",
    "bachelor_of_music_full": "بكالوريوس موسيقى",
    "bachelor_of_nursing": "B.N.",
    "bachelor_of_nursing_full": "بكالوريوس تمريض",
    "bachelor_of_pharmacy": "ب فارما",
    "bachelor_of_pharmacy_2": "فارم ب.",
    "bachelor_of_pharmacy_full": "بكالوريوس صيدلة",
    "bachelor_of_philosophy": "ب.",
    "bachelor_of_philosophy_full": "بكالوريوس فلسفة",
    "bachelor_of_physical_therapy_and_physiotherapy": "BPT",
    "bachelor_of_physical_therapy_and_physiotherapy_full": "بكالوريوس في العلاج الطبيعي ، بكالوريوس علاج طبيعي",
    "bachelor_of_science_2": "بكالوريوس.",
    "bachelor_of_science_honours": "بكالوريوس. (مع مرتبة الشرف)",
    "bachelor_of_science_honours_full": "بكالوريوس العلوم مع مرتبة الشرف",
    "bachelor_of_science_in_education": "BSEd.",
    "bachelor_of_science_in_education_full": "بكالوريوس العلوم في التربية",
    "bachelor_of_science_in_electrical_engineering": "بكالوريوس",
    "bachelor_of_science_in_electrical_engineering_2": "BSEE",
    "bachelor_of_science_in_electrical_engineering_full": "بكالوريوس العلوم في الهندسة الكهربائية",
    "bachelor_of_science_in_nursing": "BSN",
    "bachelor_of_science_in_nursing_full": "بكالوريوس العلوم في التمريض",
    "bachelor_of_technology": "بي تك.",
    "bachelor_of_technology_full": "بكالوريس تقنية",
    "bachelor_of_technology_honours": "بي تك. (مع مرتبة الشرف)",
    "bachelor_of_technology_honours_full": "بكالوريوس في التكنولوجيا مع مرتبة الشرف",
    "common_professional_examination": "CPE",
    "common_professional_examination_full": "الامتحان المهني المشترك",
    "diploma_of_college_studies": "DCS",
    "diploma_of_college_studies_full": "دبلوم الدراسات الجامعية",
    "doctor_of_dental_surgery": "دس",
    "doctor_of_dental_surgery_full": "دكتور جراحة الأسنان",
    "doctor_of_medicine_in_dentistry": "DMD",
    "doctor_of_medicine_in_dentistry_full": "دكتور في الطب في طب الأسنان",
    "doctor_of_naturopathic_medicine": "ND",
    "doctor_of_naturopathic_medicine_full": "دكتور في الطب الطبيعي",
    "doctor_of_nursing_practice": "DNP",
    "doctor_of_nursing_practice_full": "دكتوراه في ممارسة التمريض",
    "doctor_of_osteopathic_medicine": "فعل",
    "doctor_of_osteopathic_medicine_full": "دكتور في طب تقويم العظام",
    "doctor_of_pharmacy": "دكتور صيدلة",
    "doctor_of_pharmacy_2": "د فارما.",
    "doctor_of_pharmacy_full": "دكتور صيدلة",
    "doctor_of_physical_therapy_and_physiotherapy": "DPT",
    "doctor_of_physical_therapy_and_physiotherapy_full": "دكتور في العلاج الطبيعي ، دكتور في العلاج الطبيعي",
    "graduate_diploma": "ز.",
    "graduate_diploma_2": "جراد ديب",
    "graduate_diploma_full": "دبلوم الدراسات العليا",
    "graduate_diploma_in_law": "GDL",
    "graduate_diploma_in_law_full": "دبلوم الدراسات العليا في القانون",
    "high_school_diploma": "HSD",
    "high_school_diploma_full": "شهادة الدراسة الثانوية",
    "master_in_urban_planning": "MUP",
    "master_in_urban_planning_full": "ماجستير في التخطيط العمراني",
    "master_of_architecture": "مارس.",
    "master_of_architecture_full": "ماجستير في العمارة",
    "master_of_chemistry": "م.",
    "master_of_chemistry_full": "ماجستير في الكيمياء",
    "master_of_commerce": "م.",
    "master_of_commerce_2": "م.",
    "master_of_commerce_3": "ماج. كوم.",
    "master_of_commerce_full": "وزارة التجارة",
    "master_of_computer_applications": "مولودية الجزائر",
    "master_of_computer_applications_full": "ماجستير تطبيقات الحاسوب",
    "master_of_divinity": "م ديف",
    "master_of_divinity_full": "سيد اللاهوت",
    "master_of_education": "م.",
    "master_of_education_2": "إد.",
    "master_of_education_full": "ماجستير في التربية",
    "master_of_engineering_2": "أنا",
    "master_of_fine_arts": "MFA",
    "master_of_fine_arts_full": "ماجستير في الفنون الجميلة",
    "master_of_international_affairs": "لي",
    "master_of_international_affairs_full": "ماجستير في الشؤون الدولية",
    "master_of_international_studies": "سوء",
    "master_of_international_studies_full": "ماجستير في الدراسات الدولية",
    "master_of_music": "مم",
    "master_of_music_2": "م.",
    "master_of_music_full": "ماجستير في الموسيقى",
    "master_of_pharmacy": "م فارما.",
    "master_of_pharmacy_2": "فارم م.",
    "master_of_pharmacy_full": "ماجستير صيدلة",
    "master_of_physical_therapy_and_physiotherapy": "MPT",
    "master_of_physical_therapy_and_physiotherapy_full": "ماجستير العلاج الطبيعي ، ماجستير العلاج الطبيعي",
    "master_of_physics": "م.",
    "master_of_physics_full": "ماجستير في الفيزياء",
    "master_of_physics_honours": "م. (مع مرتبة الشرف)",
    "master_of_physics_honours_full": "ماجستير في الفيزياء مع مرتبة الشرف",
    "master_of_public_health": "MPH",
    "master_of_public_health_full": "ماجستير في الصحة العامة",
    "master_of_science_3": "ماجستير",
    "master_of_science_4": "م.",
    "master_of_science_in_education": "MSEd.",
    "master_of_science_in_education_full": "ماجستير العلوم في التربية",
    "master_of_social_work": "MSW",
    "master_of_social_work_full": "ماجستير في العمل الاجتماعي",
    "nurse_practitioner": "فمثلا",
    "nurse_practitioner_full": "ممرضة متدربة",
    "postgraduate_diploma": "PGDip.",
    "postgraduate_diploma_2": "ص.",
    "postgraduate_diploma_3": "ص. تراجع.",
    "postgraduate_diploma_4": "PostGradDip",
    "postgraduate_diploma_full": "دبلوم الدراسات العليا",
    "postgraduate_diploma_in_law": "PGDL",
    "postgraduate_diploma_in_law_full": "دبلوم دراسات عليا في القانون",
    "institution_location": "موقع المؤسسة (الدولة)",
    "country_not_applicable": "لا ينطبق / عبر الإنترنت",
    "country_not_listed": "بلدي غير مدرج",
    "executive_master_of_business_administration": "EMBA",
    "executive_master_of_business_administration_full": "الماجستير التنفيذي في إدارة الأعمال",
    "education_starting_with": "يرجى إدراج <strong>الدرجات العلمية الرسمية</strong> التي حصلت عليها أو التحقت بها، بدءًا من شهادتك الجامعية أو ما يعادلها. وهذا أمر مطلوب أن يؤخذ في الاعتبار بالنسبة لبرامج درجة الماجستير لدينا. يمكنك إدراج الشهادات أو البرامج غير الجامعية أو الدبلومات الأخرى في القسم التالي.",
    "education_starting_with_slim_application": "يرجى إدراج <strong>الدرجات العلمية الرسمية</strong> التي حصلت عليها أو التحقت بها، بدءًا من شهادتك الجامعية أو ما يعادلها. وهذا أمر مطلوب أن يؤخذ في الاعتبار بالنسبة لبرامج درجة الماجستير لدينا. يمكنك إدراج الشهادات أو البرامج غير الجامعية أو الدبلومات الأخرى في القسم التالي.",
    "education_instructions_when_checkbox_visible": "يرجى إدراج <strong>الدرجات العلمية الرسمية</strong> التي حصلت عليها أو التحقت بها، بدءًا من شهادتك الجامعية أو ما يعادلها. وهذا أمر مطلوب أن يؤخذ في الاعتبار بالنسبة لبرامج درجة الماجستير لدينا. يمكنك إدراج الشهادات أو البرامج غير الجامعية أو الدبلومات الأخرى في القسم التالي."
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.education_experience_detail = window.locales.en.careers.edit_career_profile.education_experience_detail || {};window.locales.en.careers.edit_career_profile.education_experience_detail = {...window.locales.en.careers.edit_career_profile.education_experience_detail, ...{
    "add_education": "Add Another Program",
    "add_education_first": "Add a Program",
    "college": "University/College",
    "degree": "Degree",
    "institution_location": "Institution's Location (country)",
    "grad_year": "Grad Year",
    "year_completed": "Year Completed",
    "major": "Major or Specialization",
    "minor": "Minor (optional)",
    "gpa": "GPA (if applicable)",
    "gpa_description": "Describe GPA scale if not out of 4.0",
    "education_starting_with": "Please list the <strong>formal degrees</strong> you have earned or enrolled in, beginning with your undergraduate or equivalent university degree. This is required to be considered for our masters degree programs. You may list certificates, non-degree programs, or other diplomas in the next section.",
    "education_starting_with_slim_application": "Please list the <strong>formal degrees</strong> you have earned or enrolled in, beginning with your undergraduate or equivalent university degree. This is required to be considered for our masters degree programs. You may list certificates, non-degree programs, or other diplomas in the next section.",
    "education_instructions_when_checkbox_visible": "Please list the <strong>formal degrees</strong> you have earned or enrolled in, beginning with your undergraduate or equivalent university degree. This is required to be considered for our masters degree programs. You may list certificates, non-degree programs, or other diplomas in the next section.",
    "if_accepted_provide_copies": "Upon acceptance, we will require copies of official academic transcripts.",
    "non_degree_programs": "You may list any certificates or non-degree programs that have contributed to your professional education.",
    "associate_of_applied_arts": "A.A.A.",
    "associate_of_applied_arts_full": "Associate of Applied Arts",
    "associate_of_applied_science": "A.A.S.",
    "associate_of_applied_science_full": "Associate of Applied Science",
    "associate_of_arts": "A.A.",
    "associate_of_arts_full": "Associate of Arts",
    "associate_of_engineering": "A.E.",
    "associate_of_engineering_full": "Associate of Engineering",
    "associate_of_political_science": "A.P.S.",
    "associate_of_political_science_full": "Associate of Political Science",
    "associate_of_science": "A.S.",
    "associate_of_science_full": "Associate of Science",
    "bachelor_of_accounting": "B.Acc.",
    "bachelor_of_accounting_full": "Bachelor of Accounting",
    "bachelor_of_accountancy": "B.Acy.",
    "bachelor_of_accountancy_full": "Bachelor of Accountancy",
    "bachelor_of_applied_science": "B.App.Sc.",
    "bachelor_of_applied_science_2": "B.A.Sc.",
    "bachelor_of_applied_science_full": "Bachelor of Applied Science",
    "bachelor_of_architecture": "B.Arch.",
    "bachelor_of_architecture_full": "Bachelor of Architecture",
    "bachelor_of_arts": "B.A.",
    "bachelor_of_arts_2": "A.B.",
    "bachelor_of_arts_full": "Bachelor of Arts",
    "bachelor_of_arts_honours": "B.A. (Hons)",
    "bachelor_of_arts_honours_full": "Bachelor of Arts with Honours",
    "bachelor_of_business_administration": "B.B.A.",
    "bachelor_of_business_administration_full": "Bachelor of Business Administration",
    "bachelor_of_business_management": "B.B.M.",
    "bachelor_of_business_management_full": "Bachelor of Business Management",
    "bachelor_of_business_studies": "B.B.S.",
    "bachelor_of_business_studies_full": "Bachelor of Business Studies",
    "bachelor_of_civil_engineering": "B.C.E.",
    "bachelor_of_civil_engineering_full": "Bachelor of Civil Engineering",
    "bachelor_of_commerce": "B.Com.",
    "bachelor_of_commerce_full": "Bachelor of Commerce",
    "bachelor_of_commerce_honours": "B.Com. (Hons)",
    "bachelor_of_commerce_honours_2": "H.B.Com.",
    "bachelor_of_commerce_honours_full": "Honours Bachelor of Commerce",
    "bachelor_of_computer_applications": "B.C.A",
    "bachelor_of_computer_applications_full": "Bachelor of Computer Applications",
    "bachelor_of_computer_science": "B.Comp.Sc.",
    "bachelor_of_computer_science_2": "B.C.S.",
    "bachelor_of_computer_science_3": "B.S.C.S.",
    "bachelor_of_computer_science_full": "Bachelor of Computer Science",
    "bachelor_of_dentistry": "B.D.S.",
    "bachelor_of_dentistry_full": "Bachelor of Dentistry",
    "bachelor_of_education": "B.Ed.",
    "bachelor_of_education_full": "Bachelor of Education",
    "bachelor_of_electrical_engineering": "B.E.E.",
    "bachelor_of_electrical_engineering_full": "Bachelor of Electrical Engineering",
    "bachelor_of_engineering": "B.E.",
    "bachelor_of_engineering_2": "B.Eng.",
    "bachelor_of_engineering_full": "Bachelor of Engineering",
    "bachelor_of_engineering_honours": "B.Eng. (Hons)",
    "bachelor_of_engineering_honours_full": "Bachelor of Engineering with Honours",
    "bachelor_of_engineering_in_electrical_engineering": "B.E.E.E.",
    "bachelor_of_engineering_in_electrical_engineering_full": "Bachelor of Engineering in Electrical Engineering",
    "bachelor_of_fine_arts": "B.F.A.",
    "bachelor_of_fine_arts_full": "Bachelor of Fine Arts",
    "bachelor_of_information_technology": "B.I.T.",
    "bachelor_of_information_technology_full": "Bachelor of Information Technology",
    "bachelor_of_laws": "B.L.",
    "bachelor_of_laws_2": "LL.B.",
    "bachelor_of_laws_full": "Bachelor of Laws",
    "bachelor_of_laws_honours": "LL.B. (Hons)",
    "bachelor_of_laws_honours_full": "Bachelor of Laws with Honours",
    "bachelor_of_library_science": "B.Lib.",
    "bachelor_of_library_science_2": "B.L.S.",
    "bachelor_of_library_science_full": "Bachelor of Library Science",
    "bachelor_of_literature": "B.Lit.",
    "bachelor_of_literature_full": "Bachelor of Literature",
    "bachelor_of_management_studies": "B.M.S.",
    "bachelor_of_management_studies_full": "Bachelor of Management Studies",
    "bachelor_of_mathematics": "B.Math.",
    "bachelor_of_mathematics_full": "Bachelor of Mathematics",
    "bachelor_of_mechanical_engineering": "B.M.E.",
    "bachelor_of_mechanical_engineering_full": "Bachelor of Mechanical Engineering",
    "bachelor_of_medical_laboratory_science": "B.M.L.Sc.",
    "bachelor_of_medical_laboratory_science_full": "Bachelor of Medical Laboratory Science",
    "bachelor_of_medical_science": "B.Med.Sc.",
    "bachelor_of_medical_science_full": "Bachelor of Medical Science",
    "bachelor_of_medicine": "M.B.",
    "bachelor_of_medicine_full": "Bachelor of Medicine",
    "bachelor_of_medicine_and_surgery": "M.B.B.S.",
    "bachelor_of_medicine_and_surgery_2": "B.M.B.S.",
    "bachelor_of_medicine_and_surgery_3": "M.B Ch.B.",
    "bachelor_of_medicine_and_surgery_4": "M.B. B.Ch. (Oxon)",
    "bachelor_of_medicine_and_surgery_5": "M.B. B.Ch.",
    "bachelor_of_medicine_and_surgery_6": "M.B. B.Chir. (Cantab)",
    "bachelor_of_medicine_and_surgery_full": "Bachelor of Medicine, Bachelor of Surgery",
    "bachelor_of_music": "B.M.",
    "bachelor_of_music_2": "B.Mus.",
    "bachelor_of_music_full": "Bachelor of Music",
    "bachelor_of_nursing": "B.N.",
    "bachelor_of_nursing_full": "Bachelor of Nursing",
    "bachelor_of_pharmacy": "B.Pharm",
    "bachelor_of_pharmacy_2": "Pharm.B.",
    "bachelor_of_pharmacy_full": "Bachelor of Pharmacy",
    "bachelor_of_philosophy": "B.Ph.",
    "bachelor_of_philosophy_full": "Bachelor of Philosophy",
    "bachelor_of_physical_therapy_and_physiotherapy": "B.P.T.",
    "bachelor_of_physical_therapy_and_physiotherapy_full": "Bachelor of Physical Therapy, Bachelor of Physiotherapy",
    "bachelor_of_science": "B.S.",
    "bachelor_of_science_2": "B.Sc.",
    "bachelor_of_science_full": "Bachelor of Science",
    "bachelor_of_science_honours": "B.Sc. (Hons)",
    "bachelor_of_science_honours_full": "Bachelor of Science with Honours",
    "bachelor_of_science_in_education": "B.S.Ed.",
    "bachelor_of_science_in_education_full": "Bachelor of Science in Education",
    "bachelor_of_science_in_electrical_engineering": "B.Sc.E.E.",
    "bachelor_of_science_in_electrical_engineering_2": "B.S.E.E.",
    "bachelor_of_science_in_electrical_engineering_full": "Bachelor of Science in Electrical Engineering",
    "bachelor_of_science_in_nursing": "B.S.N.",
    "bachelor_of_science_in_nursing_full": "Bachelor of Science in Nursing",
    "bachelor_of_technology": "B.Tech.",
    "bachelor_of_technology_full": "Bachelor of Technology",
    "bachelor_of_technology_honours": "B.Tech. (Hons)",
    "bachelor_of_technology_honours_full": "Bachelor of Technology with Honours",
    "common_professional_examination": "C.P.E.",
    "common_professional_examination_full": "Common Professional Examination",
    "diploma_of_college_studies": "D.C.S.",
    "diploma_of_college_studies_full": "Diploma of College Studies",
    "doctor_of_dental_surgery": "D.D.S.",
    "doctor_of_dental_surgery_full": "Doctor of Dental Surgery",
    "doctor_of_education": "Ed.D.",
    "doctor_of_education_full": "Doctor of Education",
    "doctor_of_medicine": "M.D.",
    "doctor_of_medicine_full": "Doctor of Medicine",
    "doctor_of_medicine_in_dentistry": "D.M.D.",
    "doctor_of_medicine_in_dentistry_full": "Doctor of Medicine in Dentistry",
    "doctor_of_naturopathic_medicine": "N.D.",
    "doctor_of_naturopathic_medicine_full": "Doctor of Naturopathic Medicine",
    "doctor_of_nursing_practice": "D.N.P.",
    "doctor_of_nursing_practice_full": "Doctor of Nursing Practice",
    "doctor_of_osteopathic_medicine": "D.O.",
    "doctor_of_osteopathic_medicine_full": "Doctor of Osteopathic Medicine",
    "doctor_of_pharmacy": "Pharm.D.",
    "doctor_of_pharmacy_2": "D.Pharm.",
    "doctor_of_pharmacy_full": "Doctor of Pharmacy",
    "doctor_of_philosophy": "Ph.D.",
    "doctor_of_philosophy_2": "D.Phil.",
    "doctor_of_philosophy_full": "Doctor of Philosophy",
    "doctor_of_physical_therapy_and_physiotherapy": "D.P.T.",
    "doctor_of_physical_therapy_and_physiotherapy_full": "Doctor of Physical Therapy, Doctor of Physiotherapy",
    "doctor_of_veterinary_medicine": "D.V.M.",
    "doctor_of_veterinary_medicine_full": "Doctor of Veterinary Medicine",
    "graduate_diploma": "G.Dip.",
    "graduate_diploma_2": "GradDip",
    "graduate_diploma_full": "Graduate Diploma",
    "graduate_diploma_in_law": "G.D.L.",
    "graduate_diploma_in_law_full": "Graduate Diploma in Law",
    "high_school_diploma": "H.S.D.",
    "high_school_diploma_full": "High School Diploma",
    "higher_national_diploma": "HND",
    "higher_national_diploma_full": "Higher National Diploma",
    "juris_doctor": "J.D.",
    "juris_doctor_full": "Doctor of Jurisprudence",
    "master_in_urban_planning": "M.U.P.",
    "master_in_urban_planning_full": "Master in Urban Planning",
    "master_of_advanced_studies": "M.A.S.",
    "master_of_advanced_studies_2": "M.A.St.",
    "master_of_advanced_studies_full": "Master of Advanced Studies",
    "master_of_architecture": "M.Arch.",
    "master_of_architecture_full": "Master of Architecture",
    "master_of_arts": "M.A.",
    "master_of_arts_2": "A.M.",
    "master_of_arts_full": "Master of Arts",
    "master_of_business_administration": "M.B.A.",
    "master_of_business_administration_full": "Master of Business Administration",
    "executive_master_of_business_administration": "E.M.B.A.",
    "executive_master_of_business_administration_full": "Executive Master of Business Administration",
    "master_of_chemistry": "M.Chem.",
    "master_of_chemistry_full": "Master of Chemistry",
    "master_of_commerce": "M.Com.",
    "master_of_commerce_2": "M.Comm.",
    "master_of_commerce_3": "Mag.Comm.",
    "master_of_commerce_full": "Master of Commerce",
    "master_of_computer_applications": "M.C.A.",
    "master_of_computer_applications_full": "Master of Computer Applications",
    "master_of_divinity": "M.Div",
    "master_of_divinity_full": "Master of Divinity",
    "master_of_education": "M.Ed.",
    "master_of_education_2": "Ed.M.",
    "master_of_education_full": "Master of Education",
    "master_of_engineering": "M.Eng.",
    "master_of_engineering_2": "M.E.",
    "master_of_engineering_full": "Master of Engineering",
    "master_of_fine_arts": "M.F.A.",
    "master_of_fine_arts_full": "Master of Fine Arts",
    "master_of_international_affairs": "M.I.A.",
    "master_of_international_affairs_full": "Master of International Affairs",
    "master_of_international_studies": "M.I.S.",
    "master_of_international_studies_full": "Master of International Studies",
    "master_of_laws": "LL.M.",
    "master_of_laws_full": "Master of Laws",
    "master_of_music": "M.M.",
    "master_of_music_2": "M.Mus.",
    "master_of_music_full": "Master of Music",
    "master_of_pharmacy": "M.Pharm.",
    "master_of_pharmacy_2": "Pharm.M.",
    "master_of_pharmacy_full": "Master of Pharmacy",
    "master_of_philosophy": "M.Phil.",
    "master_of_philosophy_full": "Master of Philosophy",
    "master_of_physical_therapy_and_physiotherapy": "M.P.T.",
    "master_of_physical_therapy_and_physiotherapy_full": "Master of Physical Therapy, Master of Physiotherapy",
    "master_of_physics": "M.Phys.",
    "master_of_physics_full": "Master of Physics",
    "master_of_physics_honours": "M.Phys. (Hons)",
    "master_of_physics_honours_full": "Master of Physics with Honours",
    "master_of_public_health": "M.P.H.",
    "master_of_public_health_full": "Master of Public Health",
    "master_of_research": "M.Res.",
    "master_of_research_full": "Master of Research",
    "master_of_science": "M.S.",
    "master_of_science_2": "S.M.",
    "master_of_science_3": "M.Sc.",
    "master_of_science_4": "Sc.M.",
    "master_of_science_full": "Master of Science",
    "master_of_science_in_education": "M.S.Ed.",
    "master_of_science_in_education_full": "Master of Science in Education",
    "master_of_social_work": "M.S.W.",
    "master_of_social_work_full": "Master of Social Work",
    "master_of_studies": "M.St.",
    "master_of_studies_full": "Master of Studies",
    "master_of_technology": "M.Tech.",
    "master_of_technology_full": "Master of Technology",
    "nurse_practitioner": "N.P.",
    "nurse_practitioner_full": "Nurse Practitioner",
    "postgraduate_diploma": "P.G.Dip.",
    "postgraduate_diploma_2": "Pg.D.",
    "postgraduate_diploma_3": "Pg.Dip.",
    "postgraduate_diploma_4": "PostGradDip",
    "postgraduate_diploma_full": "Postgraduate Diploma",
    "postgraduate_diploma_in_law": "P.G.D.L.",
    "postgraduate_diploma_in_law_full": "Postgraduate Diploma in Law",
    "education_blank_required": "Please add at least one education entry.",
    "select": "Select",
    "yes": "Yes",
    "no": "No",
    "last_year_attended": "Last Year Attended",
    "add_degree": "Add Another Degree Program",
    "add_degree_first": "Add a Degree Program",
    "institution_name": "Institution Name",
    "program_name": "Program Name",
    "have_completed": "I have completed or expect to complete this program:",
    "completed_year": "Year Completed",
    "start_year": "Start Year",
    "end_year": "End Year",
    "degree_tip": "Choose from the list or type to add your own.",
    "have_already_graduated": "Have you already graduated with this degree?",
    "expect_to_graduate": "Do you expect to graduate with this degree?",
    "country_not_applicable": "Not applicable / Online",
    "country_not_listed": "My country is not listed"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.education_experience_detail = window.locales.es.careers.edit_career_profile.education_experience_detail || {};window.locales.es.careers.edit_career_profile.education_experience_detail = {...window.locales.es.careers.edit_career_profile.education_experience_detail, ...{
    "college": "Universidad",           
    "degree": "Titulación",           
    "minor": "Menor (opcional)",           
    "grad_year": "Año de graduación",           
    "associate_of_applied_arts": "A.A.A.",           
    "associate_of_applied_science": "A.A.S.",           
    "associate_of_arts": "A.A.",           
    "associate_of_engineering": "A.E.",           
    "associate_of_political_science": "A.P.S.",           
    "associate_of_science": "A.S.",           
    "bachelor_of_architecture": "B.Arch.",           
    "bachelor_of_arts": "B.A.",           
    "bachelor_of_arts_2": "A.B.",           
    "bachelor_of_business_administration": "B.B.A.",           
    "bachelor_of_engineering": "B.E.",           
    "bachelor_of_science": "B.S.",           
    "doctor_of_education": "Ed.D.",           
    "doctor_of_medicine": "M.D.",           
    "doctor_of_philosophy": "Ph.D.",           
    "doctor_of_philosophy_2": "D.Phil.",           
    "doctor_of_veterinary_medicine": "D.V.M.",           
    "juris_doctor": "J.D.",           
    "master_of_advanced_studies": "M.A.S.",           
    "master_of_advanced_studies_2": "M.A.St.",           
    "master_of_arts": "M.A.",           
    "master_of_arts_2": "A.M.",           
    "master_of_business_administration": "M.B.A.",           
    "master_of_engineering": "M.Eng.",           
    "master_of_laws": "LL.M.",           
    "master_of_philosophy": "M.Phil.",           
    "master_of_research": "M.Res.",           
    "master_of_science": "M.S.",           
    "master_of_science_2": "S.M",           
    "master_of_studies": "M.St.",           
    "master_of_technology": "M.Tech.",           
    "gpa_description": "GPA Descripción (opcional)",
    "education_blank_required": "Por favor añada al menos una entrada de educación",
    "add_education": "Añadir otro Educación",
    "higher_national_diploma": "HND",
    "bachelor_of_commerce": "B.Com.",
    "year_completed": "año completado",
    "major": "Mayor o especialización",
    "gpa": "GPA (si corresponde)",
    "select": "Seleccionar",
    "yes": "Sí",
    "no": "No",
    "last_year_attended": "El año pasado asistió",
    "add_education_first": "Agregar un programa",
    "institution_name": "Nombre de la Institución",
    "program_name": "Nombre del programa",
    "have_completed": "He completado o espero completar este programa:",
    "completed_year": "año completado",
    "degree_tip": "Elija de la lista o escriba para agregar el suyo.",
    "add_degree": "Añadir otro programa de grado",
    "add_degree_first": "Añadir un programa de grado",
    "if_accepted_provide_copies": "Una vez aceptado, necesitaremos copias de las transcripciones académicas oficiales.",
    "non_degree_programs": "Puede enumerar cualquier certificado o programa sin título que haya contribuido a su educación profesional.",
    "start_year": "Año de inicio",
    "end_year": "Año final",
    "have_already_graduated": "¿Ya te graduaste con este título?",
    "expect_to_graduate": "¿Espera graduarse con este título?",
    "associate_of_applied_arts_full": "Asociado en Artes Aplicadas",
    "associate_of_applied_science_full": "Asociado en Ciencias Aplicadas",
    "associate_of_arts_full": "Asociación de Arte",
    "associate_of_engineering_full": "Asociado de Ingeniería",
    "associate_of_political_science_full": "Asociado en Ciencias Políticas",
    "associate_of_science_full": "Asociado en ciencia",
    "bachelor_of_architecture_full": "Licenciado en Arquitectura",
    "bachelor_of_arts_full": "Licenciado en letras",
    "bachelor_of_arts_2_full": "Licenciado en letras",
    "bachelor_of_business_administration_full": "Licenciado en Administración de Empresas",
    "bachelor_of_commerce_full": "Licenciado en Comercio",
    "bachelor_of_engineering_full": "Licenciatura en Ingeniería",
    "bachelor_of_fine_arts": "BFA",
    "bachelor_of_fine_arts_full": "Licenciatura en Bellas Artes",
    "bachelor_of_science_full": "Licenciado en Ciencias",
    "doctor_of_education_full": "Doctor en educación",
    "doctor_of_medicine_full": "Doctor de medicina",
    "doctor_of_philosophy_full": "Doctor en Filosofía",
    "doctor_of_philosophy_2_full": "Doctor en Filosofía",
    "doctor_of_veterinary_medicine_full": "Doctor en medicina veterinaria",
    "higher_national_diploma_full": "Diploma Superior Nacional",
    "juris_doctor_full": "Doctor en Jurisprudencia",
    "master_of_advanced_studies_full": "Maestría en estudios avanzados",
    "master_of_advanced_studies_2_full": "Maestría en estudios avanzados",
    "master_of_arts_full": "Licenciado en filosofía y letras",
    "master_of_arts_2_full": "Licenciado en filosofía y letras",
    "master_of_business_administration_full": "Máster en Administración de Empresas",
    "master_of_engineering_full": "Master en ingeniería",
    "master_of_laws_full": "Maestro de leyes",
    "master_of_philosophy_full": "Maestro de filosofía",
    "master_of_research_full": "Maestría en Investigación",
    "master_of_science_full": "Maestro de la ciencia",
    "master_of_science_2_full": "Maestro de la ciencia",
    "master_of_studies_full": "Maestría en estudios",
    "master_of_technology_full": "Maestría en tecnología",
    "honours_bachelor_of_commerce": "B.Com. (Honores)",
    "honours_bachelor_of_commerce_full": "Licenciatura en Comercio con Honores",
    "honours_bachelor_of_commerce_2": "HBCom.",
    "honours_bachelor_of_commerce_2_full": "Licenciatura en Comercio con Honores",
    "bachelor_of_accounting": "B.Acc.",
    "bachelor_of_accounting_full": "Licenciado en contaduria",
    "bachelor_of_accountancy": "B. Accy.",
    "bachelor_of_accountancy_full": "Licenciatura en contabilidad",
    "bachelor_of_applied_science": "B.App.Sc.",
    "bachelor_of_applied_science_2": "Vasco.",
    "bachelor_of_applied_science_full": "Bachiller de Ciencias aplicadas",
    "bachelor_of_arts_honours": "BA (Hons)",
    "bachelor_of_arts_honours_full": "Licenciatura en Artes con Honores",
    "bachelor_of_business_management": "BBM",
    "bachelor_of_business_management_full": "Licenciatura en Administración de Empresas",
    "bachelor_of_business_studies": "BBS",
    "bachelor_of_business_studies_full": "Licenciatura en Ciencias Empresariales",
    "bachelor_of_civil_engineering": "ECB",
    "bachelor_of_civil_engineering_full": "Licenciatura en Ingeniería Civil",
    "bachelor_of_commerce_honours": "B.Com. (Honores)",
    "bachelor_of_commerce_honours_2": "HBCom.",
    "bachelor_of_commerce_honours_full": "Licenciatura en Comercio con Honores",
    "bachelor_of_computer_applications": "BCA",
    "bachelor_of_computer_applications_full": "Licenciatura en aplicaciones informáticas",
    "bachelor_of_computer_science": "B.Comp.Sc.",
    "bachelor_of_computer_science_2": "BCS",
    "bachelor_of_computer_science_3": "BSCS",
    "bachelor_of_computer_science_full": "Licenciado en Ciencias de la Computación",
    "bachelor_of_dentistry": "BDS",
    "bachelor_of_dentistry_full": "Licenciatura en Odontología",
    "bachelor_of_education": "Cama.",
    "bachelor_of_education_full": "licenciatura en Educación",
    "bachelor_of_electrical_engineering": "ABEJA",
    "bachelor_of_electrical_engineering_full": "Licenciatura en Ingeniería Eléctrica",
    "bachelor_of_engineering_2": "B. Ing.",
    "bachelor_of_engineering_honours": "B.Eng. (Honores)",
    "bachelor_of_engineering_honours_full": "Licenciatura en Ingeniería con Honores",
    "bachelor_of_engineering_in_electrical_engineering": "Abeja",
    "bachelor_of_engineering_in_electrical_engineering_full": "Licenciatura en Ingeniería en Ingeniería Eléctrica",
    "bachelor_of_information_technology": "POCO",
    "bachelor_of_information_technology_full": "Licenciatura en tecnología de la información",
    "bachelor_of_laws": "licenciado en Derecho",
    "bachelor_of_laws_2": "LL.B.",
    "bachelor_of_laws_full": "Licenciado en derecho",
    "bachelor_of_laws_honours": "LL.B. (Honores)",
    "bachelor_of_laws_honours_full": "Licenciatura en Derecho con Honores",
    "bachelor_of_library_science": "B.Lib.",
    "bachelor_of_library_science_2": "BLS",
    "bachelor_of_library_science_full": "Licenciatura en Bibliotecología",
    "bachelor_of_literature": "B.Lit.",
    "bachelor_of_literature_full": "Licenciado en Literatura",
    "bachelor_of_management_studies": "BMS",
    "bachelor_of_management_studies_full": "Licenciatura en Estudios de Gestión",
    "bachelor_of_mathematics": "B.Matemáticas.",
    "bachelor_of_mathematics_full": "Licenciado en Matemáticas",
    "bachelor_of_mechanical_engineering": "BME",
    "bachelor_of_mechanical_engineering_full": "Licenciatura en Ingeniería Mecánica",
    "bachelor_of_medical_laboratory_science": "BMLSc.",
    "bachelor_of_medical_laboratory_science_full": "Licenciatura en Ciencias de Laboratorio Médico",
    "bachelor_of_medical_science": "B.Med.Sc.",
    "bachelor_of_medical_science_full": "Licenciatura en Ciencias Médicas",
    "bachelor_of_medicine": "MEGABYTE",
    "bachelor_of_medicine_full": "Licenciatura en Medicina",
    "bachelor_of_medicine_and_surgery": "MBBS",
    "bachelor_of_medicine_and_surgery_2": "BMBS",
    "bachelor_of_medicine_and_surgery_3": "MB Ch.B.",
    "bachelor_of_medicine_and_surgery_4": "MBBCh. (Oxon)",
    "bachelor_of_medicine_and_surgery_5": "MBBCh.",
    "bachelor_of_medicine_and_surgery_6": "MBBChir. (Cantab)",
    "bachelor_of_medicine_and_surgery_full": "Licenciatura en Medicina, Licenciatura en Cirugía",
    "bachelor_of_music": "BM",
    "bachelor_of_music_2": "B.Mus.",
    "bachelor_of_music_full": "Licenciatura en Música",
    "bachelor_of_nursing": "BN",
    "bachelor_of_nursing_full": "Licenciatura en Enfermería",
    "bachelor_of_pharmacy": "B.Pharm",
    "bachelor_of_pharmacy_2": "Pharm.B.",
    "bachelor_of_pharmacy_full": "Licenciado en Farmacia",
    "bachelor_of_philosophy": "B.Ph.",
    "bachelor_of_philosophy_full": "Licenciado en Filosofía",
    "bachelor_of_physical_therapy_and_physiotherapy": "BPT",
    "bachelor_of_physical_therapy_and_physiotherapy_full": "Licenciado en Fisioterapia, Licenciado en Fisioterapia",
    "bachelor_of_science_2": "B.Sc.",
    "bachelor_of_science_honours": "B.Sc. (Honores)",
    "bachelor_of_science_honours_full": "Licenciatura en Ciencias con Honores",
    "bachelor_of_science_in_education": "EEB.",
    "bachelor_of_science_in_education_full": "Licenciatura en Ciencias de la Educación",
    "bachelor_of_science_in_electrical_engineering": "B.Sc.EE",
    "bachelor_of_science_in_electrical_engineering_2": "BSEE",
    "bachelor_of_science_in_electrical_engineering_full": "Licenciatura en Ciencias en Ingeniería Eléctrica",
    "bachelor_of_science_in_nursing": "BSN",
    "bachelor_of_science_in_nursing_full": "Licenciado en Ciencias de Enfermería",
    "bachelor_of_technology": "Tecnología B.",
    "bachelor_of_technology_full": "Licenciatura en tecnología",
    "bachelor_of_technology_honours": "Tecnología B. (Honores)",
    "bachelor_of_technology_honours_full": "Licenciatura en Tecnología con Honores",
    "common_professional_examination": "CPE",
    "common_professional_examination_full": "Examen profesional común",
    "diploma_of_college_studies": "DCS",
    "diploma_of_college_studies_full": "Diploma de estudios universitarios",
    "doctor_of_dental_surgery": "DDS",
    "doctor_of_dental_surgery_full": "Doctor en Cirugía Dental",
    "doctor_of_medicine_in_dentistry": "DMD",
    "doctor_of_medicine_in_dentistry_full": "Doctor en Medicina en Odontología",
    "doctor_of_naturopathic_medicine": "DAKOTA DEL NORTE",
    "doctor_of_naturopathic_medicine_full": "Doctor en Medicina Naturopática",
    "doctor_of_nursing_practice": "DNP",
    "doctor_of_nursing_practice_full": "Doctor en Práctica de Enfermería",
    "doctor_of_osteopathic_medicine": "HACER",
    "doctor_of_osteopathic_medicine_full": "Doctor en Medicina Osteopática",
    "doctor_of_pharmacy": "Pharm.D.",
    "doctor_of_pharmacy_2": "D.Pharm.",
    "doctor_of_pharmacy_full": "Doctor en farmacia",
    "doctor_of_physical_therapy_and_physiotherapy": "DPT",
    "doctor_of_physical_therapy_and_physiotherapy_full": "Doctor en Fisioterapia, Doctor en Fisioterapia",
    "graduate_diploma": "G.Dip.",
    "graduate_diploma_2": "GradDip",
    "graduate_diploma_full": "Diploma de graduación",
    "graduate_diploma_in_law": "GDL",
    "graduate_diploma_in_law_full": "Diploma de Posgrado en Derecho",
    "high_school_diploma": "HSD",
    "high_school_diploma_full": "Diploma de escuela secundaria",
    "master_in_urban_planning": "MUP",
    "master_in_urban_planning_full": "Maestría en Urbanismo",
    "master_of_architecture": "Marzo.",
    "master_of_architecture_full": "Maestría en Arquitectura",
    "master_of_chemistry": "M.Chem.",
    "master_of_chemistry_full": "Maestría en Química",
    "master_of_commerce": "M.Com.",
    "master_of_commerce_2": "M.Comm.",
    "master_of_commerce_3": "Mag.Comm.",
    "master_of_commerce_full": "Maestro de comercio",
    "master_of_computer_applications": "MCA",
    "master_of_computer_applications_full": "Maestría en aplicaciones informáticas",
    "master_of_divinity": "M.Div",
    "master_of_divinity_full": "Maestro de la Divinidad",
    "master_of_education": "Medicina.",
    "master_of_education_2": "Ed.M.",
    "master_of_education_full": "Maestro de educación",
    "master_of_engineering_2": "YO",
    "master_of_fine_arts": "MFA",
    "master_of_fine_arts_full": "Maestro de Bellas Artes",
    "master_of_international_affairs": "MI",
    "master_of_international_affairs_full": "Maestría en Asuntos Internacionales",
    "master_of_international_studies": "MAL",
    "master_of_international_studies_full": "Maestría en Estudios Internacionales",
    "master_of_music": "MM",
    "master_of_music_2": "M.Mus.",
    "master_of_music_full": "Maestro de la musica",
    "master_of_pharmacy": "M.Pharm.",
    "master_of_pharmacy_2": "Pharm.M.",
    "master_of_pharmacy_full": "Maestría en Farmacia",
    "master_of_physical_therapy_and_physiotherapy": "MPT",
    "master_of_physical_therapy_and_physiotherapy_full": "Maestría en Fisioterapia, Maestría en Fisioterapia",
    "master_of_physics": "M.Phys.",
    "master_of_physics_full": "Maestría en física",
    "master_of_physics_honours": "M.Phys. (Honores)",
    "master_of_physics_honours_full": "Maestría en Física con Honores",
    "master_of_public_health": "MPH",
    "master_of_public_health_full": "Master en salud pública",
    "master_of_science_3": "M.Sc.",
    "master_of_science_4": "Sc.M.",
    "master_of_science_in_education": "MSEd.",
    "master_of_science_in_education_full": "Maestría en Ciencias de la Educación",
    "master_of_social_work": "RSU",
    "master_of_social_work_full": "Maestría en Trabajo Social",
    "nurse_practitioner": "Por ejemplo",
    "nurse_practitioner_full": "Practicante de enfermería",
    "postgraduate_diploma": "PGDip.",
    "postgraduate_diploma_2": "Pg.D.",
    "postgraduate_diploma_3": "Pg.Dip.",
    "postgraduate_diploma_4": "PostGradDip",
    "postgraduate_diploma_full": "Diploma post graduación",
    "postgraduate_diploma_in_law": "PGDL",
    "postgraduate_diploma_in_law_full": "Diploma de Postgrado en Derecho",
    "institution_location": "Ubicación de la institución (país)",
    "country_not_applicable": "No aplica / en línea",
    "country_not_listed": "Mi pais no esta en la lista",
    "executive_master_of_business_administration": "EMBA",
    "executive_master_of_business_administration_full": "Maestro Ejecutivo de Administraión de empresas",
    "education_starting_with": "Enumere los <strong>títulos formales</strong> que ha obtenido o en los que se ha matriculado, comenzando con su título universitario o título universitario equivalente. Esto es necesario para ser considerado para nuestros programas de maestría. Puede enumerar certificados, programas que no son de grado u otros diplomas en la siguiente sección.",
    "education_starting_with_slim_application": "Enumere los <strong>títulos formales</strong> que ha obtenido o en los que se ha matriculado, comenzando con su título universitario o título universitario equivalente. Esto es necesario para ser considerado para nuestros programas de maestría. Puede enumerar certificados, programas que no son de grado u otros diplomas en la siguiente sección.",
    "education_instructions_when_checkbox_visible": "Enumere los <strong>títulos formales</strong> que ha obtenido o en los que se ha matriculado, comenzando con su título universitario o título universitario equivalente. Esto es necesario para ser considerado para nuestros programas de maestría. Puede enumerar certificados, programas que no son de grado u otros diplomas en la siguiente sección."
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.education_experience_detail = window.locales.it.careers.edit_career_profile.education_experience_detail || {};window.locales.it.careers.edit_career_profile.education_experience_detail = {...window.locales.it.careers.edit_career_profile.education_experience_detail, ...{
    "add_education": "Aggiungi altra Istruzione",           
    "college": "Università/College",           
    "degree": "Laurea",           
    "grad_year": "Anno di Laurea",           
    "minor": "Indirizzo (opzionale)",           
    "gpa_description": "Descrivi l'intervallo GPA se non va da 0.0 a 4.0",           
    "associate_of_applied_arts": "A.A.A.",           
    "associate_of_applied_science": "A.A.S.",           
    "associate_of_arts": "A.A.",           
    "associate_of_engineering": "A.E.",           
    "associate_of_political_science": "A.P.S.",           
    "associate_of_science": "A.S.",           
    "bachelor_of_architecture": "B.Arch.",           
    "bachelor_of_arts": "B.A.",           
    "bachelor_of_arts_2": "A.B.",           
    "bachelor_of_business_administration": "B.B.A.",           
    "bachelor_of_commerce": "B.Com.",           
    "bachelor_of_engineering": "B.E.",           
    "bachelor_of_science": "B.S.",           
    "doctor_of_education": "Ed.D.",           
    "doctor_of_medicine": "M.D.",           
    "doctor_of_philosophy": "Ph.D.",           
    "doctor_of_philosophy_2": "D.Phil.",           
    "doctor_of_veterinary_medicine": "D.V.M.",           
    "higher_national_diploma": "HND",           
    "juris_doctor": "J.D.",           
    "master_of_advanced_studies": "M.A.S.",           
    "master_of_advanced_studies_2": "M.A.St.",           
    "master_of_arts": "M.A.",           
    "master_of_arts_2": "A.M.",           
    "master_of_business_administration": "M.B.A.",           
    "master_of_engineering": "M.Eng.",           
    "master_of_laws": "LL.M.",           
    "master_of_philosophy": "M.Phil.",           
    "master_of_research": "M.Res.",           
    "master_of_science": "M.S.",           
    "master_of_science_2": "S.M",           
    "master_of_studies": "M.St.",           
    "master_of_technology": "M.Tech.",           
    "education_blank_required": "Per favore, aggiungi almeno una voce relativa all'istruzione.",           
    "year_completed": "Anno completato",
    "major": "Maggiore o Specializzazione",
    "gpa": "GPA (se applicabile)",
    "select": "Selezionare",
    "yes": "sì",
    "no": "No",
    "last_year_attended": "Lo scorso anno ha partecipato",
    "add_education_first": "Aggiungi un programma",
    "institution_name": "Nome dell'istituzione",
    "program_name": "Nome del programma",
    "have_completed": "Ho completato o previsto di completare questo programma:",
    "completed_year": "Anno completato",
    "degree_tip": "Scegli dalla lista o digita per aggiungere il tuo.",
    "add_degree": "Aggiungi un altro programma di laurea",
    "add_degree_first": "Aggiungi un programma di laurea",
    "if_accepted_provide_copies": "Al momento dell'accettazione, richiederemo copie delle trascrizioni accademiche ufficiali.",
    "non_degree_programs": "È possibile elencare eventuali certificati o programmi di non laurea che hanno contribuito alla tua formazione professionale.",
    "start_year": "Anno di inizio",
    "end_year": "Fine anno",
    "have_already_graduated": "Ti sei già laureato con questa laurea?",
    "expect_to_graduate": "Ti aspetti di laurearti con questa laurea?",
    "associate_of_applied_arts_full": "Associato di arti applicate",
    "associate_of_applied_science_full": "Associato di scienze applicate",
    "associate_of_arts_full": "Associate of Arts",
    "associate_of_engineering_full": "Associato di ingegneria",
    "associate_of_political_science_full": "Associato di scienze politiche",
    "associate_of_science_full": "Associato di scienza",
    "bachelor_of_architecture_full": "Laurea in architettura",
    "bachelor_of_arts_full": "Laurea in lettere",
    "bachelor_of_arts_2_full": "Laurea in lettere",
    "bachelor_of_business_administration_full": "Laurea in Economia Aziendale",
    "bachelor_of_commerce_full": "Laurea triennale in commercio",
    "bachelor_of_engineering_full": "Laurea in ingegneria",
    "bachelor_of_fine_arts": "BFA",
    "bachelor_of_fine_arts_full": "Laurea in Belle Arti",
    "bachelor_of_science_full": "laurea di Scienza",
    "doctor_of_education_full": "Dottore in educazione",
    "doctor_of_medicine_full": "Dottore in medicina",
    "doctor_of_philosophy_full": "Dottore in Filosofia",
    "doctor_of_philosophy_2_full": "Dottore in Filosofia",
    "doctor_of_veterinary_medicine_full": "Dottore in Medicina Veterinaria",
    "higher_national_diploma_full": "Diploma Nazionale Superiore",
    "juris_doctor_full": "Dottore in giurisprudenza",
    "master_of_advanced_studies_full": "Maestro di studi avanzati",
    "master_of_advanced_studies_2_full": "Maestro di studi avanzati",
    "master_of_arts_full": "Maestro delle arti",
    "master_of_arts_2_full": "Maestro delle arti",
    "master_of_business_administration_full": "Specializzazione in Amministrazione Aziendale",
    "master_of_engineering_full": "dottorato in Ingegneria",
    "master_of_laws_full": "Maestro di leggi",
    "master_of_philosophy_full": "Maestro di filosofia",
    "master_of_research_full": "Maestro di ricerca",
    "master_of_science_full": "Master of Science",
    "master_of_science_2_full": "Master of Science",
    "master_of_studies_full": "Master of Studies",
    "master_of_technology_full": "Maestro di tecnologia",
    "honours_bachelor_of_commerce": "B.Com. (Hons)",
    "honours_bachelor_of_commerce_full": "Laurea triennale in commercio",
    "honours_bachelor_of_commerce_2": "HBCom.",
    "honours_bachelor_of_commerce_2_full": "Laurea triennale in commercio",
    "bachelor_of_accounting": "B.Acc.",
    "bachelor_of_accounting_full": "Laurea in contabilità",
    "bachelor_of_accountancy": "B.Acy.",
    "bachelor_of_accountancy_full": "Laurea in Ragioneria",
    "bachelor_of_applied_science": "B.App.Sc.",
    "bachelor_of_applied_science_2": "BASc.",
    "bachelor_of_applied_science_full": "Laurea in Scienze Applicate",
    "bachelor_of_arts_honours": "BA (Hons)",
    "bachelor_of_arts_honours_full": "Laurea in lettere con lode",
    "bachelor_of_business_management": "BBM",
    "bachelor_of_business_management_full": "Laurea in gestione aziendale",
    "bachelor_of_business_studies": "BBS",
    "bachelor_of_business_studies_full": "Laurea in economia aziendale",
    "bachelor_of_civil_engineering": "B.C.E.",
    "bachelor_of_civil_engineering_full": "Laurea in ingegneria civile",
    "bachelor_of_commerce_honours": "B.Com. (Hons)",
    "bachelor_of_commerce_honours_2": "HBCom.",
    "bachelor_of_commerce_honours_full": "Laurea triennale in commercio",
    "bachelor_of_computer_applications": "BCA",
    "bachelor_of_computer_applications_full": "Laurea in applicazioni informatiche",
    "bachelor_of_computer_science": "B.Comp.Sc.",
    "bachelor_of_computer_science_2": "BCS",
    "bachelor_of_computer_science_3": "BSCS",
    "bachelor_of_computer_science_full": "Laurea in Informatica",
    "bachelor_of_dentistry": "BDS",
    "bachelor_of_dentistry_full": "Laurea in Odontoiatria",
    "bachelor_of_education": "Letto.",
    "bachelor_of_education_full": "Laurea in educazione",
    "bachelor_of_electrical_engineering": "APE",
    "bachelor_of_electrical_engineering_full": "Laurea in ingegneria elettrica",
    "bachelor_of_engineering_2": "B.Eng.",
    "bachelor_of_engineering_honours": "B.Eng. (Hons)",
    "bachelor_of_engineering_honours_full": "Laurea in ingegneria con lode",
    "bachelor_of_engineering_in_electrical_engineering": "BEEE",
    "bachelor_of_engineering_in_electrical_engineering_full": "Laurea in Ingegneria in Ingegneria Elettrica",
    "bachelor_of_information_technology": "PO",
    "bachelor_of_information_technology_full": "Laurea in informatica",
    "bachelor_of_laws": "BL",
    "bachelor_of_laws_2": "LL.B.",
    "bachelor_of_laws_full": "Laurea in giurisprudenza",
    "bachelor_of_laws_honours": "LL.B. (Hons)",
    "bachelor_of_laws_honours_full": "Laurea in giurisprudenza con lode",
    "bachelor_of_library_science": "B.Lib.",
    "bachelor_of_library_science_2": "BLS",
    "bachelor_of_library_science_full": "Laurea in Biblioteconomia",
    "bachelor_of_literature": "B.Lit.",
    "bachelor_of_literature_full": "Laurea in lettere",
    "bachelor_of_management_studies": "BMS",
    "bachelor_of_management_studies_full": "Laurea in studi di gestione",
    "bachelor_of_mathematics": "B.Math.",
    "bachelor_of_mathematics_full": "Laurea in matematica",
    "bachelor_of_mechanical_engineering": "BME",
    "bachelor_of_mechanical_engineering_full": "Laurea in ingegneria meccanica",
    "bachelor_of_medical_laboratory_science": "BMLSc.",
    "bachelor_of_medical_laboratory_science_full": "Laurea in Scienze dei Laboratori Medici",
    "bachelor_of_medical_science": "B.Med.Sc.",
    "bachelor_of_medical_science_full": "Laurea in Scienze Mediche",
    "bachelor_of_medicine": "MB",
    "bachelor_of_medicine_full": "Laurea in Medicina",
    "bachelor_of_medicine_and_surgery": "MBBS",
    "bachelor_of_medicine_and_surgery_2": "BMBS",
    "bachelor_of_medicine_and_surgery_3": "MB Ch.B.",
    "bachelor_of_medicine_and_surgery_4": "MBBCh. (Oxon)",
    "bachelor_of_medicine_and_surgery_5": "MBBCh.",
    "bachelor_of_medicine_and_surgery_6": "MBBChir. (Cantab)",
    "bachelor_of_medicine_and_surgery_full": "Laurea in Medicina, Laurea in Chirurgia",
    "bachelor_of_music": "BM",
    "bachelor_of_music_2": "B.Mus.",
    "bachelor_of_music_full": "Laurea in musica",
    "bachelor_of_nursing": "BN",
    "bachelor_of_nursing_full": "Laurea in infermieristica",
    "bachelor_of_pharmacy": "B.Pharm",
    "bachelor_of_pharmacy_2": "Pharm.B.",
    "bachelor_of_pharmacy_full": "Laurea in Farmacia",
    "bachelor_of_philosophy": "B.Ph.",
    "bachelor_of_philosophy_full": "Laurea in Filosofia",
    "bachelor_of_physical_therapy_and_physiotherapy": "BPT",
    "bachelor_of_physical_therapy_and_physiotherapy_full": "Laurea in Terapia Fisica, Laurea in Fisioterapia",
    "bachelor_of_science_2": "B.Sc.",
    "bachelor_of_science_honours": "B.Sc. (Hons)",
    "bachelor_of_science_honours_full": "Corso di laurea con lode",
    "bachelor_of_science_in_education": "BSEd.",
    "bachelor_of_science_in_education_full": "Corso di laurea in scienze dell'educazione",
    "bachelor_of_science_in_electrical_engineering": "B.Sc.EE",
    "bachelor_of_science_in_electrical_engineering_2": "BSEE",
    "bachelor_of_science_in_electrical_engineering_full": "Corso di laurea in ingegneria elettrica",
    "bachelor_of_science_in_nursing": "BSN",
    "bachelor_of_science_in_nursing_full": "Corso di laurea in infermieristica",
    "bachelor_of_technology": "B.Tech.",
    "bachelor_of_technology_full": "Laurea in tecnologia",
    "bachelor_of_technology_honours": "B.Tech. (Hons)",
    "bachelor_of_technology_honours_full": "Laurea in tecnologia con lode",
    "common_professional_examination": "CPE",
    "common_professional_examination_full": "Esame professionale comune",
    "diploma_of_college_studies": "DCS",
    "diploma_of_college_studies_full": "Diploma di studi universitari",
    "doctor_of_dental_surgery": "DDS",
    "doctor_of_dental_surgery_full": "Dottore in chirurgia dentale",
    "doctor_of_medicine_in_dentistry": "DMD",
    "doctor_of_medicine_in_dentistry_full": "Dottore in Medicina in Odontoiatria",
    "doctor_of_naturopathic_medicine": "ND",
    "doctor_of_naturopathic_medicine_full": "Dottore in Medicina Naturopatica",
    "doctor_of_nursing_practice": "DNP",
    "doctor_of_nursing_practice_full": "Dottore in infermieristica",
    "doctor_of_osteopathic_medicine": "FARE",
    "doctor_of_osteopathic_medicine_full": "Dottore in Medicina Osteopatica",
    "doctor_of_pharmacy": "Pharm.D.",
    "doctor_of_pharmacy_2": "D.Pharm.",
    "doctor_of_pharmacy_full": "Dottore in Farmacia",
    "doctor_of_physical_therapy_and_physiotherapy": "DPT",
    "doctor_of_physical_therapy_and_physiotherapy_full": "Dottore in Fisioterapia, Dottore in Fisioterapia",
    "graduate_diploma": "G.Dip.",
    "graduate_diploma_2": "GradDip",
    "graduate_diploma_full": "Laurea",
    "graduate_diploma_in_law": "GDL",
    "graduate_diploma_in_law_full": "Diploma di Laurea in Giurisprudenza",
    "high_school_diploma": "HSD",
    "high_school_diploma_full": "Diploma di scuola superiore",
    "master_in_urban_planning": "MUP",
    "master_in_urban_planning_full": "Master in Urbanistica",
    "master_of_architecture": "Marzo.",
    "master_of_architecture_full": "Maestro di architettura",
    "master_of_chemistry": "M.Chem.",
    "master_of_chemistry_full": "Maestro di chimica",
    "master_of_commerce": "M.Com.",
    "master_of_commerce_2": "M.Comm.",
    "master_of_commerce_3": "Mag.Comm.",
    "master_of_commerce_full": "Maestro del commercio",
    "master_of_computer_applications": "MCA",
    "master_of_computer_applications_full": "Maestro di applicazioni informatiche",
    "master_of_divinity": "M.Div",
    "master_of_divinity_full": "Maestro della divinità",
    "master_of_education": "M.Ed.",
    "master_of_education_2": "Ed.M.",
    "master_of_education_full": "Maestro di educazione",
    "master_of_engineering_2": "ME",
    "master_of_fine_arts": "MFA",
    "master_of_fine_arts_full": "Maestro di belle arti",
    "master_of_international_affairs": "M.I.A.",
    "master_of_international_affairs_full": "Maestro degli affari internazionali",
    "master_of_international_studies": "MIS",
    "master_of_international_studies_full": "Maestro di studi internazionali",
    "master_of_music": "MM",
    "master_of_music_2": "M.Mus.",
    "master_of_music_full": "Maestro di musica",
    "master_of_pharmacy": "M.Pharm.",
    "master_of_pharmacy_2": "Pharm.M.",
    "master_of_pharmacy_full": "Maestro di farmacia",
    "master_of_physical_therapy_and_physiotherapy": "MPT",
    "master_of_physical_therapy_and_physiotherapy_full": "Master in Terapia Fisica, Master in Fisioterapia",
    "master_of_physics": "M.Phys.",
    "master_of_physics_full": "Maestro di fisica",
    "master_of_physics_honours": "M.Phys. (Hons)",
    "master_of_physics_honours_full": "Master in Fisica con lode",
    "master_of_public_health": "MPH",
    "master_of_public_health_full": "Maestro di sanità pubblica",
    "master_of_science_3": "M.Sc.",
    "master_of_science_4": "Sc.M.",
    "master_of_science_in_education": "MSEd.",
    "master_of_science_in_education_full": "Master of Science in Education",
    "master_of_social_work": "MSW",
    "master_of_social_work_full": "Maestro del lavoro sociale",
    "nurse_practitioner": "Per esempio",
    "nurse_practitioner_full": "Infermiere tirocinante",
    "postgraduate_diploma": "PGDip.",
    "postgraduate_diploma_2": "Pg.D.",
    "postgraduate_diploma_3": "Pg.Dip.",
    "postgraduate_diploma_4": "PostGradDip",
    "postgraduate_diploma_full": "Diploma post-laurea",
    "postgraduate_diploma_in_law": "PGDL",
    "postgraduate_diploma_in_law_full": "Diploma post-laurea in giurisprudenza",
    "institution_location": "Posizione dell'istituto (paese)",
    "country_not_applicable": "Non applicabile / Online",
    "country_not_listed": "Il mio paese non è elencato",
    "executive_master_of_business_administration": "EMBA",
    "executive_master_of_business_administration_full": "Master esecutivo in economia aziendale",
    "education_starting_with": "Elenca i <strong>titoli formali</strong> che hai conseguito o ai quali ti sei iscritto, a partire dal titolo universitario o equivalente. Questo è necessario per essere preso in considerazione per i nostri programmi di master. Puoi elencare certificati, programmi non di laurea o altri diplomi nella sezione successiva.",
    "education_starting_with_slim_application": "Elenca i <strong>titoli formali</strong> che hai conseguito o ai quali ti sei iscritto, a partire dal titolo universitario o equivalente. Questo è necessario per essere preso in considerazione per i nostri programmi di master. Puoi elencare certificati, programmi non di laurea o altri diplomi nella sezione successiva.",
    "education_instructions_when_checkbox_visible": "Elenca i <strong>titoli formali</strong> che hai conseguito o ai quali ti sei iscritto, a partire dal titolo universitario o equivalente. Questo è necessario per essere preso in considerazione per i nostri programmi di master. Puoi elencare certificati, programmi non di laurea o altri diplomi nella sezione successiva."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.education_experience_detail = window.locales.zh.careers.edit_career_profile.education_experience_detail || {};window.locales.zh.careers.edit_career_profile.education_experience_detail = {...window.locales.zh.careers.edit_career_profile.education_experience_detail, ...{
    "college": "大学",           
    "degree": "学位",           
    "minor": "辅修专业（可选）",           
    "grad_year": "毕业年份",           
    "associate_of_applied_arts": "A.A.A.",           
    "associate_of_applied_science": "A.A.S.",           
    "associate_of_arts": "A.A.",           
    "associate_of_engineering": "A.E.",           
    "associate_of_political_science": "A.P.S.",           
    "associate_of_science": "A.S.",           
    "bachelor_of_architecture": "B.Arch.",           
    "bachelor_of_arts": "B.A.",           
    "bachelor_of_arts_2": "A.B.",           
    "bachelor_of_business_administration": "B.B.A.",           
    "bachelor_of_engineering": "B.E.",           
    "bachelor_of_science": "B.S.",           
    "doctor_of_education": "Ed.D.",           
    "doctor_of_medicine": "M.D.",           
    "doctor_of_philosophy": "Ph.D.",           
    "doctor_of_philosophy_2": "D.Phil.",           
    "doctor_of_veterinary_medicine": "D.V.M.",           
    "juris_doctor": "J.D.",           
    "master_of_advanced_studies": "M.A.S.",           
    "master_of_advanced_studies_2": "M.A.St.",           
    "master_of_arts": "M.A.",           
    "master_of_arts_2": "A.M.",           
    "master_of_business_administration": "M.B.A.",           
    "master_of_engineering": "M.Eng.",           
    "master_of_laws": "LL.M.",           
    "master_of_philosophy": "M.Phil.",           
    "master_of_research": "M.Res.",           
    "master_of_science": "M.S.",           
    "master_of_science_2": "S.M",           
    "master_of_studies": "M.St.",           
    "master_of_technology": "M.Tech.",           
    "gpa_description": "如未达到4.0，请阐明GPA范围",           
    "education_blank_required": "请添加至少一项教育背景。",           
    "add_education": "添加另一个项目",           
    "higher_national_diploma": "HND",           
    "bachelor_of_commerce": "B.Com.",           
    "year_completed": "完成年份",           
    "major": "主修或专业",           
    "gpa": "平均绩点（如适用）",           
    "select": "选择",           
    "yes": "是",           
    "no": "否",           
    "last_year_attended": "去年参加的课程",           
    "add_education_first": "添加一个项目",           
    "institution_name": "机构名称",           
    "program_name": "课程名称",           
    "have_completed": "本人已完成或即将完成本课程：",           
    "completed_year": "完成年份",           
    "degree_tip": "从列表或类型中选择以添加自己的课程。",           
    "add_degree": "添加另一个学位课程",           
    "add_degree_first": "添加学位课程",           
    "_when_checkbox_visible": "<b>请列出你已获得或就读的正式学位，从本科学位开始，或勾选下面的方框，表明你未就读学位课程。</b>不包括证书、非学位课程或高中文凭；在下一步中可添加证书、非学位课程和高中文凭。",           
    "if_accepted_provide_copies": "一经录取，我们将要求提供正式成绩单的复印件。",           
    "non_degree_programs": "你可以列出任何有助于你职业教育的证书或非学位课程。",           
    "start_year": "开始年份",           
    "end_year": "结束年份",           
    "have_already_graduated": "你已经拿到这个学位了吗？",           
    "expect_to_graduate": "你希望获得这个学位吗？",           
    "associate_of_applied_arts_full": "应用艺术副学士学位",           
    "associate_of_applied_science_full": "应用科学副学士学位",           
    "associate_of_arts_full": "文学副学士学位",           
    "associate_of_engineering_full": "工程学副学士学位",           
    "associate_of_political_science_full": "政治学副学士学位",           
    "associate_of_science_full": "理学副学士学位",           
    "bachelor_of_architecture_full": "建筑学学士学位",           
    "bachelor_of_arts_full": "文学士学位",           
    "bachelor_of_arts_2_full": "文科学士学位",
    "bachelor_of_business_administration_full": "工商管理学士学位",           
    "bachelor_of_commerce_full": "商科学士学位",           
    "bachelor_of_engineering_full": "工程学学士学位",           
    "bachelor_of_fine_arts": "B.F.A.",           
    "bachelor_of_fine_arts_full": "美术学士学位",           
    "bachelor_of_science_full": "理学学士",           
    "doctor_of_education_full": "教育学博士学位",           
    "doctor_of_medicine_full": "医学博士学位",           
    "doctor_of_philosophy_full": "哲学博士学位",           
    "doctor_of_philosophy_2_full": "哲学博士",
    "doctor_of_veterinary_medicine_full": "兽医学博士学位",           
    "higher_national_diploma_full": "国家高级大专文凭",           
    "juris_doctor_full": "法学博士学位",           
    "master_of_advanced_studies_full": "高等深入研究文凭",           
    "master_of_advanced_studies_2_full": "高级研究硕士",
    "master_of_arts_full": "文学硕士学位",           
    "master_of_arts_2_full": "艺术大师",
    "master_of_business_administration_full": "工商管理学硕士学位",           
    "master_of_engineering_full": "工程硕士学位",           
    "master_of_laws_full": "法学硕士学位",           
    "master_of_philosophy_full": "哲学硕士学位",           
    "master_of_research_full": "研究型硕士",           
    "master_of_science_full": "理学硕士",           
    "master_of_science_2_full": "科学大师",
    "master_of_studies_full": "研究硕士学位",           
    "master_of_technology_full": "技术硕士学位",           
    "honours_bachelor_of_commerce": "B.Com。 （荣誉）",
    "honours_bachelor_of_commerce_full": "荣誉商业学士学位",
    "honours_bachelor_of_commerce_2": "HBCom。",
    "honours_bachelor_of_commerce_2_full": "荣誉商业学士学位",
    "bachelor_of_accounting": "B.Acc.",           
    "bachelor_of_accounting_full": "会计学学士学位",           
    "bachelor_of_accountancy": "B.Acy.",           
    "bachelor_of_accountancy_full": "会计学学士学位",           
    "bachelor_of_applied_science": "B.App.Sc.",           
    "bachelor_of_applied_science_2": "B.A.Sc.",           
    "bachelor_of_applied_science_full": "应用科学学士学位",           
    "bachelor_of_arts_honours": "B.A.（荣誉学位）",           
    "bachelor_of_arts_honours_full": "荣誉文学士学位",           
    "bachelor_of_business_management": "B.B.M.",           
    "bachelor_of_business_management_full": "企业管理学士学位",           
    "bachelor_of_business_studies": "B.B.S.",           
    "bachelor_of_business_studies_full": "商学学士学位",           
    "bachelor_of_civil_engineering": "B.C.E.",           
    "bachelor_of_civil_engineering_full": "土木工程学士学位",           
    "bachelor_of_commerce_honours": "B.Com.（荣誉学位）",           
    "bachelor_of_commerce_honours_2": "H.B.Com.",           
    "bachelor_of_commerce_honours_full": "荣誉商科学士学位",           
    "bachelor_of_computer_applications": "B.C.A",           
    "bachelor_of_computer_applications_full": "计算机应用学士学位",           
    "bachelor_of_computer_science": "B.Comp.Sc.",           
    "bachelor_of_computer_science_2": "B.C.S.",           
    "bachelor_of_computer_science_3": "B.S.C.S.",           
    "bachelor_of_computer_science_full": "计算机科学学士学位",           
    "bachelor_of_dentistry": "B.D.S.",           
    "bachelor_of_dentistry_full": "牙科学士学位",           
    "bachelor_of_education": "B.Ed.",           
    "bachelor_of_education_full": "教育学学士学位",           
    "bachelor_of_electrical_engineering": "B.E.E.",           
    "bachelor_of_electrical_engineering_full": "电气工程学士学位",           
    "bachelor_of_engineering_2": "B.Eng.",           
    "bachelor_of_engineering_honours": "B.Eng.（荣誉学位）",           
    "bachelor_of_engineering_honours_full": "工程学荣誉学士学位",           
    "bachelor_of_engineering_in_electrical_engineering": "B.E.E.E.",           
    "bachelor_of_engineering_in_electrical_engineering_full": "电气工程学工程学士学位",           
    "bachelor_of_information_technology": "B.I.T.",           
    "bachelor_of_information_technology_full": "信息技术学学士学位",           
    "bachelor_of_laws": "B.L.",           
    "bachelor_of_laws_2": "LL.B.",           
    "bachelor_of_laws_full": "法学学士学位",           
    "bachelor_of_laws_honours": "LL.B.（荣誉学位）",           
    "bachelor_of_laws_honours_full": "荣誉法学学士学位",           
    "bachelor_of_library_science": "B.Lib.",           
    "bachelor_of_library_science_2": "B.L.S.",           
    "bachelor_of_library_science_full": "图书馆学学士学位",           
    "bachelor_of_literature": "B.Lit.",           
    "bachelor_of_literature_full": "文学学士学位",           
    "bachelor_of_management_studies": "B.M.S.",           
    "bachelor_of_management_studies_full": "管理学学士学位",           
    "bachelor_of_mathematics": "B.Math.",           
    "bachelor_of_mathematics_full": "数学学士学位",           
    "bachelor_of_mechanical_engineering": "B.M.E.",           
    "bachelor_of_mechanical_engineering_full": "机械工程学学士学位",           
    "bachelor_of_medical_laboratory_science": "B.M.L.Sc.",           
    "bachelor_of_medical_laboratory_science_full": "医学实验学学士学位",           
    "bachelor_of_medical_science": "B.Med.Sc.",           
    "bachelor_of_medical_science_full": "医学科学学士学位",           
    "bachelor_of_medicine": "M.B.",           
    "bachelor_of_medicine_full": "医学学士学位",           
    "bachelor_of_medicine_and_surgery": "M.B.B.S.",           
    "bachelor_of_medicine_and_surgery_2": "B.M.B.S.",           
    "bachelor_of_medicine_and_surgery_3": "M.B Ch.B.",           
    "bachelor_of_medicine_and_surgery_4": "M.B.B.Ch.（牛津大学）",           
    "bachelor_of_medicine_and_surgery_5": "M.B.B.Ch.",           
    "bachelor_of_medicine_and_surgery_6": "M.B.B.Chir.（剑桥大学）",           
    "bachelor_of_medicine_and_surgery_full": "医学学士学位，外科学士学位",           
    "bachelor_of_music": "B.M.",           
    "bachelor_of_music_2": "B.Mus.",           
    "bachelor_of_music_full": "音乐学士学位",           
    "bachelor_of_nursing": "B.N.",           
    "bachelor_of_nursing_full": "护理学学士学位",           
    "bachelor_of_pharmacy": "B.Pharm",           
    "bachelor_of_pharmacy_2": "Pharm.B.",           
    "bachelor_of_pharmacy_full": "药学学士学位",           
    "bachelor_of_philosophy": "B.Ph.",           
    "bachelor_of_philosophy_full": "哲学学士学位",           
    "bachelor_of_physical_therapy_and_physiotherapy": "B.P.T.",           
    "bachelor_of_physical_therapy_and_physiotherapy_full": "物理疗法学士学位",           
    "bachelor_of_science_2": "B.Sc.",           
    "bachelor_of_science_honours": "B.Sc.（荣誉学位）",           
    "bachelor_of_science_honours_full": "荣誉理学学士",           
    "bachelor_of_science_in_education": "B.S.Ed.",           
    "bachelor_of_science_in_education_full": "教育学理学学士",           
    "bachelor_of_science_in_electrical_engineering": "B.Sc.E.E.",           
    "bachelor_of_science_in_electrical_engineering_2": "B.S.E.E.",           
    "bachelor_of_science_in_electrical_engineering_full": "电气工程学理学学士学位",           
    "bachelor_of_science_in_nursing": "B.S.N.",           
    "bachelor_of_science_in_nursing_full": "护理理学学士",           
    "bachelor_of_technology": "B.Tech.",           
    "bachelor_of_technology_full": "科技学士学位",           
    "bachelor_of_technology_honours": "B.Tech.（荣誉学位）",           
    "bachelor_of_technology_honours_full": "荣誉科技学士学位",           
    "common_professional_examination": "C.P.E.",           
    "common_professional_examination_full": "普通职业考试",           
    "diploma_of_college_studies": "D.C.S.",           
    "diploma_of_college_studies_full": "大学文凭",           
    "doctor_of_dental_surgery": "D.D.S.",           
    "doctor_of_dental_surgery_full": "牙科博士学位",           
    "doctor_of_medicine_in_dentistry": "D.M.D.",           
    "doctor_of_medicine_in_dentistry_full": "牙科医学博士学位",           
    "doctor_of_naturopathic_medicine": "N.D.",           
    "doctor_of_naturopathic_medicine_full": "自然医学博士学位",           
    "doctor_of_nursing_practice": "D.N.P.",           
    "doctor_of_nursing_practice_full": "临床护理博士学位",           
    "doctor_of_osteopathic_medicine": "D.O.",           
    "doctor_of_osteopathic_medicine_full": "骨科医学博士学位",           
    "doctor_of_pharmacy": "Pharm.D.",           
    "doctor_of_pharmacy_2": "D.Pharm.",           
    "doctor_of_pharmacy_full": "药学博士学位",           
    "doctor_of_physical_therapy_and_physiotherapy": "D.P.T.",           
    "doctor_of_physical_therapy_and_physiotherapy_full": "物理疗法博士学位",           
    "graduate_diploma": "G.Dip.",           
    "graduate_diploma_2": "GradDip",           
    "graduate_diploma_full": "研究生文凭",           
    "graduate_diploma_in_law": "G.D.L.",           
    "graduate_diploma_in_law_full": "法学研究生文凭",           
    "high_school_diploma": "H.S.D.",           
    "high_school_diploma_full": "高中文凭",           
    "master_in_urban_planning": "M.U.P.",           
    "master_in_urban_planning_full": "城市规划硕士学位",           
    "master_of_architecture": "M.Arch.",           
    "master_of_architecture_full": "建筑学硕士学位",           
    "master_of_chemistry": "M.Chem.",           
    "master_of_chemistry_full": "化学硕士学位",           
    "master_of_commerce": "M.Com.",           
    "master_of_commerce_2": "M.Comm.",           
    "master_of_commerce_3": "Mag.Comm.",           
    "master_of_commerce_full": "商业硕士学位",           
    "master_of_computer_applications": "M.C.A.",           
    "master_of_computer_applications_full": "计算机应用学硕士学位",           
    "master_of_divinity": "M.Div",           
    "master_of_divinity_full": "神学硕士学位",           
    "master_of_education": "M.Ed.",           
    "master_of_education_2": "Ed.M.",           
    "master_of_education_full": "教育学硕士学位",           
    "master_of_engineering_2": "M.E.",           
    "master_of_fine_arts": "M.F.A.",           
    "master_of_fine_arts_full": "美术硕士学位",           
    "master_of_international_affairs": "M.I.A.",           
    "master_of_international_affairs_full": "国际事务硕士学位",           
    "master_of_international_studies": "M.I.S.",           
    "master_of_international_studies_full": "国际学研究硕士学位",           
    "master_of_music": "M.M.",           
    "master_of_music_2": "M.Mus.",           
    "master_of_music_full": "音乐硕士学位",           
    "master_of_pharmacy": "M.Pharm.",           
    "master_of_pharmacy_2": "Pharm.M.",           
    "master_of_pharmacy_full": "药学硕士学位",           
    "master_of_physical_therapy_and_physiotherapy": "M.P.T.",           
    "master_of_physical_therapy_and_physiotherapy_full": "物理疗法硕士学位",           
    "master_of_physics": "M.Phys.",           
    "master_of_physics_full": "物理学硕士学位",           
    "master_of_physics_honours": "M.Phys.（荣誉学位）",           
    "master_of_physics_honours_full": "荣誉物理学硕士学位",           
    "master_of_public_health": "M.P.H.",           
    "master_of_public_health_full": "公共健康硕士学位",           
    "master_of_science_3": "M.Sc.",           
    "master_of_science_4": "Sc.M.",           
    "master_of_science_in_education": "M.S.Ed.",           
    "master_of_science_in_education_full": "教育学理学硕士",           
    "master_of_social_work": "M.S.W.",           
    "master_of_social_work_full": "社会工作学硕士学位",           
    "nurse_practitioner": "N.P.",           
    "nurse_practitioner_full": "护理师",           
    "postgraduate_diploma": "P.G.Dip.",           
    "postgraduate_diploma_2": "Pg.D.",           
    "postgraduate_diploma_3": "Pg.Dip.",           
    "postgraduate_diploma_4": "PostGradDip",           
    "postgraduate_diploma_full": "研究生文凭",           
    "postgraduate_diploma_in_law": "P.G.D.L.",           
    "postgraduate_diploma_in_law_full": "法学研究生文凭",           
    "institution_location": "机构地点（国家）",           
    "country_not_applicable": "不适用/网上",           
    "country_not_listed": "我的国家不在列表中",           
    "executive_master_of_business_administration": "工商管理硕士",
    "executive_master_of_business_administration_full": "行政工商管理硕士",
    "education_starting_with": "请列出您已获得或就读的<strong>正式学位</strong>，从您的本科或同等大学学位开始。这是申请硕士学位课程的必要条件。您可以在下一节列出证书、非学位课程或其他文凭。",
    "education_starting_with_slim_application": "请列出您已获得或就读的<strong>正式学位</strong>，从您的本科或同等大学学位开始。这是申请硕士学位课程的必要条件。您可以在下一节列出证书、非学位课程或其他文凭。",
    "education_instructions_when_checkbox_visible": "请列出您已获得或就读的<strong>正式学位</strong>，从您的本科或同等大学学位开始。这是申请硕士学位课程的必要条件。您可以在下一节列出证书、非学位课程或其他文凭。"
}
};