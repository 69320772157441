window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.demographics_form_section = window.locales.am.careers.edit_career_profile.demographics_form_section || {};window.locales.am.careers.edit_career_profile.demographics_form_section = {...window.locales.am.careers.edit_career_profile.demographics_form_section, ...{
    "demographics": "የስነ-ህዝብ አወቃቀር",           
    "welcome_to_move_on": "የስነ-ህዝብ አወቃቀር እና ጾታን የሚመለከቱ ማንኛቸውም ጥያቄዎች የዩኤስ የትምህርት መምሪያ መመዘኛዎችን ያከብራሉ። ሙሉ በሙሉ አማራጭ ናቸው።",           
    "sex": "ፆታ",           
    "identify_placeholder": "እዚህ ይግለጹ",
    "how_identify_yourself": "እባክዎን እራስዎን እንዴት እንደሚለዩ ያመልክቱ (የሚመለከተውን ሁሉ ይምረጡ)",
    "gender": "ጾታ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.demographics_form_section = window.locales.ar.careers.edit_career_profile.demographics_form_section || {};window.locales.ar.careers.edit_career_profile.demographics_form_section = {...window.locales.ar.careers.edit_career_profile.demographics_form_section, ...{
    "demographics": "التركيبة السكانية",
    "welcome_to_move_on": "أي أسئلة بخصوص التركيبة السكانية والجنس يتوافق مع معايير وزارة التعليم الأمريكية. فهي اختيارية تماما.",
    "sex": "جنس",
    "identify_placeholder": "وصف هنا",
    "how_identify_yourself": "يرجى الإشارة إلى كيفية تعريفك بنفسك (اختر كل ما ينطبق)",
    "gender": "جنس"
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.demographics_form_section = window.locales.en.careers.edit_career_profile.demographics_form_section || {};window.locales.en.careers.edit_career_profile.demographics_form_section = {...window.locales.en.careers.edit_career_profile.demographics_form_section, ...{
    "demographics": "Demographics",
    "welcome_to_move_on": "Any questions regarding demographics and sex comply with the U.S. Department of Education's standards. They are completely optional.",
    "sex": "Sex",
    "how_identify_yourself": "Please indicate how you identify yourself (select all that apply)",
    "identify_placeholder": "Describe here",
    "gender": "Gender"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.demographics_form_section = window.locales.es.careers.edit_career_profile.demographics_form_section || {};window.locales.es.careers.edit_career_profile.demographics_form_section = {...window.locales.es.careers.edit_career_profile.demographics_form_section, ...{
    "demographics": "Demografía",
    "welcome_to_move_on": "Cualquier pregunta relacionada con la demografía y el sexo cumple con los estándares del Departamento de Educación de los EE. UU. Son completamente opcionales.",
    "sex": "Sexo",
    "identify_placeholder": "Describe aquí",
    "how_identify_yourself": "Por favor indique cómo se identifica (seleccione todo lo que corresponda)",
    "gender": "Género"
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.demographics_form_section = window.locales.it.careers.edit_career_profile.demographics_form_section || {};window.locales.it.careers.edit_career_profile.demographics_form_section = {...window.locales.it.careers.edit_career_profile.demographics_form_section, ...{
    "demographics": "Dati Demografici",           
    "welcome_to_move_on": "Qualsiasi domanda relativa a dati demografici e sesso è conforme agli standard del Dipartimento della Pubblica Istruzione degli Stati Uniti. Sono completamente opzionali.",
    "sex": "Sesso",           
    "identify_placeholder": "Descrivi qui",
    "how_identify_yourself": "Per favore indica come ti identifichi (seleziona tutte le risposte pertinenti)",
    "gender": "Genere"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.demographics_form_section = window.locales.zh.careers.edit_career_profile.demographics_form_section || {};window.locales.zh.careers.edit_career_profile.demographics_form_section = {...window.locales.zh.careers.edit_career_profile.demographics_form_section, ...{
    "demographics": "人口统计资料",           
    "welcome_to_move_on": "任何有关人口统计资料和性别的问题都符合美国教育部的标准。且皆为选填项。",           
    "sex": "性别",           
    "identify_placeholder": "在这里描述",
    "how_identify_yourself": "请说明您如何识别自己（选择所有适用项）",
    "gender": "性别"
}
};