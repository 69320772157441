window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.student_network_settings_form = window.locales.am.careers.edit_career_profile.student_network_settings_form || {};window.locales.am.careers.edit_career_profile.student_network_settings_form = {...window.locales.am.careers.edit_career_profile.student_network_settings_form, ...{
    "fun_fact": "አስደሳች እውነታ",
    "fun_fact_tell_us": "ከአሰሪዎ ወይም ከክፍል ጓደኛዎ ጋር ለመነጋገር ሊያነሳሳ የሚችል የግል ነገር ይጻፉ።",
    "fun_fact_tell_us_non_english": "ከአሰሪዎ ወይም ከክፍል ጓደኛዎ ጋር ለመነጋገር ሊያነሳሳ የሚችል የግል ነገር ይጻፉ። እባኮትን በእንግሊዝኛ መልሱ።",
    "fun_fact_desc": "ከሚችለው ቀጣሪ ጋር ለመነጋገር ሊያነሳሳ የሚችል የግል ነገር ይጻፉ።",
    "fun_fact_desc_non_english": "ከሚችለው ቀጣሪ ጋር ለመነጋገር ሊያነሳሳ የሚችል የግል ነገር ይጻፉ። እባኮትን በእንግሊዝኛ መልሱ።",
    "fun_fact_placeholder": "3D የታተመ ልብስ መሥራት እችላለሁ",
    "fun_fact_tell_us_classmates_only": "ከክፍል ጓደኛው ጋር ለመነጋገር ሊያነሳሳ የሚችል የግል ነገር ይጻፉ።",
    "fun_fact_tell_us_classmates_only_non_english": "ከክፍል ጓደኛው ጋር ለመነጋገር ሊያነሳሳ የሚችል የግል ነገር ይጻፉ። እባኮትን በእንግሊዝኛ መልሱ።",
    "show_my_profile_to_non_degree_students": "ዲግሪ ላልሆኑ ተማሪዎች መገለጫዬን አሳይ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.student_network_settings_form = window.locales.ar.careers.edit_career_profile.student_network_settings_form || {};window.locales.ar.careers.edit_career_profile.student_network_settings_form = {...window.locales.ar.careers.edit_career_profile.student_network_settings_form, ...{
    "fun_fact": "حقيقة ممتعة",
    "fun_fact_tell_us": "اكتب شيئًا شخصيًا قد يؤدي إلى محادثة مع صاحب عمل أو زميل محتمل.",
    "fun_fact_tell_us_non_english": "اكتب شيئًا شخصيًا قد يؤدي إلى محادثة مع صاحب عمل أو زميل محتمل. الرجاء الإجابة باللغة الإنجليزية.",
    "fun_fact_desc": "اكتب شيئًا شخصيًا قد يؤدي إلى محادثة مع صاحب عمل محتمل.",
    "fun_fact_desc_non_english": "اكتب شيئًا شخصيًا قد يؤدي إلى محادثة مع صاحب عمل محتمل. الرجاء الإجابة باللغة الإنجليزية.",
    "fun_fact_placeholder": "يمكنني صنع ملابس مطبوعة ثلاثية الأبعاد",
    "fun_fact_tell_us_classmates_only": "اكتب شيئًا شخصيًا قد يؤدي إلى محادثة مع زميل في الفصل.",
    "fun_fact_tell_us_classmates_only_non_english": "اكتب شيئًا شخصيًا قد يؤدي إلى محادثة مع زميل في الفصل. الرجاء الإجابة باللغة الإنجليزية.",
    "show_my_profile_to_non_degree_students": "إظهار ملفي الشخصي للطلاب غير الحاصلين على درجة علمية"
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.student_network_settings_form = window.locales.en.careers.edit_career_profile.student_network_settings_form || {};window.locales.en.careers.edit_career_profile.student_network_settings_form = {...window.locales.en.careers.edit_career_profile.student_network_settings_form, ...{
    "fun_fact": "Fun Fact",
    "fun_fact_tell_us": "Write something personal that could prompt conversation with a potential employer or classmate.",
    "fun_fact_tell_us_non_english": "Write something personal that could prompt conversation with a potential employer or classmate. Please answer in English.",
    "fun_fact_desc": "Write something personal that could prompt conversation with a potential employer.",
    "fun_fact_desc_non_english": "Write something personal that could prompt conversation with a potential employer. Please answer in English.",
    "fun_fact_placeholder": "I can make 3D printed clothing",
    "fun_fact_tell_us_classmates_only": "Write something personal that could prompt a conversation with a classmate.",
    "fun_fact_tell_us_classmates_only_non_english": "Write something personal that could prompt a conversation with a classmate. Please answer in English.",
    "show_my_profile_to_non_degree_students": "Show my profile to non degree students"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.student_network_settings_form = window.locales.es.careers.edit_career_profile.student_network_settings_form || {};window.locales.es.careers.edit_career_profile.student_network_settings_form = {...window.locales.es.careers.edit_career_profile.student_network_settings_form, ...{
    "fun_fact": "Hecho de la diversión",
    "fun_fact_tell_us": "Escribe algo personal que pueda generar una conversación con un posible empleador o compañero de clase.",
    "fun_fact_tell_us_non_english": "Escribe algo personal que pueda generar una conversación con un posible empleador o compañero de clase. Por favor responda en inglés.",
    "fun_fact_desc": "Escriba algo personal que pueda generar una conversación con un empleador potencial.",
    "fun_fact_desc_non_english": "Escriba algo personal que pueda generar una conversación con un empleador potencial. Por favor responda en inglés.",
    "fun_fact_placeholder": "Puedo hacer ropa impresa en 3D",
    "fun_fact_tell_us_classmates_only": "Escribe algo personal que pueda provocar una conversación con un compañero de clase.",
    "fun_fact_tell_us_classmates_only_non_english": "Escribe algo personal que pueda provocar una conversación con un compañero de clase. Por favor responda en inglés.",
    "show_my_profile_to_non_degree_students": "Mostrar mi perfil a estudiantes no titulados"
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.student_network_settings_form = window.locales.it.careers.edit_career_profile.student_network_settings_form || {};window.locales.it.careers.edit_career_profile.student_network_settings_form = {...window.locales.it.careers.edit_career_profile.student_network_settings_form, ...{
    "fun_fact": "Fatto divertente",
    "fun_fact_tell_us": "Scrivi qualcosa di personale che potrebbe stimolare una conversazione con un potenziale datore di lavoro o compagno di classe.",
    "fun_fact_tell_us_non_english": "Scrivi qualcosa di personale che potrebbe stimolare una conversazione con un potenziale datore di lavoro o compagno di classe. Si prega di rispondere in inglese.",
    "fun_fact_desc": "Scrivi qualcosa di personale che possa stimolare una conversazione con un potenziale datore di lavoro.",
    "fun_fact_desc_non_english": "Scrivi qualcosa di personale che possa stimolare una conversazione con un potenziale datore di lavoro. Si prega di rispondere in inglese.",
    "fun_fact_placeholder": "Posso realizzare abiti stampati in 3D",
    "fun_fact_tell_us_classmates_only": "Scrivi qualcosa di personale che potrebbe indurre una conversazione con un compagno di classe.",
    "fun_fact_tell_us_classmates_only_non_english": "Scrivi qualcosa di personale che potrebbe indurre una conversazione con un compagno di classe. Si prega di rispondere in inglese.",
    "show_my_profile_to_non_degree_students": "Mostra il mio profilo agli studenti non laureati"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.student_network_settings_form = window.locales.zh.careers.edit_career_profile.student_network_settings_form || {};window.locales.zh.careers.edit_career_profile.student_network_settings_form = {...window.locales.zh.careers.edit_career_profile.student_network_settings_form, ...{
    "fun_fact": "有趣的事实",
    "fun_fact_tell_us": "写一些个人的东西，可以促进与潜在雇主或同学的对话。",
    "fun_fact_tell_us_non_english": "写一些个人的东西，可以促进与潜在雇主或同学的对话。请用英文回答。",
    "fun_fact_desc": "写一些个人的东西，可以促使与潜在雇主的对话。",
    "fun_fact_desc_non_english": "写一些个人的东西，可以促使与潜在雇主的对话。请用英文回答。",
    "fun_fact_placeholder": "我可以制作 3D 打印服装",
    "fun_fact_tell_us_classmates_only": "写一些个人的东西，可以促使与同学的对话。",
    "fun_fact_tell_us_classmates_only_non_english": "写一些个人的东西，可以促使与同学交谈。请用英文回答。",
    "show_my_profile_to_non_degree_students": "向非学位学生显示我的个人资料"
}
};