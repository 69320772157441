window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.submit_application_form = window.locales.am.careers.edit_career_profile.submit_application_form || {};window.locales.am.careers.edit_career_profile.submit_application_form = {...window.locales.am.careers.edit_career_profile.submit_application_form, ...{
    "primary_message": "ይኸው ነው! ማመልከቻዎን ለግምገማ ለማስገባት ከዚህ በታች ያለውን ቁልፍ ይጠቀሙ።",           
    "primary_message_network_only": "ማመልከቻዎን ስላጠናቀቁ እናመሰግናለን። ማመልከቻዎን ለግምገማ ለማስገባት ከዚህ በታች ያለውን ቁልፍ ይጠቀሙ።",           
    "deadline_message_network_only": "እኛ የምንገመግመው በቅደም ተከተል ሲሆን አብዛኛውን ጊዜ በጥቂት ቀናት ውስጥ ወደ አመልካቾች እንመለሳለን።",           
    "chearful_message_mba": "የሚቀጥለው የ MBA ክፍል አካል ሆነው ልናይዎት ተስፋ እናደርጋለን!",           
    "chearful_message_emba": "የሚቀጥለው የዋና MBA ክፍል አካል ሆነው ልናይዎት ተስፋ እናደርጋለን!",           
    "not_complete_message": "እባክዎ ማስረከብን ለመክፈት ሁሉንም አስፈላጊ ደረጃዎች ያጠናቅቁ። አስፈላጊ መስኮች በሚከተሉት ምልክቶች ተለይተዋል: <span>*</span>",           
    "submit_application": "ማመልከቻ ያስገቡ",           
    "application_updated": "መተግበሪያ ተዘምኗል!",           
    "profile_card_may_be_viewed": "የግለ-መገለጫ ካርድዎ ለመግቢያ ቡድናችን ብቻ ነው የሚታየው። ተቀባይነት ካገኙ፣ ወደ እኛ የሙያ አውታር መረብ ከገቡ በክፍል ጓደኞች፣ የቀድሞ ተማሪዎች እና ሊሆኑ የሚችሉ ቀጣሪዎች ሊታዩ ይችላሉ።",           
    "review_and_edit": "ግለ-መገለጫዎን ይገምግሙ እና አስፈላጊ ከሆነ ለማርትዕ ይመለሱ።",           
    "mobile_phone_number": "የሞባይል ስልክ ቁጥር",           
    "we_will_message_you": "የመግቢያ ውሳኔ በሚደረግበት ቀን መልዕክት እንልክልዎታለን። የመልዕክት እና የውሂብ ተመኖች ሊኖሩ ይችላሉ።",           
    "toggle_full_profile": "ሙሉ ገለ-መገለጫ ቀያይር",           
    "certify_all_info_and_documents_are_correct": "ይህን ማመልከቻ በማስገባት አመልካች መሆኔን እና የቀረቡት ሁሉም መረጃዎችና ደጋፊ ሰነዶች እውነተኛ እና ትክክለኛ መሆናቸውን አረጋግጣለሁ። ተቀባይነት ካገኘሁ፣ የትምህርት ብቁነቴን የሚያረጋግጡ ይፋዊ ግልባጮችን ማቅረብ እንደሚጠበቅብኝ ተረድቻለሁ።",           
    "you_are_about_to_apply": "ወደ እኛ MBA ሊያመለክቱ ነው…",           
    "we_also_offer": "የአስተዳደር እና የስራ ፈጠራ ኮርሶችን ለቀጥታ እና የኦንላይን ትብብር ትልቅ እድሎችን የሚጨምር ልምድ ላላቸው ባለሙያዎች በተመጣጣኝ ዋጋ ያለው ዋና MBA እሰጣለን። በብቃት እና ፍላጎት ላይ የተመሰረቱ ስኮላርሺፖች (የትምህርት እድሎች) አሉ።",           
    "switch_to_emba": "ወደ ዋና MBA ቀይር",           
    "or": "ወይም",           
    "submit_mba_application": "MBA ማመልከቻ ያስገቡ",           
    "valar_opt_in_agreement": "ለሁለቱም የቫላር እና የኳንቲክ {{programType}} ዲግሪ (የሚመከር) መቆጠር እፈልጋለሁ።",
    "valar_opt_in_desc": "ቫላር ኢንስቲትዩት፣ የኳንቲክ ክፍል፣ በተመሳሳዩ የቴክኖሎጂ መድረክ ላይ {{programType}} ን ያቀርባል፣ ነገር ግን በአስተዳደር እና አመራር ላይ የበለጠ ትኩረት ይሰጣል። <strong>አጠቃላይ የመግባት እድላቸውን ለማሻሻል ለሁለቱም ፕሮግራሞች ግምት ውስጥ እንዲገቡ አመልካቾችን አጥብቀን እናበረታታለን።</strong>",
    "profile_card_may_be_viewed_student_network": "የመገለጫ ካርድዎ ለመግቢያ ቡድናችን ብቻ ነው የሚታየው። ተቀባይነት ካገኘህ፣ ወደ የተማሪ ኔትዎርክ ከገባህ በክፍል ጓደኞችህ እና ተማሪዎች ሊታዩ ይችላሉ።",
    "choose_admission_preference": "የመግቢያ ምርጫዎን ይምረጡ",
    "early_admission": "ቀደም መግቢያ",
    "regular_admission": "መደበኛ መግቢያ",
    "regular_admission_desc": "የመግቢያ ውሳኔዎን በ {{decisionDate}} ላይ በመደበኛ የአመልካቾች ስብስብ ይቀበሉ። ለዚህ ቡድን የመቀበያ ዋጋ በትንሹ ዝቅተኛ ሲሆን የስኮላርሺፕ የገንዘብ ድጋፍ በጣም የተገደበ ነው።",
    "regular_admission_desc_no_scholarship": "የመግቢያ ውሳኔዎን በ {{decisionDate}} ላይ በመደበኛ የአመልካቾች ስብስብ ይቀበሉ። የዚህ ቡድን ተቀባይነት መጠን በትንሹ ዝቅተኛ ነው።",
    "early_admission_desc": "በተጣደፉ የመግቢያ ውሳኔዎች፣ ከፍተኛ ተቀባይነት ደረጃዎች እና ብዙ ጊዜ የላቀ የስኮላርሺፕ ሽልማቶችን በመጠቀም ቅድሚያ ግምገማን ተቀበል። ይህን ማመልከቻ ካስገቡ በኋላ፣ በእርስዎ {{brandNameShort}} ዳሽቦርድ ውስጥ የሚገኘውን አንድ በራስ የሚሄድ ኮርስ ብቻ ማጠናቀቅ ያስፈልግዎታል።",
    "early_admission_desc_no_scholarship": "ከተጣደፉ የመግቢያ ውሳኔዎች እና ከፍተኛ ተቀባይነት ዋጋዎች ጋር የቅድሚያ ግምገማን ተቀበል። ይህን ማመልከቻ ካስገቡ በኋላ፣ በእርስዎ {{brandNameShort}} ዳሽቦርድ ውስጥ የሚገኘውን አንድ በራስ የሚሄድ ኮርስ ብቻ ማጠናቀቅ ያስፈልግዎታል።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.submit_application_form = window.locales.ar.careers.edit_career_profile.submit_application_form || {};window.locales.ar.careers.edit_career_profile.submit_application_form = {...window.locales.ar.careers.edit_career_profile.submit_application_form, ...{
    "primary_message": "هذا هو! ما عليك سوى استخدام الزر أدناه لإرسال طلبك للمراجعة.",
    "chearful_message_mba": "نأمل أن نراكم كجزء من الدرجة ماجستير في إدارة الأعمال المقبل!",
    "chearful_message_emba": "نأمل أن نراكم كجزء من الدرجة ماجستير في إدارة الأعمال التنفيذية القادمة!",
    "submit_application": "قدم الطلب",
    "application_updated": "تم تحديث التطبيق!",
    "primary_message_network_only": "شكرا لاستكمال طلبك. ما عليك سوى استخدام الزر أدناه لإرسال طلبك للمراجعة.",
    "deadline_message_network_only": "نحن نراجع على أساس متجدد، وعادة ما نعود إلى مقدمي الطلبات في غضون أيام قليلة.",
    "review_and_edit": "راجع ملفك الشخصي ثم ارجع إلى التعديل إذا لزم الأمر.",
    "mobile_phone_number": "رقم الهاتف الجوال",
    "we_will_message_you": "سوف نرسل لك رسالة في يوم قرارات القبول. قد يتم تطبيق رسوم البيانات والرسائل.",
    "not_complete_message": "يرجى إكمال جميع الخطوات المطلوبة لإلغاء تأمين الإرسال. الحقول الإلزامية مشار إليها ب: <span>*</span>",
    "toggle_full_profile": "تبديل الملف الكامل",
    "we_also_offer": "كما نقدم شهادة ماجستير إدارة أعمال تنفيذية ميسورة التكلفة لأصحاب المهن الأكثر خبرة والتي تضيف دورات في الإدارة وريادة الأعمال مع فرص أكبر للتعاون المباشر عبر الإنترنت. تتوفر الاستحقاقات الدراسية القائمة على الحاجة.",
    "switch_to_emba": "التحول إلى ماجستير في إدارة الأعمال التنفيذية",
    "or": "أو",
    "submit_mba_application": "تقديم طلب ماجستير في إدارة الأعمال",
    "profile_card_may_be_viewed": "بطاقة ملفك الشخصي مرئية فقط لفريق القبول لدينا. إذا تم قبولك ، فقد يتم عرضه من قِبل زملاء الدراسة والخريجين وأصحاب العمل المحتملين إذا اشتركت في شبكتنا المهنية.",
    "certify_all_info_and_documents_are_correct": "من خلال تقديم هذا الطلب ، أقر بأنني مقدم الطلب وأن جميع المعلومات والوثائق الداعمة المقدمة صحيحة وصحيحة. أفهم أنه في حالة قبولي ، سيُطلب مني تقديم نسخ رسمية تثبت أهليتي الأكاديمية.",
    "you_are_about_to_apply": "أنت على وشك التقدم إلى ماجستير إدارة الأعمال لدينا ...",
    "valar_opt_in_desc": "يقدم Valar Institute ، أحد أقسام Quantic ، {{programType}} على نفس النظام الأساسي التكنولوجي ، ولكن مع تركيز أكبر على الإدارة والقيادة. نحن نشجع المتقدمين بشدة على البقاء في الاعتبار لكلا البرنامجين <strong>لتحسين فرصهم الإجمالية للقبول.</strong>",
    "valar_opt_in_agreement": "أرغب في الحصول على درجتي Valar و {{programType}} (موصى به)",
    "profile_card_may_be_viewed_student_network": "بطاقة ملفك الشخصي مرئية فقط لفريق القبول لدينا. إذا تم قبولك ، فقد يراه زملاء الدراسة والخريجون إذا اشتركت في شبكة الطلاب الخاصة بنا.",
    "choose_admission_preference": "اختر تفضيلات القبول الخاصة بك",
    "early_admission": "القبول المبكر",
    "regular_admission": "القبول العادي",
    "regular_admission_desc": "احصل على قرار القبول الخاص بك ضمن مجموعة المتقدمين المنتظمة في {{decisionDate}} . معدلات القبول أقل قليلاً لهذه المجموعة وتمويل المنح الدراسية أكثر محدودية.",
    "regular_admission_desc_no_scholarship": "احصل على قرار القبول الخاص بك ضمن مجموعة المتقدمين المنتظمة في {{decisionDate}} . معدلات القبول أقل قليلاً لهذه المجموعة.",
    "early_admission_desc": "احصل على مراجعة أولوية مع قرارات قبول سريعة ومعدلات قبول أعلى وجوائز منح دراسية أكبر غالبًا. بعد تقديم هذا الطلب، ستحتاج إلى إكمال دورة تدريبية واحدة فقط بالسرعة التي تريدها والمتوفرة في لوحة معلومات {{brandNameShort}}.",
    "early_admission_desc_no_scholarship": "احصل على مراجعة أولوية مع قرارات قبول سريعة ومعدلات قبول أعلى. بعد تقديم هذا الطلب، ستحتاج إلى إكمال دورة تدريبية واحدة فقط بالسرعة التي تناسبك ومتاحة في لوحة معلومات {{brandNameShort}}."
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.submit_application_form = window.locales.en.careers.edit_career_profile.submit_application_form || {};window.locales.en.careers.edit_career_profile.submit_application_form = {...window.locales.en.careers.edit_career_profile.submit_application_form, ...{
    "primary_message": "That's it! Just use the button below to submit your application for review.",
    "primary_message_network_only": "Thanks for completing your application. Just use the button below to submit your application for review.",
    "deadline_message_network_only": "We review on a rolling basis, and usually get back to applicants within a few days.",
    "chearful_message_mba": "We hope to see you as part of the next MBA class!",
    "chearful_message_emba": "We hope to see you as part of the next Executive MBA class!",
    "not_complete_message": "Please complete all required steps to unlock submission. Required fields are marked with: <span>*</span>",
    "submit_application": "Submit Application",
    "application_updated": "Application Updated!",
    "profile_card_may_be_viewed": "Your profile card is only visible to our Admissions team. If you're accepted, it may be seen by classmates, alumni and potential employers if you opt in to our career network.",
    "profile_card_may_be_viewed_student_network": "Your profile card is only visible to our Admissions team. If you're accepted, it may be seen by classmates and alumni if you opt in to our student network.",
    "review_and_edit": "Review your profile and go back to edit if necessary.",
    "mobile_phone_number": "Mobile Phone Number",
    "we_will_message_you": "We will message you on the day of admission decisions. Message and data rates may apply.",
    "toggle_full_profile": "Toggle Full Profile",
    "certify_all_info_and_documents_are_correct": "By submitting this application, I certify that I am the applicant and that all information and supporting documents provided are true and correct. I understand that if I am accepted, I will be required to provide official transcripts that verify my academic eligibility.",
    "choose_admission_preference": "Choose Your Admission Preference",
    "early_admission": "Early Admission",
    "regular_admission": "Regular Admission",
    "early_admission_desc": "Receive priority review with accelerated admission decisions, higher acceptance rates, and often greater scholarship awards. After submitting this application, you will need to complete just one self-paced course available in your {{brandNameShort}} dashboard.",
    "early_admission_desc_no_scholarship": "Receive priority review with accelerated admission decisions and higher acceptance rates. After submitting this application, you will need to complete just one self-paced course available in your {{brandNameShort}} dashboard.",
    "regular_admission_desc": "Receive your admission decision in the regular pool of applicants on {{decisionDate}}. Acceptance rates are slightly lower for this group and scholarship funding is more limited.",
    "regular_admission_desc_no_scholarship": "Receive your admission decision in the regular pool of applicants on {{decisionDate}}. Acceptance rates are slightly lower for this group.",

    "valar_opt_in_desc": "Valar Institute, a division of Quantic, offers an {{programType}} on the same technology platform, but with a greater emphasis on management and leadership. We strongly encourage applicants to remain in consideration for both programs to <strong>improve their overall chance of admission.</strong>",
    "valar_opt_in_agreement": "I would like to be considered for both the Valar and Quantic {{programType}} degree (recommended)",

                                              
    "you_are_about_to_apply": "You are about to apply to our MBA…",
    "we_also_offer": "We also offer an affordable Executive MBA for more experienced professionals that adds management and entrepreneurship courses with greater opportunities for live and online collaboration. Merit and need-based scholarships are available.",
    "switch_to_emba": "Switch to Executive MBA",
    "or": "or",
    "submit_mba_application": "Submit MBA Application"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.submit_application_form = window.locales.es.careers.edit_career_profile.submit_application_form || {};window.locales.es.careers.edit_career_profile.submit_application_form = {...window.locales.es.careers.edit_career_profile.submit_application_form, ...{
    "primary_message": "¡Eso es! Simplemente utilice el botón de abajo para enviar su solicitud para su revisión.",
    "chearful_message_mba": "¡Esperamos verte como parte de la próxima clase de MBA!",
    "chearful_message_emba": "¡Esperamos verlo como parte de la próxima clase de Executive MBA!",
    "submit_application": "Presentar la solicitud",
    "application_updated": "Solicitud de Actualización!",
    "primary_message_network_only": "Gracias por completar su solicitud. Simplemente utilice el botón de abajo para enviar su solicitud para su revisión.",
    "deadline_message_network_only": "Repasamos en una base de balanceo, y por lo general volver a los solicitantes dentro de unos días.",
    "review_and_edit": "Revise su perfil y vuelva a editar si es necesario.",
    "mobile_phone_number": "Número de teléfono móvil",
    "we_will_message_you": "Le enviaremos un mensaje el día de las decisiones de admisión. Se pueden aplicar tarifas por mensajes y datos.",
    "not_complete_message": "Complete todos los pasos necesarios para desbloquear el envío. Los campos obligatorios están marcados con: <span>*</span>",
    "toggle_full_profile": "Alternar perfil completo",
    "we_also_offer": "También ofrecemos un Executive MBA asequible para profesionales más experimentados que agrega cursos de administración y emprendimiento con mayores oportunidades para la colaboración en vivo y en línea. Méritos y becas basadas en la necesidad están disponibles.",
    "switch_to_emba": "Cambiar a Executive MBA",
    "or": "o",
    "submit_mba_application": "Enviar solicitud de MBA",
    "profile_card_may_be_viewed": "Su tarjeta de perfil solo es visible para nuestro equipo de Admisiones. Si es aceptado, puede ser visto por compañeros de clase, ex alumnos y posibles empleadores si opta por nuestra red de carreras.",
    "certify_all_info_and_documents_are_correct": "Al enviar esta solicitud, certifico que soy el solicitante y que toda la información y los documentos de respaldo proporcionados son verdaderos y correctos. Entiendo que si me aceptan, se me pedirá que proporcione transcripciones oficiales que verifiquen mi elegibilidad académica.",
    "you_are_about_to_apply": "Estás a punto de postularte a nuestro MBA ...",
    "valar_opt_in_agreement": "Me gustaría ser considerado para el título {{programType}} de Valar y Quantic (recomendado)",
    "valar_opt_in_desc": "Valar Institute, una división de Quantic, ofrece un {{programType}} en la misma plataforma tecnológica, pero con un mayor énfasis en la gestión y el liderazgo. Recomendamos encarecidamente a los solicitantes que permanezcan en consideración para ambos programas para <strong>mejorar sus posibilidades generales de admisión.</strong>",
    "profile_card_may_be_viewed_student_network": "Su tarjeta de perfil solo es visible para nuestro equipo de Admisiones. Si es aceptado, sus compañeros de clase y ex alumnos pueden verlo si se suscribe a nuestra red de estudiantes.",
    "choose_admission_preference": "Elija su preferencia de admisión",
    "early_admission": "Admisión anticipada",
    "regular_admission": "Admisión regular",
    "regular_admission_desc": "Reciba la decisión de admisión en el grupo regular de solicitantes el {{decisionDate}} . Las tasas de aceptación son ligeramente más bajas para este grupo y la financiación de las becas es más limitada.",
    "regular_admission_desc_no_scholarship": "Reciba la decisión de admisión en el grupo regular de solicitantes el {{decisionDate}} . Las tasas de aceptación son ligeramente más bajas para este grupo.",
    "early_admission_desc": "Reciba una revisión prioritaria con decisiones de admisión aceleradas, tasas de aceptación más altas y, a menudo, mayores becas. Después de enviar esta solicitud, deberá completar solo un curso a su propio ritmo disponible en su panel de control de {{brandNameShort}}.",
    "early_admission_desc_no_scholarship": "Reciba una revisión prioritaria con decisiones de admisión aceleradas y tasas de aceptación más altas. Después de enviar esta solicitud, deberá completar solo un curso a su propio ritmo disponible en su panel de control de {{brandNameShort}}."
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.submit_application_form = window.locales.it.careers.edit_career_profile.submit_application_form || {};window.locales.it.careers.edit_career_profile.submit_application_form = {...window.locales.it.careers.edit_career_profile.submit_application_form, ...{
    "primary_message": "Ecco fatto! Ora usa il pulsante in basso per inoltrare la tua candidatura per l'esame.",           
    "primary_message_network_only": "Grazie per aver completato la tua candidatura. Ora usa il pulsante in basso per inoltrare la tua candidatura per l'esame.",           
    "deadline_message_network_only": "Gli esami avvengono a rotazione, e solitamente ricontattiamo i candidati entro qualche giorno.",           
    "chearful_message_mba": "Speriamo di averti come partecipante alla prossima classe del MBA!",           
    "chearful_message_emba": "Speriamo di averti come partecipante alla prossima classe dell'Executive MBA!",           
    "submit_application": "Inoltra Candidatura",           
    "application_updated": "Candidatura Aggiornata!",           
    "review_and_edit": "Rivedere il tuo profilo e tornare indietro per modificare se necessario.",
    "mobile_phone_number": "Numero di cellulare",
    "we_will_message_you": "Ti invieremo un messaggio nel giorno delle decisioni di ammissione. Potrebbero essere applicate le tariffe di messaggi e dati.",
    "not_complete_message": "Si prega di completare tutti i passaggi richiesti per sbloccare l'invio. I campi obbligatori sono contrassegnati con: <span>*</span>",
    "toggle_full_profile": "Attiva / disattiva profilo completo",
    "we_also_offer": "Offriamo inoltre un Executive MBA a prezzi accessibili per professionisti più esperti che aggiungono corsi di management e imprenditorialità con maggiori opportunità di collaborazione dal vivo e online. Sono disponibili borse di studio basate sul merito e sul bisogno.",
    "switch_to_emba": "Passa a Executive MBA",
    "or": "o",
    "submit_mba_application": "Invia l'applicazione MBA",
    "profile_card_may_be_viewed": "La tua scheda profilo è visibile solo al nostro team di ammissione. Se sei accettato, può essere visto da compagni di classe, ex studenti e potenziali datori di lavoro se accetti alla nostra rete di carriera.",
    "certify_all_info_and_documents_are_correct": "Inviando questa domanda, dichiaro di essere il richiedente e che tutte le informazioni e i documenti giustificativi forniti sono veritieri e corretti. Capisco che se sarò accettato, mi sarà richiesto di fornire trascrizioni ufficiali che verifichino la mia idoneità accademica.",
    "you_are_about_to_apply": "Stai per iscriverti al nostro MBA ...",
    "valar_opt_in_agreement": "Vorrei essere preso in considerazione sia per la laurea Valar che Quantic {{programType}} (consigliato)",
    "valar_opt_in_desc": "Valar Institute, una divisione di Quantic, offre un {{programType}} sulla stessa piattaforma tecnologica, ma con una maggiore enfasi sulla gestione e sulla leadership. Incoraggiamo vivamente i candidati a tenere in considerazione entrambi i programmi per <strong>migliorare le loro possibilità complessive di ammissione.</strong>",
    "profile_card_may_be_viewed_student_network": "La tua scheda profilo è visibile solo al nostro team di ammissione. Se sei accettato, potrebbe essere visto da compagni di classe ed ex studenti se aderisci alla nostra rete di studenti.",
    "choose_admission_preference": "Scegli la tua preferenza di ammissione",
    "early_admission": "Ammissione anticipata",
    "regular_admission": "Ingresso regolare",
    "regular_admission_desc": "Ricevi la tua decisione di ammissione nel pool regolare di candidati il giorno {{decisionDate}} . I tassi di accettazione sono leggermente inferiori per questo gruppo e i finanziamenti per le borse di studio sono più limitati.",
    "regular_admission_desc_no_scholarship": "Ricevi la decisione sulla tua ammissione nel normale pool di candidati il giorno {{decisionDate}} . I tassi di accettazione sono leggermente inferiori per questo gruppo.",
    "early_admission_desc": "Ricevi una revisione prioritaria con decisioni di ammissione accelerate, tassi di accettazione più elevati e spesso borse di studio più elevate. Dopo aver inviato questa domanda, dovrai completare solo un corso autogestito disponibile nella tua dashboard {{brandNameShort}}.",
    "early_admission_desc_no_scholarship": "Ricevi una revisione prioritaria con decisioni di ammissione accelerate e tassi di accettazione più elevati. Dopo aver inviato questa domanda, dovrai completare solo un corso autogestito disponibile nella tua dashboard {{brandNameShort}}."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.submit_application_form = window.locales.zh.careers.edit_career_profile.submit_application_form || {};window.locales.zh.careers.edit_career_profile.submit_application_form = {...window.locales.zh.careers.edit_career_profile.submit_application_form, ...{
    "primary_message": "就是这样！点击下面的按钮提交你的申请以供审核。",           
    "chearful_message_mba": "我们期待你成为下期工商管理硕士（MBA）课程的一员！",           
    "chearful_message_emba": "我们期待你成为下期高级工商管理硕士（EMBA）课程的一员！",           
    "submit_application": "提交申请",           
    "application_updated": "申请更新！",           
    "primary_message_network_only": "感谢你完成申请。点击下面的按钮提交你的申请以供审核。",           
    "deadline_message_network_only": "我们按顺序进行审核，通常几天内就会向申请人作出答复。",           
    "review_and_edit": "检查自己的个人资料，如有必要，返回进行编辑。",           
    "mobile_phone_number": "手机号码",           
    "we_will_message_you": "我们会在录取决定当天通知你。消息和数据率可能适用。",           
    "not_complete_message": "请完成所有必要步骤以进行提交。带下列<span>*</span>标记的为必填项：",           
    "toggle_full_profile": "切换完整简介",           
    "we_also_offer": "我们还为更有经验的专业人士提供价格实惠的高级工商管理硕士（EMBA ）课程，增加了管理和创业课程，提供更多线下和线上合作机会。我们根据成绩和需求提供相应奖学金。",           
    "switch_to_emba": "跳到高级工商管理硕士（EMBA ）课程",           
    "or": "或者",           
    "submit_mba_application": "提交工商管理硕士（MBA ）申请",           
    "profile_card_may_be_viewed": "你的个人资料卡只对我们的招生团队可见。如果你被录取，并选择加入我们的求职网络，你的同学、校友和潜在雇主可能会看到你的简历。",           
    "certify_all_info_and_documents_are_correct": "提交此申请即证明本人为申请人，所提供的资料及证明文件均真实无误。本人明白，一经录取，即需要提供正式的成绩单，以验证自身的学术资格。",           
    "you_are_about_to_apply": "你要申请我们的工商管理硕士课程",           
    "valar_opt_in_agreement": "我想同时获得 Valar 和 Quantic {{programType}}学位（推荐）",
    "valar_opt_in_desc": "Valar Institute 是 Quantic 的一个部门，在同一技术平台上提供{{programType}} ，但更加强调管理和领导力。我们强烈鼓励申请人继续考虑这两个项目，以<strong>提高他们的整体录取机会。</strong>",
    "profile_card_may_be_viewed_student_network": "您的个人资料卡仅对我们的招生团队可见。如果您被录取，如果您选择加入我们的学生网络，同学和校友可能会看到。",
    "choose_admission_preference": "选择你的入学偏好",
    "early_admission": "提前录取",
    "regular_admission": "常规入场",
    "regular_admission_desc": "您将于{{decisionDate}}在常规申请者中收到录取决定。该群体的录取率略低，奖学金资助也较为有限。",
    "regular_admission_desc_no_scholarship": "您将于{{decisionDate}}在常规申请者中收到录取决定。此群体的录取率略低。",
    "early_admission_desc": "获得优先审核，加快录取决定，提高录取率，并通常获得更高的奖学金。提交此申请后，您只需完成{{brandNameShort}}仪表板中提供的一门自定进度课程。",
    "early_admission_desc_no_scholarship": "获得优先审核，加快录取决定并提高录取率。提交此申请后，您只需要完成{{brandNameShort}}仪表板上提供的一门自定进度课程。"
}
};