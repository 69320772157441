window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.application_questions_form = window.locales.am.careers.edit_career_profile.application_questions_form || {};window.locales.am.careers.edit_career_profile.application_questions_form = {...window.locales.am.careers.edit_career_profile.application_questions_form, ...{
    "funding_and_scholarships": "የገንዘብ ድጋፍ እና ስኮላርሺፕ (የትምህርት እድል)",           
    "employer_funding": "የአሰሪ የገንዘብ ድጋፍ",           
    "employer_funding_caption": "ኳንቲክ የሰራተኛ ትምህርትን በክፍያ ማካካሻ ጥቅማ ጥቅሞች ከሚረዱ ከበርካታ ኩባንያዎች ጋር ይሰራል።",           
    "scholarships": "ስኮላርሺፕ (አማራጭ)",           
    "how_hear": "ስለ {{brandName}} እንዴት ሰማህ?",
    "how_hear_placeholder": "ስለ እኛ የሰማህበትን ቦታ ግለጽ",
    "anything_else_to_tell_us": "ሌላ ሊነግሩን የሚፈልጉት ነገር አለ?",
    "short_answer_use_skills_to_advance": "በፕሮግራሙ ውስጥ የሚያገኟቸውን ችሎታዎች በተለይ ሥራዎን ለማሳደግ ምን ያህል ይጠቀማሉ?",
    "short_answer_prior_experience_master_of_science_in_business_analytics": "የቅድሚያ ትምህርትህ እና/ወይም ያለፉ ልምምዶችህ በፕሮግራሙ ውስጥ ለአካዳሚክ ስኬት እንዴት ያቆማሉ?",
    "short_answer_prior_experience_master_of_science_in_software_engineering": "ሶፍትዌሮችን እና/ወይም ድረ-ገጾችን ከማዘጋጀት ጋር የተያያዘ ቀዳሚ ልምድ (የተከፈለ ወይም በራስዎ ጊዜ) ልምድ አለዎት?",
    "short_answer_prior_experience_master_of_business_administrations": "የቀደመ አካዳሚያዊ ወይም የህይወት ተሞክሮዎ በ {{brandNameShort}} የዲግሪ መርሃ ግብር ውስጥ ለስኬታማነት ቦታ እንደሚሰጥዎት እንዴት ያምናሉ?",
    "short_answer_why_pursuing_master_of_business_administration": "ለምን በዚህ ጊዜ የቢዝነስ አስተዳደር ማስተርን ትከታተላለህ? እና ለምን በተለይ {{brandName}} እያጤኑ ነው?",
    "short_answer_why_pursuing_master_of_science_in_business_analytics": "ለምንድን ነው በዚህ ጊዜ የሳይንስ ማስተርን በቢዝነስ ትንታኔ የምትከታተለው? እና ለምን በተለይ {{brandName}} እያጤኑ ነው?",
    "short_answer_why_pursuing_master_of_science_in_software_engineering": "ለምንድን ነው በዚህ ጊዜ የሳይንስ ማስተር በሶፍትዌር ኢንጂነሪንግ የምትከታተለው? እና ለምን በተለይ {{brandName}} እያጤኑ ነው?"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.application_questions_form = window.locales.ar.careers.edit_career_profile.application_questions_form || {};window.locales.ar.careers.edit_career_profile.application_questions_form = {...window.locales.ar.careers.edit_career_profile.application_questions_form, ...{
    "funding_and_scholarships": "التمويل والمنح الدراسية",
    "employer_funding": "تمويل صاحب العمل",
    "employer_funding_caption": "تعمل {{brandName}} مع العديد من الشركات التي تمول تعليم الموظفين من خلال مزايا سداد الرسوم الدراسية.",
    "scholarships": "المنح الدراسية (اختياري)",
    "how_hear": "كيف سمعت عن {{brandName}} ؟",
    "how_hear_placeholder": "صف أين سمعت عنا",
    "anything_else_to_tell_us": "هل هناك أي شيء آخر تود إخبارنا به؟",
    "short_answer_use_skills_to_advance": "كيف ستستخدم على وجه التحديد المهارات التي ستكتسبها في البرنامج لتعزيز حياتك المهنية؟",
    "short_answer_prior_experience_master_of_science_in_business_analytics": "كيف يضعك تعليمك السابق و/أو تجاربك السابقة في تحقيق النجاح الأكاديمي في البرنامج؟",
    "short_answer_prior_experience_master_of_science_in_software_engineering": "هل لديك خبرة سابقة (مدفوعة الأجر أو بوقتك الخاص) تتعلق بتطوير البرامج و/أو مواقع الويب؟",
    "short_answer_prior_experience_master_of_business_administrations": "كيف تعتقد أن خبرتك الأكاديمية أو الحياتية السابقة ستساعدك على تحقيق النجاح في برنامج درجة {{brandNameShort}} ؟",
    "short_answer_why_pursuing_master_of_business_administration": "لماذا تسعى للحصول على درجة الماجستير في إدارة الأعمال في هذا الوقت؟ ولماذا تفكر في {{brandName}} على وجه الخصوص؟",
    "short_answer_why_pursuing_master_of_science_in_business_analytics": "لماذا تسعى للحصول على درجة الماجستير في تحليلات الأعمال في هذا الوقت؟ ولماذا تفكر في {{brandName}} على وجه الخصوص؟",
    "short_answer_why_pursuing_master_of_science_in_software_engineering": "لماذا تسعى للحصول على درجة الماجستير في هندسة البرمجيات في هذا الوقت؟ ولماذا تفكر في {{brandName}} على وجه الخصوص؟"
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.application_questions_form = window.locales.en.careers.edit_career_profile.application_questions_form || {};window.locales.en.careers.edit_career_profile.application_questions_form = {...window.locales.en.careers.edit_career_profile.application_questions_form, ...{
    "short_answer_why_pursuing_master_of_business_administration": "Why are you pursuing a Master of Business Administration at this time? And why are you considering {{brandName}} in particular?",
    "short_answer_why_pursuing_master_of_science_in_business_analytics": "Why are you pursuing a Master of Science in Business Analytics at this time? And why are you considering {{brandName}} in particular?",
    "short_answer_why_pursuing_master_of_science_in_software_engineering": "Why are you pursuing a Master of Science in Software Engineering at this time? And why are you considering {{brandName}} in particular?",
    "short_answer_use_skills_to_advance": "How specifically will you use the skills you will gain in the program to advance your career?",
    "short_answer_prior_experience_master_of_business_administrations": "How do you believe your prior academic or life experience positions you for success in {{brandNameShort}}'s degree program?",
    "short_answer_prior_experience_master_of_science_in_business_analytics": "How does your prior education and/or past experiences position you for academic success in the program?",
    "short_answer_prior_experience_master_of_science_in_software_engineering": "Do you have prior experience (paid or in your own time) related to developing software and/or websites?",

                                      
    "funding_and_scholarships": "Funding and Scholarships",

    "employer_funding": "Employer Funding",
    "employer_funding_caption": "{{brandName}} works with many companies that fund employee education through tuition reimbursement benefits.",

    "scholarships": "Scholarships (optional)",
    "how_hear": "How did you hear about {{brandName}}?",
    "how_hear_placeholder": "Describe where you heard about us",
    "anything_else_to_tell_us": "Is there anything else you would like to tell us?"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.application_questions_form = window.locales.es.careers.edit_career_profile.application_questions_form || {};window.locales.es.careers.edit_career_profile.application_questions_form = {...window.locales.es.careers.edit_career_profile.application_questions_form, ...{
    "funding_and_scholarships": "Financiamiento y becas",
    "employer_funding": "Financiamiento del empleador",
    "employer_funding_caption": "{{brandName}} trabaja con muchas empresas que financian la educación de los empleados a través de beneficios de reembolso de matrícula.",
    "scholarships": "Becas (opcional)",
    "how_hear": "¿Cómo se enteró de {{brandName}} ?",
    "how_hear_placeholder": "Describa dónde se enteró de nosotros",
    "anything_else_to_tell_us": "¿Hay algo más que quieras decirnos?",
    "short_answer_use_skills_to_advance": "¿Cómo usará específicamente las habilidades que obtendrá en el programa para avanzar en su carrera?",
    "short_answer_prior_experience_master_of_science_in_business_analytics": "¿Cómo su educación previa y/o experiencias pasadas lo posicionan para el éxito académico en el programa?",
    "short_answer_prior_experience_master_of_science_in_software_engineering": "¿Tiene experiencia previa (pagada o por su cuenta) relacionada con el desarrollo de software y/o sitios web?",
    "short_answer_prior_experience_master_of_business_administrations": "¿Cómo cree que su experiencia académica o de vida anterior lo posiciona para tener éxito en el programa de estudios de {{brandNameShort}} ?",
    "short_answer_why_pursuing_master_of_business_administration": "¿Por qué estás cursando un Máster en Administración de Empresas en este momento? ¿Y por qué estás considerando {{brandName}} en particular?",
    "short_answer_why_pursuing_master_of_science_in_business_analytics": "¿Por qué estás cursando una Maestría en Ciencias en Análisis de Negocios en este momento? ¿Y por qué estás considerando {{brandName}} en particular?",
    "short_answer_why_pursuing_master_of_science_in_software_engineering": "¿Por qué estás cursando una Maestría en Ciencias en Ingeniería de Software en este momento? ¿Y por qué estás considerando {{brandName}} en particular?"
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.application_questions_form = window.locales.it.careers.edit_career_profile.application_questions_form || {};window.locales.it.careers.edit_career_profile.application_questions_form = {...window.locales.it.careers.edit_career_profile.application_questions_form, ...{
    "funding_and_scholarships": "Finanziamenti e borse di studio",
    "employer_funding": "Finanziamento del datore di lavoro",
    "employer_funding_caption": "{{brandName}} lavora con molte aziende che finanziano la formazione dei dipendenti attraverso vantaggi di rimborso delle tasse scolastiche.",
    "scholarships": "Borse di studio (facoltative)",
    "how_hear": "Come hai saputo di {{brandName}} ?",
    "how_hear_placeholder": "Descrivi dove hai sentito parlare di noi",
    "anything_else_to_tell_us": "C'è qualcos'altro che vorresti dirci?",
    "short_answer_use_skills_to_advance": "In che modo utilizzerai in modo specifico le competenze che acquisirai nel programma per far avanzare la tua carriera?",
    "short_answer_prior_experience_master_of_science_in_business_analytics": "In che modo la tua istruzione precedente e/o le tue esperienze passate ti posizionano per il successo accademico nel programma?",
    "short_answer_prior_experience_master_of_science_in_software_engineering": "Hai esperienza precedente (retribuita o nel tuo tempo libero) relativa allo sviluppo di software e/o siti Web?",
    "short_answer_prior_experience_master_of_business_administrations": "In che modo ritieni che la tua precedente esperienza accademica o di vita ti posizioni per il successo nel corso di laurea di {{brandNameShort}} ?",
    "short_answer_why_pursuing_master_of_business_administration": "Perché stai conseguendo un Master in Business Administration in questo momento? E perché stai prendendo in considerazione in particolare {{brandName}} ?",
    "short_answer_why_pursuing_master_of_science_in_business_analytics": "Perché stai conseguendo un Master of Science in Business Analytics in questo momento? E perché stai prendendo in considerazione in particolare {{brandName}} ?",
    "short_answer_why_pursuing_master_of_science_in_software_engineering": "Perché stai conseguendo un Master of Science in Ingegneria del Software in questo momento? E perché stai prendendo in considerazione in particolare {{brandName}} ?"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.application_questions_form = window.locales.zh.careers.edit_career_profile.application_questions_form || {};window.locales.zh.careers.edit_career_profile.application_questions_form = {...window.locales.zh.careers.edit_career_profile.application_questions_form, ...{
    "funding_and_scholarships": "资助和奖学金",           
    "employer_funding": "雇主资助",           
    "employer_funding_caption": "{{brandName}}与许多公司合作，通过学费报销福利资助员工的教育。",           
    "scholarships": "奖学金（可选）",           
    "how_hear": "您是如何{{brandName}} ？",
    "how_hear_placeholder": "描述您在哪里听说过我们",
    "anything_else_to_tell_us": "您还有什么想告诉我们的吗？",
    "short_answer_use_skills_to_advance": "您将如何具体地使用您将在该计划中获得的技能来推进您的职业生涯？",
    "short_answer_prior_experience_master_of_science_in_business_analytics": "您之前的教育和/或过去的经历如何帮助您在该课程中取得学业成功？",
    "short_answer_prior_experience_master_of_science_in_software_engineering": "您是否具有开发软件和/或网站相关的经验（有偿或自费）？",
    "short_answer_prior_experience_master_of_business_administrations": "您认为您之前的学术或生活经历如何帮助您在{{brandNameShort}}的学位课程中取得成功？",
    "short_answer_why_pursuing_master_of_business_administration": "您为什么此时要攻读工商管理硕士学位？为什么您特别考虑{{brandName}} ？",
    "short_answer_why_pursuing_master_of_science_in_business_analytics": "您为什么此时要攻读商业分析理学硕士学位？为什么您特别考虑{{brandName}} ？",
    "short_answer_why_pursuing_master_of_science_in_software_engineering": "您为什么此时要攻读软件工程理学硕士学位？为什么您特别考虑{{brandName}} ？"
}
};