window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.salary_survey_form_section = window.locales.am.careers.edit_career_profile.salary_survey_form_section || {};window.locales.am.careers.edit_career_profile.salary_survey_form_section = {...window.locales.am.careers.edit_career_profile.salary_survey_form_section, ...{
    "salary_survey": "የደመወዝ የዳሰሳ ጥናት",           
    "salary_survey_caption": "ይህ መረጃ ሚስጥራዊ ነው እናም በይፋ አይታይም። ወደ የሙያ አውታረ መረብ ከተቀላቀሉ ቀጣሪዎች ሊሆኑ ከሚችሉት ጋር እንድናጣምርዎት ይረዳናል።",           
    "total_salary": "እባክዎ በባለፈው አመት የነበረውን ጠቅላላ ደሞዝዎን (ቦነስ ወይም ኮሚሽንን ሳይጨምር) ያመልክቱ",           
    "prefer_not_to_disclose": "አለመግለፅን እመርጣለሁ።",           
    "less_than_40000": "ከ $40,000 የአሜሪካ ዶላር በታች",           
    "40000_to_49999": "ከ $40,000 እስከ $49,999 የአሜሪካ ዶላር",           
    "50000_to_59999": "ከ $50,000 እስከ $59,999 የአሜሪካ ዶላር",           
    "60000_to_69999": "ከ $60,000 እስከ $69,999 የአሜሪካ ዶላር",           
    "70000_to_79999": "ከ $70,000 እስከ $79,999 የአሜሪካ ዶላር",           
    "80000_to_89999": "ከ $80,000 እስከ $89,999 የአሜሪካ ዶላር",           
    "90000_to_99999": "ከ $90,000 እስከ $99,999 የአሜሪካ ዶላር",           
    "100000_to_119999": "ከ $100,000 እስከ $119,999 የአሜሪካ ዶላር",           
    "120000_to_149999": "ከ $120,000 እስከ $149,999 የአሜሪካ ዶላር",           
    "150000_to_199999": "ከ $150,000 እስከ $199,999 የአሜሪካ ዶላር",           
    "over_200000": "ከ $200,000 የአሜሪካ ዶላር",           
    "less_than_20000": "ከ $20,000 የአሜሪካ ዶላር በታች",           
    "20000_to_29999": "ከ $20,000 እስከ $29,999 የአሜሪካ ዶላር",           
    "30000_to_39999": "ከ $30,000 እስከ $39,999 የአሜሪካ ዶላር"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.salary_survey_form_section = window.locales.ar.careers.edit_career_profile.salary_survey_form_section || {};window.locales.ar.careers.edit_career_profile.salary_survey_form_section = {...window.locales.ar.careers.edit_career_profile.salary_survey_form_section, ...{
    "salary_survey": "استطلاع الراتب",
    "salary_survey_caption": "هذه المعلومات سرية ولن يتم عرضها على الملأ. إنها تساعدنا على التوفيق بينك وبين أصحاب العمل المحتملين إذا انضممت إلى شبكة التوظيف.",
    "total_salary": "يرجى الإشارة إلى إجمالي راتبك (لا يشمل المكافأة أو العمولة) في العام السابق",
    "prefer_not_to_disclose": "أفضل عدم الكشف",
    "less_than_40000": "أقل من 40،000 دولار أمريكي",
    "40000_to_49999": "40000 دولار إلى 49999 دولار أمريكي",
    "50000_to_59999": "50،000 دولار إلى 59999 دولار أمريكي",
    "60000_to_69999": "60،000 دولار إلى 69999 دولار أمريكي",
    "70000_to_79999": "من 70،000 دولار إلى 79999 دولار أمريكي",
    "80000_to_89999": "80،000 دولار إلى 89،999 دولار أمريكي",
    "90000_to_99999": "90،000 دولار إلى 99،999 دولار أمريكي",
    "100000_to_119999": "100،000 دولار إلى 119،999 دولار",
    "120000_to_149999": "120،000 دولار إلى 149999 دولار أمريكي",
    "150000_to_199999": "150،000 دولار إلى 199999 دولار أمريكي",
    "over_200000": "أكثر من 200،000 دولار أمريكي",
    "less_than_20000": "أقل من 20000 دولار أمريكي",
    "20000_to_29999": "20000 دولار إلى 29999 دولارًا أمريكيًا",
    "30000_to_39999": "30 ألف دولار إلى 39999 دولارًا أمريكيًا"
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.salary_survey_form_section = window.locales.en.careers.edit_career_profile.salary_survey_form_section || {};window.locales.en.careers.edit_career_profile.salary_survey_form_section = {...window.locales.en.careers.edit_career_profile.salary_survey_form_section, ...{
    "salary_survey": "Salary Survey",
    "salary_survey_caption": "This information is confidential and will not be shown publicly. It helps us match you with potential employers if you join the career network.",
    "total_salary": "Please indicate your total salary (not including bonus or commission) in the previous year",
    "prefer_not_to_disclose": "Prefer not to disclose",
    "less_than_20000": "Less than $20,000 USD",
    "20000_to_29999": "$20,000 to $29,999 USD",
    "30000_to_39999": "$30,000 to $39,999 USD",
    "less_than_40000": "Less than $40,000 USD",                
    "40000_to_49999": "$40,000 to $49,999 USD",
    "50000_to_59999": "$50,000 to $59,999 USD",
    "60000_to_69999": "$60,000 to $69,999 USD",
    "70000_to_79999": "$70,000 to $79,999 USD",
    "80000_to_89999": "$80,000 to $89,999 USD",
    "90000_to_99999": "$90,000 to $99,999 USD",
    "100000_to_119999": "$100,000 to $119,999 USD",
    "120000_to_149999": "$120,000 to $149,999 USD",
    "150000_to_199999": "$150,000 to $199,999 USD",
    "over_200000": "Over $200,000 USD"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.salary_survey_form_section = window.locales.es.careers.edit_career_profile.salary_survey_form_section || {};window.locales.es.careers.edit_career_profile.salary_survey_form_section = {...window.locales.es.careers.edit_career_profile.salary_survey_form_section, ...{
    "salary_survey": "Encuesta salarial",
    "salary_survey_caption": "Esta información es confidencial y no se mostrará públicamente. Nos ayuda a emparejarlo con empleadores potenciales si se une a la red profesional.",
    "total_salary": "Indique su salario total (sin incluir bonificación o comisión) en el año anterior",
    "prefer_not_to_disclose": "Prefiero no revelar",
    "less_than_40000": "Menos de $ 40,000 USD",
    "40000_to_49999": "$ 40,000 a $ 49,999 USD",
    "50000_to_59999": "$ 50,000 a $ 59,999 USD",
    "60000_to_69999": "$ 60,000 a $ 69,999 USD",
    "70000_to_79999": "$ 70,000 a $ 79,999 USD",
    "80000_to_89999": "$ 80,000 a $ 89,999 USD",
    "90000_to_99999": "$ 90,000 a $ 99,999 USD",
    "100000_to_119999": "$ 100,000 a $ 119,999 USD",
    "120000_to_149999": "$ 120,000 a $ 149,999 USD",
    "150000_to_199999": "$ 150,000 a $ 199,999 USD",
    "over_200000": "Más de $ 200,000 USD",
    "less_than_20000": "Menos de $ 20,000 USD",
    "20000_to_29999": "$ 20,000 a $ 29,999 USD",
    "30000_to_39999": "$ 30,000 a $ 39,999 USD"
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.salary_survey_form_section = window.locales.it.careers.edit_career_profile.salary_survey_form_section || {};window.locales.it.careers.edit_career_profile.salary_survey_form_section = {...window.locales.it.careers.edit_career_profile.salary_survey_form_section, ...{
    "salary_survey": "Sondaggio Retribuzione",           
    "salary_survey_caption": "Questa informazione è confidenziale e non sarà mostrata pubblicamente. Ci aiuterà ad abbinarti con potenziali datori di lavoro se ti unirai alla rete professionale.",           
    "total_salary": "Per favore, indica la tua retribuzione totale (senza includere bonus o provvigioni) relativa all'anno precedente",           
    "prefer_not_to_disclose": "Preferisco non comunicarlo",           
    "less_than_40000": "Meno di $40,000 USD",           
    "40000_to_49999": "Da $40,000 a $49,999 USD",           
    "50000_to_59999": "Da $50,000 a $59,999 USD",           
    "60000_to_69999": "Da $60,000 a $69,999 USD",           
    "70000_to_79999": "Da $70,000 a $79,999 USD",           
    "80000_to_89999": "Da $80,000 a $89,999 USD",           
    "90000_to_99999": "Da $90,000 a $99,999 USD",           
    "100000_to_119999": "Da $100,000 a $119,999 USD",           
    "120000_to_149999": "Da $120,000 a $149,999 USD",           
    "150000_to_199999": "Da $150,000 a $199,999 USD",           
    "over_200000": "Oltre $200,000 USD",           
    "less_than_20000": "Meno di $20.000 USD",
    "20000_to_29999": "$ 20.000 a $ 29.999 USD",
    "30000_to_39999": "$ 30.000 a $ 39.999 USD"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.salary_survey_form_section = window.locales.zh.careers.edit_career_profile.salary_survey_form_section || {};window.locales.zh.careers.edit_career_profile.salary_survey_form_section = {...window.locales.zh.careers.edit_career_profile.salary_survey_form_section, ...{
    "salary_survey": "薪资调查",           
    "salary_survey_caption": "这些机密信息不作公开展示处理。如果你加入这个求职网络，其可有助于我们为你找到潜在雇主。",           
    "total_salary": "请注明你上一年度的薪金总额（不包括奖金或佣金）",           
    "prefer_not_to_disclose": "不愿透露",           
    "less_than_40000": "不到40000美元",           
    "40000_to_49999": "40000美元-49999美元",           
    "50000_to_59999": "50000美元-59999美元",           
    "60000_to_69999": "60000美元-69999美元",           
    "70000_to_79999": "70000美元-79999美元",           
    "80000_to_89999": "80000美元-89999美元",           
    "90000_to_99999": "90000美元-99999美元",           
    "100000_to_119999": "100000美元-119999美元",           
    "120000_to_149999": "120000美元-149999美元",           
    "150000_to_199999": "150000美元-199999美元",           
    "over_200000": "200000美元以上",           
    "less_than_20000": "不到20000美元",           
    "20000_to_29999": "20000美元-29999美元",           
    "30000_to_39999": "30000美元-39999美元"           
}
};