window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.field_options = window.locales.am.careers.edit_career_profile.field_options || {};window.locales.am.careers.edit_career_profile.field_options = {...window.locales.am.careers.edit_career_profile.field_options, ...{
    "eligible": "በኩባንያዬ በኩል ለክፍያ እርዳታ ብቁ ነኝ",           
    "not_eligible": "ለከፍያ እርዳታ ብቁ አይደለሁም",           
    "not_sure": "ብቁ መሆኔን እርግጠኛ አይደለሁም።",           
    "not_applicable": "ተግባራዊ የማይሆን"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.field_options = window.locales.ar.careers.edit_career_profile.field_options || {};window.locales.ar.careers.edit_career_profile.field_options = {...window.locales.ar.careers.edit_career_profile.field_options, ...{
    "eligible": "أنا مؤهل للحصول على مساعدة دراسية من خلال شركتي",
    "not_eligible": "أنا لست مؤهلاً للحصول على مساعدة في الرسوم الدراسية",
    "not_sure": "لست متأكدًا مما إذا كنت مؤهلاً",
    "not_applicable": "لا ينطبق"
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.field_options = window.locales.en.careers.edit_career_profile.field_options || {};window.locales.en.careers.edit_career_profile.field_options = {...window.locales.en.careers.edit_career_profile.field_options, ...{
                       
    "eligible": "I am eligible for tuition assistance through my company",
    "not_eligible": "I am not eligible for tuition assistance",
    "not_sure": "I am not sure if I am eligible",
    "not_applicable": "Not applicable"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.field_options = window.locales.es.careers.edit_career_profile.field_options || {};window.locales.es.careers.edit_career_profile.field_options = {...window.locales.es.careers.edit_career_profile.field_options, ...{
    "eligible": "Soy elegible para recibir asistencia para la matrícula a través de mi empresa.",
    "not_eligible": "No soy elegible para asistencia de matrícula",
    "not_sure": "No estoy seguro de si soy elegible",
    "not_applicable": "No aplica"
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.field_options = window.locales.it.careers.edit_career_profile.field_options || {};window.locales.it.careers.edit_career_profile.field_options = {...window.locales.it.careers.edit_career_profile.field_options, ...{
    "eligible": "Ho diritto all'assistenza scolastica tramite la mia azienda",
    "not_eligible": "Non sono idoneo per l'assistenza scolastica",
    "not_sure": "Non sono sicuro di essere idoneo",
    "not_applicable": "Non applicabile"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.field_options = window.locales.zh.careers.edit_career_profile.field_options || {};window.locales.zh.careers.edit_career_profile.field_options = {...window.locales.zh.careers.edit_career_profile.field_options, ...{
    "eligible": "我有资格通过我的公司申请助学金",           
    "not_eligible": "我没有资格申请助学金",           
    "not_sure": "我不清楚自己是否有资格",           
    "not_applicable": "不适用"           
}
};