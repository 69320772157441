window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile = {...window.locales.am.careers.edit_career_profile, ...{
    "lets_go": "እንቀጥል!",           
    "edit_profile": "መገለጫ አርትዕ",           
    "view_profile": "ግለ-መገለጫ ይመልከቱ",           
    "profile_completion_x": "<strong>የግለ-መገለጫ ማጠናቀቅ:</strong> <em>{{percent}}%</em>",           
    "completion_x": "<strong>ማጠናቀቅ:</strong> <em>{{percent}}%</em>",           
    "basic_info": "መሰረታዊ መረጃ",           
    "education": "ትምህርት",           
    "work": "የስራ ታሪክ",           
    "job_preferences": "የስራ ምርጫዎች",           
    "more_about_you": "ስለ እርስዎ ተጨማሪ",           
    "preview_profile": "የግለ-መገለጫ ይመልከቱ",           
    "submit_application": "ይገምግሙ እና ያስገቡ",           
    "submit_application_header": "ይገምግሙ እና ያስገቡ",           
    "save": "አስቀምጥ",           
    "save_and_next": "አስቀምጥ እና ቀጣይ",           
    "cancel": "ሰርዝ",           
    "unsaved_changes_confirm": "በአሁኑ ወቅት ያልተቀመጡ ለውጦች አሉዎት። እርግጠኛ ነዎት ወደ ሌላ ማሰስ ይፈልጋሉ?",           
    "inbox": "የገቢ መልዕክት ሳጥን",           
    "complete_all_fields": "* እባክዎ ሁሉንም አስፈላጊ መስኮች ይሙሉ።",           
    "the_following": "የሚከተሉት ጥያቄዎች ወደ MBA ፕሮግራም ተቀባይነት ካገኙ የተማሪዎን / የቀድሞ ምሩቅ ተማሪዎችዎን <br class=\"hidden-xs\"> ግለ-መገለጫ እንድንገነባ ይረዱናል።",           
    "the_following_emba": "የሚከተሉት ጥያቄዎች ወደ EMBA ፕሮግራም ተቀባይነት ካገኙ የተማሪዎን / የቀድሞ ምሩቅ ተማሪዎችዎን <br class=\"hidden-xs\"> ግለ-መገለጫ እንድንገነባ ይረዱናል።",           
    "resume_and_links_desc": "የመመዝገቢያ ቡድናችን ከትምህርት እና ስራ ታሪክና ይፋዊ የሊንከዲን ግለ-መገለጫ የሚያቀርቡ የተሟሉ ማመልከቻዎች ዋጋ ይሰጣል።",           
    "resume_and_links_desc_zh": "የመመዝገቢያ ቡድናችን የእርስዎን የስራ ልምድ የሚሸፍን የተሟላ ማመልከቻ ይፈልጋል።",           
    "student_network_settings": "አውታረ መረብ",           
    "student_network_settings_desc": "ተቀባይነት ያገኙ አመልካቾች በአገር ውስጥ እና በዓለም ዙሪያ ካሉ ሌሎች ተማሪዎች ጋር መገናኘት የሚችሉበት የዓለም አቀፍ የቀድሞ ተማሪዎች አውታረ መረባችን ማግኘት ይችላሉ።",           
    "use_edit_buttons": "ወደ ተዛማጅ የግለ-መገለጫዎ ክፍሎች ለማሰስ የ <i class=\"fa fa-pencil\"></i> አርትዕ አዝራሮችን ይጠቀሙ።",           
    "application_questions": "የመተግበሪያ ጥያቄዎች",           
    "application_questions_sub_header_degree_program": "ከዚህ በታች ባሉት አስፈላጊና አማራጭ ጥያቄዎች ማመልከቻዎን ለግል እንዲያመቻቹ እናበረታታዎታለን። <br class=\"hidden-xs\"> ለማብራራት ወደ የኦንላይን ቃለ መጠይቅ ሊጋበዙ ይችላሉ።",           
    "program_preferences": "የፕሮግራም ምርጫዎች",
    "resume_and_links": "ከቆመበት ቀጥል እና አገናኞች (አማራጭ)",
    "demographics": "ስነ-ሕዝብ (አማራጭ)",
    "program_preferences_desc": "ከፍተኛ አቅም ላላቸው ተማሪዎች እና ከፍተኛ ውጤት ላመጡ ባለሙያዎች የዲግሪ መርሃ ግብሮች ቤተሰብ።",
    "program_preferences_desc_valar": "ከፍተኛ አቅም ላላቸው ተማሪዎች እና ከፍተኛ ውጤት ላመጡ ባለሙያዎች የቢዝነስ ዲግሪ ፕሮግራሞች።",
    "demographics_desc": "የስነ ሕዝብ አወቃቀርን የሚመለከቱ ጥያቄዎች ከ<br class=\"hidden-xs\"/> የዩኤስ የትምህርት መምሪያ ደረጃዎች። እነሱ ሙሉ በሙሉ አማራጭ ናቸው.",
    "tuition_and_scholarships": "ትምህርት እና ስኮላርሺፕ",
    "tuition_and_scholarships_desc": "እንደዚህ ያለ <a ng-click=\"handleAffordableTuitionClick()\">ተመጣጣኝ ክፍያ</a> በሚያስችሉ ፈጠራዎች እንኮራለን።<br class=\"hidden-xs\"/> ለልዩ እጩዎቻችን ስኮላርሺፕ የበለጠ ወጪን ሊቀንስ ይችላል።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile = {...window.locales.ar.careers.edit_career_profile, ...{
    "lets_go": "هيا بنا!",           
    "edit_profile": "تعديل الملف الشخصي",           
    "view_profile": "عرض الملف الشخصي",           
    "profile_completion_x": "<strong>إكمال الملف الشخصي:</strong> <em>{{percent}}%</em>",           
    "basic_info": "المعلومات الأساسية",           
    "job_preferences": "خصائص الوظيفة",           
    "more_about_you": "المزيد عنك",           
    "save": "حفظ",           
    "save_and_next": "حفظ والتالي",           
    "unsaved_changes_confirm": "لديك حاليا تغييرات لم يتم حفظها. هل أنت متأكد من أنك تريد مغادرة هذه الصفحة؟",           
    "inbox": "صندوق الوارد",           
    "complete_all_fields": "* يرجى تعبئة جميع الحقول المطلوبة.",           
    "completion_x": "<strong>الانتهاء:</strong> <em>{{percent}} ٪</em>",
    "submit_application": "قدم الطلب",
    "submit_application_header": "قدم الطلب",
    "the_following": "الأسئلة التالية تساعدنا بناء بلدكم الطالب / الخريجين <br class=\"hidden-xs\"> يجب أن تكون مقبولة الشخصي في برنامج الماجستير في إدارة الأعمال.",
    "the_following_emba": "الأسئلة التالية تساعدنا بناء بلدكم الطالب / الخريجين <br class=\"hidden-xs\"> يجب أن تكون مقبولة الشخصي في برنامج EMBA.",
    "education": "التعليم",
    "work": "تاريخ العمل",
    "resume_and_links_desc": "لدينا فريق القبول القيم التطبيقات الكاملة التي توفر السيرة الذاتية والملف الشخصي لينكيدين العامة.",
    "resume_and_links_desc_zh": "يتطلب فريق القبول لدينا تطبيقًا كاملاً يغطي سيرتك الذاتية.",
    "cancel": "إلغاء",
    "student_network_settings": "شبكة الاتصال",
    "student_network_settings_desc": "يمكن للمتقدمين المقبولين الوصول إلى شبكة الخريجين العالمية الخاصة بنا ، حيث يمكنهم التواصل مع الطلاب الآخرين محليًا وفي جميع أنحاء العالم.",
    "preview_profile": "معاينة الملف الشخصي",
    "use_edit_buttons": "استخدم ال <i class=\"fa fa-pencil\"></i> تعديل أزرار للانتقال إلى الأجزاء ذات الصلة من ملفك الشخصي.",
    "application_questions": "أسئلة التطبيق",
    "application_questions_sub_header_degree_program": "نحن نشجعك على تخصيص طلبك من خلال الأسئلة المطلوبة والاختيارية أدناه.<br class=\"hidden-xs\"> قد تتم دعوتك إلى مقابلة عبر الإنترنت للتوضيح.",
    "program_preferences": "تفضيلات البرنامج",
    "resume_and_links": "السيرة الذاتية والروابط (اختياري)",
    "demographics": "الديموغرافيات (اختياري)",
    "program_preferences_desc": "عائلة من برامج الدرجات العلمية للمتعلمين ذوي الإمكانات العالية والمهنيين المتفوقين.",
    "program_preferences_desc_valar": "برامج درجة الأعمال للمتعلمين ذوي الإمكانات العالية والمهنيين المتفوقين.",
    "demographics_desc": "الأسئلة المتعلقة بالتركيبة السكانية تتوافق مع<br class=\"hidden-xs\"/> معايير وزارة التعليم الأمريكية. إنها اختيارية تمامًا.",
    "tuition_and_scholarships": "الرسوم الدراسية والمنح الدراسية",
    "tuition_and_scholarships_desc": "نحن فخورون بالابتكارات التي تتيح مثل هذه <a ng-click=\"handleAffordableTuitionClick()\">الرسوم الدراسية بأسعار معقولة</a> .<br class=\"hidden-xs\"/> المنح الدراسية لمرشحينا الاستثنائيين يمكن أن تقلل التكلفة بشكل أكبر."
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile = {...window.locales.en.careers.edit_career_profile, ...{
    "lets_go": "Let's Go!",
    "edit_profile": "Edit Profile",
    "view_profile": "View Profile",
    "profile_completion_x": "<strong>Profile Completion:</strong> <em>{{percent}}%</em>",
    "completion_x": "<strong>Completion:</strong> <em>{{percent}}%</em>",
    "program_preferences": "Program Preferences",
    "basic_info": "Basic Info",
    "education": "Education",
    "work": "Work History",
    "job_preferences": "Job Preferences",
    "more_about_you": "More About You",
    "resume_and_links": "Resume and Links (Optional)",
    "preview_profile": "Preview Profile",
    "submit_application": "Review and Submit",
    "submit_application_header": "Review and Submit",
    "save": "Save",
    "save_and_next": "Save and Next",
    "cancel": "Cancel",
    "unsaved_changes_confirm": "You currently have unsaved changes. Are you sure you wish to navigate away?",
    "inbox": "Inbox",
    "complete_all_fields": "* Please complete all required fields.",
    "the_following": "The following questions help us build your student / alumni <br class=\"hidden-xs\"> profile should you be accepted into the MBA program.",
    "the_following_emba": "The following questions help us build your student / alumni <br class=\"hidden-xs\"> profile should you be accepted into the EMBA program.",
    "resume_and_links_desc": "Our admissions team values complete applications that provide a resume and public LinkedIn profile.",
    "resume_and_links_desc_zh": "Our admissions team values complete applications that provide a resume.",
    "program_preferences_desc": "A family of degree programs for high potential learners and high achieving professionals.",
    "program_preferences_desc_valar": "Business degree programs for high potential learners and high achieving professionals.",
    "student_network_settings": "Network",
    "student_network_settings_desc": "Admitted applicants gain access to our global alumni network, where they can connect with other students locally and around the world.",
    "use_edit_buttons": "Use the <i class=\"fa fa-pencil\"></i> edit buttons to navigate to relevant parts of your profile.",
    "application_questions": "Application Questions",
    "application_questions_sub_header_degree_program": "We encourage you to personalize your application with the required and optional questions below. <br class=\"hidden-xs\"> You may be invited to an online interview to elaborate.",
    "demographics_desc": "Questions regarding demographics comply with the <br class=\"hidden-xs\"/>U.S. Department of Education's standards. They are completely optional.",
    "demographics": "Demographics (Optional)",
    "tuition_and_scholarships": "Tuition & Scholarships",
    "tuition_and_scholarships_desc": "We're proud of the innovations that enable such <a ng-click=\"handleAffordableTuitionClick()\">affordable tuition</a>.<br class=\"hidden-xs\"/> Scholarships for our exceptional candidates can further reduce cost."
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile = {...window.locales.es.careers.edit_career_profile, ...{
    "lets_go": "¡Vamos!",           
    "edit_profile": "Editar perfil",           
    "view_profile": "Ver perfil",           
    "profile_completion_x": "<strong>Perfil completado en un:</strong> <em>{{percent}} %</em>",           
    "basic_info": "Información básica",           
    "job_preferences": "Preferencias laborales",           
    "more_about_you": "Más acerca de usted",           
    "save": "Guardar",           
    "save_and_next": "Guardar y siguiente",           
    "unsaved_changes_confirm": "Actualmente tiene cambios no guardados. ¿Seguro que desea salir?",           
    "inbox": "Bandeja de entrada",           
    "complete_all_fields": "* Por favor, complete todos los campos obligatorios.",           
    "completion_x": "<strong>Finalización:</strong> <em>{{percent}} %</em>",
    "submit_application": "Presentar la solicitud",
    "submit_application_header": "Presentar la solicitud",
    "the_following": "Las siguientes preguntas nos ayudan a construir sus estudiantes / alumnos <br class=\"hidden-xs\"> el perfil que debe ser aceptado en el programa de MBA.",
    "the_following_emba": "Las siguientes preguntas nos ayudan a construir sus estudiantes / alumnos <br class=\"hidden-xs\"> el perfil que debe ser aceptado en el programa de EMBA.",
    "education": "Educación",
    "work": "Historial de trabajo",
    "resume_and_links_desc": "Nuestro equipo de admisiones valora las aplicaciones completas que proporcionan un currículum y un perfil público de LinkedIn.",
    "resume_and_links_desc_zh": "Nuestro equipo de admisiones requiere una solicitud completa que cubra su currículum.",
    "cancel": "Cancelar",
    "student_network_settings": "Red",
    "student_network_settings_desc": "Los solicitantes admitidos obtienen acceso a nuestra red global de alumnos, donde pueden conectarse con otros estudiantes a nivel local y en todo el mundo.",
    "preview_profile": "Vista previa del perfil",
    "use_edit_buttons": "Utilizar el <i class=\"fa fa-pencil\"></i> botones de edición para navegar a las partes relevantes de su perfil.",
    "application_questions": "Preguntas de aplicación",
    "application_questions_sub_header_degree_program": "Le recomendamos que personalice su solicitud con las preguntas obligatorias y opcionales a continuación.<br class=\"hidden-xs\"> Es posible que lo inviten a una entrevista en línea para dar más detalles.",
    "program_preferences": "Preferencias del programa",
    "resume_and_links": "Currículo y enlaces (Opcional)",
    "demographics": "Datos demográficos (Opcional)",
    "program_preferences_desc": "Una familia de programas de grado para estudiantes de alto potencial y profesionales de alto rendimiento.",
    "program_preferences_desc_valar": "Programas de grado en negocios para estudiantes de alto potencial y profesionales de alto rendimiento.",
    "demographics_desc": "Las preguntas sobre datos demográficos cumplen con las<br class=\"hidden-xs\"/> Estándares del Departamento de Educación de los Estados Unidos. Son completamente opcionales.",
    "tuition_and_scholarships": "Matrícula y becas",
    "tuition_and_scholarships_desc": "Estamos orgullosos de las innovaciones que permiten <a ng-click=\"handleAffordableTuitionClick()\">una matrícula tan asequible</a> .<br class=\"hidden-xs\"/> Las becas para nuestros candidatos excepcionales pueden reducir aún más los costos."
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile = {...window.locales.it.careers.edit_career_profile, ...{
    "lets_go": "Cominciamo!",           
    "edit_profile": "Modifica Profilo",           
    "view_profile": "Visualizza Profilo",           
    "profile_completion_x": "<strong>Completamento Profilo:</strong> <em>{{percent}}%</em>",           
    "completion_x": "<strong>Completamento:</strong> <em>{{percent}}%</em>",           
    "basic_info": "Informazioni Fondamentali",           
    "job_preferences": "Preferenze Lavorative",           
    "more_about_you": "Altro su di te",           
    "submit_application": "Inoltra Candidatura",           
    "submit_application_header": "Inoltra Candidatura",           
    "save": "Salva",           
    "save_and_next": "Salva e Avanti",           
    "unsaved_changes_confirm": "Attualmente ci sono modifiche non salvate. Sei sicuro di voler uscire?",           
    "inbox": "Posta in arrivo",           
    "complete_all_fields": "* Per favore, completa tutti i campi obbligatori.",           
    "the_following": "Le seguenti domande ci aiutano a costruire il tuo profilo studente / laureato <br class=\"hidden-xs\"> qualora tu venga accettato nel programma MBA.",           
    "the_following_emba": "Le seguenti domande ci aiutano a costruire il tuo profilo studente / laureato <br class=\"hidden-xs\"> qualora tu venga accettato nel programma EMBA.",           
    "education": "Formazione scolastica",
    "work": "Storia del lavoro",
    "resume_and_links_desc": "Il nostro team di ammissione valuta le applicazioni complete che forniscono un curriculum e un profilo LinkedIn pubblico.",
    "resume_and_links_desc_zh": "Il nostro team di ammissione richiede una domanda completa che copra il tuo curriculum.",
    "cancel": "Annulla",
    "student_network_settings": "Rete",
    "student_network_settings_desc": "I candidati ammessi hanno accesso alla nostra rete globale di alunni, dove possono connettersi con altri studenti a livello locale e in tutto il mondo.",
    "preview_profile": "Profilo di anteprima",
    "use_edit_buttons": "Utilizzare il <i class=\"fa fa-pencil\"></i> modifica i pulsanti per navigare verso le parti rilevanti del tuo profilo.",
    "application_questions": "Domande sull'applicazione",
    "application_questions_sub_header_degree_program": "Ti invitiamo a personalizzare la tua applicazione con le domande obbligatorie e facoltative di seguito.<br class=\"hidden-xs\"> Potresti essere invitato a un colloquio online per approfondire.",
    "program_preferences": "Preferenze del programma",
    "resume_and_links": "Resume e Link (Facoltativo)",
    "demographics": "Dati demografici (Facoltativo)",
    "program_preferences_desc": "Una famiglia di corsi di laurea per studenti ad alto potenziale e professionisti di alto livello.",
    "program_preferences_desc_valar": "Corsi di laurea in economia aziendale per studenti ad alto potenziale e professionisti di alto livello.",
    "demographics_desc": "Le domande riguardanti i dati demografici sono conformi al<br class=\"hidden-xs\"/> Standard del Dipartimento dell'Istruzione degli Stati Uniti. Sono completamente opzionali.",
    "tuition_and_scholarships": "Lezioni e borse di studio",
    "tuition_and_scholarships_desc": "Siamo orgogliosi delle innovazioni che consentono <a ng-click=\"handleAffordableTuitionClick()\">lezioni così convenienti</a> .<br class=\"hidden-xs\"/> Le borse di studio per i nostri candidati eccezionali possono ridurre ulteriormente i costi."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile = {...window.locales.zh.careers.edit_career_profile, ...{
    "lets_go": "开始吧！",           
    "edit_profile": "编辑个人主页",           
    "view_profile": "查看个人主页",           
    "profile_completion_x": "<strong>完成主页：</strong> <em>{{percent}}%</em>",           
    "basic_info": "基本信息",           
    "job_preferences": "求职意向",           
    "more_about_you": "更多关于您的信息",           
    "save": "保存",           
    "save_and_next": "保存并前往下一页",           
    "unsaved_changes_confirm": "您目前有未保存的更改。您确定要离开吗？",           
    "inbox": "收件箱",           
    "complete_all_fields": "* 请填写所有必填字段。",           
    "completion_x": "<strong>完成：</strong><em>{{percent}}%</em>",           
    "submit_application": "审查并提交",           
    "submit_application_header": "请核对，下滑并按提交申请",           
    "the_following": "如果你被工商管理硕士（MBA）课程录取，以下问题将帮助我们建立你的学员/校友<br class=\"hidden-xs\">档案。",           
    "the_following_emba": "如果你被高级工商管理硕士（EMBA）课程录取，以下问题将帮助我们建立你的学员/校友<br class=\"hidden-xs\">档案。",           
    "education": "教育背景",           
    "work": "工作经历",           
    "resume_and_links_desc": "我们的招生团队需要涵盖简历和公开的LinkedIn个人资料的完整申请。",           
    "resume_and_links_desc_zh": "我们的招生团队需要涵盖简历的完整申请。",           
    "cancel": "取消",           
    "student_network_settings": "网络",           
    "student_network_settings_desc": "被录取的申请人可以进入我们的全球校友网络，进而与当地和世界各地的其他学员进行联系。",           
    "preview_profile": "预览简介",           
    "use_edit_buttons": "点击<i class=\"fa fa-pencil\"></i>编辑按钮对你个人资料的相关部分进行编辑。",           
    "application_questions": "申请问题",           
    "application_questions_sub_header_degree_program": "我们建议你填写以下必填和选填问题以便让你的申请呈现个性化特色。<br class=\"hidden-xs\">我们可能会邀请你进行在线面试来详细说明。",           
    "program_preferences": "程序偏好",
    "resume_and_links": "简历和链接（可选）",
    "demographics": "人口统计（可选）",
    "program_preferences_desc": "一系列面向高潜力学习者和高成就专业人士的学位课程。",
    "program_preferences_desc_valar": "面向高潜力学习者和高成就专业人士的商业学位课程。",
    "demographics_desc": "有关人口统计的问题符合<br class=\"hidden-xs\"/>美国教育部的标准。它们是完全可选的。",
    "tuition_and_scholarships": "学费和奖学金",
    "tuition_and_scholarships_desc": "我们为能够实现如此<a ng-click=\"handleAffordableTuitionClick()\">实惠的学费</a>的创新感到自豪。<br class=\"hidden-xs\"/>为杰出候选人提供的奖学金可以进一步降低成本。"
}
};