window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.answers_too_short_modal = window.locales.am.careers.edit_career_profile.answers_too_short_modal || {};window.locales.am.careers.edit_career_profile.answers_too_short_modal = {...window.locales.am.careers.edit_career_profile.answers_too_short_modal, ...{
    "title": "እባክዎ ልብ ይበሉ…",           
    "description": "ስለ እርስዎ ጥሩ ምስል ለማግኘት የምላሾችዎ ለእኛ ትንሽ በጣም አጭር ናቸው! ስኬታማ እጩዎች በተለምዶ በዚህ ክፍል ውስጥ አሳቢ፣ የተብራሩ መልሶች ይሰጣሉ። በመጪው ክፍል ማመልከቻዎን ለማስገባት፣ እባክዎ አንዳንድ ተጨማሪ ሀሳቦችን፣ ግንዛቤዎችን ወይም አስተያየቶችን ያክሉ።",           
    "close": "ዝጋ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.answers_too_short_modal = window.locales.ar.careers.edit_career_profile.answers_too_short_modal || {};window.locales.ar.careers.edit_career_profile.answers_too_short_modal = {...window.locales.ar.careers.edit_career_profile.answers_too_short_modal, ...{
    "title": "يرجى الملاحظة…",
    "close": "قريب",
    "description": "ردودك موجزة قليلاً بالنسبة لنا للحصول على صورة جيدة لك! يقدم المرشحون الناجحون عادةً إجابات مدروسة وشاملة في هذا القسم. يرجى إضافة بعض الأفكار أو الأفكار أو الأفكار الإضافية لتقديم طلبك للفصل القادم."
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.answers_too_short_modal = window.locales.en.careers.edit_career_profile.answers_too_short_modal || {};window.locales.en.careers.edit_career_profile.answers_too_short_modal = {...window.locales.en.careers.edit_career_profile.answers_too_short_modal, ...{
    "title": "Please note…",
    "description": "Your responses are a little too brief for us to get a good picture of you! Successful candidates typically provide thoughtful, thorough answers in this section. Please add some additional thoughts, insights or reflections to submit your application for the upcoming class.",
    "close": "Close"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.answers_too_short_modal = window.locales.es.careers.edit_career_profile.answers_too_short_modal || {};window.locales.es.careers.edit_career_profile.answers_too_short_modal = {...window.locales.es.careers.edit_career_profile.answers_too_short_modal, ...{
    "title": "Tenga en cuenta…",
    "close": "Cerrar",
    "description": "¡Tus respuestas son demasiado breves para que podamos tener una buena imagen de ti! Los candidatos seleccionados suelen proporcionar respuestas meditadas y completas en esta sección. Agregue algunos pensamientos, ideas o reflexiones adicionales para enviar su solicitud para la próxima clase."
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.answers_too_short_modal = window.locales.it.careers.edit_career_profile.answers_too_short_modal || {};window.locales.it.careers.edit_career_profile.answers_too_short_modal = {...window.locales.it.careers.edit_career_profile.answers_too_short_modal, ...{
    "title": "Notare che…",
    "close": "Vicino",
    "description": "Le tue risposte sono un po 'troppo brevi per farci una buona foto! I candidati idonei in genere forniscono risposte ponderate e complete in questa sezione. Aggiungi altri pensieri, approfondimenti o riflessioni per inviare la tua domanda per la prossima lezione."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.answers_too_short_modal = window.locales.zh.careers.edit_career_profile.answers_too_short_modal || {};window.locales.zh.careers.edit_career_profile.answers_too_short_modal = {...window.locales.zh.careers.edit_career_profile.answers_too_short_modal, ...{
    "title": "请注意……",           
    "close": "关闭",           
    "description": "您的回答过于简短，请具体描述您的情况，这样我们可以更全面的了解您！成功的申请者通常会在这部分给出深思熟虑并透彻的答案。同时，也请告诉我们一些您额外的想法、见解或思考，为即将到来的课程提交申请。"           
}
};