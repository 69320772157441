window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.job_preferences_form = window.locales.am.careers.edit_career_profile.job_preferences_form || {};window.locales.am.careers.edit_career_profile.job_preferences_form = {...window.locales.am.careers.edit_career_profile.job_preferences_form, ...{
    "authorization_status": "የእርስዎን የዜግነት/ስደት ሁኔታ ይምረጡ",           
    "contract": "ውል",           
    "desired_company_size": "ከየትኞቹ ኩባንያዎች ጋር ቃለ መጠይቅ ለማድረግ ይፈልጋሉ?",           
    "desired_company_size_would": "በየትኛው መጠን ያላቸው ኩባንያዎች ውስጥ መስራትን ይመርጣሉ?",           
    "desired_company_size_desc": "በሙሉ ጊዜ ሰራተኞች ብዛት ላይ በመመስረት፣ ቢያንስ አንድ መጠን ምርጫን ይምረጡ።",           
    "desired_employment_type": "ምን ዓይነት የስራ ቅጥር ይፈልጋሉ?",           
    "desired_employment_type_would": "ወደ የሙያ አውታረመረብ ከገቡ፣ ምን ዓይነት የስራ ቅጥር ይፈልጋሉ?",           
    "desired_location": "የት ነው ለመስራት የሚፈልጉት?",           
    "where_want": "ወደ የሙያ አውታረመረብ ከገቡ፣ የት መስራት ይፈልጋሉ?",           
    "desired_start_date": "አዲሱን ስራዎን መቼ ይጀምራሉ?",           
    "interested_in_joining": "በሚቀጥሉት 6 ወራት ውስጥ አዲስ ኩባንያ ለመቀላቀል ምን ያህል ፈቃደኛ ነዎት እና ምን ያህል ፍላጎት አለዎት?",           
    "internship": "ኢንተርንሺፕ (ልምምድ)",           
    "open_to_remote_work": "ለርቀት ስራ ዝግጁ ነኝ",           
    "would_be_open_to_remote_work": "ለርቀት ስራ ዝግጁ እሆናለሁ",           
    "part_time": "የትርፍ ጊዜ",           
    "permanent": "ቋሚ",           
    "status_citizen": "የአሜሪካ ዜጋ ወይም ቋሚ ነዋሪ",           
    "where_do_you_want_to_work": "የትኞቹን ተጨማሪ ከተሞች ለመስራት ዝግጁ ይሆናሉ?",           
    "willing_to_relocate": "ወደ ሌላ ቦታ ለመዘዋወር ፈቃደኛ ነኝ",           
    "would_be_willing_to_relocate": "ወደ ሌላ ቦታ ለመዘዋወር ፈቃደኛ እሆናለሁ።",           
    "show_all_locations": "ሁሉንም ቦታዎች አሳይ",           
    "not_interested": "ምንም ፍላጎት የለኝም",           
    "neutral": "ገለልተኛ",           
    "interested": "ፍላጎት አለኝ",           
    "very_interested": "በጣም ፍላጎት አለኝ",           
    "citizen": "የአሜሪካ ዜጋ ወይም ቋሚ ነዋሪ",           
    "h1visa": "በአሁኑ ጊዜ የH-1B ቪዛ አለኝ",           
    "f1visa": "በአሁኑ ጊዜ የF-1/OPT አለኝ",           
    "ead": "በአሁኑ ጊዜ EAD አለኝ",           
    "tnvisa": "በአሁኑ ጊዜ የTN ቪዛ አለኝ",           
    "j1visa": "በአሁኑ ጊዜ የJ-1 ቪዛ አለኝ",           
    "o1visa": "በአሁኑ ጊዜ የO-1 ቪዛ አለኝ",           
    "l1visa": "በአሁኑ ጊዜ የL-1 ቪዛ አለኝ",           
    "tneligible": "ለTN ቪዛ ብቁ",           
    "e1eligible": "በአሁኑ ጊዜ የE-1 ቪዛ አለኝ",           
    "e2eligible": "በአሁኑ ጊዜ የE-2 ቪዛ አለኝ",           
    "e3eligible": "በአሁኑ ጊዜ የE-3 ቪዛ አለኝ",           
    "h1b1eligible": "ለ H1B1 ብቁ",           
    "novisa": "የአሜሪካ ዜጋ ወይም ቋሚ ነዋሪ አይደለሁም"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.job_preferences_form = window.locales.ar.careers.edit_career_profile.job_preferences_form || {};window.locales.ar.careers.edit_career_profile.job_preferences_form = {...window.locales.ar.careers.edit_career_profile.job_preferences_form, ...{
    "after_a_month": "بعد شهر من الآن",           
    "contract": "عقد",           
    "currently_interviewing": "يتم إجراء مقابلة حالياً",           
    "desired_company_size": "ما هو حجم الشركات التي تفضل إجراء مقابلة فيها؟",           
    "desired_company_size_desc": "اختر خيار حجم واحد على الأقل، وفقاً لعدد الموظفين الذين يعملون بدوامٍ كامل.",           
    "desired_employment_type": "ما هو نوع العمل الذي تفضله؟",           
    "desired_location": "ما هو المكان الذي تبحث عن العمل فيه؟",           
    "desired_start_date": "متى يكون الوقت المثالي الذي سوف تبدأ فيه عملك الجديد؟",           
    "internship": "فترة التدريب",           
    "not_looking": "إنك لا تبحث بنشاط عن دور جديد",           
    "open_to_remote_work": "انا مستعد للعمل عن بُعد",           
    "part_time": "دوام جزئى",           
    "permanent": "دائم",           
    "ready_to_start": "ألم يتم إجراء المقابلة بعد، لكنك على استعدادٍ للبدء فيها",           
    "soon_as_possible": "في أقرب وقتٍ ممكن",           
    "stage_of_search": "ما هي المرحلة الموجود فيها في عملية البحث عن وظيفة؟",           
    "status_citizen": "مواطن أمريكي أو مقيم دائم",           
    "willing_to_relocate": "أنا على استعدادٍ للانتقال",           
    "within_the_month": "خلال 4 أسابيع",           
    "citizen": "مواطن أمريكي أو مقيم دائم",           
    "h1visa": "لديه حالياً تأشيرة H-1B",           
    "f1visa": "لديه حالياً تأشيرة F-1/OPT",           
    "ead": "لديه حالياً EAD",           
    "tnvisa": "لديه حالياً تأشيرة TN",           
    "j1visa": "لديه حالياً تأشيرة J-1",           
    "o1visa": "لديه حالياً  O-1",           
    "tneligible": "مؤهل للحصول على تأشيرة TN",           
    "e3eligible": "لديه حالياً تأشيرة E-3",           
    "h1b1eligible": "مؤهل للحصول على تأشيرة H1B1",           
    "would_be_open_to_remote_work": "وأود أن تكون مفتوحة للعمل عن بعد",
    "would_be_willing_to_relocate": "سأكون على استعداد للانتقال",
    "novisa": "ليس مواطنا أمريكيا أو مقيم دائم",
    "authorization_status": "تحديد المواطنة / الهجرة الخاص بك",
    "desired_company_size_would": "ما هي الشركات حجم تفضل العمل في؟",
    "desired_employment_type_would": "إذا قمت بإدخال الشبكة الوظيفية، ما نوع العمل هل تكون مهتمة في؟",
    "where_want": "إذا قمت بإدخال الشبكة المهنية، حيث تريد أن تعمل؟",
    "interested": "يستفد",
    "interested_in_joining": "مدى استعداد والمهتمين هل سيكون للانضمام الى شركة جديدة في ال 6 أشهر القادمة؟",
    "not_interested": "ليس في جميع المهتمين",
    "neutral": "محايد",
    "very_interested": "مهتم جدا",
    "where_do_you_want_to_work": "ما هي المدن الإضافية التي ستكون مفتوحة للعمل فيها؟",
    "show_all_locations": "عرض جميع المواقع",
    "l1visa": "لديك حاليا تأشيرة L-1",
    "e1eligible": "لديك حاليًا تأشيرة E-1",
    "e2eligible": "لديك حاليًا تأشيرة E-2"
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.job_preferences_form = window.locales.en.careers.edit_career_profile.job_preferences_form || {};window.locales.en.careers.edit_career_profile.job_preferences_form = {...window.locales.en.careers.edit_career_profile.job_preferences_form, ...{
    "authorization_status": "Select your citizenship/immigration status",
    "contract": "Contract",
    "desired_company_size": "What size companies are you interested in interviewing with?",
    "desired_company_size_would": "What size companies do you prefer working in?",
    "desired_company_size_desc": "Choose at least one size option, based on the number of full time employees.",
    "desired_employment_type": "What type of employment are you interested in?",
    "desired_employment_type_would": "If you enter the career network, what type of employment would you be interested in?",
    "desired_location": "Where are you looking to work?",
    "where_want": "If you enter the career network, where would you want to work?",
    "desired_start_date": "When would you ideally start your new job?",
    "interested_in_joining": "How willing and interested would you be to join a new company in the next 6 months?",
    "internship": "Internship",
    "open_to_remote_work": "I am open to remote work",
    "would_be_open_to_remote_work": "I would be open to remote work",
    "part_time": "Part-Time",
    "permanent": "Permanent",
    "status_citizen": "US Citizen or Permanent Resident",
    "where_do_you_want_to_work": "What additional cities would you be open to working in?",
    "willing_to_relocate": "I am willing to relocate",
    "would_be_willing_to_relocate": "I would be willing to relocate",
    "show_all_locations": "Show All Locations",

                                        
    "not_interested": "Not at all interested",
    "neutral": "Neutral",
    "interested": "Interested",
    "very_interested": "Very Interested",

                               
    "citizen": "US Citizen or Permanent Resident",
    "h1visa": "Currently have H-1B visa",
    "f1visa": "Currently have F-1/OPT visa",
    "ead": "Currently have EAD",
    "tnvisa": "Currently have TN visa",
    "j1visa": "Currently have J-1 visa",
    "o1visa": "Currently have O-1 visa",
    "l1visa": "Currently have L-1 visa",
    "tneligible": "Eligible for TN visa",
    "e1eligible": "Currently have E-1 visa",
    "e2eligible": "Currently have E-2 visa",
    "e3eligible": "Currently have E-3 visa",
    "h1b1eligible": "Eligible for H1B1",
    "novisa": "Not a US citizen or permanent resident"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.job_preferences_form = window.locales.es.careers.edit_career_profile.job_preferences_form || {};window.locales.es.careers.edit_career_profile.job_preferences_form = {...window.locales.es.careers.edit_career_profile.job_preferences_form, ...{
    "after_a_month": "Dentro de más de un mes",           
    "contract": "Contrato",           
    "currently_interviewing": "Actualmente entrevistando",           
    "desired_company_size": "¿Con empresas de qué tamaño está dispuesto a entrevistarse?",           
    "desired_company_size_desc": "Elija al menos una opción de tamaño, según el número de empleados a tiempo completo.",           
    "desired_employment_type": "¿En qué tipo de empleo está interesado?",           
    "desired_location": "¿Dónde le gustaría trabajar?",           
    "desired_start_date": "¿Cuándo comenzaría su nuevo trabajo de forma ideal?",           
    "internship": "Prácticas",           
    "not_looking": "No busco activamente una nueva labor",           
    "open_to_remote_work": "Estoy abierto al trabajo a distancia",           
    "part_time": "Tiempo parcial",           
    "permanent": "Permanente",           
    "ready_to_start": "No he realizado una entrevista todavía, pero estoy listo para comenzar",           
    "soon_as_possible": "Tan pronto como sea posible",           
    "stage_of_search": "¿En qué etapa está en su búsqueda de empleo?",           
    "status_citizen": "Ciudadano estadounidense o residente permanente",           
    "willing_to_relocate": "Estoy dispuesto a trasladarme",           
    "within_the_month": "En las próximas cuatro semanas",           
    "citizen": "Ciudadano estadounidense o residente permanente",           
    "h1visa": "Actualmente tengo visado H-1B",           
    "f1visa": "Actualmente tengo visado F-1/OPT",           
    "ead": "Actualmente tengo EAD",           
    "tnvisa": "Actualmente tengo visado TN",           
    "j1visa": "Actualmente tengo visado J-1",           
    "o1visa": "Actualmente tengo visado O-1",           
    "tneligible": "Actualmente tengo visado TN",           
    "e3eligible": "Actualmente tengo visado E-3",           
    "h1b1eligible": "Con derecho a H1B1",           
    "would_be_open_to_remote_work": "Yo estaría abierto al trabajo a distancia",
    "would_be_willing_to_relocate": "Yo estaría dispuesto a trasladar",
    "novisa": "No es ciudadano estadounidense o residente permanente",
    "authorization_status": "Indique su condición / inmigración",
    "desired_company_size_would": "Lo que las empresas tamaño usted prefiere trabajar en?",
    "desired_employment_type_would": "Si introduce la red de carrera, qué tipo de trabajo le gustaría ser interesado?",
    "where_want": "Si entras en la red de carrera, ¿dónde quieres trabajar?",
    "interested": "Interesado",
    "interested_in_joining": "¿Qué tan dispuesto e interesado estaría usted a unirse a una nueva empresa en los próximos 6 meses?",
    "not_interested": "No es en absoluto interesado",
    "neutral": "Neutral",
    "very_interested": "Muy interesado",
    "where_do_you_want_to_work": "¿En qué ciudades adicionales estaría usted dispuesto a trabajar?",
    "show_all_locations": "Mostrar todas las ubicaciones",
    "l1visa": "Actualmente tenemos visa L-1",
    "e1eligible": "Actualmente tiene visa E-1",
    "e2eligible": "Actualmente tiene visa E-2"
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.job_preferences_form = window.locales.it.careers.edit_career_profile.job_preferences_form || {};window.locales.it.careers.edit_career_profile.job_preferences_form = {...window.locales.it.careers.edit_career_profile.job_preferences_form, ...{
    "authorization_status": "Seleziona il tuo status di cittadinanza/immigrazione",           
    "contract": "Contratto",           
    "desired_company_size": "Che dimensioni devono avere le aziende con le quali ti interessa avere un colloquio?",           
    "desired_company_size_would": "Che dimensioni hanno le aziende nelle quali preferisci lavorare?",           
    "desired_company_size_desc": "Scegli almeno una opzione per le dimensioni, in base al numero di impiegati a tempo pieno.",           
    "desired_employment_type": "Che tipo di impiego ti interessa?",           
    "desired_employment_type_would": "Qualora dovessi entrare nella rete professionale, che tipo di impiego ti interesserebbe?",           
    "desired_location": "Dove vorresti lavorare?",           
    "where_want": "Qualora dovessi entrare nella rete professionale, dove preferiresti lavorare?",           
    "desired_start_date": "Idealmente, quando vorresti cominciare il tuo nuovo lavoro?",           
    "interested_in_joining": "Quanto saresti disposto e interessato a far parte di una nuova azienda nei prossimi 6 mesi?",           
    "internship": "Tirocinio",           
    "open_to_remote_work": "Sono aperto al lavoro a distanza",           
    "would_be_open_to_remote_work": "Sarei aperto al lavoro a distanza",           
    "part_time": "A tempo parziale",           
    "permanent": "Indeterminato",           
    "status_citizen": "Cittadino US o Residente Permanente",           
    "where_do_you_want_to_work": "In quali altre città saresti disposto a lavorare?",           
    "willing_to_relocate": "Sono disposto a traslocare",           
    "would_be_willing_to_relocate": "Sarei disposto a traslocare",           
    "show_all_locations": "Mostra tutte le Località",           
    "not_interested": "Per nulla interessato",           
    "neutral": "Neutrale",           
    "interested": "Interessato",           
    "very_interested": "Molto interessato",           
    "citizen": "Cittadino US o Residente Permanente",           
    "h1visa": "Attualmente in possesso di visto H-1B",           
    "f1visa": "Attualmente in possesso di visto F-1/OPT",           
    "ead": "Attualmente in possesso di EAD",           
    "tnvisa": "Attualmente in possesso di visto TN",           
    "j1visa": "Attualmente in possesso di visto J-1",           
    "o1visa": "Attualmente in possesso di visto O-1",           
    "tneligible": "Idoneo per visto TN",           
    "e3eligible": "Attualmente in possesso di visto E-3",           
    "h1b1eligible": "Idoneo per H1B1",           
    "novisa": "Né Cittadino US, né Residente Permanente",           
    "total_salary": "Per favore, indica la tua retribuzione totale (senza includere bonus o provvigioni) relativa all'anno precedente",           
    "l1visa": "Attualmente hanno un visto L-1",
    "e1eligible": "Attualmente ho un visto E-1",
    "e2eligible": "Attualmente ho un visto E-2"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.job_preferences_form = window.locales.zh.careers.edit_career_profile.job_preferences_form || {};window.locales.zh.careers.edit_career_profile.job_preferences_form = {...window.locales.zh.careers.edit_career_profile.job_preferences_form, ...{
    "after_a_month": "从现在起的一个月以后",           
    "contract": "合同",           
    "currently_interviewing": "目前正在面试",           
    "desired_company_size": "您有兴趣和什么规模的企业面试？",           
    "desired_company_size_desc": "选择至少一个大小选项，基于全职员工的数量。",           
    "desired_employment_type": "您对哪个类型的就业有兴趣？",           
    "desired_location": "您想在哪里工作？",           
    "desired_start_date": "您想什么时候开始您的新工作？",           
    "internship": "实习",           
    "not_looking": "目前不在主动寻找新角色",           
    "open_to_remote_work": "我愿意远程工作",           
    "part_time": "兼职",           
    "permanent": "永久",           
    "ready_to_start": "尚未面试，但是准备好了",           
    "soon_as_possible": "尽快",           
    "stage_of_search": "您目前位于哪个求职阶段？",           
    "status_citizen": "美国公民或永久居民",           
    "willing_to_relocate": "我愿意搬迁",           
    "within_the_month": "4周内",           
    "citizen": "美国公民或永久居民",           
    "h1visa": "目前有H-1B签证",           
    "f1visa": "目前拥有F-1 / OPT签证",           
    "ead": "目前有EAD",           
    "tnvisa": "目前有TN签证",           
    "j1visa": "目前有J-1签证",           
    "o1visa": "目前有O-1签证",           
    "tneligible": "有资格获得TN签证",           
    "e3eligible": "目前有E-3签证",           
    "h1b1eligible": "有资格获得H1B1",           
    "would_be_open_to_remote_work": "我愿意接受远程工作",           
    "would_be_willing_to_relocate": "我接受调动",           
    "novisa": "不是美国公民或永久居民",
    "authorization_status": "选择你的公民/移民身份",           
    "desired_company_size_would": "你喜欢在多大的公司工作？",           
    "desired_employment_type_would": "如果你进入职业网，你对什么类型的工作感兴趣？",           
    "where_want": "如果你进入职业网，你想在哪里工作？",           
    "interested": "感兴趣",           
    "interested_in_joining": "在未来的6个月里，你有意愿和兴趣加入一家新公司的程度为？",           
    "not_interested": "毫无兴趣",           
    "neutral": "一般",           
    "very_interested": "非常感兴趣",           
    "where_do_you_want_to_work": "你还愿意在哪些城市工作？",           
    "show_all_locations": "显示所有地点",           
    "l1visa": "目前持有L-1签证",           
    "e1eligible": "目前持有E-1签证",           
    "e2eligible": "目前持有E-2签证"           
}
};