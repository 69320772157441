window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.education_form = window.locales.am.careers.edit_career_profile.education_form || {};window.locales.am.careers.edit_career_profile.education_form = {...window.locales.am.careers.edit_career_profile.education_form, ...{
    "education": "ትምህርት",           
    "formal_degree_programs": "መደበኛ የዲግሪ ፕሮግራሞች",           
    "non_degree_programs": "የዲግሪ ያልሆኑ ፕሮግራሞች (አማራጭ)",           
    "yes": "አዎ",           
    "no": "አይ",           
    "english_language_proficiency": "የእንግሊዝኛ ቋንቋ ችሎታ",           
    "native_english_speaker": "ተወላጅ እንግሊዝኛ ተናጋሪ ነኝ።",           
    "earned_accredited_degree_in_english": "እንግሊዝኛ በማስተማር እውቅና ካለው ዩኒቨርሲቲ ዲግሪን አግኝቻለሁ።",           
    "education_summary": "የትምህርት ማጠቃለያ",           
    "my_highest_level_education": "እኔ ያጠናቀቅኩት ከፍተኛ የትምህርት ደረጃ በተሻለ ሁኔታ የሚገለፀው…",           
    "highest_level_education": "ከፍተኛ የትምህርት ደረጃ",           
    "has_no_formal_education": "በመደበኛ ዲግሪ ፕሮግራም ተመዝግቤ አላውቅም።",           
    "earned_accredited_degree_in_english_degree_sub_text": "ተቀባይነት ካገኙ በኋላ፣ ከተፈቀደ ግምገማ ተቀባይነት ያለው የእንግሊዝኛ ቋንቋ ብቃት ውጤቶችን እንዲያቀርቡ ይጠየቃሉ።",
    "sufficient_english_work_experience": "በብዛት እንግሊዝኛ ተናጋሪ በሆነ የስራ ቦታ ለአምስት ወይም ከዚያ በላይ ሠርተዋል?",
    "sufficient_english_work_experience_degree_sub_text": "ተቀባይነት ካገኘ በኋላ፣ ከ TOEFL ወይም በተመሳሳይ የታወቀ ፈተና የእንግሊዝኛ ቋንቋ የብቃት ውጤቶች እንዲያቀርቡ እንፈልግዎታለን።",
    "english_work_experience_description": "ምን ኩባንያ ወይም ኩባንያዎች?",
    "legal_names": "ህጋዊ ስም(ዎች)",
    "legal_names_question": "ተቀባይነት ካገኘ ዲግሪዎን የሚያረጋግጡ ግልባጮች እንዲያቀርቡ ይጠየቃሉ። እባክዎ ባለፉት ግልባጮችዎ ላይ የሚታዩትን ተጨማሪ ህጋዊ ስሞች ይዘርዝሩ፡-",
    "legal_names_subtext_less_than_five": "{{maxNames}} ገድብ",
    "legal_names_subtext_greater_than_five": "ከ {{maxNames}} ስሞች አልፈዋል። እባኮትን ከከፍተኛ የትምህርት ደረጃዎ ይፋዊ ግልባጭ ላይ ላሉት ስም ዝርዝር ቅድሚያ ይስጡ።",
    "legal_names_subtext_five": "ከፍተኛው የ {{maxNames}} ገደብ ላይ ደርሷል።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.education_form = window.locales.ar.careers.edit_career_profile.education_form || {};window.locales.ar.careers.edit_career_profile.education_form = {...window.locales.ar.careers.edit_career_profile.education_form, ...{
    "education": "التعليم",
    "formal_degree_programs": "برامج درجة الرسمية",
    "yes": "نعم فعلا",
    "no": "لا",
    "english_language_proficiency": "مهارة اللغة الإنجليزية",
    "native_english_speaker": "أنا متحدث باللغة الإنجليزية.",
    "earned_accredited_degree_in_english": "لقد حصلت على شهادة من جامعة معترف بها تدرس باللغة الإنجليزية.",
    "education_summary": "ملخص تعليمي",
    "my_highest_level_education": "أفضل مستوى من التعليم المتكامل هو أفضل وصف بأنه ...",
    "highest_level_education": "أعلى مستوى التعليم",
    "has_no_formal_education": "لم أسجل أبداً في برنامج شهادة رسمية.",
    "non_degree_programs": "برامج غير درجة (اختياري)",
    "earned_accredited_degree_in_english_degree_sub_text": "عند القبول ، سيُطلب منك تقديم نتائج مقبولة في إتقان اللغة الإنجليزية من تقييم معتمد.",
    "sufficient_english_work_experience": "هل عملت في مكان تغلب عليه اللغة الإنجليزية لمدة خمس سنوات أو أكثر؟",
    "sufficient_english_work_experience_degree_sub_text": "عند القبول ، سنطلب منك تقديم درجات إجادة اللغة الإنجليزية من اختبار TOEFL أو اختبار معترف به مشابه.",
    "english_work_experience_description": "ما الشركة أو الشركات؟",
    "legal_names": "الاسم (الأسماء) القانوني",
    "legal_names_question": "إذا تم قبولك ، سيُطلب منك تقديم نصوص تؤكد درجاتك. الرجاء سرد أي أسماء قانونية إضافية تظهر في نسخك السابقة:",
    "legal_names_subtext_less_than_five": "الحد {{maxNames}}",
    "legal_names_subtext_greater_than_five": "لقد تجاوزت {{maxNames}} الأسماء. يرجى إعطاء الأولوية لإدراج الأسماء الموجودة في النصوص الرسمية الخاصة بك من أعلى مستوى تعليمي لديك.",
    "legal_names_subtext_five": "تم الوصول إلى الحد الأقصى {{maxNames}} ."
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.education_form = window.locales.en.careers.edit_career_profile.education_form || {};window.locales.en.careers.edit_career_profile.education_form = {...window.locales.en.careers.edit_career_profile.education_form, ...{
    "education": "Education",
    "formal_degree_programs": "Formal Degree Programs",
    "non_degree_programs": "Non-Degree Programs (Optional)",
    "yes": "Yes",
    "no": "No",
    "english_language_proficiency": "English Language Proficiency",
    "native_english_speaker": "I am a native English speaker.",
    "earned_accredited_degree_in_english": "I have earned a degree from an accredited university taught in English.",
    "education_summary": "Education Summary",
    "my_highest_level_education": "My highest level of COMPLETED education is best described as…",
    "highest_level_education": "Highest level education",
    "earned_accredited_degree_in_english_degree_sub_text": "Upon acceptance, you will be required to provide acceptable English language proficiency results from an approved assessment.",
    "has_no_formal_education": "I have never enrolled in a formal degree program.",
    "sufficient_english_work_experience": "Have you worked in a predominantly English-speaking workplace for five or more years?",
    "sufficient_english_work_experience_degree_sub_text": "Upon acceptance, we will require you to provide English language proficiency scores from the TOEFL or similarly recognized test.",
    "english_work_experience_description": "What company or companies?",
    "legal_names": "Legal Name(s)",
    "legal_names_question": "If admitted, you will be asked to provide transcripts confirming your degrees. Please list any additional legal names that appear on your past transcripts:",
    "legal_names_subtext_less_than_five": "Limit {{maxNames}}",
    "legal_names_subtext_greater_than_five": "You have exceeded {{maxNames}} names. Please prioritize listing names that are on your official transcripts from your highest level of education.",
    "legal_names_subtext_five": "Maximum limit of {{maxNames}} reached."
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.education_form = window.locales.es.careers.edit_career_profile.education_form || {};window.locales.es.careers.edit_career_profile.education_form = {...window.locales.es.careers.edit_career_profile.education_form, ...{
    "education": "Educación",
    "formal_degree_programs": "Programas formales de grado",
    "yes": "Sí",
    "no": "No",
    "english_language_proficiency": "Dominio del idioma Inglés",
    "native_english_speaker": "Soy un hablante nativo de inglés.",
    "earned_accredited_degree_in_english": "Obtuve un título de una universidad acreditada que se enseña en inglés.",
    "education_summary": "Resumen de educación",
    "my_highest_level_education": "Mi nivel más alto de educación COMPLETADA se describe mejor como ...",
    "highest_level_education": "Educación de alto nivel",
    "has_no_formal_education": "Nunca me he inscrito en un programa de grado formal.",
    "non_degree_programs": "Programas sin título (opcional)",
    "earned_accredited_degree_in_english_degree_sub_text": "Una vez aceptado, se le pedirá que proporcione resultados aceptables de dominio del idioma inglés de una evaluación aprobada.",
    "sufficient_english_work_experience": "¿Ha trabajado en un lugar de trabajo predominantemente de habla inglesa durante cinco años o más?",
    "sufficient_english_work_experience_degree_sub_text": "Una vez aceptado, le solicitaremos que proporcione puntajes de dominio del idioma inglés del TOEFL o una prueba reconocida similar.",
    "english_work_experience_description": "¿Qué empresa o empresas?",
    "legal_names": "Nombres legales",
    "legal_names_question": "Si es admitido, se le pedirá que proporcione transcripciones que confirmen sus títulos. Enumere cualquier nombre legal adicional que aparezca en sus transcripciones anteriores:",
    "legal_names_subtext_less_than_five": "Límite {{maxNames}}",
    "legal_names_subtext_greater_than_five": "Ha excedido {{maxNames}} nombres. Priorice la lista de nombres que se encuentran en sus transcripciones oficiales de su nivel más alto de educación.",
    "legal_names_subtext_five": "Límite máximo de {{maxNames}} alcanzado."
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.education_form = window.locales.it.careers.edit_career_profile.education_form || {};window.locales.it.careers.edit_career_profile.education_form = {...window.locales.it.careers.edit_career_profile.education_form, ...{
    "education": "Formazione scolastica",
    "formal_degree_programs": "Programmi di laurea magistrale",
    "yes": "sì",
    "no": "No",
    "english_language_proficiency": "Conoscenza della lingua inglese",
    "native_english_speaker": "Sono un madrelingua inglese.",
    "earned_accredited_degree_in_english": "Mi sono laureato in una università accreditata in inglese.",
    "education_summary": "Riepilogo della formazione",
    "my_highest_level_education": "Il mio livello più alto di istruzione COMPLETATA è meglio descritto come ...",
    "highest_level_education": "Istruzione di livello più alto",
    "has_no_formal_education": "Non mi sono mai iscritto a un corso di laurea formale.",
    "non_degree_programs": "Programmi non di laurea (facoltativo)",
    "earned_accredited_degree_in_english_degree_sub_text": "Dopo l'accettazione, ti verrà richiesto di fornire risultati accettabili di conoscenza della lingua inglese da una valutazione approvata.",
    "sufficient_english_work_experience": "Hai lavorato in un luogo di lavoro prevalentemente di lingua inglese per cinque o più anni?",
    "sufficient_english_work_experience_degree_sub_text": "Dopo l'accettazione, ti chiederemo di fornire i punteggi di conoscenza della lingua inglese del TOEFL o di un test riconosciuto in modo simile.",
    "english_work_experience_description": "Quale azienda o aziende?",
    "legal_names": "Nome/i legale",
    "legal_names_question": "Se ammesso, ti verrà chiesto di fornire trascrizioni che confermano i tuoi diplomi. Si prega di elencare eventuali nomi legali aggiuntivi che compaiono nelle trascrizioni precedenti:",
    "legal_names_subtext_less_than_five": "Limite {{maxNames}}",
    "legal_names_subtext_greater_than_five": "Hai superato {{maxNames}} nomi. Si prega di dare la priorità all'elenco dei nomi che si trovano sulle trascrizioni ufficiali del livello di istruzione più alto.",
    "legal_names_subtext_five": "Limite massimo di {{maxNames}} raggiunto."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.education_form = window.locales.zh.careers.edit_career_profile.education_form || {};window.locales.zh.careers.edit_career_profile.education_form = {...window.locales.zh.careers.edit_career_profile.education_form, ...{
    "education": "教育背景",           
    "formal_degree_programs": "正式的学位课程",           
    "yes": "是",           
    "no": "否",           
    "english_language_proficiency": "英语语言能力",           
    "native_english_speaker": "我的母语是英语。",           
    "earned_accredited_degree_in_english": "我在一所英语授课的大学获得了学位。",           
    "education_summary": "教育背景",           
    "my_highest_level_education": "我完成的最高教育水平为",           
    "highest_level_education": "最高教育水平",           
    "has_no_formal_education": "我从未参加过正式的学位课程。",           
    "non_degree_programs": "非学位课程（选填）",           
    "earned_accredited_degree_in_english_degree_sub_text": "接受后，您将需要提供经批准的评估中可接受的英语语言能力结果。",
    "sufficient_english_work_experience": "您是否在以英语为主的工作场所工作了五年或更长时间？",
    "sufficient_english_work_experience_degree_sub_text": "接受后，我们将要求您提供托福或类似认可考试的英语语言能力成绩。",
    "english_work_experience_description": "什么公司或公司？",
    "legal_names": "法定名称",
    "legal_names_question": "如果被录取，您将被要求提供确认您学位的成绩单。请列出您过去的成绩单上出现的任何其他合法姓名：",
    "legal_names_subtext_less_than_five": "限制{{maxNames}}",
    "legal_names_subtext_greater_than_five": "您已超过{{maxNames}}名称。请优先列出您最高教育水平的正式成绩单上的姓名。",
    "legal_names_subtext_five": "已达到{{maxNames}}的最大限制。"
}
};