window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.program_preferences_form = window.locales.am.careers.edit_career_profile.program_preferences_form || {};window.locales.am.careers.edit_career_profile.program_preferences_form = {...window.locales.am.careers.edit_career_profile.program_preferences_form, ...{
    "minimum_education_requirements": "ዝቅተኛ የትምህርት መስፈርቶች",
    "mba": "MBA",
    "mba_and_emba": "MBA እና አስፈፃሚ MBA",
    "emba": "አስፈፃሚ MBA",
    "valar_opt_in_agreement": "ለሁለቱም የቫላር እና የኳንቲክ ዲግሪዎች (የሚመከር) ግምት ውስጥ መግባት እፈልጋለሁ.",
    "interview_pref_question": "በሚቀጥሉት ሳምንታት፣ ከአስገቢ ቡድናችን አባል ጋር አጭር ቃለ መጠይቅ እንዲያደርጉ ሊጋበዙ ይችላሉ። ከሆነ፣ በተገኝነት ላይ ምርጫ አለህ?",
    "interview_pref_answer_weekdays": "የሳምንት ቀናት",
    "interview_pref_answer_weekend": "ቅዳሜና እሁድ",
    "interview_pref_answer_no_pref": "ምርጫ የለም።",
    "interview_pref": "የመግቢያ ቃለ መጠይቅ",
    "minimum_education_requirements_desc_generic": "<p>በአሜሪካ እውቅና መስፈርቶች ምክንያት፣ ሁሉም {{brandName}} ተማሪዎች የባችለር ዲግሪ ወይም አለምአቀፍ አቻውን መያዝ አለባቸው።</p><p> በዚህ ጊዜ ወደ {{programTitle}} ፕሮግራም እንድትገባ ልንወስድህ አንችልም።</p><p> ይህ ስህተት ነው ብለው ካመኑ እባክዎ <a ng-click=\"loadRoute('/settings/application?page=1')\">ከፍተኛውን የተጠናቀቁትን የትምህርት ደረጃዎን</a> ይከልሱ።</p>",
    "other": "{{programTitle}}",
    "valar_mba_desc": "ቫላር ኢንስቲትዩት፣ የኳንቲክ ክፍል፣ ኤምቢኤ በተመሳሳይ የቴክኖሎጂ መድረክ ላይ ይሰጣል፣ የተለየ ሥርዓተ-ትምህርት ያለው ሲሆን ይህም የቆይታ ጊዜ አጭር ነው። አጠቃላይ የመግባት እድላቸውን ከፍ ለማድረግ እጩዎች ለሁለቱም ግምት ውስጥ እንዲገቡ አበክረን እንመክራለን።",
    "valar_mba_and_emba_desc_mba_default": "ቫላር ኢንስቲትዩት፣ የኳንቲክ ክፍል፣ MBA እና Executive MBA ዲግሪዎችን በተመሳሳይ የቴክኖሎጂ መድረክ ያቀርባል፣ የተለየ ሥርዓተ-ትምህርት እና ቆይታ አጭር ነው። አጠቃላይ የመግባት እድላቸውን ከፍ ለማድረግ እጩዎች ለሁለቱም ግምት ውስጥ እንዲገቡ አበክረን እንመክራለን።",
    "valar_mba_and_emba_desc_emba_default": "ቫላር ኢንስቲትዩት፣ የኳንቲክ ክፍል፣ የ MBA እና የኤክቲቭ MBA ዲግሪዎችን በተመሳሳይ የቴክኖሎጂ መድረክ ያቀርባል፣ የተለየ ሥርዓተ-ትምህርት እና ቆይታ አጭር ነው። አጠቃላይ የመግባት እድላቸውን ከፍ ለማድረግ እጩዎች ለሁለቱም ግምት ውስጥ እንዲገቡ አበክረን እንመክራለን።",
    "valar_emba_desc": "ቫላር ኢንስቲትዩት፣ የኳንቲክ ክፍል፣ የቆይታ ጊዜ አጭር በሆነ የተለየ ሥርዓተ-ትምህርት በተመሳሳይ የቴክኖሎጂ መድረክ ላይ አስፈፃሚ MBA ይሰጣል። አጠቃላይ የመግባት እድላቸውን ከፍ ለማድረግ እጩዎች ለሁለቱም ግምት ውስጥ እንዲገቡ አበክረን እንመክራለን።",
    "considered_for_mba": "በተዘገበው የስራ ልምድዎ የነጥብ ግምገማ ላይ በመመስረት፣ እንደ <strong>MBA አመልካች</strong> ይመዘገባሉ። ማመልከቻዎ እንደገባ እና ከተገመገመ በኋላ የፕሮግራሙን ምርጫ እናረጋግጣለን።",
    "quantic_mba_and_emba_desc_emba_default": "በተዘገበው የስራ ልምድዎ የነጥብ ግምገማ ላይ በመመስረት፣ እንደ <strong>አስፈፃሚ MBA አመልካች</strong> ይመዘገባሉ። ማመልከቻዎ እንደገባ እና ከተገመገመ በኋላ የፕሮግራሙን ምርጫ እናረጋግጣለን።",
    "considered_for_emba": "በተዘገበው የስራ ልምድዎ የነጥብ ግምገማ ላይ በመመስረት፣ እንደ <strong>አስፈፃሚ MBA አመልካች</strong> ይመዘገባሉ። ማመልከቻዎ እንደገባ እና ከተገመገመ በኋላ የፕሮግራሙን ምርጫ እናረጋግጣለን።",
    "mba_or_emba": "MBA ወይም አስፈፃሚ MBA",
    "registered_as_one_but_considered_for_both": "በተዘገበው የስራ ልምድዎ የነጥብ ግምገማ መሰረት፣ እንደ <strong>{{registeredAs}} አመልካች</strong> ይመዘገባሉ። ማመልከቻዎ እንደገባ እና ከተገመገመ በኋላ የፕሮግራሙን ምርጫ እናረጋግጣለን።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.program_preferences_form = window.locales.ar.careers.edit_career_profile.program_preferences_form || {};window.locales.ar.careers.edit_career_profile.program_preferences_form = {...window.locales.ar.careers.edit_career_profile.program_preferences_form, ...{
    "minimum_education_requirements": "متطلبات الحد الأدنى من التعليم",
    "mba": "ماجستير في إدارة الأعمال",
    "mba_and_emba": "ماجستير في إدارة الأعمال وماجستير في إدارة الأعمال التنفيذية",
    "emba": "ماجستير في إدارة الأعمال التنفيذية",
    "valar_opt_in_agreement": "أود أن أضع في الاعتبار درجتي Valar و Quantic (موصى به).",
    "interview_pref_question": "خلال الأسابيع المقبلة ، قد تتم دعوتك لإجراء مقابلة قصيرة مع أحد أعضاء فريق القبول لدينا. إذا كان الأمر كذلك ، هل لديك تفضيل بشأن التوافر؟",
    "interview_pref_answer_weekdays": "أيام الأسبوع",
    "interview_pref_answer_weekend": "عطلة نهاية الاسبوع",
    "interview_pref_answer_no_pref": "بدون تفضيل",
    "interview_pref": "مقابلة القبول",
    "minimum_education_requirements_desc_generic": "<p style=\";text-align:right;direction:rtl\">نظرًا لمتطلبات الاعتماد الأمريكية ، يجب أن يكون جميع طلاب {{brandName}} حاصلين على درجة البكالوريوس أو ما يعادلها دوليًا.</p><p style=\";text-align:right;direction:rtl\"> لا يمكننا اعتبارك للقبول في برنامج {{programTitle}} في الوقت الحالي.</p><p style=\";text-align:right;direction:rtl\"> يرجى مراجعة <a ng-click=\"loadRoute('/settings/application?page=1')\">أعلى مستوى تعليمي مكتمل</a> إذا كنت تعتقد أن هذا خطأ.</p>",
    "other": "{{programTitle}}",
    "valar_mba_desc": "يقدم معهد Valar، وهو قسم من شركة Quantic، درجة الماجستير في إدارة الأعمال على نفس منصة التكنولوجيا، مع منهج دراسي مختلف أقصر مدة. نوصي بشدة أن يظل المرشحون في الاعتبار لكليهما لزيادة فرصتهم الإجمالية في القبول.",
    "valar_mba_and_emba_desc_mba_default": "يقدم معهد Valar، أحد أقسام شركة Quantic، درجات الماجستير في إدارة الأعمال والماجستير في إدارة الأعمال التنفيذية على نفس منصة التكنولوجيا، مع منهج دراسي متميز أقصر مدة. نوصي بشدة أن يظل المرشحون في الاعتبار لكليهما لزيادة فرصتهم الإجمالية في القبول.",
    "valar_mba_and_emba_desc_emba_default": "يقدم معهد Valar، أحد أقسام شركة Quantic، درجات الماجستير في إدارة الأعمال والماجستير في إدارة الأعمال التنفيذية على نفس منصة التكنولوجيا، مع منهج دراسي متميز أقصر مدة. نوصي بشدة أن يظل المرشحون في الاعتبار لكليهما لزيادة فرصتهم الإجمالية في القبول.",
    "valar_emba_desc": "يقدم معهد Valar، أحد أقسام شركة Quantic، درجة الماجستير في إدارة الأعمال التنفيذية على نفس منصة التكنولوجيا، مع منهج دراسي متميز أقصر مدة. نوصي بشدة أن يظل المرشحون في الاعتبار لكليهما لزيادة فرصتهم الإجمالية في القبول.",
    "considered_for_mba": "بناءً على مراجعة سريعة لخبرتك المهنية، سيتم تسجيلك <strong>كمتقدم للحصول على ماجستير إدارة الأعمال</strong> . سنؤكد اختيار البرنامج بمجرد تقديم طلبك ومراجعته.",
    "quantic_mba_and_emba_desc_emba_default": "بناءً على مراجعة سريعة لخبرتك المهنية، سيتم تسجيلك <strong>كمتقدم للحصول على درجة الماجستير في إدارة الأعمال التنفيذية</strong> . سنؤكد اختيار البرنامج بمجرد تقديم طلبك ومراجعته.",
    "considered_for_emba": "بناءً على مراجعة سريعة لخبرتك المهنية، سيتم تسجيلك <strong>كمتقدم للحصول على ماجستير إدارة الأعمال التنفيذية</strong> . سنؤكد اختيار البرنامج بمجرد تقديم طلبك ومراجعته.",
    "mba_or_emba": "ماجستير إدارة الأعمال أو ماجستير إدارة الأعمال التنفيذي",
    "registered_as_one_but_considered_for_both": "بناءً على مراجعة سريعة لخبرتك المهنية المذكورة، سيتم تسجيلك كمتقدم <strong>{{registeredAs}}</strong> . وسنؤكد اختيار البرنامج بمجرد تقديم طلبك ومراجعته."
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.program_preferences_form = window.locales.en.careers.edit_career_profile.program_preferences_form || {};window.locales.en.careers.edit_career_profile.program_preferences_form = {...window.locales.en.careers.edit_career_profile.program_preferences_form, ...{
    "minimum_education_requirements": "Minimum Education Requirements",
    "mba": "MBA",
    "considered_for_mba": "Based on a cursory review of your reported career experience, you will be registered as an <strong>MBA applicant</strong>. We will confirm the program selection once your application has been submitted and reviewed.",
    "valar_mba_desc": "Valar Institute, a division of Quantic, offers an MBA on the same technology platform, with a differentiated curriculum that is shorter in duration. We strongly recommend candidates remain in consideration for both to raise their overall chance of admission.",
    "mba_and_emba": "MBA & Executive MBA",
    "mba_or_emba": "MBA or Executive MBA",
    "registered_as_one_but_considered_for_both": "Based on a cursory review of your reported career experience, you will be registered as an <strong>{{registeredAs}} applicant</strong>. We will confirm the program selection once your application has been submitted and reviewed.",
    "valar_mba_and_emba_desc_mba_default": "Valar Institute, a division of Quantic, offers MBA and Executive MBA degrees on the same technology platform, with a differentiated curriculum that is shorter in duration. We strongly recommend candidates remain in consideration for both to raise their overall chance of admission.",
    "quantic_mba_and_emba_desc_emba_default": "Based on a cursory review of your reported career experience, you will be registered as an <strong>Executive MBA applicant</strong>. We will confirm the program selection once your application has been submitted and reviewed.",
    "valar_mba_and_emba_desc_emba_default": "Valar Institute, a division of Quantic, offers MBA and Executive MBA degrees on the same technology platform, with a differentiated curriculum that is shorter in duration. We strongly recommend candidates remain in consideration for both to raise their overall chance of admission.",
    "emba": "Executive MBA",
    "considered_for_emba": "Based on a cursory review of your reported career experience, you will be registered as an <strong>Executive MBA applicant</strong>. We will confirm the program selection once your application has been submitted and reviewed.",
    "valar_emba_desc": "Valar Institute, a division of Quantic, offers an Executive MBA on the same technology platform, with a differentiated curriculum that is shorter in duration. We strongly recommend candidates remain in consideration for both to raise their overall chance of admission.",
    "valar_opt_in_agreement": "I would like to be in consideration for both Valar and Quantic degrees (recommended).",
    "interview_pref": "Admissions Interview",
    "interview_pref_question": "Over the coming weeks, you may be invited to a brief interview with a member of our admissions team. If so, do you have a preference on availability?",
    "interview_pref_answer_weekdays": "Weekdays",
    "interview_pref_answer_weekend": "Weekend",
    "interview_pref_answer_no_pref": "No preference",
    "minimum_education_requirements_desc_generic": "<p>Due to US accreditation requirements, all {{brandName}} students must hold a bachelor’s degree or its international equivalent.</p><p>We are unable to consider you for admission to the {{programTitle}} program at this time.</p><p>Please review your <a ng-click=\"loadRoute('/settings/application?page=1')\">highest level of completed education</a> if you believe this to be in error.</p>",
    "other": "{{programTitle}}"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.program_preferences_form = window.locales.es.careers.edit_career_profile.program_preferences_form || {};window.locales.es.careers.edit_career_profile.program_preferences_form = {...window.locales.es.careers.edit_career_profile.program_preferences_form, ...{
    "minimum_education_requirements": "Requisitos mínimos de educación",
    "mba": "Maestría en Administración de Empresas",
    "mba_and_emba": "MBA y MBA Ejecutivo",
    "emba": "Ejecutivo mba",
    "valar_opt_in_agreement": "Me gustaría ser considerado para los grados Valar y Quantic (recomendado).",
    "interview_pref_question": "En las próximas semanas, es posible que lo inviten a una breve entrevista con un miembro de nuestro equipo de admisiones. En caso afirmativo, ¿tiene alguna preferencia sobre la disponibilidad?",
    "interview_pref_answer_weekdays": "Días de semana",
    "interview_pref_answer_weekend": "Fin de semana",
    "interview_pref_answer_no_pref": "Sin preferencias",
    "interview_pref": "Entrevista de admisión",
    "minimum_education_requirements_desc_generic": "<p>Debido a los requisitos de acreditación de EE. UU., todos los estudiantes {{brandName}} deben tener una licenciatura o su equivalente internacional.</p><p> No podemos considerarlo para la admisión al programa {{programTitle}} en este momento.</p><p> Revise su <a ng-click=\"loadRoute('/settings/application?page=1')\">nivel más alto de educación completa</a> si cree que esto es un error.</p>",
    "other": "{{programTitle}}",
    "valar_mba_desc": "Valar Institute, una división de Quantic, ofrece un MBA en la misma plataforma tecnológica, con un plan de estudios diferenciado y de menor duración. Recomendamos encarecidamente que los candidatos sigan siendo considerados para ambos para aumentar sus posibilidades generales de admisión.",
    "valar_mba_and_emba_desc_mba_default": "Valar Institute, una división de Quantic, ofrece títulos MBA y Executive MBA en la misma plataforma tecnológica, con un plan de estudios diferenciado y de menor duración. Recomendamos encarecidamente que los candidatos sigan siendo considerados para ambos para aumentar sus posibilidades generales de admisión.",
    "valar_mba_and_emba_desc_emba_default": "Valar Institute, una división de Quantic, ofrece títulos MBA y Executive MBA en la misma plataforma tecnológica, con un plan de estudios diferenciado y de menor duración. Recomendamos encarecidamente que los candidatos sigan siendo considerados para ambos para aumentar sus posibilidades generales de admisión.",
    "valar_emba_desc": "Valar Institute, una división de Quantic, ofrece un Executive MBA en la misma plataforma tecnológica, con un plan de estudios diferenciado y de menor duración. Recomendamos encarecidamente que los candidatos sigan siendo considerados para ambos para aumentar sus posibilidades generales de admisión.",
    "considered_for_mba": "Según una revisión superficial de su experiencia profesional informada, quedará registrado como <strong>solicitante de MBA</strong> . Confirmaremos la selección del programa una vez que su solicitud haya sido enviada y revisada.",
    "quantic_mba_and_emba_desc_emba_default": "Según una revisión superficial de su experiencia profesional informada, quedará registrado como <strong>solicitante de Executive MBA</strong> . Confirmaremos la selección del programa una vez que su solicitud haya sido enviada y revisada.",
    "considered_for_emba": "Según una revisión superficial de su experiencia profesional informada, quedará registrado como <strong>solicitante de Executive MBA</strong> . Confirmaremos la selección del programa una vez que su solicitud haya sido enviada y revisada.",
    "mba_or_emba": "MBA o MBA Ejecutivo",
    "registered_as_one_but_considered_for_both": "Con base en una revisión superficial de su experiencia laboral informada, se lo registrará como <strong>solicitante {{registeredAs}}</strong> . Confirmaremos la selección del programa una vez que hayamos enviado y revisado su solicitud."
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.program_preferences_form = window.locales.it.careers.edit_career_profile.program_preferences_form || {};window.locales.it.careers.edit_career_profile.program_preferences_form = {...window.locales.it.careers.edit_career_profile.program_preferences_form, ...{
    "minimum_education_requirements": "Requisiti minimi di istruzione",
    "mba": "MBA",
    "mba_and_emba": "MBA e MBA esecutivo",
    "emba": "MBA esecutivo",
    "valar_opt_in_agreement": "Vorrei essere preso in considerazione per entrambi i gradi Valar e Quantic (consigliato).",
    "interview_pref_question": "Nelle prossime settimane, potresti essere invitato a un breve colloquio con un membro del nostro team di ammissione. In caso affermativo, hai una preferenza sulla disponibilità?",
    "interview_pref_answer_weekdays": "Giorni della settimana",
    "interview_pref_answer_weekend": "Fine settimana",
    "interview_pref_answer_no_pref": "Nessuna preferenza",
    "interview_pref": "Colloquio di ammissione",
    "minimum_education_requirements_desc_generic": "<p>A causa dei requisiti di accreditamento degli Stati Uniti, tutti gli studenti {{brandName}} devono essere in possesso di un diploma di laurea o equivalente internazionale.</p><p> Al momento non siamo in grado di prenderti in considerazione per l'ammissione al programma {{programTitle}} .</p><p> Si prega di rivedere il <a ng-click=\"loadRoute('/settings/application?page=1')\">livello più alto di istruzione completata</a> se si ritiene che ciò sia errato.</p>",
    "other": "{{programTitle}}",
    "valar_mba_desc": "Valar Institute, una divisione di Quantic, offre un MBA sulla stessa piattaforma tecnologica, con un curriculum differenziato di durata più breve. Raccomandiamo vivamente che i candidati rimangano in considerazione per entrambi per aumentare le loro possibilità complessive di ammissione.",
    "valar_mba_and_emba_desc_mba_default": "Valar Institute, una divisione di Quantic, offre diplomi MBA ed Executive MBA sulla stessa piattaforma tecnologica, con un curriculum differenziato di durata più breve. Raccomandiamo vivamente che i candidati rimangano in considerazione per entrambi per aumentare le loro possibilità complessive di ammissione.",
    "valar_mba_and_emba_desc_emba_default": "Valar Institute, una divisione di Quantic, offre diplomi MBA ed Executive MBA sulla stessa piattaforma tecnologica, con un curriculum differenziato di durata più breve. Raccomandiamo vivamente che i candidati rimangano in considerazione per entrambi per aumentare le loro possibilità complessive di ammissione.",
    "valar_emba_desc": "Valar Institute, una divisione di Quantic, offre un Executive MBA sulla stessa piattaforma tecnologica, con un curriculum differenziato di durata più breve. Raccomandiamo vivamente che i candidati rimangano in considerazione per entrambi per aumentare le loro possibilità complessive di ammissione.",
    "considered_for_mba": "Sulla base di una rapida revisione della tua esperienza professionale segnalata, verrai registrato come <strong>candidato MBA</strong> . Confermeremo la selezione del programma una volta che la tua domanda sarà stata inviata ed esaminata.",
    "quantic_mba_and_emba_desc_emba_default": "Sulla base di una rapida revisione della tua esperienza professionale segnalata, verrai registrato come <strong>candidato Executive MBA</strong> . Confermeremo la selezione del programma una volta che la tua domanda sarà stata inviata ed esaminata.",
    "considered_for_emba": "Sulla base di una rapida revisione della tua esperienza professionale segnalata, verrai registrato come <strong>candidato Executive MBA</strong> . Confermeremo la selezione del programma una volta che la tua domanda sarà stata inviata ed esaminata.",
    "mba_or_emba": "MBA o MBA esecutivo",
    "registered_as_one_but_considered_for_both": "Sulla base di una rapida analisi della tua esperienza professionale segnalata, sarai registrato come <strong>candidato {{registeredAs}}</strong> . Confermeremo la selezione del programma una volta che la tua candidatura sarà stata inviata e revisionata."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.program_preferences_form = window.locales.zh.careers.edit_career_profile.program_preferences_form || {};window.locales.zh.careers.edit_career_profile.program_preferences_form = {...window.locales.zh.careers.edit_career_profile.program_preferences_form, ...{
    "minimum_education_requirements": "最低教育要求",
    "mba": "工商管理硕士",
    "mba_and_emba": "工商管理硕士和行政工商管理硕士",
    "emba": "行政工商管理硕士",
    "valar_opt_in_agreement": "我想同时考虑 Valar 和 Quantic 学位（推荐）。",
    "interview_pref_question": "在接下来的几周内，您可能会被邀请与我们的招生团队成员进行一次简短的面试。如果是这样，您对可用性有偏好吗？",
    "interview_pref_answer_weekdays": "平日",
    "interview_pref_answer_weekend": "周末",
    "interview_pref_answer_no_pref": "没有偏好",
    "interview_pref": "招生面试",
    "minimum_education_requirements_desc_generic": "<p>根据美国认证要求，所有{{brandName}}学生必须持有学士学位或国际同等学历。</p><p>我们目前无法考虑让您加入{{programTitle}}计划。</p><p>如果您认为这是错误的，请查看您<a ng-click=\"loadRoute('/settings/application?page=1')\">完成的最高教育水平</a>。</p>",
    "other": "{{programTitle}}",
    "valar_mba_desc": "Valar Institute 是 Quantic 的一个部门，提供基于相同技术平台的 MBA 课程，但课程设置不同，且课程时间较短。我们强烈建议考生同时考虑这两所学校，以提高整体录取率。",
    "valar_mba_and_emba_desc_mba_default": "Valar Institute 是 Quantic 的一个部门，它在同一技术平台上提供 MBA 和 EMBA 学位，课程差异化，时间较短。我们强烈建议考生同时考虑这两个项目，以提高整体录取率。",
    "valar_mba_and_emba_desc_emba_default": "Valar Institute 是 Quantic 的一个部门，它在同一技术平台上提供 MBA 和 EMBA 学位，课程差异化，时间较短。我们强烈建议考生同时考虑这两个项目，以提高整体录取率。",
    "valar_emba_desc": "Valar Institute 是 Quantic 的一个部门，提供基于同一技术平台的高级工商管理硕士课程，但课程设置不同，且课程时间较短。我们强烈建议考生同时考虑这两个课程，以提高整体录取率。",
    "considered_for_mba": "根据对您报告的职业经历的粗略审查，您将被注册为<strong>MBA 申请人</strong>。您的申请提交并审核后，我们将确认课程选择。",
    "quantic_mba_and_emba_desc_emba_default": "根据对您报告的职业经历的粗略审查，您将被注册为<strong>高级工商管理硕士 (EMBA) 申请人</strong>。您的申请提交并审核后，我们将确认课程选择。",
    "considered_for_emba": "根据对您报告的职业经历的粗略审查，您将被注册为<strong>高级工商管理硕士 (EMBA) 申请人</strong>。您的申请提交并审核后，我们将确认课程选择。",
    "mba_or_emba": "工商管理硕士或高级工商管理硕士",
    "registered_as_one_but_considered_for_both": "根据对您报告的职业经历的粗略审查，您将被注册为<strong>{{registeredAs}}申请人</strong>。您的申请提交并审核后，我们将确认课程选择。"
}
};