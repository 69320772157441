window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.work_form = window.locales.am.careers.edit_career_profile.work_form || {};window.locales.am.careers.edit_career_profile.work_form = {...window.locales.am.careers.edit_career_profile.work_form, ...{
    "work_experience": "የስራ ልምድ",           
    "other_experience": "ሌላ ልምድ (አማራጭ)",           
    "featured_work_exp": "ተለይቶ የቀረበ የስራ ልምድ",           
    "featured_work_exp_caption": "አሁን ካሉት የስራ ልምዶችዎ በግለ-መገለጫዎ ውስጥ የትኛውን እንደሚያሳዩ ይምረጡ።",           
    "list_part_time_work_students_and_graduates": "እንደ የቦርድ መደቦች ወይም የበጎ ፈቃደኞች እንቅስቃሴዎች ያሉ ጉልህ የሙሉ ጊዜ ያልሆኑ ልምዶችን ይዘርዝሩ። ለተማሪዎች እና ቅርብ ተመራቂዎች፣ የእርስዎን በጣም ጉልህ የካምፓስ እንቅስቃሴዎች እዚህ ይዘርዝሩ።",           
    "work_summary": "የስራ ማጠቃለያ",           
    "my_most_recent_role": "የቅርብ ጊዜ ሚናዬ በተሻለ ሁኔታ እንደሚከተለው ሊገለፅ ይችላል…",           
    "years_experience": "የዓመታት ልምድ",           
    "most_recent_role": "የቅርብ ጊዜ ሚና",           
    "work_history_description_high_experience": "በመጀመሪያ ከቅርቡ የስራ ቦታ ጀምሮ ቢያንስ የ6 አመት የስራ ልምድ አሳይ።",
    "work_history_description_low_experience": "በመጀመሪያ ከቅርቡ ቦታ ጀምሮ የስራ ልምድዎን ያሳዩ።",
    "i_have_this_many_years_experience": "ይህ ለብዙ ዓመታት የሙሉ ጊዜ የሥራ ልምድ አለኝ…"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.work_form = window.locales.ar.careers.edit_career_profile.work_form || {};window.locales.ar.careers.edit_career_profile.work_form = {...window.locales.ar.careers.edit_career_profile.work_form, ...{
    "work_experience": "خبرة العمل",           
    "featured_work_exp": "مميزة خبرة العمل",
    "featured_work_exp_caption": "اختيار أي من خبرات العمل الحالية يشارك في ملفك الشخصي.",
    "other_experience": "خبرة أخرى (اختياري)",
    "list_part_time_work_students_and_graduates": "سرد تجارب كبيرة غير متفرغة ، مثل مواقف مجلس الإدارة أو الأنشطة التطوعية. للطلاب والخريجين الجدد ، قم بإدراج أهم أنشطة الحرم الجامعي هنا.",
    "work_summary": "ملخص العمل",
    "my_most_recent_role": "يمكن وصف أحدث دور لي على أنه ...",
    "years_experience": "سنوات خبرة",
    "most_recent_role": "معظم الدور الأخير",
    "work_history_description_high_experience": "اعرض ما لا يقل عن 6 سنوات من خبرة العمل السابقة ، بدءًا من أحدث منصب أولاً.",
    "work_history_description_low_experience": "اعرض خبرتك في العمل ، بدءًا من أحدث منصب أولاً.",
    "i_have_this_many_years_experience": "لدي سنوات عديدة من الخبرة في العمل بدوام كامل ..."
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.work_form = window.locales.en.careers.edit_career_profile.work_form || {};window.locales.en.careers.edit_career_profile.work_form = {...window.locales.en.careers.edit_career_profile.work_form, ...{
    "work_experience": "Work Experience",
    "other_experience": "Other Experience (optional)",
    "featured_work_exp": "Featured Work Experience",
    "featured_work_exp_caption": "Choose which of your current work experiences to feature in your profile.",
    "work_history_description_high_experience": "Showcase at least 6 years of prior work experience, starting with the most recent position first.",
    "work_history_description_low_experience": "Showcase your work experience, starting with the most recent position first.",
    "list_part_time_work_students_and_graduates": "List significant non-full time experiences, like board positions or volunteer activities. For students and recent graduates, list your most significant campus activities here.",
    "work_summary": "Work Summary",
    "my_most_recent_role": "My most recent role can best be described as…",
    "years_experience": "Years experience",
    "most_recent_role": "Most recent role",
    "i_have_this_many_years_experience": "I have this many years of full-time work experience..."
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.work_form = window.locales.es.careers.edit_career_profile.work_form || {};window.locales.es.careers.edit_career_profile.work_form = {...window.locales.es.careers.edit_career_profile.work_form, ...{
    "work_experience": "Experiencia laboral",           
    "featured_work_exp": "Destacado Experiencia laboral",
    "featured_work_exp_caption": "Elegir cuál de sus experiencias de trabajo actuales para ofrecer en su perfil.",
    "other_experience": "Otra experiencia (opcional)",
    "list_part_time_work_students_and_graduates": "Haga una lista de experiencias significativas no de tiempo completo, como posiciones en la junta o actividades de voluntariado. Para estudiantes y recién graduados, enumere sus actividades más importantes en el campus aquí.",
    "work_summary": "Resumen de trabajo",
    "my_most_recent_role": "Mi papel más reciente se puede describir mejor como ...",
    "years_experience": "Años de experiencia",
    "most_recent_role": "Papel más reciente",
    "work_history_description_high_experience": "Muestre al menos 6 años de experiencia laboral previa, comenzando con el puesto más reciente primero.",
    "work_history_description_low_experience": "Muestre su experiencia laboral, comenzando con la posición más reciente primero.",
    "i_have_this_many_years_experience": "Tengo muchos años de experiencia laboral a tiempo completo..."
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.work_form = window.locales.it.careers.edit_career_profile.work_form || {};window.locales.it.careers.edit_career_profile.work_form = {...window.locales.it.careers.edit_career_profile.work_form, ...{
    "work_experience": "Esperienza Lavorativa",           
    "featured_work_exp": "Esperienza Lavorativa in Primo Piano",           
    "featured_work_exp_caption": "Scegli quali delle tue attuali esperienze di lavoro vuoi mettere in primo piano sul tuo profilo.",           
    "other_experience": "Altre esperienze (opzionale)",
    "list_part_time_work_students_and_graduates": "Elenca le esperienze significative non a tempo pieno, come le posizioni di bordo o le attività di volontariato. Per studenti e neolaureati, elenca qui le attività più significative del campus.",
    "work_summary": "Riepilogo del lavoro",
    "my_most_recent_role": "Il mio ruolo più recente può essere meglio descritto come ...",
    "years_experience": "Anni di esperienza",
    "most_recent_role": "Il ruolo più recente",
    "work_history_description_high_experience": "Mostra almeno 6 anni di esperienza lavorativa precedente, iniziando dalla posizione più recente per prima.",
    "work_history_description_low_experience": "Mostra la tua esperienza lavorativa, iniziando dalla posizione più recente.",
    "i_have_this_many_years_experience": "Ho molti anni di esperienza lavorativa a tempo pieno..."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.work_form = window.locales.zh.careers.edit_career_profile.work_form || {};window.locales.zh.careers.edit_career_profile.work_form = {...window.locales.zh.careers.edit_career_profile.work_form, ...{
    "work_experience": "工作经验",           
    "featured_work_exp": "特色工作经历",           
    "featured_work_exp_caption": "选择你的个人简介中目前最突出的一项工作经历。",           
    "other_experience": "其他经历（选填）",           
    "list_part_time_work_students_and_graduates": "列出重要的非全职工作经历，如董事会职位或志愿者活动。学员和应届毕业生请在此列出你最重要的校园活动。",           
    "work_summary": "工作总结",           
    "my_most_recent_role": "我最近的工作职位是",           
    "years_experience": "工作年限",           
    "most_recent_role": "最近的工作职位",           
    "work_history_description_high_experience": "展示至少 6 年的先前工作经验，从最近的职位开始。",
    "work_history_description_low_experience": "展示您的工作经验，首先从最近的职位开始。",
    "i_have_this_many_years_experience": "我有这么多年的全职工作经验..."
}
};