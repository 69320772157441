window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.pagination = window.locales.am.careers.pagination || {};window.locales.am.careers.pagination = {...window.locales.am.careers.pagination, ...{
    "first": "አንደኛ",
    "prev": "ቀዳሚ",
    "next": "ቀጥሎ",
    "last": "የመጨረሻ",
    "of": "የ",
    "expand": "ዘርጋ",
    "collapse": "ሰብስብ"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.pagination = window.locales.ar.careers.pagination || {};window.locales.ar.careers.pagination = {...window.locales.ar.careers.pagination, ...{
    "first": "أول",
    "prev": "السابق",
    "next": "التالى",
    "last": "الاخير",
    "expand": "وسعت",
    "collapse": "انهدام",
    "of": "من"
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.pagination = window.locales.en.careers.pagination || {};window.locales.en.careers.pagination = {...window.locales.en.careers.pagination, ...{
                 
    "first": "First",
    "prev": "Prev",
    "next": "Next",
    "last": "Last",
    "of": "of",

                
    "expand": "Expand",
    "collapse": "Collapse"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.pagination = window.locales.es.careers.pagination || {};window.locales.es.careers.pagination = {...window.locales.es.careers.pagination, ...{
    "first": "primero",
    "prev": "Anterior",
    "next": "Siguiente",
    "last": "Último",
    "expand": "Expandir",
    "collapse": "Colapso",
    "of": "de"
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.pagination = window.locales.it.careers.pagination || {};window.locales.it.careers.pagination = {...window.locales.it.careers.pagination, ...{
    "first": "Primo",
    "prev": "prev",
    "next": "Il prossimo",
    "last": "Ultimo",
    "expand": "Espandere",
    "collapse": "Crollo",
    "of": "di"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.pagination = window.locales.zh.careers.pagination || {};window.locales.zh.careers.pagination = {...window.locales.zh.careers.pagination, ...{
    "first": "第一",           
    "prev": "前",           
    "next": "后一个",           
    "last": "最后",           
    "expand": "展开",           
    "collapse": "收起",           
    "of": "-"           
}
};