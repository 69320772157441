window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.choose_a_role = window.locales.am.careers.choose_a_role || {};window.locales.am.careers.choose_a_role = {...window.locales.am.careers.choose_a_role, ...{
    "role": "ድርሻ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.choose_a_role = window.locales.ar.careers.choose_a_role || {};window.locales.ar.careers.choose_a_role = {...window.locales.ar.careers.choose_a_role, ...{
    "role": "وظيفة"
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.choose_a_role = window.locales.en.careers.choose_a_role || {};window.locales.en.careers.choose_a_role = {...window.locales.en.careers.choose_a_role, ...{
    "role": "Role"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.choose_a_role = window.locales.es.careers.choose_a_role || {};window.locales.es.careers.choose_a_role = {...window.locales.es.careers.choose_a_role, ...{
    "role": "Papel"
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.choose_a_role = window.locales.it.careers.choose_a_role || {};window.locales.it.careers.choose_a_role = {...window.locales.it.careers.choose_a_role, ...{
    "role": "Ruolo"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.choose_a_role = window.locales.zh.careers.choose_a_role || {};window.locales.zh.careers.choose_a_role = {...window.locales.zh.careers.choose_a_role, ...{
    "role": "角色"           
}
};