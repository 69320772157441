window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.preview_profile_status_message = window.locales.am.careers.preview_profile_status_message || {};window.locales.am.careers.preview_profile_status_message = {...window.locales.am.careers.preview_profile_status_message, ...{
    "active": "ግለ-መገለጫ ንቁ ነው!",           
    "active_desc": "ጥሩ ይመስላል! ግለ-መገለጫዎ በሙያ አውታረበ መረብ ውስጥ የተሟላ እና ንቁ ነው። ለመገናኘት ፍላጎት ያላቸው አሰሪዎች በግንኙነቶች ክፍል ውስጥ ባሉ መልዕክቶች እርስዎን ማግኘት ይችላሉ።",           
    "inactive": "ግለ-መገለጫ የቦዘነ",           
    "inactive_desc": "ግለ-መገለጫዎ ተጠናቅቋል፣ ሆኖም ግን በአሁኑ ጊዜ ቦዝኗል። ቀጣሪዎች በአውታረ መረቡ ውስጥ ከእርስዎ ጋር እንዲዛመዱ ለማድረግ ከዚህ በታች የእርስዎን ግለ-መገለጫ ያግብሩ።",           
    "incomplete": "ግለ-መገለጫ አልተጠናቀቀም።",           
    "incomplete_desc": "ቀጣሪዎች በአውታረ መረቡ ውስጥ ከእርስዎ ጋር እንዲዛመዱ ለማድረግ ግለ-መገለጫዎን ያጠናቅቁ እና ያግብሩ።",           
    "applied": "በመጠባበቅ ላይ ያለ ማመልከቻ",           
    "applied_desc": "ማመልከቻዎ ወደ {{brandName}} ገብቷል! ለ MBA፣ EMBA ወይም ለምስክር ወረቀት ፕሮግራሞቻችን ካመለከቱ፣ የሙያ ግለ-መግለጫ ከፕሮግራም ማመልከቻዎ ጋር በተመሳሳይ ጊዜ ይገመገማል።<br><br>ብቁ ከሆኑ፣ በመቶዎች ከሚቆጠሩ ታዋቂ ኩባንያዎች እና እጩዎች ልክ እንደ እርስዎ ከሚፈልጉ ፈጠራ ጀማሪዎች ጋር የሚገናኙበት ቦታ ነው!",           
    "reviewing_degree": "እንኳን ደስ አለዎት!",           
    "reviewing_degree_desc": "የእርስዎ ግለ-መገለጫ አሁን ተጠናቅቋል። ከአጭር ግምገማ በኋላ ክፍሎች ከጀመሩ ከ 1-2 ሳምንታት ውስጥ በአውታረ መረባችን ውስጥ መታየት አለበት።",           
    "reviewing_non_degree": "በግምገማ ላይ",           
    "reviewing_non_degree_desc": "የሰርተፍኬት ፕሮግራሙን ስለተቀበሉ እንኳን ደስ አለዎት!<br><br>በዚህ ወቅት ከእርስዎ ፍላጎቶች፣ ሚና ወይም የአካባቢ ምርጫዎች ጋር የሚዛመዱ እድሎች የሉንም። መዳረሻ ማግኘት እንዲችሉ ያ ከተለወጠ እና መቼ እንደሆነ እናሳውቅዎታለን።",           
    "deferred": "የዘገየ",           
    "deferred_desc": "ተቀባይት ስላገኙ እንኳን ደስ አለዎት! አዲስ ክፍል ከተቀላቀሉ በኋላ ግለ-መገለጫዎ እንዲነቃ ይደረጋል።",           
    "no_cn_access": "የሙያ አውታረ መረብ መዳረሻ",           
    "no_cn_access_desc": "ወደ {{brandName}} ስላመለከቱ እናመሰግናለን! ሆኖም ግን፣ አውታረ መረባችን ለማስገባት ብቁ ሊሆኑ ይችላሉ። ፍላጎትዎን እናከብራለን እና በመማርና ስራ ግቦችዎ ውስጥ ምርጡን እንመኛለን!",           
    "no_cn_access_cno": "የሙያ አውታረ መረብ መዳረሻ",           
    "no_cn_access_cno_desc": "ወደ {{brandName}} ስላመለከቱ እናመሰግናለን! ሆኖም ግን፣ ከእርስዎ ሚና እና የአካባቢ ምርጫዎች ጋር የሚዛመዱ በቂ እድሎች የሉንም። መዳረሻ ማግኘት እንዲችሉ ያ ከተለወጠ እና መቼ እንደሆነ እናሳውቅዎታለን።",           
    "accepted_degree_incomplete_profile": "ግለ-መገለጫ አልተጠናቀቀም።",           
    "accepted_degree_incomplete_profile_desc": "ተቀባይት ስላገኙ እንኳን ደስ አለዎት! እባክዎ ግለ-መገለጫዎን እንደ የምዝገባ አካል ያጠናቅቁ። ያልተሟሉ እርምጃዎች ከታች በሰማያዊ ክበብ ምልክት ይደረይግቡቸዋል።",           
    "accepted_degree_incomplete_profile_alt": "ግለ-መገለጫ አልተጠናቀቀም።",           
    "accepted_degree_incomplete_profile_alt_desc": "ተቀባይት ስላገኙ እንኳን ደስ አለዎት! እባክዎ ግለ-መገለጫዎን እንደ የምዝገባ አካል ያጠናቅቁ።",           
    "pending_student_network": "የተማሪ አውታረ መረብ መዳረሻ",
    "pending_student_network_desc": "የእርስዎ መገለጫ በአሁኑ ጊዜ ለመግቢያ ቡድናችን ብቻ ነው የሚታየው። በዚህ ክፍል ውስጥ ዝርዝሮችን ማከል እና ማርትዕ እና ከገቡ ተማሪዎች ጋር ምን ያህል እንደሚያካፍሉ መወሰን ይችላሉ።",
    "no_student_network_access": "የተማሪ አውታረ መረብ መዳረሻ",
    "no_student_network_access_desc": "ለ {{brandName}} እናመሰግናለን! እንደ አለመታደል ሆኖ፣ ተቀባይነት ያላቸው ተማሪዎች ብቻ የእኛን የተማሪ አውታረ መረብ ለማግኘት ብቁ ሊሆኑ ይችላሉ። ፍላጎትዎን እናደንቃለን እና በመማር እና በስራ ግቦችዎ ውስጥ ምርጡን እንመኝልዎታለን!",
    "reviewing_student_network": "እንኳን ደስ አላችሁ!",
    "reviewing_student_network_desc": "የእርስዎ መገለጫ አሁን ተጠናቅቋል። ከአጭር ግምገማ በኋላ፣ ክፍሎች ከጀመሩ ከ1-2 ሳምንታት ውስጥ በተማሪ አውታረ መረቡ ውስጥ መታየት አለበት።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.preview_profile_status_message = window.locales.ar.careers.preview_profile_status_message || {};window.locales.ar.careers.preview_profile_status_message = {...window.locales.ar.careers.preview_profile_status_message, ...{
    "active": "الملف الشخصي",
    "active_desc": "تبدو جيدة! ملفك الشخصي كامل ونشط في الشبكة المهنية. يمكن لأصحاب العمل المهتمين بالاتصال التواصل معك من خلال الرسائل في قسم الاتصالات.",
    "inactive": "الملف غير نشط",
    "inactive_desc": "ملفك الشخصي مكتمل ، ولكن تم إلغاء تنشيطه حاليًا. قم بتنشيط ملف التعريف الخاص بك أدناه للسماح لأصحاب العمل بمطابقة معك في الشبكة.",
    "incomplete": "الملف الشخصي غير مكتمل",
    "incomplete_desc": "أكمل ملفك الشخصي وقم بتنشيطه للسماح لأصحاب العمل بالتوفيق معك في الشبكة.",
    "applied": "في انتظار التطبيق",
    "reviewing_non_degree": "في مراجعة",
    "no_cn_access": "شبكة الوصول الوظيفي",
    "no_cn_access_cno": "شبكة الوصول الوظيفي",
    "deferred": "المؤجلة",
    "deferred_desc": "تهانينا على قبولك! سيتم تفعيل ملفك الشخصي بعد انضمامك إلى فصل جديد.",
    "accepted_degree_incomplete_profile": "الملف الشخصي غير مكتمل",
    "accepted_degree_incomplete_profile_desc": "مبروك على موافقتك يرجى إكمال ملف التعريف الخاص بك كجزء من التسجيل. يتم تمييز الخطوات غير المكتملة بدائرة زرقاء أدناه.",
    "accepted_degree_incomplete_profile_alt": "الملف الشخصي غير مكتمل",
    "accepted_degree_incomplete_profile_alt_desc": "مبروك على موافقتك يرجى إكمال ملف التعريف الخاص بك كجزء من التسجيل.",
    "reviewing_degree": "تهانينا!",
    "reviewing_degree_desc": "ملفك الشخصي اكتمل الآن. بعد مراجعة موجزة ، يجب أن تكون مرئية في شبكتنا في غضون 1-2 أسابيع من بداية الفصول الدراسية.",
    "applied_desc": "تم إرسال طلبك إلى {{brandName}} ! إذا تقدمت بطلب للحصول على ماجستير إدارة الأعمال أو برنامج الماجستير في إدارة الأعمال (EMBA) أو برامج الشهادات الخاصة بنا ، فستتم مراجعة ملفك الوظيفي في نفس الوقت الذي يتم فيه تطبيق البرنامج. <br><br> إذا كنت مؤهلاً ، فستتواصل مع مئات الشركات الرائدة والشركات الناشئة المبتكرة التي تبحث عن مرشحين مثلك تمامًا!",
    "reviewing_non_degree_desc": "مبروك على موافقتك على برنامج الشهادة! <br><br> في الوقت الحالي ، ليست لدينا فرص تتناسب مع اهتماماتك أو دورك أو تفضيلات موقعك. سنقوم بإعلامك إذا ومتى هذه التغييرات حتى تتمكن من الوصول.",
    "no_cn_access_cno_desc": "شكرًا لك على التقديم على {{brandName}} ! لسوء الحظ ، ليس لدينا ما يكفي من الفرص التي تتناسب مع دورك وتفضيلات موقعك. سنقوم بإعلامك إذا ومتى هذه التغييرات حتى تتمكن من الوصول.",
    "no_cn_access_desc": "شكرًا لك على التقديم على {{brandName}} ! لسوء الحظ ، يمكن للطلاب المقبولين فقط التأهل للوصول إلى شبكتنا المهنية. نحن نقدر اهتمامك ونتمنى لك الأفضل في أهدافك التعليمية والوظيفية!",
    "pending_student_network": "الوصول إلى شبكة الطلاب",
    "pending_student_network_desc": "ملفك الشخصي مرئي حاليًا فقط لفريق القبول لدينا. في هذا القسم ، يمكنك إضافة التفاصيل وتحريرها وتحديد مقدار مشاركتها مع زملائك الطلاب إذا تم قبولك.",
    "no_student_network_access": "الوصول إلى شبكة الطلاب",
    "no_student_network_access_desc": "شكرًا للتقديم على {{brandName}} ! لسوء الحظ ، يمكن للطلاب المقبولين فقط التأهل للوصول إلى شبكة الطلاب الخاصة بنا. نحن نقدر اهتمامك ونتمنى لك التوفيق في أهدافك التعليمية والوظيفية!",
    "reviewing_student_network": "تهانينا!",
    "reviewing_student_network_desc": "ملفك الشخصي اكتمل الآن. بعد مراجعة موجزة ، يجب أن تكون مرئية في شبكة الطلاب لدينا في غضون أسبوع إلى أسبوعين من بدء الفصول الدراسية."
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.preview_profile_status_message = window.locales.en.careers.preview_profile_status_message || {};window.locales.en.careers.preview_profile_status_message = {...window.locales.en.careers.preview_profile_status_message, ...{
                                        
                                      
                                        
    "active": "Profile Active!",
    "active_desc": "Looking good! Your profile is complete and active in the career network. Employers that are interested in connecting can reach out to you through messages in the Connections section.",

    "inactive": "Profile Inactive",
    "inactive_desc": "Your profile is complete, but currently deactivated. Activate your profile below to allow employers to match with you in the network.",

    "incomplete": "Profile Incomplete",
    "incomplete_desc": "Complete and activate your profile to allow employers to match with you in the network.",

                                        
                                       
                                        

                          
    "applied": "Pending Application",
    "applied_desc": "Your application to {{brandName}} has been submitted! If you applied to the MBA, EMBA, or our certificate programs, your career profile will be reviewed at the same time as your program application.<br><br>If you qualify, this is where you'll connect with hundreds of leading companies and innovative startups looking for candidates just like you!",

                                 
    "reviewing_degree": "Congratulations!",
    "reviewing_degree_desc": "Your profile is now complete. After a brief review, it should be visible in our network within 1-2 weeks of the start of classes.",

                                     
    "reviewing_non_degree": "In Review",
    "reviewing_non_degree_desc": "Congratulations on your acceptance to the certificate program!<br><br>At this time, we don’t have opportunities that match your interests, role or location preferences. We’ll notify you if and when that changes so you can gain access.",

               
    "deferred": "Deferred",
    "deferred_desc": "Congratulations on your acceptance! Your profile will be activated after you join a new class.",

                                                                       
    "no_cn_access": "Career Network Access",
    "no_cn_access_desc": "Thanks for applying to {{brandName}}! Unfortunately, only accepted students can qualify for access to our career network. We appreciate your interest and wish you the best in your learning and career goals!",

                                                  
    "no_cn_access_cno": "Career Network Access",
    "no_cn_access_cno_desc": "Thanks for applying to {{brandName}}! Unfortunately, we don’t have enough opportunities that match your role and location preferences. We’ll notify you if and when that changes so you can gain access.",

                                            
                                          
                                            

                                                                                             
    "accepted_degree_incomplete_profile": "Profile Incomplete",
    "accepted_degree_incomplete_profile_desc": "Congratulations on your acceptance! Please complete your profile as part of enrollment. Incomplete steps are marked with a blue circle below.",

                                                                                                 
    "accepted_degree_incomplete_profile_alt": "Profile Incomplete",
    "accepted_degree_incomplete_profile_alt_desc": "Congratulations on your acceptance! Please complete your profile as part of enrollment.",

                                            
                                                             
                                            

                                                 
    "pending_student_network": "Student Network Access",
    "pending_student_network_desc": "Your profile is currently only visible to our admissions team. In this section you can add and edit details and decide how much to share with your fellow students if you are admitted.",

                                                                       
    "no_student_network_access": "Student Network Access",
    "no_student_network_access_desc": "Thanks for applying to {{brandName}}! Unfortunately, only accepted students can qualify for access to our student network. We appreciate your interest and wish you the best in your learning and career goals!",

               
    "reviewing_student_network": "Congratulations!",
    "reviewing_student_network_desc": "Your profile is now complete. After a brief review, it should be visible in our student network within 1-2 weeks of the start of classes."
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.preview_profile_status_message = window.locales.es.careers.preview_profile_status_message || {};window.locales.es.careers.preview_profile_status_message = {...window.locales.es.careers.preview_profile_status_message, ...{
    "active": "Perfil activo!",
    "active_desc": "¡Luciendo bien! Tu perfil está completo y activo en la red profesional. Los empleadores que estén interesados ​​en conectarse pueden comunicarse con usted a través de mensajes en la sección de Conexiones.",
    "inactive": "Perfil Inactivo",
    "inactive_desc": "Su perfil está completo, pero actualmente desactivado. Active su perfil a continuación para permitir que los empleadores coincidan con usted en la red.",
    "incomplete": "Perfil Incompleto",
    "incomplete_desc": "Complete y active su perfil para permitir que los empleadores coincidan con usted en la red.",
    "applied": "Solicitud pendiente",
    "reviewing_non_degree": "En revisión",
    "no_cn_access": "Acceso a la red profesional",
    "no_cn_access_cno": "Acceso a la red profesional",
    "deferred": "Diferido",
    "deferred_desc": "Felicitaciones por su aceptación! Su perfil se activará después de unirse a una nueva clase.",
    "accepted_degree_incomplete_profile": "Perfil incompleto",
    "accepted_degree_incomplete_profile_desc": "¡Felicitaciones por tu aceptación! Complete su perfil como parte de la inscripción. Los pasos incompletos están marcados con un círculo azul debajo.",
    "accepted_degree_incomplete_profile_alt": "Perfil incompleto",
    "accepted_degree_incomplete_profile_alt_desc": "¡Felicitaciones por tu aceptación! Complete su perfil como parte de la inscripción.",
    "reviewing_degree": "¡Felicidades!",
    "reviewing_degree_desc": "Tu perfil ahora está completo. Después de una breve revisión, debería estar visible en nuestra red dentro de 1-2 semanas del comienzo de las clases.",
    "applied_desc": "¡Su solicitud a {{brandName}} ha sido enviada! Si solicitó el MBA, EMBA o nuestros programas de certificados, su perfil profesional será revisado al mismo tiempo que su solicitud de programa. <br><br> Si califica, ¡aquí es donde se conectará con cientos de empresas líderes y nuevas empresas innovadoras que buscan candidatos como usted!",
    "reviewing_non_degree_desc": "¡Felicitaciones por su aceptación al programa de certificados! <br><br> En este momento, no tenemos oportunidades que coincidan con sus intereses, roles o preferencias de ubicación. Le notificaremos si eso cambia y cuándo, para que pueda obtener acceso.",
    "no_cn_access_cno_desc": "¡Gracias por {{brandName}} solicitud a {{brandName}} ! Desafortunadamente, no tenemos suficientes oportunidades que coincidan con su rol y preferencias de ubicación. Le notificaremos si eso cambia y cuándo, para que pueda obtener acceso.",
    "no_cn_access_desc": "¡Gracias por {{brandName}} solicitud a {{brandName}} ! Desafortunadamente, solo los estudiantes aceptados pueden calificar para acceder a nuestra red de carreras. ¡Apreciamos su interés y le deseamos lo mejor en sus objetivos de aprendizaje y carrera!",
    "pending_student_network": "Acceso a la red de estudiantes",
    "pending_student_network_desc": "Actualmente, su perfil solo es visible para nuestro equipo de admisiones. En esta sección puedes agregar y editar detalles y decidir cuánto compartir con tus compañeros de estudios si eres admitido.",
    "no_student_network_access": "Acceso a la red de estudiantes",
    "no_student_network_access_desc": "¡Gracias por aplicar a {{brandName}} ! Desafortunadamente, solo los estudiantes aceptados pueden calificar para acceder a nuestra red de estudiantes. ¡Agradecemos su interés y le deseamos lo mejor en sus metas de aprendizaje y carrera!",
    "reviewing_student_network": "¡Felicidades!",
    "reviewing_student_network_desc": "Tu perfil ya está completo. Después de una breve revisión, debería estar visible en nuestra red de estudiantes dentro de 1 a 2 semanas del comienzo de las clases."
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.preview_profile_status_message = window.locales.it.careers.preview_profile_status_message || {};window.locales.it.careers.preview_profile_status_message = {...window.locales.it.careers.preview_profile_status_message, ...{
    "active": "Profilo attivo!",
    "active_desc": "Guardando bene! Il tuo profilo è completo e attivo nella rete di carriera. I datori di lavoro interessati a collegarsi possono contattarti attraverso i messaggi nella sezione Connessioni.",
    "inactive": "Profilo non attivo",
    "inactive_desc": "Il tuo profilo è completo, ma attualmente disattivato. Attiva il tuo profilo qui sotto per consentire ai datori di lavoro di abbinarsi a te nella rete.",
    "incomplete": "Profilo incompleto",
    "incomplete_desc": "Completa e attiva il tuo profilo per consentire ai datori di lavoro di abbinarsi a te nella rete.",
    "applied": "Applicazione in sospeso",
    "reviewing_non_degree": "In revisione",
    "no_cn_access": "Accesso alla rete di carriera",
    "no_cn_access_cno": "Accesso alla rete di carriera",
    "deferred": "differite",
    "deferred_desc": "Congratulazioni per la tua accettazione! Il tuo profilo verrà attivato dopo esserti iscritto a una nuova classe.",
    "accepted_degree_incomplete_profile": "Profilo incompleto",
    "accepted_degree_incomplete_profile_desc": "Congratulazioni per la tua accettazione! Completa il tuo profilo come parte dell'iscrizione. I passaggi incompleti sono contrassegnati da un cerchio blu in basso.",
    "accepted_degree_incomplete_profile_alt": "Profilo incompleto",
    "accepted_degree_incomplete_profile_alt_desc": "Congratulazioni per la tua accettazione! Completa il tuo profilo come parte dell'iscrizione.",
    "reviewing_degree": "Congratulazioni!",
    "reviewing_degree_desc": "Il tuo profilo è ora completo. Dopo una breve revisione, dovrebbe essere visibile nella nostra rete entro 1-2 settimane dall'inizio delle lezioni.",
    "applied_desc": "La tua richiesta a {{brandName}} è stata inviata! Se ti sei iscritto all'MBA, all'EMBA o ai nostri programmi di certificazione, il tuo profilo professionale verrà rivisto contemporaneamente all'applicazione del programma. <br><br> Se ti qualifichi, è qui che ti collegherai con centinaia di aziende leader e startup innovative in cerca di candidati proprio come te!",
    "reviewing_non_degree_desc": "Congratulazioni per aver accettato il programma di certificazione! <br><br> Al momento, non abbiamo opportunità che corrispondano ai tuoi interessi, ruolo o preferenze sulla posizione. Ti informeremo se e quando cambierà in modo che tu possa ottenere l'accesso.",
    "no_cn_access_cno_desc": "Grazie per {{brandName}} candidato a {{brandName}} ! Sfortunatamente, non abbiamo abbastanza opportunità che corrispondono al tuo ruolo e alle preferenze sulla posizione. Ti informeremo se e quando cambierà in modo che tu possa ottenere l'accesso.",
    "no_cn_access_desc": "Grazie per {{brandName}} candidato a {{brandName}} ! Sfortunatamente, solo gli studenti ammessi possono beneficiare dell'accesso alla nostra rete di carriera. Apprezziamo il tuo interesse e ti auguriamo il meglio nei tuoi obiettivi di apprendimento e carriera!",
    "pending_student_network": "Accesso alla rete degli studenti",
    "pending_student_network_desc": "Il tuo profilo è attualmente visibile solo al nostro team di ammissione. In questa sezione puoi aggiungere e modificare dettagli e decidere quanto condividere con i tuoi compagni studenti se sei ammesso.",
    "no_student_network_access": "Accesso alla rete degli studenti",
    "no_student_network_access_desc": "Grazie per aver fatto domanda a {{brandName}} ! Sfortunatamente, solo gli studenti accettati possono qualificarsi per l'accesso alla nostra rete di studenti. Apprezziamo il tuo interesse e ti auguriamo il meglio per i tuoi obiettivi di apprendimento e di carriera!",
    "reviewing_student_network": "Congratulazioni!",
    "reviewing_student_network_desc": "Il tuo profilo è ora completo. Dopo una breve revisione, dovrebbe essere visibile nella nostra rete di studenti entro 1-2 settimane dall'inizio delle lezioni."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.preview_profile_status_message = window.locales.zh.careers.preview_profile_status_message || {};window.locales.zh.careers.preview_profile_status_message = {...window.locales.zh.careers.preview_profile_status_message, ...{
    "active": "资料活跃！",           
    "active_desc": "很不错！你的个人资料在求职网络中非常完整且活跃。有兴趣与你联系的雇主可以通过“联系”版块的信息与你联系。",           
    "inactive": "个人资料不活跃",           
    "inactive_desc": "你的个人资料很完整，但目前并未激活。激活下面的个人资料，以便在网络中与雇主进行匹配。",           
    "incomplete": "个人资料不完整",           
    "incomplete_desc": "完善并激活下面的个人资料，以便在网络中与雇主进行匹配。",           
    "applied": "待审批的申请",           
    "reviewing_non_degree": "在审核中",           
    "no_cn_access": "加入求职网络",           
    "no_cn_access_cno": "加入求职网络",           
    "deferred": "延期",           
    "deferred_desc": "恭喜你被录取了！你的个人资料将被激活，之后你可以加入一个新班级。",           
    "accepted_degree_incomplete_profile": "个人资料不完整",           
    "accepted_degree_incomplete_profile_desc": "恭喜你被录取了！请填写你的个人资料以完成注册。未完善的步骤已用下面的蓝色圆圈标出。",           
    "accepted_degree_incomplete_profile_alt": "个人资料不完整",           
    "accepted_degree_incomplete_profile_alt_desc": "恭喜你被录取了！请填写你的个人资料以完成注册。",           
    "reviewing_degree": "恭喜你！",           
    "reviewing_degree_desc": "你的个人资料已填写完整。经过简单的审核后，于课程开始1-2周内在我们的网络中显示。",           
    "applied_desc": "你对{{brandName}}的申请已经提交！如果你申请的是MBA、EMBA或我们的证书课程，我们将对你的职业履历将与课程申请同时进行审核。<br><br>如果你符合条件，即有机会加入数百家正寻求像你这类人才的领先公司和创新创业公司！",           
    "reviewing_non_degree_desc": "恭喜你被证书课程录取！<br><br>此时，我们无法为你提供与你的兴趣、职位或地点偏好相匹配的机会。如果情况有变，我们会立即通知你，以便你进行访问。",           
    "no_cn_access_cno_desc": "感谢申请{{brandName}}！很遗憾，我们无法为你提供与你的职位和地点偏好相匹配的机会。如果情况有变，我们会立即通知你，以便你进行访问。",           
    "no_cn_access_desc": "感谢申请{{brandName}}！很遗憾，只有被录取的学员才有资格进入我们的求职网络。我们感谢你的关注，祝你在学习和职业目标上取得成功！",           
    "pending_student_network": "学生网络访问",
    "pending_student_network_desc": "您的个人资料目前仅对我们的招生团队可见。在本节中，您可以添加和编辑详细信息，并决定如果您被录取，与您的同学分享多少。",
    "no_student_network_access": "学生网络访问",
    "no_student_network_access_desc": "感谢您申请{{brandName}} ！不幸的是，只有被录取的学生才有资格访问我们的学生网络。我们感谢您的兴趣，并祝您在学习和职业目标方面取得最大成绩！",
    "reviewing_student_network": "恭喜！",
    "reviewing_student_network_desc": "您的个人资料现已完成。经过简短的审查后，它应该会在开课后的 1-2 周内出现在我们的学生网络中。"
}
};