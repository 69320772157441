window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.candidate_list_card = window.locales.am.careers.candidate_list_card || {};window.locales.am.careers.candidate_list_card = {...window.locales.am.careers.candidate_list_card, ...{
    "new": "አዲስ",           
    "new_match": "አዲስ ተዛማጅ",           
    "new_message": "አዲስ መልዕክት",           
    "outstanding": "<i class=\"fa fa-star\"></i>&nbsp;ታላቅ አመልካች",           
    "plus_n_more": "+{{num}} ተጨማሪ",           
    "status_very_interested": "አዲስ ስራን በንቃት በመፈለግ ላይ",           
    "status_interested": "አዲስ ስራን አልፎ አልፎ በመፈለግ ላይ",           
    "status_neutral": "ለግንኙነቶች ክፍት",           
    "status_not_interested": "በንቃት እየፈለግኩ አይደለም",           
    "fun_fact": "አስገራሚ እውነታ፡-",           
    "more": "ተጨማሪ",           
    "experience": "ልምድ",           
    "other_experience": "ሌላ ልምድ",           
    "education": "ትምህርት",           
    "preferences": "መፈለግ",           
    "skills": "ችሎታዎች",           
    "strengths": "ጥንካሬዎች",           
    "position": "የስራ ድርሻ",           
    "open_to_locations": "ለ {{numLocations}} ቦታዎች ክፍት ነው (<span class=\"underline\">ይመልከቱ</span>)",           
    "open_flexible": "ለአብዛኛዎቹ ቦታዎች ክፍት ነው",           
    "open_to_remote": "ርቀት ላላቸው ክፍት ነው",           
    "seeking": "በ {{industries}} ኢንዱስትሪዎች ውስጥ {{roles}} ሚናዎች",           
    "seeking_singular": "በ {{industries}} ኢንዱስትሪ ውስጥ {{roles}} ሚናዎች",           
    "seeking_or": " ወይም ",           
    "years": "አመታት",           
    "num_years": "{num} {num, plural, =1{አመት} other{አመታት}}",           
    "num_months": "{num} {num, plural, =1{ወር} other{ወራት}}",           
    "gpa": "GPA",           
    "minor": "አነስተኛ",           
    "non_formal_programs": "የዲግሪ ያልሆኑ ፕሮግራሞች",           
    "test_scores": "የፈተና ውጤቶች",           
    "present": "አሁን",           
    "resume": "ከቆመበት ቀጥል",           
    "github": "Github",           
    "formerly": "ቀደም ሲል",           
    "invite_to_connect": "ለመገናኘት <span>ጋብዝ</span>",           
    "full_profile": "ሙሉ ግለ-መገለጫ",           
    "save": "አስቀምጥ",           
    "save_for_review": "ለበኋላ ግምገማ አስቀምጥ",           
    "like": "ውደድ",           
    "hide": "ደብቅ",           
    "hide_from_browse": "ከሁሉም ፍለጋዎች ደብቅ",           
    "general_interest": "አጠቃላይ ፍላጎት",           
    "open": "ክፈት",           
    "open_in_tracker": "በመከታተያ ውስጥ ክፈት",           
    "connect_now": "አሁን አገናኝ",           
    "close": "ዝጋ",           
    "close_connection": "ግንኙነትን ዝጋ",           
    "pass": "እለፍ",           
    "pass_for_position": "ለዚህ ስራ እለፍ",           
    "saved_by": "የተቀመጠው በ",           
    "connected_with": "ተገናኝቷል ከ",           
    "one_click_reachout": "በአንድ ጠቅታ ያግኙ",           
    "exec_ed_cohort_title": "የአስፈፃሚ ትምህርት {cohortTitle}"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.candidate_list_card = window.locales.ar.careers.candidate_list_card || {};window.locales.ar.careers.candidate_list_card = {...window.locales.ar.careers.candidate_list_card, ...{
    "status_very_interested": "تسعى بحماس إلى موقف جديد",
    "fun_fact": "حقيقة ممتعة:",
    "more": "أكثر من",
    "experience": "تجربة",
    "education": "التعليم",
    "skills": "مهارات",
    "position": "موضع",
    "open_to_locations": "مفتوح إلى {{numLocations}} المواقع ( <span class=\"underline\">عرض )",
    "open_to_remote": "فتح إلى البعيد",
    "invite_to_connect": "<span>دعوة للاتصال",
    "full_profile": "الشخصي الكامل",
    "save": "حفظ",
    "like": "مثل",
    "hide": "إخفاء",
    "preferences": "بحث",
    "seeking_or": "أو",
    "status_interested": "تسعى بشكل سلبي إلى موقف جديد",
    "status_neutral": "فتح للاتصالات",
    "open_flexible": "مفتوح لمعظم المواقع",
    "hide_from_browse": "إخفاء من جميع عمليات البحث",
    "seeking": "{{roles}} في {{industries}}",
    "seeking_singular": "{{roles}} في صناعة {{industries}}",
    "strengths": "نقاط القوة",
    "new": "الجديد",
    "general_interest": "اهتمام عام",
    "open": "افتح",
    "open_in_tracker": "فتح في المقتفي",
    "connect_now": "الاتصال الآن",
    "new_match": "مباراة جديدة",
    "new_message": "رسالة جديدة",
    "plus_n_more": "+ {{num}} أكثر",
    "close": "قريب",
    "close_connection": "اغلق الاتصال",
    "pass": "البشري",
    "pass_for_position": "تمر لهذا المنصب",
    "status_not_interested": "لا تبحث بنشاط",
    "save_for_review": "حفظ للمراجعة لاحقًا",
    "outstanding": "<i class=\"fa fa-star\"></i> مقدم الطلب العظيم",
    "saved_by": "تم الحفظ بواسطة",
    "connected_with": "متصل مع",
    "one_click_reachout": "تواصل مع نقرة واحدة",
    "other_experience": "تجربة أخرى",
    "years": "سنوات",
    "num_years": "{num} {num, plural, =1{عام} other{سنوات}}",
    "num_months": "{num} {num, plural, =1{شهر} other{الشهور}}",
    "gpa": "المعدل التراكمي",
    "minor": "تحت السن القانوني",
    "non_formal_programs": "برامج غير درجة",
    "test_scores": "نتائج الاختبار",
    "present": "حاضر",
    "resume": "استئنف",
    "github": "جيثب",
    "formerly": "سابقا",
    "exec_ed_cohort_title": "التعليم التنفيذي: {cohortTitle}"
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.candidate_list_card = window.locales.en.careers.candidate_list_card || {};window.locales.en.careers.candidate_list_card = {...window.locales.en.careers.candidate_list_card, ...{
                
    "new": "New",
    "new_match": "New Match",
    "new_message": "New Message",

                        
    "outstanding": "<i class=\"fa fa-star\"></i>&nbsp;Great Applicant",

                      
    "plus_n_more": "+{{num}} more",

             
    "status_very_interested": "Actively seeking a new position",
    "status_interested": "Passively seeking a new position",
    "status_neutral": "Open to connections",
    "status_not_interested": "Not actively looking",
    "fun_fact": "Fun Fact:",
    "more": "more",

           
    "experience": "Experience",
    "other_experience": "Other Experience",
    "education": "Education",
    "preferences": "Seeking",
    "skills": "Skills",
    "strengths": "Strengths",
    "position": "Position",
    "open_to_locations": "Open to {{numLocations}} locations (<span class=\"underline\">view</span>)",
    "open_flexible": "Open to most locations",
    "open_to_remote": "Open to Remote",
    "seeking": "{{roles}} roles in the {{industries}} industries",
    "seeking_singular": "{{roles}} roles in the {{industries}} industry",
    "seeking_or": " or ",
    "years": "years",
    "num_years": "{num} {num, plural, =1{year} other{years}}",
    "num_months": "{num} {num, plural, =1{month} other{months}}",
    "gpa": "GPA",
    "minor": "Minor",
    "non_formal_programs": "Non-Degree Programs",
    "test_scores": "Test Scores",
    "present": "Present",
    "resume": "Resume",
    "github": "Github",
    "formerly": "Formerly",
    "exec_ed_cohort_title": "Executive Education: {cohortTitle}",                                                

             
    "invite_to_connect": "<span>Invite to</span> Connect",
    "full_profile": "Full Profile",
    "save": "Save",
    "save_for_review": "Save for review later",
    "like": "Like",
    "hide": "Hide",
    "hide_from_browse": "Hide from all searches",
    "general_interest": "General Interest",
    "open": "Open",
    "open_in_tracker": "Open In Tracker",
    "connect_now": "Connect Now",
    "close": "Close",
    "close_connection": "Close Connection",
    "pass": "Pass",
    "pass_for_position": "Pass for this position",
    "saved_by": "Saved by",
    "connected_with": "Connected with",
    "one_click_reachout": "Reach out with one click"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.candidate_list_card = window.locales.es.careers.candidate_list_card || {};window.locales.es.careers.candidate_list_card = {...window.locales.es.careers.candidate_list_card, ...{
    "status_very_interested": "Buscando entusiastamente un nuevo puesto",
    "fun_fact": "Hecho de la diversión:",
    "more": "Más",
    "experience": "Experiencia",
    "education": "Educación",
    "skills": "Habilidades",
    "position": "Posición",
    "open_to_locations": "Abierto a {{numLocations}} ubicaciones ( <span class=\"underline\">ver )",
    "open_to_remote": "Abierto a Remoto",
    "invite_to_connect": "<span>Invitar a conectarse",
    "full_profile": "Perfil completo",
    "save": "Salvar",
    "like": "Me gusta",
    "hide": "Esconder",
    "preferences": "Buscando",
    "seeking_or": "o",
    "status_interested": "Pasivamente buscando una nueva posición",
    "status_neutral": "Abierto a las conexiones",
    "open_flexible": "Abierto a la mayoría de los lugares",
    "hide_from_browse": "Ocultar de todas las búsquedas",
    "seeking": "{{roles}} roles en las {{industries}} industrias",
    "seeking_singular": "{{roles}} roles en la {{industries}}",
    "strengths": "Fortalezas",
    "new": "Nuevo",
    "general_interest": "Interés general",
    "open": "Abierto",
    "open_in_tracker": "Abrir en rastreador",
    "connect_now": "Conecte ahora",
    "new_match": "Nuevo partido",
    "new_message": "Nuevo mensaje",
    "plus_n_more": "+ {{num}} más",
    "close": "Cerca",
    "close_connection": "Conexión cercana",
    "pass": "Pasar",
    "pass_for_position": "Pasar por este puesto",
    "status_not_interested": "No está buscando activamente",
    "save_for_review": "Guardar para revisión más tarde",
    "outstanding": "<i class=\"fa fa-star\"></i> Gran solicitante",
    "saved_by": "Guardado por",
    "connected_with": "Conectado con",
    "one_click_reachout": "Alcance con un clic",
    "other_experience": "Otra experiencia",
    "years": "años",
    "num_years": "{num} {num, plural, =1{año} other{años}}",
    "num_months": "{num} {num, plural, =1{mes} other{meses}}",
    "gpa": "GPA",
    "minor": "Menor",
    "non_formal_programs": "Programas sin título",
    "test_scores": "Resultados de las pruebas",
    "present": "Presente",
    "resume": "Currículum",
    "github": "Github",
    "formerly": "Antes",
    "exec_ed_cohort_title": "Educación ejecutiva: {cohortTitle}"
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.candidate_list_card = window.locales.it.careers.candidate_list_card || {};window.locales.it.careers.candidate_list_card = {...window.locales.it.careers.candidate_list_card, ...{
    "status_very_interested": "Entusiastamente alla ricerca di una nuova posizione",
    "fun_fact": "Fatto divertente:",
    "more": "Di Più",
    "experience": "Esperienza",
    "education": "Formazione scolastica",
    "skills": "Abilità",
    "position": "Posizione",
    "open_to_locations": "Apri a {{numLocations}} posizioni ( <span class=\"underline\">visualizza )",
    "open_to_remote": "Apri su remoto",
    "invite_to_connect": "<span>Invita a Connetti",
    "full_profile": "Profilo completo",
    "save": "Salvare",
    "like": "Piace",
    "hide": "Nascondere",
    "preferences": "Cerco",
    "seeking_or": "o",
    "status_interested": "Passivamente alla ricerca di una nuova posizione",
    "status_neutral": "Aperto alle connessioni",
    "open_flexible": "Aperto alla maggior parte delle posizioni",
    "hide_from_browse": "Nascondi da tutte le ricerche",
    "seeking": "{{roles}} nei {{industries}}",
    "seeking_singular": "{{roles}} ruoli nel settore {{industries}}",
    "strengths": "Punti di forza",
    "new": "Nuovo",
    "general_interest": "Interesse generale",
    "open": "Aperto",
    "open_in_tracker": "Apri nel Tracker",
    "connect_now": "Connetti ora",
    "new_match": "Nuova partita",
    "new_message": "Nuovo messaggio",
    "plus_n_more": "+ {{num}} altro",
    "close": "Vicino",
    "close_connection": "Chiudere la connessione",
    "pass": "Passaggio",
    "pass_for_position": "Passa per questa posizione",
    "status_not_interested": "Non guardando attivamente",
    "save_for_review": "Salva per la revisione più tardi",
    "outstanding": "<i class=\"fa fa-star\"></i> Grande candidato",
    "saved_by": "Salvato da",
    "connected_with": "Connesso con",
    "one_click_reachout": "Raggiungi con un clic",
    "other_experience": "Altra esperienza",
    "years": "anni",
    "num_years": "{num} {num, plural, =1{anno} other{anni}}",
    "num_months": "{num} {num, plural, =1{mese} other{mesi}}",
    "gpa": "GPA",
    "minor": "Minore",
    "non_formal_programs": "Programmi non laurea",
    "test_scores": "Punteggi dei test",
    "present": "Presente",
    "resume": "Curriculum vitae",
    "github": "Github",
    "formerly": "precedentemente",
    "exec_ed_cohort_title": "Formazione esecutiva: {cohortTitle}"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.candidate_list_card = window.locales.zh.careers.candidate_list_card || {};window.locales.zh.careers.candidate_list_card = {...window.locales.zh.careers.candidate_list_card, ...{
    "status_very_interested": "主动寻找新职位",           
    "fun_fact": "有趣事实：",           
    "more": "更多",           
    "experience": "经历",           
    "education": "教育背景",           
    "skills": "技能",           
    "position": "职位",           
    "open_to_locations": "接受{{numLocations}}地点（<span class=\"underline\">查看</span>）",           
    "open_to_remote": "接受远程工作",           
    "invite_to_connect": "<span>邀请</span>加入",           
    "full_profile": "完整简介",           
    "save": "保存",           
    "like": "点赞",           
    "hide": "隐藏",           
    "preferences": "寻求",           
    "seeking_or": " 或者 ",           
    "status_interested": "被动寻找新职位",           
    "status_neutral": "接受联系",           
    "open_flexible": "接受大部分地点",           
    "hide_from_browse": "隐藏所有搜索",           
    "seeking": "在{{industries}}行业中的{{roles}}角色",           
    "seeking_singular": "在{{industries}}行业中的{{roles}}角色",           
    "strengths": "优势",           
    "new": "新",           
    "general_interest": "一般兴趣",           
    "open": "打开",           
    "open_in_tracker": "在跟进版块打开",           
    "connect_now": "立即连接",           
    "new_match": "最新匹配",           
    "new_message": "最新消息",           
    "plus_n_more": "+{{num}}更多",           
    "close": "关闭",           
    "close_connection": "关闭联系",           
    "pass": "通过",           
    "pass_for_position": "通过此职位",           
    "status_not_interested": "非主动寻找",           
    "save_for_review": "保存以备后续查看",           
    "outstanding": "<i class=\"fa fa-star\"></i>&nbsp;优秀申请人",           
    "saved_by": "保存",           
    "connected_with": "联系",           
    "one_click_reachout": "一键直达",           
    "other_experience": "其他经历",           
    "years": "年数",           
    "num_years": "{num} {num, plural, =1{年} other{年}}",           
    "num_months": "{num} {num, plural, =1{月} other{月}}",           
    "gpa": "平均绩点",           
    "minor": "辅修",           
    "non_formal_programs": "非学位课程",           
    "test_scores": "成绩",           
    "present": "现在",           
    "resume": "简历",           
    "github": "Github",           
    "formerly": "以前",           
    "exec_ed_cohort_title": "高管教育： {cohortTitle}"
}
};