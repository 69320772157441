import { type CurrentUserIguanaObject } from 'Users';
import { type TranslationHelperClass } from 'Translation';
import { targetBrandConfig, Brand, type BrandConfig, fetchBrandConfig } from 'AppBranding';
import { type CohortClass } from 'Cohorts';
import { type ProgramType } from 'Program';
import {
    ProgramPreferencesStateKey,
    type ProgramPreferencesBlurbLogoConfig,
    type ProgramPreferencesConfig,
} from './Careers.types';

const getBlurbLogoConfig = (brandConfig: BrandConfig): ProgramPreferencesBlurbLogoConfig => ({
    alt: brandConfig.brandNameShort,
    classes: [brandConfig.brandStyleClass],
    imgSrc: brandConfig.programPreferencesLogo,
    imgStyles: brandConfig.programPreferencesLogoImgStyles,
});

// eslint-disable-next-line max-lines-per-function
export default function programPreferencesConfigs(
    $injector: ng.auto.IInjectorService,
    user: CurrentUserIguanaObject,
    programType: ProgramType,
): Record<ProgramPreferencesStateKey, ProgramPreferencesConfig> {
    const _Cohort: CohortClass = $injector.get('Cohort');
    const supportsValarOptIn = _Cohort.supportsValarOptIn(programType);
    const TranslationHelper = $injector.get<TranslationHelperClass>('TranslationHelper');
    const programPreferencesTranslationHelper = new TranslationHelper(
        `careers.edit_career_profile.program_preferences_form`,
    );
    const valarBrandConfig = fetchBrandConfig(Brand.valar);
    const userBrandConfig = targetBrandConfig(user);

    return {
        [ProgramPreferencesStateKey.generic_minimum_education_not_met]: {
            saveButtonsDisabled: true,
            showValarOptIn: () => false,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('minimum_education_requirements'),
                    content: programPreferencesTranslationHelper.get('minimum_education_requirements_desc_generic', {
                        brandName: userBrandConfig.brandNameShort,
                        programTitle: _Cohort.fullTitle(programType),
                    }),
                },
            ],
        },
        [ProgramPreferencesStateKey.business_admin_minimum_experience]: {
            showValarOptIn: () => supportsValarOptIn,
            showInterviewPref: true,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('mba_or_emba'),
                    classes: [userBrandConfig.brandStyleClass],
                    content: programPreferencesTranslationHelper.get('considered_for_mba', {
                        brandName: userBrandConfig.brandNameShort,
                    }),
                    logoConfig: getBlurbLogoConfig(userBrandConfig),
                },
                {
                    show: supportsValarOptIn,
                    classes: [valarBrandConfig.brandStyleClass],
                    content: programPreferencesTranslationHelper.get('valar_mba_desc'),
                    logoConfig: getBlurbLogoConfig(valarBrandConfig),
                },
            ],
        },
        [ProgramPreferencesStateKey.business_admin_younger_with_low_experience]: {
            showValarOptIn: () => supportsValarOptIn,
            showInterviewPref: true,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('mba_or_emba'),
                    classes: [userBrandConfig.brandStyleClass],
                    content: programPreferencesTranslationHelper.get('considered_for_mba', {
                        brandName: userBrandConfig.brandNameShort,
                    }),
                    logoConfig: getBlurbLogoConfig(userBrandConfig),
                },
                {
                    show: supportsValarOptIn,
                    classes: [valarBrandConfig.brandStyleClass],
                    content: programPreferencesTranslationHelper.get('valar_mba_desc'),
                    logoConfig: getBlurbLogoConfig(valarBrandConfig),
                },
            ],
        },
        [ProgramPreferencesStateKey.business_admin_older_with_low_experience]: {
            showValarOptIn: () => supportsValarOptIn,
            showInterviewPref: true,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('mba_or_emba'),
                    content: programPreferencesTranslationHelper.get('registered_as_one_but_considered_for_both', {
                        registeredAs: 'MBA',
                    }),
                    logoConfig: getBlurbLogoConfig(userBrandConfig),
                },
                {
                    show: supportsValarOptIn,
                    content: programPreferencesTranslationHelper.get('valar_mba_and_emba_desc_mba_default'),
                    logoConfig: getBlurbLogoConfig(valarBrandConfig),
                },
            ],
        },
        [ProgramPreferencesStateKey.business_admin_middle_experience]: {
            showValarOptIn: () => supportsValarOptIn,
            showInterviewPref: true,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('mba_or_emba'),
                    content: programPreferencesTranslationHelper.get('registered_as_one_but_considered_for_both', {
                        registeredAs: 'Executive MBA',
                    }),
                    logoConfig: getBlurbLogoConfig(userBrandConfig),
                },
                {
                    show: supportsValarOptIn,
                    content: programPreferencesTranslationHelper.get('valar_mba_and_emba_desc_emba_default'),
                    logoConfig: getBlurbLogoConfig(valarBrandConfig),
                },
            ],
        },
        [ProgramPreferencesStateKey.business_admin_younger_with_high_experience]: {
            showValarOptIn: () => supportsValarOptIn,
            showInterviewPref: true,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('mba_or_emba'),
                    content: programPreferencesTranslationHelper.get('registered_as_one_but_considered_for_both', {
                        registeredAs: 'Executive MBA',
                    }),
                    logoConfig: getBlurbLogoConfig(userBrandConfig),
                },
                {
                    show: supportsValarOptIn,
                    content: programPreferencesTranslationHelper.get('valar_mba_and_emba_desc_emba_default'),
                    logoConfig: getBlurbLogoConfig(valarBrandConfig),
                },
            ],
        },
        [ProgramPreferencesStateKey.business_admin_older_with_high_experience]: {
            showValarOptIn: () => supportsValarOptIn,
            showInterviewPref: true,
            blurbs: [
                {
                    show: true,
                    heading: programPreferencesTranslationHelper.get('mba_or_emba'),
                    content: programPreferencesTranslationHelper.get('considered_for_emba', {
                        brandName: userBrandConfig.brandNameShort,
                    }),
                    logoConfig: getBlurbLogoConfig(userBrandConfig),
                },
                {
                    show: supportsValarOptIn,
                    content: programPreferencesTranslationHelper.get('valar_emba_desc'),
                    logoConfig: getBlurbLogoConfig(valarBrandConfig),
                },
            ],
        },
    };
}
