window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.tuition_and_scholarships_form = window.locales.am.careers.edit_career_profile.tuition_and_scholarships_form || {};window.locales.am.careers.edit_career_profile.tuition_and_scholarships_form = {...window.locales.am.careers.edit_career_profile.tuition_and_scholarships_form, ...{
    "decline_scholarships": "ስኮላርሺፕ ውድቅ አድርግ",
    "decline_scholarships_option_desc": "ብዙ የሚገባቸው ዓለም አቀፍ ተማሪዎች እና ሽልማቶች፣ በቂ ሀብቶች ያላቸው እጩዎች የስኮላርሺፕ ግምትን መቀነስ እንዲያስቡ እንጠይቃለን።",
    "apply_for_merit_and_need_scholarships": "ለሽልማት እና ለፍላጎት ስኮላርሺፕ ያመልክቱ",
    "merit": "ክብር",
    "need": "ያስፈልጋል",
    "merit_desc": "በቁልፍ አለምአቀፍ ከተሞች፣ ኢንዱስትሪዎች እና ሚናዎች ላሳየ የአካዳሚክ አፈጻጸም እና የስራ ስኬት በሜሪት ላይ የተመሰረተ ሽልማት።",
    "impact_desc": "በብዝሃነት፣ ፍትሃዊነት እና ማካተት (DEI)፣ በማህበራዊ ተፅእኖ ተነሳሽነት እና በአመራር አቅም ላይ የተመሰረተ ሽልማት።",
    "recruitment_desc": "በአሰሪ አጋሮቻችን ውስጥ ስለ ሥራ ለመስማት ፍላጎት ላላቸው ተማሪዎች የቀረበ። ለእነዚህ እድሎች ምላሽ የመስጠት ግዴታ የለበትም.",
    "need_desc": "እባኮትን በገንዘብ ችግር ውስጥ ወይም በአለም አቀፍ የኢኮኖሚ ችግር ካለበት ብቻ ይምረጡ። {{instNameShort}} በእነዚህ ውስን ገንዘቦች በዓለም ዙሪያ የትምህርት ተደራሽነትን ለማስፋት ቁርጠኛ ነው።",
    "need_statement_desc": "እባክዎ የገንዘብ ድጋፍ ጥያቄዎን ለመደገፍ ማንኛውንም ልዩ ሁኔታዎች ይግለጹ።",
    "confirm": "አረጋግጥ",
    "post_decline_modal_close_and_select": "ዝጋ እና ስኮላርሺፕ ይምረጡ",
    "employer_partner_desc": "የአሰሪዎ ወይም የማህበራችን አጋሮች ሰራተኛ ወይም አባል ከሆኑ ይህንን የነፃ ትምህርት ዕድል ያገኛሉ።",
    "admission_not_affected_by_scholarship_applications": "የመግቢያ እድሎች በስኮላርሺፕ ማመልከቻዎች አይነኩም.",
    "post_decline_modal_body": "የስኮላርሺፕ ግምት እየቀነሱ ነው። ይህ ለመመዝገብ በእነዚህ ውስን ገንዘቦች ላይ የሚተማመኑ ልዩ ዓለም አቀፍ ተማሪዎችን ይደግፋል።",
    "decline_or_apply_for_scholarships": "ውድቅ ያድርጉ ወይም ለስኮላርሺፕ ያመልክቱ",
    "alumni_desc": "እንደ የኳንቲክ እጩ በፕሮግራሙ ውስጥ ከገቡ ለዚህ የ$4,500 USD ሽልማት ቀድሞ ተቀባይነት አግኝተዋል",
    "scholarship_eligibility": "የስኮላርሺፕ ብቁነት",
    "scholarship_eligibility_sub_heading": "የሚፈልጓቸውን ሁሉንም ስኮላርሺፖች ይምረጡ።",
    "scholarship_eligibility_desc": "ይህን መተግበሪያ ካስገቡ በኋላ፣ በእርስዎ {{brandNameShort}} ዳሽቦርድ ውስጥ 6 ነፃ ኮርሶችን ያገኛሉ። በይነተገናኝ ትምህርታችንን ያሳያሉ እና የሙሉ ዲግሪ አካል ይሆናሉ። <strong>ለስኮላርሺፕ ብቁ ለመሆን ቢያንስ አንዱን ማጠናቀቅ ያስፈልግዎታል።</strong>"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.tuition_and_scholarships_form = window.locales.ar.careers.edit_career_profile.tuition_and_scholarships_form || {};window.locales.ar.careers.edit_career_profile.tuition_and_scholarships_form = {...window.locales.ar.careers.edit_career_profile.tuition_and_scholarships_form, ...{
    "decline_scholarships": "رفض المنح الدراسية",
    "decline_scholarships_option_desc": "مع وجود العديد من الطلاب العالميين المستحقين والجوائز المحدودة، نطلب من المرشحين الذين لديهم موارد كافية التفكير في رفض المنح الدراسية.",
    "apply_for_merit_and_need_scholarships": "التقدم بطلب للحصول على منحة الجدارة والحاجة",
    "merit": "استحقاق",
    "need": "يحتاج",
    "merit_desc": "جائزة على أساس الجدارة للأداء الأكاديمي الواضح والإنجاز الوظيفي في المدن والصناعات والأدوار العالمية الرئيسية.",
    "impact_desc": "جائزة على أساس الجدارة على أساس التنوع والإنصاف والشمول (DEI)، ومبادرات التأثير الاجتماعي، وإمكانات القيادة.",
    "recruitment_desc": "يُقدم للطلاب المهتمين بمعرفة المزيد عن الوظائف لدى شركائنا من أصحاب العمل. ليس هناك أي التزام للرد على هذه الفرص.",
    "need_desc": "يرجى تحديد هذا فقط إذا كنت تواجه ضائقة مالية أو في منطقة عالمية تعاني من عيوب اقتصادية. تلتزم شركة {{instNameShort}} بتوسيع نطاق الوصول إلى التعليم في جميع أنحاء العالم بهذه الأموال المحدودة.",
    "need_statement_desc": "يرجى توضيح أي ظروف خاصة لدعم طلبك للحصول على مساعدة مالية.",
    "confirm": "يتأكد",
    "post_decline_modal_close_and_select": "إغلاق واختيار المنح الدراسية",
    "employer_partner_desc": "سوف تتلقى هذه المنحة إذا كنت موظفًا أو عضوًا في أحد أصحاب العمل أو شركاء الجمعية لدينا.",
    "admission_not_affected_by_scholarship_applications": "لا تتأثر فرص القبول بطلبات المنح الدراسية.",
    "post_decline_modal_body": "أنت ترفض النظر في المنح الدراسية. وهذا يدعم الطلاب العالميين الاستثنائيين الذين يعتمدون على هذه الأموال المحدودة للتسجيل.",
    "decline_or_apply_for_scholarships": "رفض أو التقدم بطلب للحصول على المنح الدراسية",
    "alumni_desc": "باعتبارك مرشحًا لـ Quantic، فقد حصلت على موافقة مسبقة للحصول على هذه الجائزة البالغة 4500 دولار أمريكي إذا تم قبولك في البرنامج",
    "scholarship_eligibility": "أهلية المنحة الدراسية",
    "scholarship_eligibility_sub_heading": "قم باختيار جميع المنح الدراسية التي تهمك.",
    "scholarship_eligibility_desc": "بعد تقديم هذا الطلب، ستتمكن من الوصول إلى 6 دورات مجانية في لوحة معلومات {{brandNameShort}} . وهي تعرض التعلم التفاعلي لدينا وتصبح جزءًا من الدرجة الكاملة. <strong>ستحتاج إلى إكمال دورة واحدة على الأقل لتكون مؤهلاً للحصول على المنح الدراسية.</strong>"
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.tuition_and_scholarships_form = window.locales.en.careers.edit_career_profile.tuition_and_scholarships_form || {};window.locales.en.careers.edit_career_profile.tuition_and_scholarships_form = {...window.locales.en.careers.edit_career_profile.tuition_and_scholarships_form, ...{
               
    "decline_scholarships": "Decline Scholarships",
    "decline_scholarships_option_desc": "With many deserving global students and limited awards, we ask candidates with sufficient resources to consider declining scholarship consideration.",
    "apply_for_merit_and_need_scholarships": "Apply for Merit and Need Scholarships",
    "decline_or_apply_for_scholarships": "Decline or Apply for Scholarships",
    "scholarship_eligibility": "Scholarship Eligibility",
    "scholarship_eligibility_sub_heading": "Select all scholarships you are interested in.",

    "merit": "Merit",
    "need": "Need",

                   
    "merit_desc": "Merit-based award for demonstrated academic performance and career achievement in key global cities, industries, and roles.",
    "alumni_desc": "As a Quantic nominee you have been pre-approved for this $4,500 USD award if admitted to the program",
    "employer_partner_desc": "You will receive this scholarship if you are an employee or member of one of our employer or association partners.",
    "impact_desc": "Merit-based award on the basis of Diversity, Equity, and Inclusion (DEI), social impact initiatives, and leadership potential.",
    "recruitment_desc": "Offered to students interested in hearing about jobs at our employer partners. There is no obligation to respond to these opportunities.",
    "need_desc": "Please select this only if in financial hardship or in a global region with economic disadvantages. {{instNameShort}} is committed to broadening access to education worldwide with these limited funds.",

           
    "need_statement_desc": "Please outline any special circumstances to support your request for financial aid.",
    "confirm": "Confirm",
    "post_decline_modal_body": "You are declining scholarship consideration. This supports the exceptional global students who rely on these limited funds to enroll.",
    "post_decline_modal_close_and_select": "Close and Select Scholarships",
    "admission_not_affected_by_scholarship_applications": "Chances of admission are not affected by scholarship applications.",
    "scholarship_eligibility_desc": "After submitting this application, you will gain access to 6 free courses in your {{brandNameShort}} dashboard. They showcase our interactive learning and become part of the full degree. <strong>You will need to complete at least one to be eligible for scholarships.</strong>"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.tuition_and_scholarships_form = window.locales.es.careers.edit_career_profile.tuition_and_scholarships_form || {};window.locales.es.careers.edit_career_profile.tuition_and_scholarships_form = {...window.locales.es.careers.edit_career_profile.tuition_and_scholarships_form, ...{
    "decline_scholarships": "Rechazar becas",
    "decline_scholarships_option_desc": "Con muchos estudiantes globales merecedores y premios limitados, pedimos a los candidatos con recursos suficientes que consideren rechazar la consideración de becas.",
    "apply_for_merit_and_need_scholarships": "Solicite becas por mérito y necesidad",
    "merit": "Mérito",
    "need": "Necesidad",
    "merit_desc": "Premio basado en el mérito por desempeño académico demostrado y logros profesionales en ciudades, industrias y roles globales clave.",
    "impact_desc": "Premio al mérito en función de la Diversidad, Equidad e Inclusión (DEI), iniciativas de impacto social y potencial de liderazgo.",
    "recruitment_desc": "Ofrecido a estudiantes interesados en escuchar sobre trabajos en nuestros socios empleadores. No hay obligación de responder a estas oportunidades.",
    "need_desc": "Seleccione esta opción solo si tiene dificultades financieras o se encuentra en una región global con desventajas económicas. {{instNameShort}} se compromete a ampliar el acceso a la educación en todo el mundo con estos fondos limitados.",
    "need_statement_desc": "Describa cualquier circunstancia especial que respalde su solicitud de ayuda financiera.",
    "confirm": "Confirmar",
    "post_decline_modal_close_and_select": "Cerrar y seleccionar becas",
    "employer_partner_desc": "Recibirá esta beca si es empleado o miembro de uno de nuestros socios empleadores o asociaciones.",
    "admission_not_affected_by_scholarship_applications": "Las posibilidades de admisión no se ven afectadas por las solicitudes de becas.",
    "post_decline_modal_body": "Estás rechazando la consideración de beca. Esto apoya a los estudiantes globales excepcionales que dependen de estos fondos limitados para inscribirse.",
    "decline_or_apply_for_scholarships": "Rechazar o solicitar becas",
    "alumni_desc": "Como nominado de Quantic, ha sido preaprobado para este premio de $4500 USD si es admitido en el programa.",
    "scholarship_eligibility": "Elegibilidad para becas",
    "scholarship_eligibility_sub_heading": "Selecciona todas las becas que te interesan.",
    "scholarship_eligibility_desc": "Después de enviar esta solicitud, obtendrás acceso a 6 cursos gratuitos en tu panel de control de {{brandNameShort}} . Estos cursos muestran nuestro aprendizaje interactivo y forman parte de la carrera completa. <strong>Deberás completar al menos uno para ser elegible para las becas.</strong>"
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.tuition_and_scholarships_form = window.locales.it.careers.edit_career_profile.tuition_and_scholarships_form || {};window.locales.it.careers.edit_career_profile.tuition_and_scholarships_form = {...window.locales.it.careers.edit_career_profile.tuition_and_scholarships_form, ...{
    "decline_scholarships": "Rifiuto borse di studio",
    "decline_scholarships_option_desc": "Con molti studenti meritevoli in tutto il mondo e premi limitati, chiediamo ai candidati con risorse sufficienti di considerare di rifiutare la considerazione della borsa di studio.",
    "apply_for_merit_and_need_scholarships": "Richiedi borse di studio per merito e necessità",
    "merit": "Merito",
    "need": "Bisogno",
    "merit_desc": "Premio basato sul merito per risultati accademici dimostrati e risultati di carriera in città, industrie e ruoli chiave a livello globale.",
    "impact_desc": "Premio basato sul merito sulla base di Diversità, Equità e Inclusione (DEI), iniziative di impatto sociale e potenziale di leadership.",
    "recruitment_desc": "Offerto agli studenti interessati a conoscere le offerte di lavoro presso i nostri datori di lavoro partner. Non vi è alcun obbligo di rispondere a queste opportunità.",
    "need_desc": "Seleziona questa opzione solo se ti trovi in difficoltà finanziarie o in una regione globale con svantaggi economici. {{instNameShort}} si impegna ad ampliare l'accesso all'istruzione in tutto il mondo con questi fondi limitati.",
    "need_statement_desc": "Si prega di descrivere eventuali circostanze particolari a sostegno della vostra richiesta di aiuto finanziario.",
    "confirm": "Confermare",
    "post_decline_modal_close_and_select": "Chiudi e seleziona borse di studio",
    "employer_partner_desc": "Riceverai questa borsa di studio se sei un dipendente o membro di uno dei nostri datori di lavoro o associazioni partner.",
    "admission_not_affected_by_scholarship_applications": "Le possibilità di ammissione non sono influenzate dalle domande di borsa di studio.",
    "post_decline_modal_body": "Stai rifiutando la considerazione della borsa di studio. Ciò supporta gli eccezionali studenti globali che fanno affidamento su questi fondi limitati per iscriversi.",
    "decline_or_apply_for_scholarships": "Rifiuta o richiedi borse di studio",
    "alumni_desc": "In qualità di candidato Quantic, sei stato pre-approvato per questo premio di $ 4.500 USD se ammesso al programma",
    "scholarship_eligibility": "Idoneità alla borsa di studio",
    "scholarship_eligibility_sub_heading": "Seleziona tutte le borse di studio a cui sei interessato.",
    "scholarship_eligibility_desc": "Dopo aver inviato questa domanda, avrai accesso a 6 corsi gratuiti nella tua dashboard {{brandNameShort}} . Essi mostrano il nostro apprendimento interattivo e diventano parte della laurea completa. <strong>Dovrai completarne almeno uno per essere idoneo alle borse di studio.</strong>"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.tuition_and_scholarships_form = window.locales.zh.careers.edit_career_profile.tuition_and_scholarships_form || {};window.locales.zh.careers.edit_career_profile.tuition_and_scholarships_form = {...window.locales.zh.careers.edit_career_profile.tuition_and_scholarships_form, ...{
    "decline_scholarships": "拒绝奖学金",
    "decline_scholarships_option_desc": "由于全球学生众多，且奖项有限，我们要求拥有足够资源的候选人考虑拒绝奖学金。",
    "apply_for_merit_and_need_scholarships": "申请优秀和需要奖学金",
    "merit": "优点",
    "need": "需要",
    "merit_desc": "择优奖励，奖励在全球主要城市、行业和职位中表现出的学术表现和职业成就。",
    "impact_desc": "基于多元化、公平和包容性 (DEI)、社会影响力举措和领导潜力的绩效奖励。",
    "recruitment_desc": "提供给有兴趣了解我们雇主合作伙伴的工作机会的学生。没有义务回应这些机会。",
    "need_desc": "请仅在经济困难或处于经济不利的全球地区时选择此选项。 {{instNameShort}}致力于利用这些有限的资金扩大全球受教育的机会。",
    "need_statement_desc": "请概述支持您的经济援助请求的任何特殊情况。",
    "confirm": "确认",
    "post_decline_modal_close_and_select": "关闭并选择奖学金",
    "employer_partner_desc": "如果您是我们雇主或协会合作伙伴之一的雇员或成员，您将获得这项奖学金。",
    "admission_not_affected_by_scholarship_applications": "入学机会不受奖学金申请的影响。",
    "post_decline_modal_body": "您正在拒绝奖学金考虑。这为依赖这些有限资金入学的优秀全球学生提供了支持。",
    "decline_or_apply_for_scholarships": "拒绝或申请奖学金",
    "alumni_desc": "作为 Quantic 提名人，如果您被录取加入该计划，您将被预先批准获得 4,500 美元的奖励",
    "scholarship_eligibility": "奖学金资格",
    "scholarship_eligibility_sub_heading": "选择您感兴趣的所有奖学金。",
    "scholarship_eligibility_desc": "提交此申请后，您将可以在{{brandNameShort}}仪表板中免费访问 6 门课程。它们展示了我们的互动学习，并成为完整学位的一部分。<strong>您需要完成至少一门课程才有资格获得奖学金。</strong>"
}
};