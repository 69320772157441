window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.resume_and_links_form = window.locales.am.careers.edit_career_profile.resume_and_links_form || {};window.locales.am.careers.edit_career_profile.resume_and_links_form = {...window.locales.am.careers.edit_career_profile.resume_and_links_form, ...{
    "li_profile_url": "ይፋዊ የሊንኪዲን ዩአርኤል",           
    "website_url": "የግል ድረገፅ",           
    "blog_url": "የጦማር (ብሎገ) ዩአርኤል",           
    "tw_profile_url": "ትዊተር ግለ-መገለጫ ዩአርኤል",           
    "fb_profile_url": "ፌስቡክ ግለ-መገለጫ ዩአርኤል",           
    "fb_connect": "ፌስቡክ",           
    "upload": "ስቀል",           
    "uploaded_date": "ተሰቅሏል {{uploadDate}}",           
    "connect": "አገናኝ",           
    "github_profile_url": "GitHub ግለ-መገለጫ ዩአርኤል",           
    "resume_caption": "የሥራ ልምድ ካለዎት፣ እባክዎ እንደ ፒዲኤፍ ይሰቀሉት።",           
    "add_more_links": "ተጨማሪ አገናኞችን ያክሉ",
    "show_less": "ያነሰ አሳይ",
    "resume_cv": "የሥራ ልምድ/ሲቪ (አማራጭ)",
    "add_relevant_links": "እባክዎ ከዚህ በታች ማንኛውንም ተዛማጅ ሙያዊ አገናኞችን ያክሉ።"
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.resume_and_links_form = window.locales.ar.careers.edit_career_profile.resume_and_links_form || {};window.locales.ar.careers.edit_career_profile.resume_and_links_form = {...window.locales.ar.careers.edit_career_profile.resume_and_links_form, ...{
    "li_profile_url": "عنوان URL العام لموقع LinkedIn",           
    "website_url": "الموقع الشخصي",           
    "blog_url": "عنوان URL المدونة",           
    "tw_profile_url": "عنوان URL لملف تويتر الشخصي",           
    "fb_profile_url": "عنوان URL لملف فيس بوك الشخصي",           
    "fb_connect": "فيس بوك",           
    "upload": "تحميل",           
    "uploaded_date": "تم التحميل {{uploadDate}}",           
    "connect": "اتصال",           
    "facebook": "فيس بوك",
    "instagram": "الانستقرام",
    "friend": "من صديق",
    "search_engine": "محرك البحث",
    "other": "آخر",
    "github_profile_url": "جيثوب الملف الشخصي ورل",
    "resume_caption": "إذا كانت لديك سيرة ذاتية ، فيرجى تحميلها بصيغة PDF.",
    "add_more_links": "إضافة المزيد من الروابط",
    "show_less": "عرض أقل",
    "resume_cv": "السيرة الذاتية (اختياري)",
    "add_relevant_links": "يرجى إضافة أي روابط مهنية ذات صلة أدناه."
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.resume_and_links_form = window.locales.en.careers.edit_career_profile.resume_and_links_form || {};window.locales.en.careers.edit_career_profile.resume_and_links_form = {...window.locales.en.careers.edit_career_profile.resume_and_links_form, ...{
    "li_profile_url": "Public LinkedIn URL",
    "resume_cv": "Resume / CV (optional)",
    "website_url": "Personal Website",
    "blog_url": "Blog URL",
    "tw_profile_url": "Twitter Profile URL",
    "fb_profile_url": "Facebook Profile URL",
    "fb_connect": "Facebook",
    "upload": "Upload",
    "uploaded_date": "Uploaded {{uploadDate}}",
    "connect": "Connect",
    "github_profile_url": "GitHub Profile URL",
    "resume_caption": "If you have a resume, please upload it as a PDF.",
    "add_more_links": "Add More Links",
    "show_less": "Show Less",
    "add_relevant_links": "Please add any relevant professional links below."
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.resume_and_links_form = window.locales.es.careers.edit_career_profile.resume_and_links_form || {};window.locales.es.careers.edit_career_profile.resume_and_links_form = {...window.locales.es.careers.edit_career_profile.resume_and_links_form, ...{
    "li_profile_url": "URL pública de LinkedIn ",           
    "website_url": "Sitio web personal",           
    "blog_url": "URL del blog",           
    "tw_profile_url": "URL del perfil de Twitter",           
    "fb_profile_url": "URL del perfil de Facebook",           
    "fb_connect": "Facebook",           
    "upload": "Subir",           
    "uploaded_date": "Subido el {{uploadDate}}",           
    "connect": "Conectar",           
    "facebook": "Facebook",
    "instagram": "Instagram",
    "friend": "De un amigo",
    "search_engine": "Buscador",
    "other": "Otro",
    "github_profile_url": "URL del perfil de GitHub",
    "resume_caption": "Si tiene un currículum, cárguelo como PDF.",
    "add_more_links": "Agregar más enlaces",
    "show_less": "Muestra menos",
    "resume_cv": "Currículum vitae/CV (opcional)",
    "add_relevant_links": "Agregue cualquier enlace profesional relevante a continuación."
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.resume_and_links_form = window.locales.it.careers.edit_career_profile.resume_and_links_form || {};window.locales.it.careers.edit_career_profile.resume_and_links_form = {...window.locales.it.careers.edit_career_profile.resume_and_links_form, ...{
    "li_profile_url": "URL LinkedIn Pubblico",           
    "website_url": "Sito web Personale",           
    "blog_url": "URL Blog",           
    "tw_profile_url": "URL Profilo Twitter",           
    "fb_profile_url": "URL Profilo Facebook",           
    "fb_connect": "Facebook",           
    "upload": "Carica",           
    "uploaded_date": "Caricato {{uploadDate}}",           
    "connect": "Mettiti in contatto",           
    "github_profile_url": "URL del profilo GitHub",
    "resume_caption": "Se hai un curriculum, si prega di caricarlo come PDF.",
    "add_more_links": "Aggiungi altri collegamenti",
    "show_less": "Mostra meno",
    "resume_cv": "Curriculum vitae/CV (facoltativo)",
    "add_relevant_links": "Si prega di aggiungere di seguito eventuali collegamenti professionali pertinenti."
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.resume_and_links_form = window.locales.zh.careers.edit_career_profile.resume_and_links_form || {};window.locales.zh.careers.edit_career_profile.resume_and_links_form = {...window.locales.zh.careers.edit_career_profile.resume_and_links_form, ...{
    "li_profile_url": "公共LinkedIn网址",           
    "website_url": "个人网站",           
    "blog_url": "博客网址",           
    "tw_profile_url": "Twitter的个人主页网址",           
    "fb_profile_url": "Facebook个人主页网址",           
    "fb_connect": "Facebook",           
    "upload": "上传",           
    "uploaded_date": "已上传 {{uploadDate}}",           
    "connect": "连接",           
    "facebook": "Facebook的",
    "instagram": "Instagram的",
    "friend": "从朋友",
    "search_engine": "搜索引擎",
    "other": "其他",
    "github_profile_url": "GitHub简介网址",           
    "resume_caption": "请将你的简历以PDF格式上传（如有）。",           
    "add_more_links": "添加更多链接",
    "show_less": "显示较少",
    "resume_cv": "简历/履历（可选）",
    "add_relevant_links": "请在下面添加任何相关的专业链接。"
}
};