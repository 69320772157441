window.locales = window.locales || {};window.locales.am = window.locales.am || {};window.locales.am.careers = window.locales.am.careers || {};window.locales.am.careers.edit_career_profile = window.locales.am.careers.edit_career_profile || {};window.locales.am.careers.edit_career_profile.work_experience_detail = window.locales.am.careers.edit_career_profile.work_experience_detail || {};window.locales.am.careers.edit_career_profile.work_experience_detail = {...window.locales.am.careers.edit_career_profile.work_experience_detail, ...{
    "enter_responsibilities": "እባክዎ በ 1 እና 3 ኃላፊነቶች ወይም ስኬቶች (ከፍተኛው 120 አሀዞች) መካከል ያስገቡ፡-",           
    "enter_responsibilities_non_english": "እባክዎን በ1 እና 3 ኃላፊነቶች ወይም ስኬቶች መካከል በእንግሊዝኛ (ከፍተኛ 120 ቁምፊዎች) ያስገቡ።",           
    "add_employment": "ሌላ የስራ ልምድ አክል",           
    "add_employment_first": "የስራ ልምድ አክል",           
    "organization": "ድርጅት",           
    "job_title": "የስራ መደቡ መጠሪያ",           
    "start": "ጀምር",           
    "end": "አብቃ",           
    "responsibilities_placeholder": "ለዲጂታል ግብይት ቡድን የማህበራዊ ሚዲያ ጥረትን አስጀምሯል።",           
    "change_from_full_time": "ወደ የትርፍ ጊዜ ልምዶች ውሰድ",           
    "change_from_part_time": "ወደ የሙሉ ጊዜ ተሞክሮዎች ውሰድ"           
}
};window.locales.ar = window.locales.ar || {};window.locales.ar.careers = window.locales.ar.careers || {};window.locales.ar.careers.edit_career_profile = window.locales.ar.careers.edit_career_profile || {};window.locales.ar.careers.edit_career_profile.work_experience_detail = window.locales.ar.careers.edit_career_profile.work_experience_detail || {};window.locales.ar.careers.edit_career_profile.work_experience_detail = {...window.locales.ar.careers.edit_career_profile.work_experience_detail, ...{
    "enter_responsibilities": "يرجى إدخال ما يصل إلى 3 مسؤوليات أو إنجازات خاصة بك (بحد أقصى 75 حرفاً لكل واحدة منهما):",           
    "enter_responsibilities_non_english": "الرجاء إدخال ما بين 1 و 3 مسؤوليات أو إنجازات ، باللغة الإنجليزية (بحد أقصى 120 حرفًا):",           
    "organization": "المؤسسة",           
    "job_title": "المسمى الوظيفي",           
    "start": "البداية",           
    "end": "النهاية",           
    "add_employment": "إضافة خبرة العمل آخر",
    "responsibilities_placeholder": "أطلقت جهود وسائل الإعلام الاجتماعية لفريق التسويق الرقمي.",
    "change_from_full_time": "الانتقال إلى تجارب دوام جزئي",
    "change_from_part_time": "الانتقال إلى تجارب الوقت الكامل",
    "add_employment_first": "أضف تجربة عمل"
}
};window.locales.en = window.locales.en || {};window.locales.en.careers = window.locales.en.careers || {};window.locales.en.careers.edit_career_profile = window.locales.en.careers.edit_career_profile || {};window.locales.en.careers.edit_career_profile.work_experience_detail = window.locales.en.careers.edit_career_profile.work_experience_detail || {};window.locales.en.careers.edit_career_profile.work_experience_detail = {...window.locales.en.careers.edit_career_profile.work_experience_detail, ...{
    "enter_responsibilities": "Please enter between 1 and 3 responsibilities or achievements (max 120 characters):",
    "enter_responsibilities_non_english": "Please enter between 1 and 3 responsibilities or achievements, in English (max 120 characters):",
    "add_employment": "Add Another Work Experience",
    "add_employment_first": "Add a Work Experience",
    "organization": "Organization",
    "job_title": "Job Title",
    "start": "Start",
    "end": "End",
    "responsibilities_placeholder": "Launched the social media effort for the digital marketing team.",
    "change_from_full_time": "Move to Part Time Experiences",
    "change_from_part_time": "Move to Full Time Experiences"
}
};window.locales.es = window.locales.es || {};window.locales.es.careers = window.locales.es.careers || {};window.locales.es.careers.edit_career_profile = window.locales.es.careers.edit_career_profile || {};window.locales.es.careers.edit_career_profile.work_experience_detail = window.locales.es.careers.edit_career_profile.work_experience_detail || {};window.locales.es.careers.edit_career_profile.work_experience_detail = {...window.locales.es.careers.edit_career_profile.work_experience_detail, ...{
    "enter_responsibilities": "Por favor, introduzca hasta tres responsabilidades o logros (máximo 75 caracteres cada una):",           
    "enter_responsibilities_non_english": "Ingrese entre 1 y 3 responsabilidades o logros, en inglés (máximo 120 caracteres):",           
    "organization": "Organización",           
    "job_title": "Título del trabajo",           
    "start": "Inicio",           
    "end": "Fin",           
    "add_employment": "Añadir otro Experiencia laboral",
    "responsibilities_placeholder": "Lanzó el esfuerzo de las redes sociales para el equipo de marketing digital.",
    "change_from_full_time": "Pasar a experiencias a tiempo parcial",
    "change_from_part_time": "Pasar a experiencias de tiempo completo",
    "add_employment_first": "Añadir una experiencia de trabajo"
}
};window.locales.it = window.locales.it || {};window.locales.it.careers = window.locales.it.careers || {};window.locales.it.careers.edit_career_profile = window.locales.it.careers.edit_career_profile || {};window.locales.it.careers.edit_career_profile.work_experience_detail = window.locales.it.careers.edit_career_profile.work_experience_detail || {};window.locales.it.careers.edit_career_profile.work_experience_detail = {...window.locales.it.careers.edit_career_profile.work_experience_detail, ...{
    "enter_responsibilities": "Per favore, inserisci fino a 3 responsabilità o conseguimenti (massimo 75 caratteri ciascuno):",           
    "enter_responsibilities_non_english": "Inserisci tra 1 e 3 responsabilità o risultati, in inglese (max 120 caratteri):",           
    "add_employment": "Aggiungi un'altra esperienza lavorativa",           
    "organization": "Organizzazione",           
    "job_title": "Titolo professionale",           
    "start": "Inizio",           
    "end": "Fine",           
    "responsibilities_placeholder": "Lanciato lo sforzo sui social media per il team di marketing digitale.",
    "change_from_full_time": "Passa alle esperienze part-time",
    "change_from_part_time": "Passa alle esperienze a tempo pieno",
    "add_employment_first": "Aggiungi un'esperienza lavorativa"
}
};window.locales.zh = window.locales.zh || {};window.locales.zh.careers = window.locales.zh.careers || {};window.locales.zh.careers.edit_career_profile = window.locales.zh.careers.edit_career_profile || {};window.locales.zh.careers.edit_career_profile.work_experience_detail = window.locales.zh.careers.edit_career_profile.work_experience_detail || {};window.locales.zh.careers.edit_career_profile.work_experience_detail = {...window.locales.zh.careers.edit_career_profile.work_experience_detail, ...{
    "enter_responsibilities": "请输入3项职责或者成就（每项最多75个字符）：",           
    "enter_responsibilities_non_english": "请输入3项职责或者成就。请以英文回答。（每项最多75个字符）：",           
    "organization": "组织",           
    "job_title": "职称",           
    "start": "开始",           
    "end": "结束",           
    "add_employment": "添加另一项工作经历",           
    "responsibilities_placeholder": "为数字营销团队启动社交媒体工作。",           
    "change_from_full_time": "前往兼职工作经历",           
    "change_from_part_time": "前往全职工作经历",           
    "add_employment_first": "添加工作经历"           
}
};